import { ToastId, useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import BaseToast, { ToastType } from '../components/BaseToast';

interface CustomToastProps {
  headerMessage: string;
  toastMessage: string;
}

type CustomToast = (option: CustomToastProps) => ToastId | undefined;

export const useCustomToast = () => {
  const toast = useToast();

  const successToast = useCallback<CustomToast>(
    ({ headerMessage, toastMessage }) =>
      toast({
        containerStyle: { margin: 0 },
        position: 'top-right',
        render: ({ id, onClose }) => (
          <BaseToast
            headerMessage={headerMessage}
            id={id}
            onClose={onClose}
            toastMessage={toastMessage}
            type={ToastType.success}
          />
        ),
      }),
    [toast],
  );

  const warningToast = useCallback<CustomToast>(
    ({ headerMessage, toastMessage }) =>
      toast({
        containerStyle: { margin: 0 },
        position: 'top-right',
        render: ({ id, onClose }) => (
          <BaseToast
            headerMessage={headerMessage}
            id={id}
            onClose={onClose}
            toastMessage={toastMessage}
            type={ToastType.warning}
          />
        ),
      }),
    [toast],
  );

  return {
    successToast,
    warningToast,
  };
};
