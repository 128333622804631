import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Project_User_Display_Status_Enum,
  Project_User_Role_Enum,
  useDeleteProjectMutation,
  useProjectDashboardByIdQuery,
  useProjectTeamQuery,
  useUpdateProjectUserDisplayStatusMutation,
  useUpdateProjectUserMutation,
} from '../generated/graphql';
import { useUserContext } from '../store/contexts/UserContext';
import { useProjectUserDisplayStatus } from './useProjectUserDisplayStatus';
import useUserEmail from './useUserEmail';

const useDeleteAR = () => {
  const { id } = useParams();

  const arId = id || '';

  const email = useUserEmail();

  const { isArchived } = useProjectUserDisplayStatus(arId);

  const [deleteProject] = useDeleteProjectMutation({
    variables: { id: { _eq: arId } },
  });

  const { data: teamMembersData } = useProjectTeamQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      arId,
    },
  });

  const { user, loading } = useUserContext();

  const { data: project } = useProjectDashboardByIdQuery({
    variables: { arId },
  });

  const userId = user?.id;

  const [archiveAR] = useUpdateProjectUserDisplayStatusMutation({
    variables: {
      value: Project_User_Display_Status_Enum.Archive,
      where: {
        project_id: { _eq: arId },
        user_id: { _eq: userId },
      },
    },
  });

  const onArchiveAR = useCallback(async () => {
    await archiveAR();
  }, [archiveAR]);

  const [updateStatus] = useUpdateProjectUserMutation();

  const updateProjectUserStatus = useCallback(
    (status) => {
      updateStatus({
        variables: {
          data: {
            status,
          },
          where: {
            project_id: { _eq: arId },
            user_id: { _eq: userId },
          },
        },
      });
    },
    [arId, updateStatus, userId],
  );

  const pickedAR = useMemo(
    () => project?.project_dashboard[0],
    [project?.project_dashboard],
  );

  const onDelete = useCallback(async () => {
    await deleteProject();
  }, [deleteProject]);

  const isDriver = email === pickedAR?.driver?.email;

  const name = pickedAR?.name;

  const projectStatus = pickedAR?.status;

  const deadline = pickedAR?.deadline?.split('-').reverse().join('.');

  const teamMembers = teamMembersData?.project[0]?.project_users;

  const status = teamMembers?.find(
    (member) => member.user?.id === userId,
  )?.status;

  const sortedTeamMembers = useMemo(
    () =>
      teamMembers
        ? [...teamMembers].sort((user) => {
            if (user.role === Project_User_Role_Enum.Driver) {
              return -1;
            }
            return 1;
          })
        : [],
    [teamMembers],
  );

  const isUserInTeam = teamMembers?.some(
    (member) => member.user.email === email,
  );

  const createdAt = moment(pickedAR?.created_at).format('DD.MM.YYYY');

  return {
    arId,
    createdAt,
    deadline,
    isArchived,
    isDriver,
    isUserInTeam,
    loading,
    name,
    onArchiveAR,
    onDelete,
    pickedAR,
    projectStatus,
    status,
    teamMembers: sortedTeamMembers || [],
    updateProjectUserStatus,
    user,
    userId,
  };
};

export default useDeleteAR;
