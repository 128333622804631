import { find } from 'ramda';
import { Project_User_Role_Enum, useProjectQuery } from '../generated/graphql';
import useUserEmail from './useUserEmail';

export const useIsARDriver = (id: string) => {
  const { data } = useProjectQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
  });

  const email = useUserEmail();

  return !!find(
    (user) =>
      user.role === Project_User_Role_Enum.Driver &&
      user?.user?.email === email,
    data?.project_by_pk?.project_users ?? [],
  );
};
