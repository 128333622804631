import { Button, Flex, Text } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import { SettingsNavigationKeys } from '../navigation/settings';
import { AttentionIcon } from './icons/AttentionIcon';

const TeamSettingsBillingWarning: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const navigate = useNavigate();

  const goBillingDetails = useCallback(
    () =>
      navigate(
        `/${routes.MainRoute.Settings}/${SettingsNavigationKeys.Billing}`,
      ),
    [navigate],
  );

  return (
    <Flex
      alignItems="center"
      bgColor="primary.100"
      borderRadius={8}
      flexDirection="row"
      mb={8}
      p={6}
      w="full"
    >
      <AttentionIcon boxSize={10} color="primary.600" />
      <Flex flex={0.65} ml={4}>
        <Text size="body1" textAlign="center">
          {t('billingWarningInfo')}
        </Text>
      </Flex>
      <Flex flex={0.35} ml={4}>
        <Button onClick={goBillingDetails}>
          {t('billingWarningButtonLabel')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(TeamSettingsBillingWarning);
