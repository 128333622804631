export default {
  checkboxArt: 'Art / Graphics',
  checkboxAudio: 'Audio / Video',
  checkboxLegal: 'Legal',
  checkboxOther: 'Other',
  checkboxPlans: 'Plans',
  checkboxPresentations: 'Presentations',
  checkboxUIUX: 'UX/UI designs',
  checkboxWrittenContent: 'Written content',
  createAR: 'Create my first AR',
  createProject: 'Create my first AR',
  domain: 'Team domain',
  domainAlreadyExists: 'The specified domain already exists',
  domainInvalid: 'Domain invalid',
  domainNotAllowed:
    'Team domain should not include special characters and uppercase letters',
  domainTooShort: 'Team domain should be at least 3 characters',
  firstHeader: 'What types of assets do you typically send for approval?',
  getOthers: `Get others to approve your work so you can finalize it. It's FREE to invite as many Reviewers as you'd like. Plus, during your trial, you can create 3 ARs FREE.`,
  lockInYourTeam: 'Lock in your team, agency, group, brand or company name.',
  next: 'Continue',
  secondHeader: 'What is your team name?',
  skip: 'Skip',
  somethingWentWrong: 'Something went wrong',
  step: 'Step {{current}}/{{max}}',
  teamName: 'Team name',
  teamNameNotAllowed:
    'Team name should include letters, numbers, dashes and underscores only',
  teamNamePlaceholder: 'teamname.ohcompletely.com',
  thirdHeader: 'Create your first Project',
};
