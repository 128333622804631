import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: {
    '*, *::before, &::after': {
      wordWrap: 'break-word',
    },
    body: {
      bgColor: 'gray.global',
      color: 'black.600',
      fontFamily: 'body',
      fontSize: ['xs', 'sm', 'sm', 'md'],
      lineHeight: 'base',
    },
  },
};

export default styles;
