import React, { memo, useCallback, useMemo } from 'react';
import {
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { findLastIndex, values } from 'ramda';
import { FieldInputProps, useFormikContext } from 'formik';
import { LocalesKeys } from '../locales/localesKeys';
import CreateARStep1 from './CreateARStep1';
import CreateARStep2, { ProjectInviteArray } from './CreateARStep2';

import { CreateARStep1Values } from '../utils/formValidations';
import useGoBack from '../hooks/useGoBack';
import TeamGuard from '../navigation/TeamGuard';
import { ARRoutes } from '../hooks/useProjectSubmit';

interface ARStep {
  route: ARRoutes;
  screen: JSX.Element;
  footer: JSX.Element;
}

type Props = {
  onClose: () => void;
  id?: string;
};

const routes = values(ARRoutes);

const ProjectEditor: React.FC<Props> = ({ onClose, id }) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const location = useLocation();

  const currentStep = findLastIndex(
    (step) => location.pathname.endsWith(step.toString()),
    routes,
  );

  const { getFieldProps, handleSubmit, setFieldValue, isValid } =
    useFormikContext<CreateARStep1Values>();

  const goBack = useGoBack();

  const {
    value: projectInvites,
  }: FieldInputProps<CreateARStep1Values['invites']> = getFieldProps('invites');

  const setProjectInvites = useCallback<(value: ProjectInviteArray) => void>(
    (value) => setFieldValue('invites', value),
    [setFieldValue],
  );

  const steps: ARStep[] = useMemo(
    () => [
      {
        footer: (
          <Button disabled={!isValid} type="submit" w={24}>
            {t('submit')}
          </Button>
        ),
        route: ARRoutes.step1,
        screen: <CreateARStep1 editMode={!!id} />,
      },
      {
        footer: (
          <HStack spacing={6}>
            <Button colorScheme="secondary" onClick={goBack} w={28}>
              {t('back')}
            </Button>
            <Button disabled={!isValid} type="submit" w={24}>
              {id ? t('edit') : t('create')}
            </Button>
          </HStack>
        ),
        route: ARRoutes.step2,
        screen: (
          <CreateARStep2
            projectInvites={projectInvites}
            setProjectInvites={setProjectInvites}
          />
        ),
      },
    ],
    [goBack, id, isValid, projectInvites, setProjectInvites, t],
  );

  const renderRoutes = useMemo(
    () =>
      steps.map((step, index) => (
        <Route
          key={step.route}
          element={step.screen}
          index={index === 0}
          path={step.route.toString()}
        />
      )),
    [steps],
  );

  const renderButtons = useMemo(
    () =>
      steps.map((step, index) => (
        <Route
          key={step.route}
          element={step.footer}
          index={index === 0}
          path={step.route.toString()}
        />
      )),
    [steps],
  );

  const Content = useMemo(
    () => (
      <chakra.form
        display="flex"
        flexDirection="column"
        h="full"
        onSubmit={handleSubmit}
        w="full"
      >
        <VStack
          align="flex-start"
          flex={1}
          h="full"
          minH={0}
          overflow="scroll"
          px={10}
          py={10}
          w="full"
        >
          <Flex justify="space-between" w="full">
            <CloseIcon color="black.500" cursor="pointer" onClick={onClose} />
            <Text size="subhead1">{`${t('step')} ${currentStep + 1}`}</Text>
          </Flex>
          <Heading pt={12} size="headline2">
            {id ? t('editHeadline') : t('createHeadline')}
          </Heading>
          <Routes>{renderRoutes}</Routes>
        </VStack>
        <Flex
          bg="white"
          borderTop="1px"
          bottom={0}
          color="black.200"
          justify="flex-end"
          position="sticky"
          px={9}
          py={8}
          w="full"
        >
          <Routes>{renderButtons}</Routes>
        </Flex>
      </chakra.form>
    ),
    [handleSubmit, onClose, t, currentStep, id, renderRoutes, renderButtons],
  );

  return (
    <TeamGuard>
      <VStack w="full">{Content}</VStack>
    </TeamGuard>
  );
};

export default memo(ProjectEditor);
