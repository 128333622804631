import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Team_User_Role_Enum,
  Team_User_Status_Enum,
  useUpdateTeamMutation,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import { useCustomToast } from './useCustomToast';

export const useTeamSettings = () => {
  const { user } = useUserContext();

  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const { successToast, warningToast } = useCustomToast();

  const currentUserId = user?.id || '';

  const [teamNameInputValue, setTeamNameInputValue] = useState<string>();

  const onChangeTeamName = useCallback(
    (e) => setTeamNameInputValue(e.target.value),
    [],
  );

  const { team: currentTeam, loading } = useTeamContext();

  const teamName = currentTeam?.name;

  const teamId = currentTeam?.id || '';

  const [updateTeamName] = useUpdateTeamMutation();

  const onUpdateTeamName = useCallback(async () => {
    try {
      await updateTeamName({
        variables: {
          _set: { name: teamNameInputValue },
          pk_columns: { id: teamId },
        },
      });

      successToast({
        headerMessage: t('teamSettings'),
        toastMessage: t('teamNameUpdated'),
      });

      window.scrollTo({ behavior: 'smooth', top: 0 });
    } catch (e) {
      warningToast({
        headerMessage: t('teamSettings'),
        toastMessage: t('teamNameNotAllowed'),
      });

      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [
    successToast,
    t,
    teamId,
    teamNameInputValue,
    updateTeamName,
    warningToast,
  ]);

  const teamMembers = currentTeam?.team_users || [];

  const teamProjects = currentTeam?.projects || [];

  const isOwner = teamMembers.some(
    (member) =>
      member.role === Team_User_Role_Enum.Owner &&
      member.user.id === currentUserId,
  );

  const isLicensedMemberInTeam = teamMembers.some(
    (member) => member.status === Team_User_Status_Enum.Licensed,
  );

  const isBillingWarningShow = useMemo(
    () =>
      isOwner &&
      isLicensedMemberInTeam &&
      currentTeam?.subscription_status === null,
    [currentTeam?.subscription_status, isLicensedMemberInTeam, isOwner],
  );

  return {
    currentUserId,
    isBillingWarningShow,
    isLicensedMemberInTeam,
    isOwner,
    loading,
    onChangeTeamName,
    onUpdateTeamName,
    setTeamNameInputValue,
    teamId,
    teamMembers,
    teamName,
    teamNameInputValue,
    teamProjects,
  };
};
