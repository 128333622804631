export default {
  approved: 'Approved',
  APPROVED: 'Approved',
  cancelled: 'Cancelled',
  CANCELLED: 'Cancelled',
  draft: 'Draft (Private)',
  notApproved: 'Not approved',
  overdue: 'Overdue',
  REJECTED: 'Not approved',
  UNDER_REVIEW: 'Under review',
  underReview: 'Under review',
};
