import { differenceInSeconds } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import useAppAuth from './useAppAuth';

export enum CodeResponse {
  RESENT = 'RESENT',
  ERROR = 'ERROR',
}

const useResendCode = (email: string | null) => {
  const [codeResponse, setCodeResponse] = useState<CodeResponse>();
  const [timeCodeSent, setTimeCodeSent] = useState<number>(Date.now());
  const [currentTime, setCurrentTime] = useState(Date.now());

  const { sendEmailCode } = useAppAuth();

  const handleResend = useCallback<() => void>(async () => {
    if (!email) return;
    setTimeCodeSent(Date.now());
    const { __typename: typename } = await sendEmailCode(email);

    setCodeResponse(
      typename === 'VerificationPayload'
        ? CodeResponse.RESENT
        : CodeResponse.ERROR,
    );
  }, [email, sendEmailCode]);

  useEffect(() => {
    const listener = setInterval(() => setCurrentTime(Date.now()), 1000);

    return () => clearInterval(listener);
  }, []);

  const timeLeftToSendCode = timeCodeSent
    ? differenceInSeconds(timeCodeSent, currentTime) + 60
    : 0;

  return { codeResponse, handleResend, timeLeftToSendCode };
};

export default useResendCode;
