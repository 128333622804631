import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _numeric: any;
  _text: any;
  bigint: any;
  date: string;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Customer = {
  __typename?: 'Customer';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  payment_method?: Maybe<PaymentMethod>;
  payment_method_id?: Maybe<Scalars['String']>;
};

export type DeletedUser = {
  __typename?: 'DeletedUser';
  id: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  currency: Scalars['String'];
  date: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  total: Scalars['Float'];
};

export enum InvoiceStatus {
  Deleted = 'deleted',
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export type InvoiceWithPdf = {
  __typename?: 'InvoiceWithPDF';
  currency: Scalars['String'];
  date: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoice_pdf: Scalars['String'];
  status: InvoiceStatus;
  total: Scalars['Float'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdatedTeam = {
  __typename?: 'UpdatedTeam';
  id: Scalars['String'];
};

/** Boolean expression to compare columns of type "_numeric". All fields are combined with logical 'AND'. */
export type _Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_numeric']>;
  _gt?: InputMaybe<Scalars['_numeric']>;
  _gte?: InputMaybe<Scalars['_numeric']>;
  _in?: InputMaybe<Array<Scalars['_numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_numeric']>;
  _lte?: InputMaybe<Scalars['_numeric']>;
  _neq?: InputMaybe<Scalars['_numeric']>;
  _nin?: InputMaybe<Array<Scalars['_numeric']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  content_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  expires_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "file" */
export type File_Aggregate = {
  __typename?: 'file_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

/** aggregate fields of "file" */
export type File_Aggregate_Fields = {
  __typename?: 'file_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
};


/** aggregate fields of "file" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "file" */
export enum File_Constraint {
  /** unique or primary key constraint */
  FilePkey = 'file_pkey'
}

/** input type for inserting data into table "file" */
export type File_Insert_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'file_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  expires_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'file_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  expires_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "file" */
export type File_Mutation_Response = {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "file" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on conflict condition type for table "file" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

export type File_Payload = {
  __typename?: 'file_payload';
  /** An object relationship */
  file: File;
  file_id: Scalars['uuid'];
  url: Scalars['String'];
};

/** primary key columns input for table: file */
export type File_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "file" */
export type File_Set_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "file" */
export enum File_Update_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** execute VOLATILE function "check_deadlines" which returns "notification" */
  check_deadlines: Array<Notification>;
  connect_payment_method: Customer;
  create_customer_by_email: Customer;
  delete_account: DeletedUser;
  /** delete data from the table: "file" */
  delete_file?: Maybe<File_Mutation_Response>;
  /** delete single row from the table: "file" */
  delete_file_by_pk?: Maybe<File>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>;
  /** delete data from the table: "notification_action" */
  delete_notification_action?: Maybe<Notification_Action_Mutation_Response>;
  /** delete single row from the table: "notification_action" */
  delete_notification_action_by_pk?: Maybe<Notification_Action>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "notification_destination" */
  delete_notification_destination?: Maybe<Notification_Destination_Mutation_Response>;
  /** delete single row from the table: "notification_destination" */
  delete_notification_destination_by_pk?: Maybe<Notification_Destination>;
  /** delete data from the table: "notification_receiver" */
  delete_notification_receiver?: Maybe<Notification_Receiver_Mutation_Response>;
  /** delete single row from the table: "notification_receiver" */
  delete_notification_receiver_by_pk?: Maybe<Notification_Receiver>;
  /** delete data from the table: "notification_setting" */
  delete_notification_setting?: Maybe<Notification_Setting_Mutation_Response>;
  /** delete single row from the table: "notification_setting" */
  delete_notification_setting_by_pk?: Maybe<Notification_Setting>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<Notification_Type>;
  /** delete data from the table: "project" */
  delete_project?: Maybe<Project_Mutation_Response>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<Project>;
  /** delete data from the table: "project_status" */
  delete_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** delete single row from the table: "project_status" */
  delete_project_status_by_pk?: Maybe<Project_Status>;
  /** delete data from the table: "project_user" */
  delete_project_user?: Maybe<Project_User_Mutation_Response>;
  /** delete single row from the table: "project_user" */
  delete_project_user_by_pk?: Maybe<Project_User>;
  /** delete data from the table: "project_user_display_status" */
  delete_project_user_display_status?: Maybe<Project_User_Display_Status_Mutation_Response>;
  /** delete single row from the table: "project_user_display_status" */
  delete_project_user_display_status_by_pk?: Maybe<Project_User_Display_Status>;
  /** delete data from the table: "project_user_role" */
  delete_project_user_role?: Maybe<Project_User_Role_Mutation_Response>;
  /** delete single row from the table: "project_user_role" */
  delete_project_user_role_by_pk?: Maybe<Project_User_Role>;
  /** delete data from the table: "project_user_status" */
  delete_project_user_status?: Maybe<Project_User_Status_Mutation_Response>;
  /** delete single row from the table: "project_user_status" */
  delete_project_user_status_by_pk?: Maybe<Project_User_Status>;
  /** delete data from the table: "reminder_protocol" */
  delete_reminder_protocol?: Maybe<Reminder_Protocol_Mutation_Response>;
  /** delete single row from the table: "reminder_protocol" */
  delete_reminder_protocol_by_pk?: Maybe<Reminder_Protocol>;
  /** delete data from the table: "reminder_type" */
  delete_reminder_type?: Maybe<Reminder_Type_Mutation_Response>;
  /** delete single row from the table: "reminder_type" */
  delete_reminder_type_by_pk?: Maybe<Reminder_Type>;
  /** delete data from the table: "subscription_status" */
  delete_subscription_status?: Maybe<Subscription_Status_Mutation_Response>;
  /** delete single row from the table: "subscription_status" */
  delete_subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** delete data from the table: "team" */
  delete_team?: Maybe<Team_Mutation_Response>;
  delete_team_and_invoice_now: UpdatedTeam;
  /** delete single row from the table: "team" */
  delete_team_by_pk?: Maybe<Team>;
  /** delete data from the table: "team_name_banned" */
  delete_team_name_banned?: Maybe<Team_Name_Banned_Mutation_Response>;
  /** delete single row from the table: "team_name_banned" */
  delete_team_name_banned_by_pk?: Maybe<Team_Name_Banned>;
  /** delete data from the table: "team_user" */
  delete_team_user?: Maybe<Team_User_Mutation_Response>;
  /** delete single row from the table: "team_user" */
  delete_team_user_by_pk?: Maybe<Team_User>;
  /** delete data from the table: "team_user_role" */
  delete_team_user_role?: Maybe<Team_User_Role_Mutation_Response>;
  /** delete single row from the table: "team_user_role" */
  delete_team_user_role_by_pk?: Maybe<Team_User_Role>;
  /** delete data from the table: "team_user_status" */
  delete_team_user_status?: Maybe<Team_User_Status_Mutation_Response>;
  /** delete single row from the table: "team_user_status" */
  delete_team_user_status_by_pk?: Maybe<Team_User_Status>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "web_push_subscription" */
  delete_web_push_subscription?: Maybe<Web_Push_Subscription_Mutation_Response>;
  /** delete single row from the table: "web_push_subscription" */
  delete_web_push_subscription_by_pk?: Maybe<Web_Push_Subscription>;
  /** insert data into the table: "file" */
  insert_file?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>;
  /** insert data into the table: "notification_action" */
  insert_notification_action?: Maybe<Notification_Action_Mutation_Response>;
  /** insert a single row into the table: "notification_action" */
  insert_notification_action_one?: Maybe<Notification_Action>;
  /** insert data into the table: "notification_destination" */
  insert_notification_destination?: Maybe<Notification_Destination_Mutation_Response>;
  /** insert a single row into the table: "notification_destination" */
  insert_notification_destination_one?: Maybe<Notification_Destination>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "notification_receiver" */
  insert_notification_receiver?: Maybe<Notification_Receiver_Mutation_Response>;
  /** insert a single row into the table: "notification_receiver" */
  insert_notification_receiver_one?: Maybe<Notification_Receiver>;
  /** insert data into the table: "notification_setting" */
  insert_notification_setting?: Maybe<Notification_Setting_Mutation_Response>;
  /** insert a single row into the table: "notification_setting" */
  insert_notification_setting_one?: Maybe<Notification_Setting>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<Notification_Type>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<Project_Mutation_Response>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<Project>;
  /** insert data into the table: "project_status" */
  insert_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** insert a single row into the table: "project_status" */
  insert_project_status_one?: Maybe<Project_Status>;
  /** insert data into the table: "project_user" */
  insert_project_user?: Maybe<Project_User_Mutation_Response>;
  /** insert data into the table: "project_user_display_status" */
  insert_project_user_display_status?: Maybe<Project_User_Display_Status_Mutation_Response>;
  /** insert a single row into the table: "project_user_display_status" */
  insert_project_user_display_status_one?: Maybe<Project_User_Display_Status>;
  /** insert a single row into the table: "project_user" */
  insert_project_user_one?: Maybe<Project_User>;
  /** insert data into the table: "project_user_role" */
  insert_project_user_role?: Maybe<Project_User_Role_Mutation_Response>;
  /** insert a single row into the table: "project_user_role" */
  insert_project_user_role_one?: Maybe<Project_User_Role>;
  /** insert data into the table: "project_user_status" */
  insert_project_user_status?: Maybe<Project_User_Status_Mutation_Response>;
  /** insert a single row into the table: "project_user_status" */
  insert_project_user_status_one?: Maybe<Project_User_Status>;
  /** insert data into the table: "reminder_protocol" */
  insert_reminder_protocol?: Maybe<Reminder_Protocol_Mutation_Response>;
  /** insert a single row into the table: "reminder_protocol" */
  insert_reminder_protocol_one?: Maybe<Reminder_Protocol>;
  /** insert data into the table: "reminder_type" */
  insert_reminder_type?: Maybe<Reminder_Type_Mutation_Response>;
  /** insert a single row into the table: "reminder_type" */
  insert_reminder_type_one?: Maybe<Reminder_Type>;
  /** insert data into the table: "subscription_status" */
  insert_subscription_status?: Maybe<Subscription_Status_Mutation_Response>;
  /** insert a single row into the table: "subscription_status" */
  insert_subscription_status_one?: Maybe<Subscription_Status>;
  /** insert data into the table: "team" */
  insert_team?: Maybe<Team_Mutation_Response>;
  /** insert data into the table: "team_name_banned" */
  insert_team_name_banned?: Maybe<Team_Name_Banned_Mutation_Response>;
  /** insert a single row into the table: "team_name_banned" */
  insert_team_name_banned_one?: Maybe<Team_Name_Banned>;
  /** insert a single row into the table: "team" */
  insert_team_one?: Maybe<Team>;
  /** insert data into the table: "team_user" */
  insert_team_user?: Maybe<Team_User_Mutation_Response>;
  /** insert a single row into the table: "team_user" */
  insert_team_user_one?: Maybe<Team_User>;
  /** insert data into the table: "team_user_role" */
  insert_team_user_role?: Maybe<Team_User_Role_Mutation_Response>;
  /** insert a single row into the table: "team_user_role" */
  insert_team_user_role_one?: Maybe<Team_User_Role>;
  /** insert data into the table: "team_user_status" */
  insert_team_user_status?: Maybe<Team_User_Status_Mutation_Response>;
  /** insert a single row into the table: "team_user_status" */
  insert_team_user_status_one?: Maybe<Team_User_Status>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "web_push_subscription" */
  insert_web_push_subscription?: Maybe<Web_Push_Subscription_Mutation_Response>;
  /** insert a single row into the table: "web_push_subscription" */
  insert_web_push_subscription_one?: Maybe<Web_Push_Subscription>;
  transfer_ownership: UpdatedTeam;
  update_customer_email: Customer;
  update_customer_payment_method: Customer;
  /** update data of the table: "file" */
  update_file?: Maybe<File_Mutation_Response>;
  /** update single row of the table: "file" */
  update_file_by_pk?: Maybe<File>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>;
  /** update data of the table: "notification_action" */
  update_notification_action?: Maybe<Notification_Action_Mutation_Response>;
  /** update single row of the table: "notification_action" */
  update_notification_action_by_pk?: Maybe<Notification_Action>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update data of the table: "notification_destination" */
  update_notification_destination?: Maybe<Notification_Destination_Mutation_Response>;
  /** update single row of the table: "notification_destination" */
  update_notification_destination_by_pk?: Maybe<Notification_Destination>;
  /** update data of the table: "notification_receiver" */
  update_notification_receiver?: Maybe<Notification_Receiver_Mutation_Response>;
  /** update single row of the table: "notification_receiver" */
  update_notification_receiver_by_pk?: Maybe<Notification_Receiver>;
  /** update data of the table: "notification_setting" */
  update_notification_setting?: Maybe<Notification_Setting_Mutation_Response>;
  /** update single row of the table: "notification_setting" */
  update_notification_setting_by_pk?: Maybe<Notification_Setting>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<Notification_Type>;
  /** update data of the table: "project" */
  update_project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<Project>;
  /** update data of the table: "project_status" */
  update_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** update single row of the table: "project_status" */
  update_project_status_by_pk?: Maybe<Project_Status>;
  /** update data of the table: "project_user" */
  update_project_user?: Maybe<Project_User_Mutation_Response>;
  /** update single row of the table: "project_user" */
  update_project_user_by_pk?: Maybe<Project_User>;
  /** update data of the table: "project_user_display_status" */
  update_project_user_display_status?: Maybe<Project_User_Display_Status_Mutation_Response>;
  /** update single row of the table: "project_user_display_status" */
  update_project_user_display_status_by_pk?: Maybe<Project_User_Display_Status>;
  /** update data of the table: "project_user_role" */
  update_project_user_role?: Maybe<Project_User_Role_Mutation_Response>;
  /** update single row of the table: "project_user_role" */
  update_project_user_role_by_pk?: Maybe<Project_User_Role>;
  /** update data of the table: "project_user_status" */
  update_project_user_status?: Maybe<Project_User_Status_Mutation_Response>;
  /** update single row of the table: "project_user_status" */
  update_project_user_status_by_pk?: Maybe<Project_User_Status>;
  /** update data of the table: "reminder_protocol" */
  update_reminder_protocol?: Maybe<Reminder_Protocol_Mutation_Response>;
  /** update single row of the table: "reminder_protocol" */
  update_reminder_protocol_by_pk?: Maybe<Reminder_Protocol>;
  /** update data of the table: "reminder_type" */
  update_reminder_type?: Maybe<Reminder_Type_Mutation_Response>;
  /** update single row of the table: "reminder_type" */
  update_reminder_type_by_pk?: Maybe<Reminder_Type>;
  /** update data of the table: "subscription_status" */
  update_subscription_status?: Maybe<Subscription_Status_Mutation_Response>;
  /** update single row of the table: "subscription_status" */
  update_subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** update data of the table: "team" */
  update_team?: Maybe<Team_Mutation_Response>;
  /** update single row of the table: "team" */
  update_team_by_pk?: Maybe<Team>;
  /** update data of the table: "team_name_banned" */
  update_team_name_banned?: Maybe<Team_Name_Banned_Mutation_Response>;
  /** update single row of the table: "team_name_banned" */
  update_team_name_banned_by_pk?: Maybe<Team_Name_Banned>;
  /** update data of the table: "team_user" */
  update_team_user?: Maybe<Team_User_Mutation_Response>;
  /** update single row of the table: "team_user" */
  update_team_user_by_pk?: Maybe<Team_User>;
  /** update data of the table: "team_user_role" */
  update_team_user_role?: Maybe<Team_User_Role_Mutation_Response>;
  /** update single row of the table: "team_user_role" */
  update_team_user_role_by_pk?: Maybe<Team_User_Role>;
  /** update data of the table: "team_user_status" */
  update_team_user_status?: Maybe<Team_User_Status_Mutation_Response>;
  /** update single row of the table: "team_user_status" */
  update_team_user_status_by_pk?: Maybe<Team_User_Status>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "web_push_subscription" */
  update_web_push_subscription?: Maybe<Web_Push_Subscription_Mutation_Response>;
  /** update single row of the table: "web_push_subscription" */
  update_web_push_subscription_by_pk?: Maybe<Web_Push_Subscription>;
  upload_file?: Maybe<File_Payload>;
};


/** mutation root */
export type Mutation_RootCheck_DeadlinesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootConnect_Payment_MethodArgs = {
  customer_id: Scalars['String'];
  payment_method_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_Customer_By_EmailArgs = {
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  payment_method_id: Scalars['String'];
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  team_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_ActionArgs = {
  where: Notification_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_DestinationArgs = {
  where: Notification_Destination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Destination_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_ReceiverArgs = {
  where: Notification_Receiver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Receiver_By_PkArgs = {
  notification_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_SettingArgs = {
  where: Notification_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_TypeArgs = {
  where: Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectArgs = {
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_StatusArgs = {
  where: Project_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Project_UserArgs = {
  where: Project_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Project_User_Display_StatusArgs = {
  where: Project_User_Display_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_Display_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Project_User_RoleArgs = {
  where: Project_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Project_User_StatusArgs = {
  where: Project_User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reminder_ProtocolArgs = {
  where: Reminder_Protocol_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reminder_Protocol_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reminder_TypeArgs = {
  where: Reminder_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reminder_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_StatusArgs = {
  where: Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TeamArgs = {
  where: Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_And_Invoice_NowArgs = {
  team_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Team_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Team_Name_BannedArgs = {
  where: Team_Name_Banned_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Name_Banned_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Team_UserArgs = {
  where: Team_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_User_By_PkArgs = {
  team_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Team_User_RoleArgs = {
  where: Team_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Team_User_StatusArgs = {
  where: Team_User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Web_Push_SubscriptionArgs = {
  where: Web_Push_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Web_Push_Subscription_By_PkArgs = {
  auth: Scalars['String'];
  browser_fingerprint: Scalars['String'];
  p256dh: Scalars['String'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_ActionArgs = {
  objects: Array<Notification_Action_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Action_OneArgs = {
  object: Notification_Action_Insert_Input;
  on_conflict?: InputMaybe<Notification_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_DestinationArgs = {
  objects: Array<Notification_Destination_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Destination_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Destination_OneArgs = {
  object: Notification_Destination_Insert_Input;
  on_conflict?: InputMaybe<Notification_Destination_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_ReceiverArgs = {
  objects: Array<Notification_Receiver_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Receiver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Receiver_OneArgs = {
  object: Notification_Receiver_Insert_Input;
  on_conflict?: InputMaybe<Notification_Receiver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_SettingArgs = {
  objects: Array<Notification_Setting_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Setting_OneArgs = {
  object: Notification_Setting_Insert_Input;
  on_conflict?: InputMaybe<Notification_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_TypeArgs = {
  objects: Array<Notification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Type_OneArgs = {
  object: Notification_Type_Insert_Input;
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_StatusArgs = {
  objects: Array<Project_Status_Insert_Input>;
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Status_OneArgs = {
  object: Project_Status_Insert_Input;
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_UserArgs = {
  objects: Array<Project_User_Insert_Input>;
  on_conflict?: InputMaybe<Project_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_Display_StatusArgs = {
  objects: Array<Project_User_Display_Status_Insert_Input>;
  on_conflict?: InputMaybe<Project_User_Display_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_Display_Status_OneArgs = {
  object: Project_User_Display_Status_Insert_Input;
  on_conflict?: InputMaybe<Project_User_Display_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_OneArgs = {
  object: Project_User_Insert_Input;
  on_conflict?: InputMaybe<Project_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_RoleArgs = {
  objects: Array<Project_User_Role_Insert_Input>;
  on_conflict?: InputMaybe<Project_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_Role_OneArgs = {
  object: Project_User_Role_Insert_Input;
  on_conflict?: InputMaybe<Project_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_StatusArgs = {
  objects: Array<Project_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Project_User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_Status_OneArgs = {
  object: Project_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Project_User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_ProtocolArgs = {
  objects: Array<Reminder_Protocol_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_Protocol_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_Protocol_OneArgs = {
  object: Reminder_Protocol_Insert_Input;
  on_conflict?: InputMaybe<Reminder_Protocol_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_TypeArgs = {
  objects: Array<Reminder_Type_Insert_Input>;
  on_conflict?: InputMaybe<Reminder_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_Type_OneArgs = {
  object: Reminder_Type_Insert_Input;
  on_conflict?: InputMaybe<Reminder_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_StatusArgs = {
  objects: Array<Subscription_Status_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_Status_OneArgs = {
  object: Subscription_Status_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamArgs = {
  objects: Array<Team_Insert_Input>;
  on_conflict?: InputMaybe<Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Name_BannedArgs = {
  objects: Array<Team_Name_Banned_Insert_Input>;
  on_conflict?: InputMaybe<Team_Name_Banned_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Name_Banned_OneArgs = {
  object: Team_Name_Banned_Insert_Input;
  on_conflict?: InputMaybe<Team_Name_Banned_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_OneArgs = {
  object: Team_Insert_Input;
  on_conflict?: InputMaybe<Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_UserArgs = {
  objects: Array<Team_User_Insert_Input>;
  on_conflict?: InputMaybe<Team_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_OneArgs = {
  object: Team_User_Insert_Input;
  on_conflict?: InputMaybe<Team_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_RoleArgs = {
  objects: Array<Team_User_Role_Insert_Input>;
  on_conflict?: InputMaybe<Team_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_Role_OneArgs = {
  object: Team_User_Role_Insert_Input;
  on_conflict?: InputMaybe<Team_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_StatusArgs = {
  objects: Array<Team_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Team_User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_Status_OneArgs = {
  object: Team_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Team_User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Web_Push_SubscriptionArgs = {
  objects: Array<Web_Push_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Web_Push_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Web_Push_Subscription_OneArgs = {
  object: Web_Push_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Web_Push_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootTransfer_OwnershipArgs = {
  future_owner_user_id: Scalars['String'];
  team_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Customer_EmailArgs = {
  customer_id: Scalars['String'];
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Payment_MethodArgs = {
  city: Scalars['String'];
  country: Scalars['String'];
  customer_id: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  payment_method_id: Scalars['String'];
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpdate_FileArgs = {
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_By_PkArgs = {
  _set?: InputMaybe<File_Set_Input>;
  pk_columns: File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _append?: InputMaybe<Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_ActionArgs = {
  _set?: InputMaybe<Notification_Action_Set_Input>;
  where: Notification_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Action_By_PkArgs = {
  _set?: InputMaybe<Notification_Action_Set_Input>;
  pk_columns: Notification_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _append?: InputMaybe<Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  pk_columns: Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_DestinationArgs = {
  _set?: InputMaybe<Notification_Destination_Set_Input>;
  where: Notification_Destination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Destination_By_PkArgs = {
  _set?: InputMaybe<Notification_Destination_Set_Input>;
  pk_columns: Notification_Destination_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_ReceiverArgs = {
  _set?: InputMaybe<Notification_Receiver_Set_Input>;
  where: Notification_Receiver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Receiver_By_PkArgs = {
  _set?: InputMaybe<Notification_Receiver_Set_Input>;
  pk_columns: Notification_Receiver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_SettingArgs = {
  _set?: InputMaybe<Notification_Setting_Set_Input>;
  where: Notification_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Setting_By_PkArgs = {
  _set?: InputMaybe<Notification_Setting_Set_Input>;
  pk_columns: Notification_Setting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_TypeArgs = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  where: Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Type_By_PkArgs = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  pk_columns: Notification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectArgs = {
  _set?: InputMaybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_By_PkArgs = {
  _set?: InputMaybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_StatusArgs = {
  _set?: InputMaybe<Project_Status_Set_Input>;
  where: Project_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Status_By_PkArgs = {
  _set?: InputMaybe<Project_Status_Set_Input>;
  pk_columns: Project_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_UserArgs = {
  _set?: InputMaybe<Project_User_Set_Input>;
  where: Project_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_By_PkArgs = {
  _set?: InputMaybe<Project_User_Set_Input>;
  pk_columns: Project_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_Display_StatusArgs = {
  _set?: InputMaybe<Project_User_Display_Status_Set_Input>;
  where: Project_User_Display_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_Display_Status_By_PkArgs = {
  _set?: InputMaybe<Project_User_Display_Status_Set_Input>;
  pk_columns: Project_User_Display_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_RoleArgs = {
  _set?: InputMaybe<Project_User_Role_Set_Input>;
  where: Project_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_Role_By_PkArgs = {
  _set?: InputMaybe<Project_User_Role_Set_Input>;
  pk_columns: Project_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_StatusArgs = {
  _set?: InputMaybe<Project_User_Status_Set_Input>;
  where: Project_User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_Status_By_PkArgs = {
  _set?: InputMaybe<Project_User_Status_Set_Input>;
  pk_columns: Project_User_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_ProtocolArgs = {
  _set?: InputMaybe<Reminder_Protocol_Set_Input>;
  where: Reminder_Protocol_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_Protocol_By_PkArgs = {
  _set?: InputMaybe<Reminder_Protocol_Set_Input>;
  pk_columns: Reminder_Protocol_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_TypeArgs = {
  _set?: InputMaybe<Reminder_Type_Set_Input>;
  where: Reminder_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_Type_By_PkArgs = {
  _set?: InputMaybe<Reminder_Type_Set_Input>;
  pk_columns: Reminder_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_StatusArgs = {
  _set?: InputMaybe<Subscription_Status_Set_Input>;
  where: Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_Status_By_PkArgs = {
  _set?: InputMaybe<Subscription_Status_Set_Input>;
  pk_columns: Subscription_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeamArgs = {
  _set?: InputMaybe<Team_Set_Input>;
  where: Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_By_PkArgs = {
  _set?: InputMaybe<Team_Set_Input>;
  pk_columns: Team_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Name_BannedArgs = {
  _set?: InputMaybe<Team_Name_Banned_Set_Input>;
  where: Team_Name_Banned_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Name_Banned_By_PkArgs = {
  _set?: InputMaybe<Team_Name_Banned_Set_Input>;
  pk_columns: Team_Name_Banned_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_UserArgs = {
  _set?: InputMaybe<Team_User_Set_Input>;
  where: Team_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_By_PkArgs = {
  _set?: InputMaybe<Team_User_Set_Input>;
  pk_columns: Team_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_RoleArgs = {
  _set?: InputMaybe<Team_User_Role_Set_Input>;
  where: Team_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Role_By_PkArgs = {
  _set?: InputMaybe<Team_User_Role_Set_Input>;
  pk_columns: Team_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_StatusArgs = {
  _set?: InputMaybe<Team_User_Status_Set_Input>;
  where: Team_User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Status_By_PkArgs = {
  _set?: InputMaybe<Team_User_Status_Set_Input>;
  pk_columns: Team_User_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Web_Push_SubscriptionArgs = {
  _set?: InputMaybe<Web_Push_Subscription_Set_Input>;
  where: Web_Push_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Web_Push_Subscription_By_PkArgs = {
  _set?: InputMaybe<Web_Push_Subscription_Set_Input>;
  pk_columns: Web_Push_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpload_FileArgs = {
  content_type: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "notification" */
export type Notification = {
  __typename?: 'notification';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  new?: Maybe<Scalars['jsonb']>;
  old?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  receivers: Array<Notification_Receiver>;
  /** An aggregate relationship */
  receivers_aggregate: Notification_Receiver_Aggregate;
  sender: Scalars['String'];
  target?: Maybe<Scalars['String']>;
  type: Notification_Type_Enum;
  /** An object relationship */
  user: User;
};


/** columns and relationships of "notification" */
export type NotificationNewArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "notification" */
export type NotificationOldArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "notification" */
export type NotificationReceiversArgs = {
  distinct_on?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Receiver_Order_By>>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};


/** columns and relationships of "notification" */
export type NotificationReceivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Receiver_Order_By>>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};

/** columns and relationships of "notification_action" */
export type Notification_Action = {
  __typename?: 'notification_action';
  value: Scalars['String'];
};

/** aggregated selection of "notification_action" */
export type Notification_Action_Aggregate = {
  __typename?: 'notification_action_aggregate';
  aggregate?: Maybe<Notification_Action_Aggregate_Fields>;
  nodes: Array<Notification_Action>;
};

/** aggregate fields of "notification_action" */
export type Notification_Action_Aggregate_Fields = {
  __typename?: 'notification_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Action_Max_Fields>;
  min?: Maybe<Notification_Action_Min_Fields>;
};


/** aggregate fields of "notification_action" */
export type Notification_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_action". All fields are combined with a logical 'AND'. */
export type Notification_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Action_Bool_Exp>>;
  _not?: InputMaybe<Notification_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Action_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_action" */
export enum Notification_Action_Constraint {
  /** unique or primary key constraint */
  NotificationActionPkey = 'notification_action_pkey'
}

export enum Notification_Action_Enum {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

/** Boolean expression to compare columns of type "notification_action_enum". All fields are combined with logical 'AND'. */
export type Notification_Action_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Action_Enum>;
  _in?: InputMaybe<Array<Notification_Action_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Action_Enum>;
  _nin?: InputMaybe<Array<Notification_Action_Enum>>;
};

/** input type for inserting data into table "notification_action" */
export type Notification_Action_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Action_Max_Fields = {
  __typename?: 'notification_action_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Action_Min_Fields = {
  __typename?: 'notification_action_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_action" */
export type Notification_Action_Mutation_Response = {
  __typename?: 'notification_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Action>;
};

/** input type for inserting object relation for remote table "notification_action" */
export type Notification_Action_Obj_Rel_Insert_Input = {
  data: Notification_Action_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Notification_Action_On_Conflict>;
};

/** on conflict condition type for table "notification_action" */
export type Notification_Action_On_Conflict = {
  constraint: Notification_Action_Constraint;
  update_columns?: Array<Notification_Action_Update_Column>;
  where?: InputMaybe<Notification_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_action". */
export type Notification_Action_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_action */
export type Notification_Action_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "notification_action" */
export enum Notification_Action_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_action" */
export type Notification_Action_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_action" */
export enum Notification_Action_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "notification" */
export type Notification_Aggregate = {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<Notification_Aggregate_Fields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Max_Fields>;
  min?: Maybe<Notification_Min_Fields>;
};


/** aggregate fields of "notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notification_Append_Input = {
  new?: InputMaybe<Scalars['jsonb']>;
  old?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Bool_Exp>>;
  _not?: InputMaybe<Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  new?: InputMaybe<Jsonb_Comparison_Exp>;
  old?: InputMaybe<Jsonb_Comparison_Exp>;
  receivers?: InputMaybe<Notification_Receiver_Bool_Exp>;
  sender?: InputMaybe<String_Comparison_Exp>;
  target?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Notification_Type_Enum_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint */
  NotificationsPkey = 'notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notification_Delete_At_Path_Input = {
  new?: InputMaybe<Array<Scalars['String']>>;
  old?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notification_Delete_Elem_Input = {
  new?: InputMaybe<Scalars['Int']>;
  old?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notification_Delete_Key_Input = {
  new?: InputMaybe<Scalars['String']>;
  old?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "notification_destination" */
export type Notification_Destination = {
  __typename?: 'notification_destination';
  value: Scalars['String'];
};

/** aggregated selection of "notification_destination" */
export type Notification_Destination_Aggregate = {
  __typename?: 'notification_destination_aggregate';
  aggregate?: Maybe<Notification_Destination_Aggregate_Fields>;
  nodes: Array<Notification_Destination>;
};

/** aggregate fields of "notification_destination" */
export type Notification_Destination_Aggregate_Fields = {
  __typename?: 'notification_destination_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Destination_Max_Fields>;
  min?: Maybe<Notification_Destination_Min_Fields>;
};


/** aggregate fields of "notification_destination" */
export type Notification_Destination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Destination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_destination". All fields are combined with a logical 'AND'. */
export type Notification_Destination_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Destination_Bool_Exp>>;
  _not?: InputMaybe<Notification_Destination_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Destination_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_destination" */
export enum Notification_Destination_Constraint {
  /** unique or primary key constraint */
  NotificationDestinationPkey = 'notification_destination_pkey'
}

export enum Notification_Destination_Enum {
  Email = 'EMAIL',
  Push = 'PUSH',
  Slack = 'SLACK'
}

/** Boolean expression to compare columns of type "notification_destination_enum". All fields are combined with logical 'AND'. */
export type Notification_Destination_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Destination_Enum>;
  _in?: InputMaybe<Array<Notification_Destination_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Destination_Enum>;
  _nin?: InputMaybe<Array<Notification_Destination_Enum>>;
};

/** input type for inserting data into table "notification_destination" */
export type Notification_Destination_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Destination_Max_Fields = {
  __typename?: 'notification_destination_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Destination_Min_Fields = {
  __typename?: 'notification_destination_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_destination" */
export type Notification_Destination_Mutation_Response = {
  __typename?: 'notification_destination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Destination>;
};

/** input type for inserting object relation for remote table "notification_destination" */
export type Notification_Destination_Obj_Rel_Insert_Input = {
  data: Notification_Destination_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Notification_Destination_On_Conflict>;
};

/** on conflict condition type for table "notification_destination" */
export type Notification_Destination_On_Conflict = {
  constraint: Notification_Destination_Constraint;
  update_columns?: Array<Notification_Destination_Update_Column>;
  where?: InputMaybe<Notification_Destination_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_destination". */
export type Notification_Destination_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_destination */
export type Notification_Destination_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "notification_destination" */
export enum Notification_Destination_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_destination" */
export type Notification_Destination_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_destination" */
export enum Notification_Destination_Update_Column {
  /** column name */
  Value = 'value'
}

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  new?: InputMaybe<Scalars['jsonb']>;
  old?: InputMaybe<Scalars['jsonb']>;
  receivers?: InputMaybe<Notification_Receiver_Arr_Rel_Insert_Input>;
  sender?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Notification_Type_Enum>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sender?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sender?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** input type for inserting object relation for remote table "notification" */
export type Notification_Obj_Rel_Insert_Input = {
  data: Notification_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};

/** on conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns?: Array<Notification_Update_Column>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new?: InputMaybe<Order_By>;
  old?: InputMaybe<Order_By>;
  receivers_aggregate?: InputMaybe<Notification_Receiver_Aggregate_Order_By>;
  sender?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notification_Prepend_Input = {
  new?: InputMaybe<Scalars['jsonb']>;
  old?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "notification_receiver" */
export type Notification_Receiver = {
  __typename?: 'notification_receiver';
  checked_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  notification: Notification;
  notification_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "notification_receiver" */
export type Notification_Receiver_Aggregate = {
  __typename?: 'notification_receiver_aggregate';
  aggregate?: Maybe<Notification_Receiver_Aggregate_Fields>;
  nodes: Array<Notification_Receiver>;
};

/** aggregate fields of "notification_receiver" */
export type Notification_Receiver_Aggregate_Fields = {
  __typename?: 'notification_receiver_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Receiver_Max_Fields>;
  min?: Maybe<Notification_Receiver_Min_Fields>;
};


/** aggregate fields of "notification_receiver" */
export type Notification_Receiver_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notification_receiver" */
export type Notification_Receiver_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Receiver_Max_Order_By>;
  min?: InputMaybe<Notification_Receiver_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_receiver" */
export type Notification_Receiver_Arr_Rel_Insert_Input = {
  data: Array<Notification_Receiver_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Notification_Receiver_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_receiver". All fields are combined with a logical 'AND'. */
export type Notification_Receiver_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Receiver_Bool_Exp>>;
  _not?: InputMaybe<Notification_Receiver_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Receiver_Bool_Exp>>;
  checked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  notification?: InputMaybe<Notification_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_receiver" */
export enum Notification_Receiver_Constraint {
  /** unique or primary key constraint */
  NotificationReceiverPkey = 'notification_receiver_pkey'
}

/** input type for inserting data into table "notification_receiver" */
export type Notification_Receiver_Insert_Input = {
  checked_at?: InputMaybe<Scalars['timestamptz']>;
  notification?: InputMaybe<Notification_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Receiver_Max_Fields = {
  __typename?: 'notification_receiver_max_fields';
  checked_at?: Maybe<Scalars['timestamptz']>;
  notification_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "notification_receiver" */
export type Notification_Receiver_Max_Order_By = {
  checked_at?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Receiver_Min_Fields = {
  __typename?: 'notification_receiver_min_fields';
  checked_at?: Maybe<Scalars['timestamptz']>;
  notification_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "notification_receiver" */
export type Notification_Receiver_Min_Order_By = {
  checked_at?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_receiver" */
export type Notification_Receiver_Mutation_Response = {
  __typename?: 'notification_receiver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Receiver>;
};

/** on conflict condition type for table "notification_receiver" */
export type Notification_Receiver_On_Conflict = {
  constraint: Notification_Receiver_Constraint;
  update_columns?: Array<Notification_Receiver_Update_Column>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_receiver". */
export type Notification_Receiver_Order_By = {
  checked_at?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notification_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_receiver */
export type Notification_Receiver_Pk_Columns_Input = {
  notification_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "notification_receiver" */
export enum Notification_Receiver_Select_Column {
  /** column name */
  CheckedAt = 'checked_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "notification_receiver" */
export type Notification_Receiver_Set_Input = {
  checked_at?: InputMaybe<Scalars['timestamptz']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_receiver" */
export enum Notification_Receiver_Update_Column {
  /** column name */
  CheckedAt = 'checked_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UserId = 'user_id'
}

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  New = 'new',
  /** column name */
  Old = 'old',
  /** column name */
  Sender = 'sender',
  /** column name */
  Target = 'target',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  new?: InputMaybe<Scalars['jsonb']>;
  old?: InputMaybe<Scalars['jsonb']>;
  sender?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Notification_Type_Enum>;
};

/** columns and relationships of "notification_setting" */
export type Notification_Setting = {
  __typename?: 'notification_setting';
  active: Scalars['Boolean'];
  field: Scalars['String'];
  id: Scalars['uuid'];
  notification_action: Notification_Action_Enum;
  notification_destination: Notification_Destination_Enum;
  notification_type: Notification_Type_Enum;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "notification_setting" */
export type Notification_Setting_Aggregate = {
  __typename?: 'notification_setting_aggregate';
  aggregate?: Maybe<Notification_Setting_Aggregate_Fields>;
  nodes: Array<Notification_Setting>;
};

/** aggregate fields of "notification_setting" */
export type Notification_Setting_Aggregate_Fields = {
  __typename?: 'notification_setting_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Setting_Max_Fields>;
  min?: Maybe<Notification_Setting_Min_Fields>;
};


/** aggregate fields of "notification_setting" */
export type Notification_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_setting". All fields are combined with a logical 'AND'. */
export type Notification_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Setting_Bool_Exp>>;
  _not?: InputMaybe<Notification_Setting_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Setting_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  field?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_action?: InputMaybe<Notification_Action_Enum_Comparison_Exp>;
  notification_destination?: InputMaybe<Notification_Destination_Enum_Comparison_Exp>;
  notification_type?: InputMaybe<Notification_Type_Enum_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_setting" */
export enum Notification_Setting_Constraint {
  /** unique or primary key constraint */
  NotificationSettingPkey = 'notification_setting_pkey',
  /** unique or primary key constraint */
  NotificationSettingUniqConstraint = 'notification_setting_uniq_constraint'
}

/** input type for inserting data into table "notification_setting" */
export type Notification_Setting_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_action?: InputMaybe<Notification_Action_Enum>;
  notification_destination?: InputMaybe<Notification_Destination_Enum>;
  notification_type?: InputMaybe<Notification_Type_Enum>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Setting_Max_Fields = {
  __typename?: 'notification_setting_max_fields';
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Setting_Min_Fields = {
  __typename?: 'notification_setting_min_fields';
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_setting" */
export type Notification_Setting_Mutation_Response = {
  __typename?: 'notification_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Setting>;
};

/** on conflict condition type for table "notification_setting" */
export type Notification_Setting_On_Conflict = {
  constraint: Notification_Setting_Constraint;
  update_columns?: Array<Notification_Setting_Update_Column>;
  where?: InputMaybe<Notification_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_setting". */
export type Notification_Setting_Order_By = {
  active?: InputMaybe<Order_By>;
  field?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_action?: InputMaybe<Order_By>;
  notification_destination?: InputMaybe<Order_By>;
  notification_type?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_setting */
export type Notification_Setting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notification_setting" */
export enum Notification_Setting_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Field = 'field',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationAction = 'notification_action',
  /** column name */
  NotificationDestination = 'notification_destination',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "notification_setting" */
export type Notification_Setting_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_action?: InputMaybe<Notification_Action_Enum>;
  notification_destination?: InputMaybe<Notification_Destination_Enum>;
  notification_type?: InputMaybe<Notification_Type_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_setting" */
export enum Notification_Setting_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Field = 'field',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationAction = 'notification_action',
  /** column name */
  NotificationDestination = 'notification_destination',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  UserId = 'user_id'
}

/**
 * notification_type
 *
 *
 * columns and relationships of "notification_type"
 *
 */
export type Notification_Type = {
  __typename?: 'notification_type';
  value: Scalars['String'];
};

/** aggregated selection of "notification_type" */
export type Notification_Type_Aggregate = {
  __typename?: 'notification_type_aggregate';
  aggregate?: Maybe<Notification_Type_Aggregate_Fields>;
  nodes: Array<Notification_Type>;
};

/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_Fields = {
  __typename?: 'notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Type_Max_Fields>;
  min?: Maybe<Notification_Type_Min_Fields>;
};


/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export type Notification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  _not?: InputMaybe<Notification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_type" */
export enum Notification_Type_Constraint {
  /** unique or primary key constraint */
  NotificationTypePkey = 'notification_type_pkey'
}

export enum Notification_Type_Enum {
  Project = 'project',
  ProjectAllApproversApproved = 'project_all_approvers_approved',
  ProjectApproved = 'project_approved',
  ProjectOverdueApprover = 'project_overdue_approver',
  ProjectOverdueDriver = 'project_overdue_driver',
  ProjectReminder = 'project_reminder',
  ProjectUser = 'project_user',
  TeamUser = 'team_user'
}

/** Boolean expression to compare columns of type "notification_type_enum". All fields are combined with logical 'AND'. */
export type Notification_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Type_Enum>;
  _in?: InputMaybe<Array<Notification_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Type_Enum>;
  _nin?: InputMaybe<Array<Notification_Type_Enum>>;
};

/** input type for inserting data into table "notification_type" */
export type Notification_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Type_Max_Fields = {
  __typename?: 'notification_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Type_Min_Fields = {
  __typename?: 'notification_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_type" */
export type Notification_Type_Mutation_Response = {
  __typename?: 'notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Type>;
};

/** input type for inserting object relation for remote table "notification_type" */
export type Notification_Type_Obj_Rel_Insert_Input = {
  data: Notification_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};

/** on conflict condition type for table "notification_type" */
export type Notification_Type_On_Conflict = {
  constraint: Notification_Type_Constraint;
  update_columns?: Array<Notification_Type_Update_Column>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_type". */
export type Notification_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_type */
export type Notification_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "notification_type" */
export enum Notification_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_type" */
export type Notification_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_type" */
export enum Notification_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  New = 'new',
  /** column name */
  Old = 'old',
  /** column name */
  Sender = 'sender',
  /** column name */
  Target = 'target',
  /** column name */
  Type = 'type'
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "project" */
export type Project = {
  __typename?: 'project';
  created_at: Scalars['timestamptz'];
  deadline: Scalars['date'];
  external_link?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  link: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  /** An object relationship */
  reminder_protocol?: Maybe<Reminder_Protocol>;
  reminder_protocol_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Project_Status_Enum>;
  /** An object relationship */
  team: Team;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "project" */
export type ProjectProject_UsersArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "project" */
export type ProjectProject_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** aggregated selection of "project" */
export type Project_Aggregate = {
  __typename?: 'project_aggregate';
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
  __typename?: 'project_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
};


/** aggregate fields of "project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project" */
export type Project_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Bool_Exp>>;
  _not?: InputMaybe<Project_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deadline?: InputMaybe<Date_Comparison_Exp>;
  external_link?: InputMaybe<String_Comparison_Exp>;
  external_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_users?: InputMaybe<Project_User_Bool_Exp>;
  reminder_protocol?: InputMaybe<Reminder_Protocol_Bool_Exp>;
  reminder_protocol_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Project_Status_Enum_Comparison_Exp>;
  team?: InputMaybe<Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
  /** unique or primary key constraint */
  ProjectNameTeamIdKey = 'project_name_team_id_key',
  /** unique or primary key constraint */
  ProjectPkey = 'project_pkey'
}

/** columns and relationships of "project_dashboard" */
export type Project_Dashboard = {
  __typename?: 'project_dashboard';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['date']>;
  display_status?: Maybe<Scalars['String']>;
  /** An object relationship */
  driver?: Maybe<User>;
  driver_id?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_dashboard" */
export type Project_Dashboard_Aggregate = {
  __typename?: 'project_dashboard_aggregate';
  aggregate?: Maybe<Project_Dashboard_Aggregate_Fields>;
  nodes: Array<Project_Dashboard>;
};

/** aggregate fields of "project_dashboard" */
export type Project_Dashboard_Aggregate_Fields = {
  __typename?: 'project_dashboard_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Dashboard_Max_Fields>;
  min?: Maybe<Project_Dashboard_Min_Fields>;
};


/** aggregate fields of "project_dashboard" */
export type Project_Dashboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Dashboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_dashboard". All fields are combined with a logical 'AND'. */
export type Project_Dashboard_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Dashboard_Bool_Exp>>;
  _not?: InputMaybe<Project_Dashboard_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Dashboard_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deadline?: InputMaybe<Date_Comparison_Exp>;
  display_status?: InputMaybe<String_Comparison_Exp>;
  driver?: InputMaybe<User_Bool_Exp>;
  driver_id?: InputMaybe<String_Comparison_Exp>;
  external_link?: InputMaybe<String_Comparison_Exp>;
  external_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_role?: InputMaybe<String_Comparison_Exp>;
  user_status?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Project_Dashboard_Max_Fields = {
  __typename?: 'project_dashboard_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['date']>;
  display_status?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Dashboard_Min_Fields = {
  __typename?: 'project_dashboard_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['date']>;
  display_status?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  external_link?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "project_dashboard". */
export type Project_Dashboard_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  display_status?: InputMaybe<Order_By>;
  driver?: InputMaybe<User_Order_By>;
  driver_id?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  external_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_role?: InputMaybe<Order_By>;
  user_status?: InputMaybe<Order_By>;
};

/** select columns of table "project_dashboard" */
export enum Project_Dashboard_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  DisplayStatus = 'display_status',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  ExternalName = 'external_name',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRole = 'user_role',
  /** column name */
  UserStatus = 'user_status'
}

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deadline?: InputMaybe<Scalars['date']>;
  external_link?: InputMaybe<Scalars['String']>;
  external_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  project_users?: InputMaybe<Project_User_Arr_Rel_Insert_Input>;
  reminder_protocol?: InputMaybe<Reminder_Protocol_Obj_Rel_Insert_Input>;
  reminder_protocol_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Project_Status_Enum>;
  team?: InputMaybe<Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: 'project_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['date']>;
  external_link?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reminder_protocol_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project" */
export type Project_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  external_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reminder_protocol_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: 'project_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['date']>;
  external_link?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reminder_protocol_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project" */
export type Project_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  external_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reminder_protocol_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
  __typename?: 'project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** on conflict condition type for table "project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns?: Array<Project_Update_Column>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deadline?: InputMaybe<Order_By>;
  external_link?: InputMaybe<Order_By>;
  external_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_users_aggregate?: InputMaybe<Project_User_Aggregate_Order_By>;
  reminder_protocol?: InputMaybe<Reminder_Protocol_Order_By>;
  reminder_protocol_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project" */
export enum Project_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  ExternalName = 'external_name',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  ReminderProtocolId = 'reminder_protocol_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "project" */
export type Project_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deadline?: InputMaybe<Scalars['date']>;
  external_link?: InputMaybe<Scalars['String']>;
  external_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reminder_protocol_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Project_Status_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "project_status" */
export type Project_Status = {
  __typename?: 'project_status';
  value: Scalars['String'];
};

/** aggregated selection of "project_status" */
export type Project_Status_Aggregate = {
  __typename?: 'project_status_aggregate';
  aggregate?: Maybe<Project_Status_Aggregate_Fields>;
  nodes: Array<Project_Status>;
};

/** aggregate fields of "project_status" */
export type Project_Status_Aggregate_Fields = {
  __typename?: 'project_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Status_Max_Fields>;
  min?: Maybe<Project_Status_Min_Fields>;
};


/** aggregate fields of "project_status" */
export type Project_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_status". All fields are combined with a logical 'AND'. */
export type Project_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Status_Bool_Exp>>;
  _not?: InputMaybe<Project_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_status" */
export enum Project_Status_Constraint {
  /** unique or primary key constraint */
  ProjectStatusPkey = 'project_status_pkey'
}

export enum Project_Status_Enum {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Overdue = 'OVERDUE',
  Rejected = 'REJECTED',
  UnderReview = 'UNDER_REVIEW'
}

/** Boolean expression to compare columns of type "project_status_enum". All fields are combined with logical 'AND'. */
export type Project_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_Status_Enum>;
  _in?: InputMaybe<Array<Project_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_Status_Enum>;
  _nin?: InputMaybe<Array<Project_Status_Enum>>;
};

/** input type for inserting data into table "project_status" */
export type Project_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Status_Max_Fields = {
  __typename?: 'project_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Status_Min_Fields = {
  __typename?: 'project_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_status" */
export type Project_Status_Mutation_Response = {
  __typename?: 'project_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Status>;
};

/** on conflict condition type for table "project_status" */
export type Project_Status_On_Conflict = {
  constraint: Project_Status_Constraint;
  update_columns?: Array<Project_Status_Update_Column>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "project_status". */
export type Project_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_status */
export type Project_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "project_status" */
export enum Project_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "project_status" */
export type Project_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_status" */
export enum Project_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "project" */
export enum Project_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  ExternalName = 'external_name',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  ReminderProtocolId = 'reminder_protocol_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id'
}

/** columns and relationships of "project_user" */
export type Project_User = {
  __typename?: 'project_user';
  display_status: Project_User_Display_Status_Enum;
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  role: Project_User_Role_Enum;
  status: Project_User_Status_Enum;
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "project_user" */
export type Project_User_Aggregate = {
  __typename?: 'project_user_aggregate';
  aggregate?: Maybe<Project_User_Aggregate_Fields>;
  nodes: Array<Project_User>;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_Fields = {
  __typename?: 'project_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_User_Max_Fields>;
  min?: Maybe<Project_User_Min_Fields>;
};


/** aggregate fields of "project_user" */
export type Project_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_user" */
export type Project_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_User_Max_Order_By>;
  min?: InputMaybe<Project_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_user" */
export type Project_User_Arr_Rel_Insert_Input = {
  data: Array<Project_User_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Project_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_user". All fields are combined with a logical 'AND'. */
export type Project_User_Bool_Exp = {
  _and?: InputMaybe<Array<Project_User_Bool_Exp>>;
  _not?: InputMaybe<Project_User_Bool_Exp>;
  _or?: InputMaybe<Array<Project_User_Bool_Exp>>;
  display_status?: InputMaybe<Project_User_Display_Status_Enum_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Project_User_Role_Enum_Comparison_Exp>;
  status?: InputMaybe<Project_User_Status_Enum_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user" */
export enum Project_User_Constraint {
  /** unique or primary key constraint */
  ProjectUserPkey = 'project_user_pkey'
}

/** columns and relationships of "project_user_display_status" */
export type Project_User_Display_Status = {
  __typename?: 'project_user_display_status';
  value: Scalars['String'];
};

/** aggregated selection of "project_user_display_status" */
export type Project_User_Display_Status_Aggregate = {
  __typename?: 'project_user_display_status_aggregate';
  aggregate?: Maybe<Project_User_Display_Status_Aggregate_Fields>;
  nodes: Array<Project_User_Display_Status>;
};

/** aggregate fields of "project_user_display_status" */
export type Project_User_Display_Status_Aggregate_Fields = {
  __typename?: 'project_user_display_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_User_Display_Status_Max_Fields>;
  min?: Maybe<Project_User_Display_Status_Min_Fields>;
};


/** aggregate fields of "project_user_display_status" */
export type Project_User_Display_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_User_Display_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_user_display_status". All fields are combined with a logical 'AND'. */
export type Project_User_Display_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Project_User_Display_Status_Bool_Exp>>;
  _not?: InputMaybe<Project_User_Display_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Project_User_Display_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user_display_status" */
export enum Project_User_Display_Status_Constraint {
  /** unique or primary key constraint */
  ProjectUserDisplayStatusPkey = 'project_user_display_status_pkey'
}

export enum Project_User_Display_Status_Enum {
  Archive = 'ARCHIVE',
  Dashboard = 'DASHBOARD'
}

/** Boolean expression to compare columns of type "project_user_display_status_enum". All fields are combined with logical 'AND'. */
export type Project_User_Display_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_User_Display_Status_Enum>;
  _in?: InputMaybe<Array<Project_User_Display_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_User_Display_Status_Enum>;
  _nin?: InputMaybe<Array<Project_User_Display_Status_Enum>>;
};

/** input type for inserting data into table "project_user_display_status" */
export type Project_User_Display_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_User_Display_Status_Max_Fields = {
  __typename?: 'project_user_display_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_User_Display_Status_Min_Fields = {
  __typename?: 'project_user_display_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_user_display_status" */
export type Project_User_Display_Status_Mutation_Response = {
  __typename?: 'project_user_display_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User_Display_Status>;
};

/** on conflict condition type for table "project_user_display_status" */
export type Project_User_Display_Status_On_Conflict = {
  constraint: Project_User_Display_Status_Constraint;
  update_columns?: Array<Project_User_Display_Status_Update_Column>;
  where?: InputMaybe<Project_User_Display_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user_display_status". */
export type Project_User_Display_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_user_display_status */
export type Project_User_Display_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "project_user_display_status" */
export enum Project_User_Display_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "project_user_display_status" */
export type Project_User_Display_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_user_display_status" */
export enum Project_User_Display_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** input type for inserting data into table "project_user" */
export type Project_User_Insert_Input = {
  display_status?: InputMaybe<Project_User_Display_Status_Enum>;
  note?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Project_User_Role_Enum>;
  status?: InputMaybe<Project_User_Status_Enum>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_User_Max_Fields = {
  __typename?: 'project_user_max_fields';
  note?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_user" */
export type Project_User_Max_Order_By = {
  note?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_User_Min_Fields = {
  __typename?: 'project_user_min_fields';
  note?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_user" */
export type Project_User_Min_Order_By = {
  note?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_user" */
export type Project_User_Mutation_Response = {
  __typename?: 'project_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User>;
};

/** on conflict condition type for table "project_user" */
export type Project_User_On_Conflict = {
  constraint: Project_User_Constraint;
  update_columns?: Array<Project_User_Update_Column>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user". */
export type Project_User_Order_By = {
  display_status?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_user */
export type Project_User_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** columns and relationships of "project_user_role" */
export type Project_User_Role = {
  __typename?: 'project_user_role';
  value: Scalars['String'];
};

/** aggregated selection of "project_user_role" */
export type Project_User_Role_Aggregate = {
  __typename?: 'project_user_role_aggregate';
  aggregate?: Maybe<Project_User_Role_Aggregate_Fields>;
  nodes: Array<Project_User_Role>;
};

/** aggregate fields of "project_user_role" */
export type Project_User_Role_Aggregate_Fields = {
  __typename?: 'project_user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_User_Role_Max_Fields>;
  min?: Maybe<Project_User_Role_Min_Fields>;
};


/** aggregate fields of "project_user_role" */
export type Project_User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_user_role". All fields are combined with a logical 'AND'. */
export type Project_User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Project_User_Role_Bool_Exp>>;
  _not?: InputMaybe<Project_User_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Project_User_Role_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user_role" */
export enum Project_User_Role_Constraint {
  /** unique or primary key constraint */
  ProjectUserRolePkey = 'project_user_role_pkey'
}

export enum Project_User_Role_Enum {
  Approver = 'APPROVER',
  Contributor = 'CONTRIBUTOR',
  Driver = 'DRIVER',
  Informed = 'INFORMED'
}

/** Boolean expression to compare columns of type "project_user_role_enum". All fields are combined with logical 'AND'. */
export type Project_User_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_User_Role_Enum>;
  _in?: InputMaybe<Array<Project_User_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_User_Role_Enum>;
  _nin?: InputMaybe<Array<Project_User_Role_Enum>>;
};

/** input type for inserting data into table "project_user_role" */
export type Project_User_Role_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_User_Role_Max_Fields = {
  __typename?: 'project_user_role_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_User_Role_Min_Fields = {
  __typename?: 'project_user_role_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_user_role" */
export type Project_User_Role_Mutation_Response = {
  __typename?: 'project_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User_Role>;
};

/** on conflict condition type for table "project_user_role" */
export type Project_User_Role_On_Conflict = {
  constraint: Project_User_Role_Constraint;
  update_columns?: Array<Project_User_Role_Update_Column>;
  where?: InputMaybe<Project_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user_role". */
export type Project_User_Role_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_user_role */
export type Project_User_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "project_user_role" */
export enum Project_User_Role_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "project_user_role" */
export type Project_User_Role_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_user_role" */
export enum Project_User_Role_Update_Column {
  /** column name */
  Value = 'value'
}

/** select columns of table "project_user" */
export enum Project_User_Select_Column {
  /** column name */
  DisplayStatus = 'display_status',
  /** column name */
  Note = 'note',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "project_user" */
export type Project_User_Set_Input = {
  display_status?: InputMaybe<Project_User_Display_Status_Enum>;
  note?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Project_User_Role_Enum>;
  status?: InputMaybe<Project_User_Status_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "project_user_status" */
export type Project_User_Status = {
  __typename?: 'project_user_status';
  value: Scalars['String'];
};

/** aggregated selection of "project_user_status" */
export type Project_User_Status_Aggregate = {
  __typename?: 'project_user_status_aggregate';
  aggregate?: Maybe<Project_User_Status_Aggregate_Fields>;
  nodes: Array<Project_User_Status>;
};

/** aggregate fields of "project_user_status" */
export type Project_User_Status_Aggregate_Fields = {
  __typename?: 'project_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_User_Status_Max_Fields>;
  min?: Maybe<Project_User_Status_Min_Fields>;
};


/** aggregate fields of "project_user_status" */
export type Project_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_user_status". All fields are combined with a logical 'AND'. */
export type Project_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Project_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Project_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Project_User_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user_status" */
export enum Project_User_Status_Constraint {
  /** unique or primary key constraint */
  ProjectUserStatusPkey = 'project_user_status_pkey'
}

export enum Project_User_Status_Enum {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  UnderReview = 'UNDER_REVIEW'
}

/** Boolean expression to compare columns of type "project_user_status_enum". All fields are combined with logical 'AND'. */
export type Project_User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_User_Status_Enum>;
  _in?: InputMaybe<Array<Project_User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_User_Status_Enum>;
  _nin?: InputMaybe<Array<Project_User_Status_Enum>>;
};

/** input type for inserting data into table "project_user_status" */
export type Project_User_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_User_Status_Max_Fields = {
  __typename?: 'project_user_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_User_Status_Min_Fields = {
  __typename?: 'project_user_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_user_status" */
export type Project_User_Status_Mutation_Response = {
  __typename?: 'project_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User_Status>;
};

/** on conflict condition type for table "project_user_status" */
export type Project_User_Status_On_Conflict = {
  constraint: Project_User_Status_Constraint;
  update_columns?: Array<Project_User_Status_Update_Column>;
  where?: InputMaybe<Project_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user_status". */
export type Project_User_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_user_status */
export type Project_User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "project_user_status" */
export enum Project_User_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "project_user_status" */
export type Project_User_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_user_status" */
export enum Project_User_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "project_user" */
export enum Project_User_Update_Column {
  /** column name */
  DisplayStatus = 'display_status',
  /** column name */
  Note = 'note',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

export type Query_Root = {
  __typename?: 'query_root';
  customer: Customer;
  customer_invoices: Array<Invoice>;
  customer_upcoming_invoice: Invoice;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  get_invoice_pdf: InvoiceWithPdf;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch data from the table: "notification_action" */
  notification_action: Array<Notification_Action>;
  /** fetch aggregated fields from the table: "notification_action" */
  notification_action_aggregate: Notification_Action_Aggregate;
  /** fetch data from the table: "notification_action" using primary key columns */
  notification_action_by_pk?: Maybe<Notification_Action>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_destination" */
  notification_destination: Array<Notification_Destination>;
  /** fetch aggregated fields from the table: "notification_destination" */
  notification_destination_aggregate: Notification_Destination_Aggregate;
  /** fetch data from the table: "notification_destination" using primary key columns */
  notification_destination_by_pk?: Maybe<Notification_Destination>;
  /** fetch data from the table: "notification_receiver" */
  notification_receiver: Array<Notification_Receiver>;
  /** fetch aggregated fields from the table: "notification_receiver" */
  notification_receiver_aggregate: Notification_Receiver_Aggregate;
  /** fetch data from the table: "notification_receiver" using primary key columns */
  notification_receiver_by_pk?: Maybe<Notification_Receiver>;
  /** fetch data from the table: "notification_setting" */
  notification_setting: Array<Notification_Setting>;
  /** fetch aggregated fields from the table: "notification_setting" */
  notification_setting_aggregate: Notification_Setting_Aggregate;
  /** fetch data from the table: "notification_setting" using primary key columns */
  notification_setting_by_pk?: Maybe<Notification_Setting>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** An aggregate relationship */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_dashboard" */
  project_dashboard: Array<Project_Dashboard>;
  /** fetch aggregated fields from the table: "project_dashboard" */
  project_dashboard_aggregate: Project_Dashboard_Aggregate;
  /** fetch data from the table: "project_status" */
  project_status: Array<Project_Status>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: Project_Status_Aggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<Project_Status>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table: "project_user_display_status" */
  project_user_display_status: Array<Project_User_Display_Status>;
  /** fetch aggregated fields from the table: "project_user_display_status" */
  project_user_display_status_aggregate: Project_User_Display_Status_Aggregate;
  /** fetch data from the table: "project_user_display_status" using primary key columns */
  project_user_display_status_by_pk?: Maybe<Project_User_Display_Status>;
  /** fetch data from the table: "project_user_role" */
  project_user_role: Array<Project_User_Role>;
  /** fetch aggregated fields from the table: "project_user_role" */
  project_user_role_aggregate: Project_User_Role_Aggregate;
  /** fetch data from the table: "project_user_role" using primary key columns */
  project_user_role_by_pk?: Maybe<Project_User_Role>;
  /** fetch data from the table: "project_user_status" */
  project_user_status: Array<Project_User_Status>;
  /** fetch aggregated fields from the table: "project_user_status" */
  project_user_status_aggregate: Project_User_Status_Aggregate;
  /** fetch data from the table: "project_user_status" using primary key columns */
  project_user_status_by_pk?: Maybe<Project_User_Status>;
  /** fetch data from the table: "reminder_protocol" */
  reminder_protocol: Array<Reminder_Protocol>;
  /** fetch aggregated fields from the table: "reminder_protocol" */
  reminder_protocol_aggregate: Reminder_Protocol_Aggregate;
  /** fetch data from the table: "reminder_protocol" using primary key columns */
  reminder_protocol_by_pk?: Maybe<Reminder_Protocol>;
  /** fetch data from the table: "reminder_type" */
  reminder_type: Array<Reminder_Type>;
  /** fetch aggregated fields from the table: "reminder_type" */
  reminder_type_aggregate: Reminder_Type_Aggregate;
  /** fetch data from the table: "reminder_type" using primary key columns */
  reminder_type_by_pk?: Maybe<Reminder_Type>;
  /** fetch data from the table: "settings_by_user" */
  settings_by_user: Array<Settings_By_User>;
  /** fetch aggregated fields from the table: "settings_by_user" */
  settings_by_user_aggregate: Settings_By_User_Aggregate;
  /** fetch data from the table: "subscription_status" */
  subscription_status: Array<Subscription_Status>;
  /** fetch aggregated fields from the table: "subscription_status" */
  subscription_status_aggregate: Subscription_Status_Aggregate;
  /** fetch data from the table: "subscription_status" using primary key columns */
  subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** fetch data from the table: "team" */
  team: Array<Team>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: Team_Aggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<Team>;
  /** fetch data from the table: "team_name_banned" */
  team_name_banned: Array<Team_Name_Banned>;
  /** fetch aggregated fields from the table: "team_name_banned" */
  team_name_banned_aggregate: Team_Name_Banned_Aggregate;
  /** fetch data from the table: "team_name_banned" using primary key columns */
  team_name_banned_by_pk?: Maybe<Team_Name_Banned>;
  /** fetch data from the table: "team_user" */
  team_user: Array<Team_User>;
  /** fetch aggregated fields from the table: "team_user" */
  team_user_aggregate: Team_User_Aggregate;
  /** fetch data from the table: "team_user" using primary key columns */
  team_user_by_pk?: Maybe<Team_User>;
  /** fetch data from the table: "team_user_role" */
  team_user_role: Array<Team_User_Role>;
  /** fetch aggregated fields from the table: "team_user_role" */
  team_user_role_aggregate: Team_User_Role_Aggregate;
  /** fetch data from the table: "team_user_role" using primary key columns */
  team_user_role_by_pk?: Maybe<Team_User_Role>;
  /** fetch data from the table: "team_user_status" */
  team_user_status: Array<Team_User_Status>;
  /** fetch aggregated fields from the table: "team_user_status" */
  team_user_status_aggregate: Team_User_Status_Aggregate;
  /** fetch data from the table: "team_user_status" using primary key columns */
  team_user_status_by_pk?: Maybe<Team_User_Status>;
  /** An array relationship */
  user: Array<User>;
  /** An aggregate relationship */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "web_push_subscription" */
  web_push_subscription: Array<Web_Push_Subscription>;
  /** fetch aggregated fields from the table: "web_push_subscription" */
  web_push_subscription_aggregate: Web_Push_Subscription_Aggregate;
  /** fetch data from the table: "web_push_subscription" using primary key columns */
  web_push_subscription_by_pk?: Maybe<Web_Push_Subscription>;
};


export type Query_RootCustomerArgs = {
  customer_id: Scalars['String'];
};


export type Query_RootCustomer_InvoicesArgs = {
  customer_id: Scalars['String'];
};


export type Query_RootCustomer_Upcoming_InvoiceArgs = {
  customer_id: Scalars['String'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGet_Invoice_PdfArgs = {
  customer_id: Scalars['String'];
  invoice_id: Scalars['String'];
  team_id: Scalars['String'];
};


export type Query_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_ActionArgs = {
  distinct_on?: InputMaybe<Array<Notification_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Action_Order_By>>;
  where?: InputMaybe<Notification_Action_Bool_Exp>;
};


export type Query_RootNotification_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Action_Order_By>>;
  where?: InputMaybe<Notification_Action_Bool_Exp>;
};


export type Query_RootNotification_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotification_DestinationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Destination_Order_By>>;
  where?: InputMaybe<Notification_Destination_Bool_Exp>;
};


export type Query_RootNotification_Destination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Destination_Order_By>>;
  where?: InputMaybe<Notification_Destination_Bool_Exp>;
};


export type Query_RootNotification_Destination_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNotification_ReceiverArgs = {
  distinct_on?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Receiver_Order_By>>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};


export type Query_RootNotification_Receiver_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Receiver_Order_By>>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};


export type Query_RootNotification_Receiver_By_PkArgs = {
  notification_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootNotification_SettingArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Order_By>>;
  where?: InputMaybe<Notification_Setting_Bool_Exp>;
};


export type Query_RootNotification_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Order_By>>;
  where?: InputMaybe<Notification_Setting_Bool_Exp>;
};


export type Query_RootNotification_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Query_RootNotification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Query_RootNotification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Query_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Query_RootProject_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProject_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Project_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Dashboard_Order_By>>;
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
};


export type Query_RootProject_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Dashboard_Order_By>>;
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
};


export type Query_RootProject_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};


export type Query_RootProject_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};


export type Query_RootProject_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProject_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


export type Query_RootProject_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


export type Query_RootProject_User_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootProject_User_Display_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Display_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Display_Status_Order_By>>;
  where?: InputMaybe<Project_User_Display_Status_Bool_Exp>;
};


export type Query_RootProject_User_Display_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Display_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Display_Status_Order_By>>;
  where?: InputMaybe<Project_User_Display_Status_Bool_Exp>;
};


export type Query_RootProject_User_Display_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProject_User_RoleArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Role_Order_By>>;
  where?: InputMaybe<Project_User_Role_Bool_Exp>;
};


export type Query_RootProject_User_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Role_Order_By>>;
  where?: InputMaybe<Project_User_Role_Bool_Exp>;
};


export type Query_RootProject_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootProject_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Status_Order_By>>;
  where?: InputMaybe<Project_User_Status_Bool_Exp>;
};


export type Query_RootProject_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Status_Order_By>>;
  where?: InputMaybe<Project_User_Status_Bool_Exp>;
};


export type Query_RootProject_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootReminder_ProtocolArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Protocol_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Protocol_Order_By>>;
  where?: InputMaybe<Reminder_Protocol_Bool_Exp>;
};


export type Query_RootReminder_Protocol_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Protocol_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Protocol_Order_By>>;
  where?: InputMaybe<Reminder_Protocol_Bool_Exp>;
};


export type Query_RootReminder_Protocol_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReminder_TypeArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Order_By>>;
  where?: InputMaybe<Reminder_Type_Bool_Exp>;
};


export type Query_RootReminder_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Order_By>>;
  where?: InputMaybe<Reminder_Type_Bool_Exp>;
};


export type Query_RootReminder_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSettings_By_UserArgs = {
  distinct_on?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_By_User_Order_By>>;
  where?: InputMaybe<Settings_By_User_Bool_Exp>;
};


export type Query_RootSettings_By_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_By_User_Order_By>>;
  where?: InputMaybe<Settings_By_User_Bool_Exp>;
};


export type Query_RootSubscription_StatusArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Status_Order_By>>;
  where?: InputMaybe<Subscription_Status_Bool_Exp>;
};


export type Query_RootSubscription_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Status_Order_By>>;
  where?: InputMaybe<Subscription_Status_Bool_Exp>;
};


export type Query_RootSubscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTeamArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Query_RootTeam_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Query_RootTeam_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeam_Name_BannedArgs = {
  distinct_on?: InputMaybe<Array<Team_Name_Banned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Name_Banned_Order_By>>;
  where?: InputMaybe<Team_Name_Banned_Bool_Exp>;
};


export type Query_RootTeam_Name_Banned_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Name_Banned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Name_Banned_Order_By>>;
  where?: InputMaybe<Team_Name_Banned_Bool_Exp>;
};


export type Query_RootTeam_Name_Banned_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTeam_UserArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};


export type Query_RootTeam_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};


export type Query_RootTeam_User_By_PkArgs = {
  team_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootTeam_User_RoleArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Role_Order_By>>;
  where?: InputMaybe<Team_User_Role_Bool_Exp>;
};


export type Query_RootTeam_User_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Role_Order_By>>;
  where?: InputMaybe<Team_User_Role_Bool_Exp>;
};


export type Query_RootTeam_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTeam_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Status_Order_By>>;
  where?: InputMaybe<Team_User_Status_Bool_Exp>;
};


export type Query_RootTeam_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Status_Order_By>>;
  where?: InputMaybe<Team_User_Status_Bool_Exp>;
};


export type Query_RootTeam_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootWeb_Push_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Push_Subscription_Order_By>>;
  where?: InputMaybe<Web_Push_Subscription_Bool_Exp>;
};


export type Query_RootWeb_Push_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Push_Subscription_Order_By>>;
  where?: InputMaybe<Web_Push_Subscription_Bool_Exp>;
};


export type Query_RootWeb_Push_Subscription_By_PkArgs = {
  auth: Scalars['String'];
  browser_fingerprint: Scalars['String'];
  p256dh: Scalars['String'];
  user_id: Scalars['String'];
};

/** columns and relationships of "reminder_protocol" */
export type Reminder_Protocol = {
  __typename?: 'reminder_protocol';
  days: Scalars['_numeric'];
  id: Scalars['uuid'];
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** An aggregate relationship */
  project_aggregate: Project_Aggregate;
  time?: Maybe<Scalars['timetz']>;
  type: Reminder_Type_Enum;
  /** An array relationship */
  user: Array<User>;
  /** An aggregate relationship */
  user_aggregate: User_Aggregate;
};


/** columns and relationships of "reminder_protocol" */
export type Reminder_ProtocolProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


/** columns and relationships of "reminder_protocol" */
export type Reminder_ProtocolProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


/** columns and relationships of "reminder_protocol" */
export type Reminder_ProtocolUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "reminder_protocol" */
export type Reminder_ProtocolUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "reminder_protocol" */
export type Reminder_Protocol_Aggregate = {
  __typename?: 'reminder_protocol_aggregate';
  aggregate?: Maybe<Reminder_Protocol_Aggregate_Fields>;
  nodes: Array<Reminder_Protocol>;
};

/** aggregate fields of "reminder_protocol" */
export type Reminder_Protocol_Aggregate_Fields = {
  __typename?: 'reminder_protocol_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reminder_Protocol_Max_Fields>;
  min?: Maybe<Reminder_Protocol_Min_Fields>;
};


/** aggregate fields of "reminder_protocol" */
export type Reminder_Protocol_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reminder_Protocol_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reminder_protocol". All fields are combined with a logical 'AND'. */
export type Reminder_Protocol_Bool_Exp = {
  _and?: InputMaybe<Array<Reminder_Protocol_Bool_Exp>>;
  _not?: InputMaybe<Reminder_Protocol_Bool_Exp>;
  _or?: InputMaybe<Array<Reminder_Protocol_Bool_Exp>>;
  days?: InputMaybe<_Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  time?: InputMaybe<Timetz_Comparison_Exp>;
  type?: InputMaybe<Reminder_Type_Enum_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "reminder_protocol" */
export enum Reminder_Protocol_Constraint {
  /** unique or primary key constraint */
  ReminderProtocolPkey = 'reminder_protocol_pkey'
}

/** input type for inserting data into table "reminder_protocol" */
export type Reminder_Protocol_Insert_Input = {
  days?: InputMaybe<Scalars['_numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  time?: InputMaybe<Scalars['timetz']>;
  type?: InputMaybe<Reminder_Type_Enum>;
  user?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Reminder_Protocol_Max_Fields = {
  __typename?: 'reminder_protocol_max_fields';
  id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['timetz']>;
};

/** aggregate min on columns */
export type Reminder_Protocol_Min_Fields = {
  __typename?: 'reminder_protocol_min_fields';
  id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['timetz']>;
};

/** response of any mutation on the table "reminder_protocol" */
export type Reminder_Protocol_Mutation_Response = {
  __typename?: 'reminder_protocol_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reminder_Protocol>;
};

/** input type for inserting object relation for remote table "reminder_protocol" */
export type Reminder_Protocol_Obj_Rel_Insert_Input = {
  data: Reminder_Protocol_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Reminder_Protocol_On_Conflict>;
};

/** on conflict condition type for table "reminder_protocol" */
export type Reminder_Protocol_On_Conflict = {
  constraint: Reminder_Protocol_Constraint;
  update_columns?: Array<Reminder_Protocol_Update_Column>;
  where?: InputMaybe<Reminder_Protocol_Bool_Exp>;
};

/** Ordering options when selecting data from "reminder_protocol". */
export type Reminder_Protocol_Order_By = {
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: reminder_protocol */
export type Reminder_Protocol_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reminder_protocol" */
export enum Reminder_Protocol_Select_Column {
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  Time = 'time',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "reminder_protocol" */
export type Reminder_Protocol_Set_Input = {
  days?: InputMaybe<Scalars['_numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  time?: InputMaybe<Scalars['timetz']>;
  type?: InputMaybe<Reminder_Type_Enum>;
};

/** update columns of table "reminder_protocol" */
export enum Reminder_Protocol_Update_Column {
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  Time = 'time',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "reminder_type" */
export type Reminder_Type = {
  __typename?: 'reminder_type';
  value: Scalars['String'];
};

/** aggregated selection of "reminder_type" */
export type Reminder_Type_Aggregate = {
  __typename?: 'reminder_type_aggregate';
  aggregate?: Maybe<Reminder_Type_Aggregate_Fields>;
  nodes: Array<Reminder_Type>;
};

/** aggregate fields of "reminder_type" */
export type Reminder_Type_Aggregate_Fields = {
  __typename?: 'reminder_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reminder_Type_Max_Fields>;
  min?: Maybe<Reminder_Type_Min_Fields>;
};


/** aggregate fields of "reminder_type" */
export type Reminder_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reminder_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reminder_type". All fields are combined with a logical 'AND'. */
export type Reminder_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Reminder_Type_Bool_Exp>>;
  _not?: InputMaybe<Reminder_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Reminder_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reminder_type" */
export enum Reminder_Type_Constraint {
  /** unique or primary key constraint */
  ReminderTypePkey = 'reminder_type_pkey'
}

export enum Reminder_Type_Enum {
  BeforeDeadline = 'BEFORE_DEADLINE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

/** Boolean expression to compare columns of type "reminder_type_enum". All fields are combined with logical 'AND'. */
export type Reminder_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Reminder_Type_Enum>;
  _in?: InputMaybe<Array<Reminder_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Reminder_Type_Enum>;
  _nin?: InputMaybe<Array<Reminder_Type_Enum>>;
};

/** input type for inserting data into table "reminder_type" */
export type Reminder_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reminder_Type_Max_Fields = {
  __typename?: 'reminder_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reminder_Type_Min_Fields = {
  __typename?: 'reminder_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reminder_type" */
export type Reminder_Type_Mutation_Response = {
  __typename?: 'reminder_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reminder_Type>;
};

/** on conflict condition type for table "reminder_type" */
export type Reminder_Type_On_Conflict = {
  constraint: Reminder_Type_Constraint;
  update_columns?: Array<Reminder_Type_Update_Column>;
  where?: InputMaybe<Reminder_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "reminder_type". */
export type Reminder_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reminder_type */
export type Reminder_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "reminder_type" */
export enum Reminder_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "reminder_type" */
export type Reminder_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "reminder_type" */
export enum Reminder_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "settings_by_user" */
export type Settings_By_User = {
  __typename?: 'settings_by_user';
  action?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  destination?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['_text']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  notification_action?: Maybe<Notification_Action>;
  /** An object relationship */
  notification_destination?: Maybe<Notification_Destination>;
  /** An object relationship */
  notification_type?: Maybe<Notification_Type>;
  type?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "settings_by_user" */
export type Settings_By_User_Aggregate = {
  __typename?: 'settings_by_user_aggregate';
  aggregate?: Maybe<Settings_By_User_Aggregate_Fields>;
  nodes: Array<Settings_By_User>;
};

/** aggregate fields of "settings_by_user" */
export type Settings_By_User_Aggregate_Fields = {
  __typename?: 'settings_by_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Settings_By_User_Max_Fields>;
  min?: Maybe<Settings_By_User_Min_Fields>;
};


/** aggregate fields of "settings_by_user" */
export type Settings_By_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "settings_by_user" */
export type Settings_By_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Settings_By_User_Max_Order_By>;
  min?: InputMaybe<Settings_By_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "settings_by_user" */
export type Settings_By_User_Arr_Rel_Insert_Input = {
  data: Array<Settings_By_User_Insert_Input>;
};

/** Boolean expression to filter rows from the table "settings_by_user". All fields are combined with a logical 'AND'. */
export type Settings_By_User_Bool_Exp = {
  _and?: InputMaybe<Array<Settings_By_User_Bool_Exp>>;
  _not?: InputMaybe<Settings_By_User_Bool_Exp>;
  _or?: InputMaybe<Array<Settings_By_User_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  destination?: InputMaybe<String_Comparison_Exp>;
  fields?: InputMaybe<_Text_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_action?: InputMaybe<Notification_Action_Bool_Exp>;
  notification_destination?: InputMaybe<Notification_Destination_Bool_Exp>;
  notification_type?: InputMaybe<Notification_Type_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "settings_by_user" */
export type Settings_By_User_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  destination?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Scalars['_text']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_action?: InputMaybe<Notification_Action_Obj_Rel_Insert_Input>;
  notification_destination?: InputMaybe<Notification_Destination_Obj_Rel_Insert_Input>;
  notification_type?: InputMaybe<Notification_Type_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Settings_By_User_Max_Fields = {
  __typename?: 'settings_by_user_max_fields';
  action?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "settings_by_user" */
export type Settings_By_User_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  destination?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Settings_By_User_Min_Fields = {
  __typename?: 'settings_by_user_min_fields';
  action?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "settings_by_user" */
export type Settings_By_User_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  destination?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "settings_by_user". */
export type Settings_By_User_Order_By = {
  action?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  destination?: InputMaybe<Order_By>;
  fields?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_action?: InputMaybe<Notification_Action_Order_By>;
  notification_destination?: InputMaybe<Notification_Destination_Order_By>;
  notification_type?: InputMaybe<Notification_Type_Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "settings_by_user" */
export enum Settings_By_User_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Active = 'active',
  /** column name */
  Destination = 'destination',
  /** column name */
  Fields = 'fields',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch data from the table: "notification_action" */
  notification_action: Array<Notification_Action>;
  /** fetch aggregated fields from the table: "notification_action" */
  notification_action_aggregate: Notification_Action_Aggregate;
  /** fetch data from the table: "notification_action" using primary key columns */
  notification_action_by_pk?: Maybe<Notification_Action>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_destination" */
  notification_destination: Array<Notification_Destination>;
  /** fetch aggregated fields from the table: "notification_destination" */
  notification_destination_aggregate: Notification_Destination_Aggregate;
  /** fetch data from the table: "notification_destination" using primary key columns */
  notification_destination_by_pk?: Maybe<Notification_Destination>;
  /** fetch data from the table: "notification_receiver" */
  notification_receiver: Array<Notification_Receiver>;
  /** fetch aggregated fields from the table: "notification_receiver" */
  notification_receiver_aggregate: Notification_Receiver_Aggregate;
  /** fetch data from the table: "notification_receiver" using primary key columns */
  notification_receiver_by_pk?: Maybe<Notification_Receiver>;
  /** fetch data from the table: "notification_setting" */
  notification_setting: Array<Notification_Setting>;
  /** fetch aggregated fields from the table: "notification_setting" */
  notification_setting_aggregate: Notification_Setting_Aggregate;
  /** fetch data from the table: "notification_setting" using primary key columns */
  notification_setting_by_pk?: Maybe<Notification_Setting>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** An aggregate relationship */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_dashboard" */
  project_dashboard: Array<Project_Dashboard>;
  /** fetch aggregated fields from the table: "project_dashboard" */
  project_dashboard_aggregate: Project_Dashboard_Aggregate;
  /** fetch data from the table: "project_status" */
  project_status: Array<Project_Status>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: Project_Status_Aggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<Project_Status>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table: "project_user_display_status" */
  project_user_display_status: Array<Project_User_Display_Status>;
  /** fetch aggregated fields from the table: "project_user_display_status" */
  project_user_display_status_aggregate: Project_User_Display_Status_Aggregate;
  /** fetch data from the table: "project_user_display_status" using primary key columns */
  project_user_display_status_by_pk?: Maybe<Project_User_Display_Status>;
  /** fetch data from the table: "project_user_role" */
  project_user_role: Array<Project_User_Role>;
  /** fetch aggregated fields from the table: "project_user_role" */
  project_user_role_aggregate: Project_User_Role_Aggregate;
  /** fetch data from the table: "project_user_role" using primary key columns */
  project_user_role_by_pk?: Maybe<Project_User_Role>;
  /** fetch data from the table: "project_user_status" */
  project_user_status: Array<Project_User_Status>;
  /** fetch aggregated fields from the table: "project_user_status" */
  project_user_status_aggregate: Project_User_Status_Aggregate;
  /** fetch data from the table: "project_user_status" using primary key columns */
  project_user_status_by_pk?: Maybe<Project_User_Status>;
  /** fetch data from the table: "reminder_protocol" */
  reminder_protocol: Array<Reminder_Protocol>;
  /** fetch aggregated fields from the table: "reminder_protocol" */
  reminder_protocol_aggregate: Reminder_Protocol_Aggregate;
  /** fetch data from the table: "reminder_protocol" using primary key columns */
  reminder_protocol_by_pk?: Maybe<Reminder_Protocol>;
  /** fetch data from the table: "reminder_type" */
  reminder_type: Array<Reminder_Type>;
  /** fetch aggregated fields from the table: "reminder_type" */
  reminder_type_aggregate: Reminder_Type_Aggregate;
  /** fetch data from the table: "reminder_type" using primary key columns */
  reminder_type_by_pk?: Maybe<Reminder_Type>;
  /** fetch data from the table: "settings_by_user" */
  settings_by_user: Array<Settings_By_User>;
  /** fetch aggregated fields from the table: "settings_by_user" */
  settings_by_user_aggregate: Settings_By_User_Aggregate;
  /** fetch data from the table: "subscription_status" */
  subscription_status: Array<Subscription_Status>;
  /** fetch aggregated fields from the table: "subscription_status" */
  subscription_status_aggregate: Subscription_Status_Aggregate;
  /** fetch data from the table: "subscription_status" using primary key columns */
  subscription_status_by_pk?: Maybe<Subscription_Status>;
  /** fetch data from the table: "team" */
  team: Array<Team>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: Team_Aggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<Team>;
  /** fetch data from the table: "team_name_banned" */
  team_name_banned: Array<Team_Name_Banned>;
  /** fetch aggregated fields from the table: "team_name_banned" */
  team_name_banned_aggregate: Team_Name_Banned_Aggregate;
  /** fetch data from the table: "team_name_banned" using primary key columns */
  team_name_banned_by_pk?: Maybe<Team_Name_Banned>;
  /** fetch data from the table: "team_user" */
  team_user: Array<Team_User>;
  /** fetch aggregated fields from the table: "team_user" */
  team_user_aggregate: Team_User_Aggregate;
  /** fetch data from the table: "team_user" using primary key columns */
  team_user_by_pk?: Maybe<Team_User>;
  /** fetch data from the table: "team_user_role" */
  team_user_role: Array<Team_User_Role>;
  /** fetch aggregated fields from the table: "team_user_role" */
  team_user_role_aggregate: Team_User_Role_Aggregate;
  /** fetch data from the table: "team_user_role" using primary key columns */
  team_user_role_by_pk?: Maybe<Team_User_Role>;
  /** fetch data from the table: "team_user_status" */
  team_user_status: Array<Team_User_Status>;
  /** fetch aggregated fields from the table: "team_user_status" */
  team_user_status_aggregate: Team_User_Status_Aggregate;
  /** fetch data from the table: "team_user_status" using primary key columns */
  team_user_status_by_pk?: Maybe<Team_User_Status>;
  /** An array relationship */
  user: Array<User>;
  /** An aggregate relationship */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "web_push_subscription" */
  web_push_subscription: Array<Web_Push_Subscription>;
  /** fetch aggregated fields from the table: "web_push_subscription" */
  web_push_subscription_aggregate: Web_Push_Subscription_Aggregate;
  /** fetch data from the table: "web_push_subscription" using primary key columns */
  web_push_subscription_by_pk?: Maybe<Web_Push_Subscription>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_ActionArgs = {
  distinct_on?: InputMaybe<Array<Notification_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Action_Order_By>>;
  where?: InputMaybe<Notification_Action_Bool_Exp>;
};


export type Subscription_RootNotification_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Action_Order_By>>;
  where?: InputMaybe<Notification_Action_Bool_Exp>;
};


export type Subscription_RootNotification_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotification_DestinationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Destination_Order_By>>;
  where?: InputMaybe<Notification_Destination_Bool_Exp>;
};


export type Subscription_RootNotification_Destination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Destination_Order_By>>;
  where?: InputMaybe<Notification_Destination_Bool_Exp>;
};


export type Subscription_RootNotification_Destination_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNotification_ReceiverArgs = {
  distinct_on?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Receiver_Order_By>>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};


export type Subscription_RootNotification_Receiver_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Receiver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Receiver_Order_By>>;
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
};


export type Subscription_RootNotification_Receiver_By_PkArgs = {
  notification_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootNotification_SettingArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Order_By>>;
  where?: InputMaybe<Notification_Setting_Bool_Exp>;
};


export type Subscription_RootNotification_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Setting_Order_By>>;
  where?: InputMaybe<Notification_Setting_Bool_Exp>;
};


export type Subscription_RootNotification_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Subscription_RootNotification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};


export type Subscription_RootNotification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProject_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Project_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Dashboard_Order_By>>;
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
};


export type Subscription_RootProject_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Dashboard_Order_By>>;
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
};


export type Subscription_RootProject_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};


export type Subscription_RootProject_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};


export type Subscription_RootProject_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProject_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProject_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProject_User_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootProject_User_Display_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Display_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Display_Status_Order_By>>;
  where?: InputMaybe<Project_User_Display_Status_Bool_Exp>;
};


export type Subscription_RootProject_User_Display_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Display_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Display_Status_Order_By>>;
  where?: InputMaybe<Project_User_Display_Status_Bool_Exp>;
};


export type Subscription_RootProject_User_Display_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProject_User_RoleArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Role_Order_By>>;
  where?: InputMaybe<Project_User_Role_Bool_Exp>;
};


export type Subscription_RootProject_User_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Role_Order_By>>;
  where?: InputMaybe<Project_User_Role_Bool_Exp>;
};


export type Subscription_RootProject_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProject_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Status_Order_By>>;
  where?: InputMaybe<Project_User_Status_Bool_Exp>;
};


export type Subscription_RootProject_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Status_Order_By>>;
  where?: InputMaybe<Project_User_Status_Bool_Exp>;
};


export type Subscription_RootProject_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootReminder_ProtocolArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Protocol_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Protocol_Order_By>>;
  where?: InputMaybe<Reminder_Protocol_Bool_Exp>;
};


export type Subscription_RootReminder_Protocol_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Protocol_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Protocol_Order_By>>;
  where?: InputMaybe<Reminder_Protocol_Bool_Exp>;
};


export type Subscription_RootReminder_Protocol_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReminder_TypeArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Order_By>>;
  where?: InputMaybe<Reminder_Type_Bool_Exp>;
};


export type Subscription_RootReminder_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reminder_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reminder_Type_Order_By>>;
  where?: InputMaybe<Reminder_Type_Bool_Exp>;
};


export type Subscription_RootReminder_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSettings_By_UserArgs = {
  distinct_on?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_By_User_Order_By>>;
  where?: InputMaybe<Settings_By_User_Bool_Exp>;
};


export type Subscription_RootSettings_By_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_By_User_Order_By>>;
  where?: InputMaybe<Settings_By_User_Bool_Exp>;
};


export type Subscription_RootSubscription_StatusArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Status_Order_By>>;
  where?: InputMaybe<Subscription_Status_Bool_Exp>;
};


export type Subscription_RootSubscription_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Status_Order_By>>;
  where?: InputMaybe<Subscription_Status_Bool_Exp>;
};


export type Subscription_RootSubscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTeamArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTeam_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTeam_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeam_Name_BannedArgs = {
  distinct_on?: InputMaybe<Array<Team_Name_Banned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Name_Banned_Order_By>>;
  where?: InputMaybe<Team_Name_Banned_Bool_Exp>;
};


export type Subscription_RootTeam_Name_Banned_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Name_Banned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Name_Banned_Order_By>>;
  where?: InputMaybe<Team_Name_Banned_Bool_Exp>;
};


export type Subscription_RootTeam_Name_Banned_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTeam_UserArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};


export type Subscription_RootTeam_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};


export type Subscription_RootTeam_User_By_PkArgs = {
  team_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootTeam_User_RoleArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Role_Order_By>>;
  where?: InputMaybe<Team_User_Role_Bool_Exp>;
};


export type Subscription_RootTeam_User_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Role_Order_By>>;
  where?: InputMaybe<Team_User_Role_Bool_Exp>;
};


export type Subscription_RootTeam_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTeam_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Status_Order_By>>;
  where?: InputMaybe<Team_User_Status_Bool_Exp>;
};


export type Subscription_RootTeam_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Status_Order_By>>;
  where?: InputMaybe<Team_User_Status_Bool_Exp>;
};


export type Subscription_RootTeam_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootWeb_Push_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Push_Subscription_Order_By>>;
  where?: InputMaybe<Web_Push_Subscription_Bool_Exp>;
};


export type Subscription_RootWeb_Push_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Push_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Push_Subscription_Order_By>>;
  where?: InputMaybe<Web_Push_Subscription_Bool_Exp>;
};


export type Subscription_RootWeb_Push_Subscription_By_PkArgs = {
  auth: Scalars['String'];
  browser_fingerprint: Scalars['String'];
  p256dh: Scalars['String'];
  user_id: Scalars['String'];
};

/** columns and relationships of "subscription_status" */
export type Subscription_Status = {
  __typename?: 'subscription_status';
  value: Scalars['String'];
};

/** aggregated selection of "subscription_status" */
export type Subscription_Status_Aggregate = {
  __typename?: 'subscription_status_aggregate';
  aggregate?: Maybe<Subscription_Status_Aggregate_Fields>;
  nodes: Array<Subscription_Status>;
};

/** aggregate fields of "subscription_status" */
export type Subscription_Status_Aggregate_Fields = {
  __typename?: 'subscription_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subscription_Status_Max_Fields>;
  min?: Maybe<Subscription_Status_Min_Fields>;
};


/** aggregate fields of "subscription_status" */
export type Subscription_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subscription_status". All fields are combined with a logical 'AND'. */
export type Subscription_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Status_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_status" */
export enum Subscription_Status_Constraint {
  /** unique or primary key constraint */
  SubscriptionStatusPkey = 'subscription_status_pkey'
}

export enum Subscription_Status_Enum {
  Active = 'ACTIVE',
  Canceled = 'CANCELED'
}

/** Boolean expression to compare columns of type "subscription_status_enum". All fields are combined with logical 'AND'. */
export type Subscription_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Subscription_Status_Enum>;
  _in?: InputMaybe<Array<Subscription_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Subscription_Status_Enum>;
  _nin?: InputMaybe<Array<Subscription_Status_Enum>>;
};

/** input type for inserting data into table "subscription_status" */
export type Subscription_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Subscription_Status_Max_Fields = {
  __typename?: 'subscription_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subscription_Status_Min_Fields = {
  __typename?: 'subscription_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subscription_status" */
export type Subscription_Status_Mutation_Response = {
  __typename?: 'subscription_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Status>;
};

/** on conflict condition type for table "subscription_status" */
export type Subscription_Status_On_Conflict = {
  constraint: Subscription_Status_Constraint;
  update_columns?: Array<Subscription_Status_Update_Column>;
  where?: InputMaybe<Subscription_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_status". */
export type Subscription_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_status */
export type Subscription_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "subscription_status" */
export enum Subscription_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "subscription_status" */
export type Subscription_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "subscription_status" */
export enum Subscription_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "team" */
export type Team = {
  __typename?: 'team';
  created_at: Scalars['timestamp'];
  customer_id?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
  id: Scalars['uuid'];
  is_admin: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  /** A computed field, executes function "team_projects_count" */
  projects_count?: Maybe<Scalars['bigint']>;
  subscription_status?: Maybe<Subscription_Status_Enum>;
  /** An array relationship */
  team_users: Array<Team_User>;
  /** An aggregate relationship */
  team_users_aggregate: Team_User_Aggregate;
};


/** columns and relationships of "team" */
export type TeamProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamTeam_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamTeam_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};

/** aggregated selection of "team" */
export type Team_Aggregate = {
  __typename?: 'team_aggregate';
  aggregate?: Maybe<Team_Aggregate_Fields>;
  nodes: Array<Team>;
};

/** aggregate fields of "team" */
export type Team_Aggregate_Fields = {
  __typename?: 'team_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Max_Fields>;
  min?: Maybe<Team_Min_Fields>;
};


/** aggregate fields of "team" */
export type Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team". All fields are combined with a logical 'AND'. */
export type Team_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Bool_Exp>>;
  _not?: InputMaybe<Team_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_admin?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_count?: InputMaybe<Bigint_Comparison_Exp>;
  subscription_status?: InputMaybe<Subscription_Status_Enum_Comparison_Exp>;
  team_users?: InputMaybe<Team_User_Bool_Exp>;
};

/** unique or primary key constraints on table "team" */
export enum Team_Constraint {
  /** unique or primary key constraint */
  TeamCustomerIdKey = 'team_customer_id_key',
  /** unique or primary key constraint */
  TeamDomainKey = 'team_domain_key',
  /** unique or primary key constraint */
  TeamPkey = 'team_pkey'
}

/** input type for inserting data into table "team" */
export type Team_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  customer_id?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  subscription_status?: InputMaybe<Subscription_Status_Enum>;
  team_users?: InputMaybe<Team_User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Team_Max_Fields = {
  __typename?: 'team_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Team_Min_Fields = {
  __typename?: 'team_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "team" */
export type Team_Mutation_Response = {
  __typename?: 'team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team>;
};

/** columns and relationships of "team_name_banned" */
export type Team_Name_Banned = {
  __typename?: 'team_name_banned';
  value: Scalars['String'];
};

/** aggregated selection of "team_name_banned" */
export type Team_Name_Banned_Aggregate = {
  __typename?: 'team_name_banned_aggregate';
  aggregate?: Maybe<Team_Name_Banned_Aggregate_Fields>;
  nodes: Array<Team_Name_Banned>;
};

/** aggregate fields of "team_name_banned" */
export type Team_Name_Banned_Aggregate_Fields = {
  __typename?: 'team_name_banned_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Name_Banned_Max_Fields>;
  min?: Maybe<Team_Name_Banned_Min_Fields>;
};


/** aggregate fields of "team_name_banned" */
export type Team_Name_Banned_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Name_Banned_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_name_banned". All fields are combined with a logical 'AND'. */
export type Team_Name_Banned_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Name_Banned_Bool_Exp>>;
  _not?: InputMaybe<Team_Name_Banned_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Name_Banned_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_name_banned" */
export enum Team_Name_Banned_Constraint {
  /** unique or primary key constraint */
  TeamNameBannedPkey = 'team_name_banned_pkey'
}

/** input type for inserting data into table "team_name_banned" */
export type Team_Name_Banned_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_Name_Banned_Max_Fields = {
  __typename?: 'team_name_banned_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Team_Name_Banned_Min_Fields = {
  __typename?: 'team_name_banned_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "team_name_banned" */
export type Team_Name_Banned_Mutation_Response = {
  __typename?: 'team_name_banned_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Name_Banned>;
};

/** on conflict condition type for table "team_name_banned" */
export type Team_Name_Banned_On_Conflict = {
  constraint: Team_Name_Banned_Constraint;
  update_columns?: Array<Team_Name_Banned_Update_Column>;
  where?: InputMaybe<Team_Name_Banned_Bool_Exp>;
};

/** Ordering options when selecting data from "team_name_banned". */
export type Team_Name_Banned_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_name_banned */
export type Team_Name_Banned_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "team_name_banned" */
export enum Team_Name_Banned_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "team_name_banned" */
export type Team_Name_Banned_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "team_name_banned" */
export enum Team_Name_Banned_Update_Column {
  /** column name */
  Value = 'value'
}

/** input type for inserting object relation for remote table "team" */
export type Team_Obj_Rel_Insert_Input = {
  data: Team_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Team_On_Conflict>;
};

/** on conflict condition type for table "team" */
export type Team_On_Conflict = {
  constraint: Team_Constraint;
  update_columns?: Array<Team_Update_Column>;
  where?: InputMaybe<Team_Bool_Exp>;
};

/** Ordering options when selecting data from "team". */
export type Team_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_admin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  projects_count?: InputMaybe<Order_By>;
  subscription_status?: InputMaybe<Order_By>;
  team_users_aggregate?: InputMaybe<Team_User_Aggregate_Order_By>;
};

/** primary key columns input for table: team */
export type Team_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team" */
export enum Team_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  Name = 'name',
  /** column name */
  SubscriptionStatus = 'subscription_status'
}

/** input type for updating data in table "team" */
export type Team_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  customer_id?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  subscription_status?: InputMaybe<Subscription_Status_Enum>;
};

/** update columns of table "team" */
export enum Team_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  Name = 'name',
  /** column name */
  SubscriptionStatus = 'subscription_status'
}

/** columns and relationships of "team_user" */
export type Team_User = {
  __typename?: 'team_user';
  role: Team_User_Role_Enum;
  status: Team_User_Status_Enum;
  /** An object relationship */
  team: Team;
  team_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "team_user" */
export type Team_User_Aggregate = {
  __typename?: 'team_user_aggregate';
  aggregate?: Maybe<Team_User_Aggregate_Fields>;
  nodes: Array<Team_User>;
};

/** aggregate fields of "team_user" */
export type Team_User_Aggregate_Fields = {
  __typename?: 'team_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_User_Max_Fields>;
  min?: Maybe<Team_User_Min_Fields>;
};


/** aggregate fields of "team_user" */
export type Team_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_user" */
export type Team_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_User_Max_Order_By>;
  min?: InputMaybe<Team_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "team_user" */
export type Team_User_Arr_Rel_Insert_Input = {
  data: Array<Team_User_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Team_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_user". All fields are combined with a logical 'AND'. */
export type Team_User_Bool_Exp = {
  _and?: InputMaybe<Array<Team_User_Bool_Exp>>;
  _not?: InputMaybe<Team_User_Bool_Exp>;
  _or?: InputMaybe<Array<Team_User_Bool_Exp>>;
  role?: InputMaybe<Team_User_Role_Enum_Comparison_Exp>;
  status?: InputMaybe<Team_User_Status_Enum_Comparison_Exp>;
  team?: InputMaybe<Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_user" */
export enum Team_User_Constraint {
  /** unique or primary key constraint */
  TeamUserPkey = 'team_user_pkey'
}

/** input type for inserting data into table "team_user" */
export type Team_User_Insert_Input = {
  role?: InputMaybe<Team_User_Role_Enum>;
  status?: InputMaybe<Team_User_Status_Enum>;
  team?: InputMaybe<Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_User_Max_Fields = {
  __typename?: 'team_user_max_fields';
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "team_user" */
export type Team_User_Max_Order_By = {
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_User_Min_Fields = {
  __typename?: 'team_user_min_fields';
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "team_user" */
export type Team_User_Min_Order_By = {
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_user" */
export type Team_User_Mutation_Response = {
  __typename?: 'team_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_User>;
};

/** on conflict condition type for table "team_user" */
export type Team_User_On_Conflict = {
  constraint: Team_User_Constraint;
  update_columns?: Array<Team_User_Update_Column>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};

/** Ordering options when selecting data from "team_user". */
export type Team_User_Order_By = {
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_user */
export type Team_User_Pk_Columns_Input = {
  team_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** columns and relationships of "team_user_role" */
export type Team_User_Role = {
  __typename?: 'team_user_role';
  value: Scalars['String'];
};

/** aggregated selection of "team_user_role" */
export type Team_User_Role_Aggregate = {
  __typename?: 'team_user_role_aggregate';
  aggregate?: Maybe<Team_User_Role_Aggregate_Fields>;
  nodes: Array<Team_User_Role>;
};

/** aggregate fields of "team_user_role" */
export type Team_User_Role_Aggregate_Fields = {
  __typename?: 'team_user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_User_Role_Max_Fields>;
  min?: Maybe<Team_User_Role_Min_Fields>;
};


/** aggregate fields of "team_user_role" */
export type Team_User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_user_role". All fields are combined with a logical 'AND'. */
export type Team_User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Team_User_Role_Bool_Exp>>;
  _not?: InputMaybe<Team_User_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Team_User_Role_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_user_role" */
export enum Team_User_Role_Constraint {
  /** unique or primary key constraint */
  TeamUserRolePkey = 'team_user_role_pkey'
}

export enum Team_User_Role_Enum {
  Guest = 'GUEST',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

/** Boolean expression to compare columns of type "team_user_role_enum". All fields are combined with logical 'AND'. */
export type Team_User_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Team_User_Role_Enum>;
  _in?: InputMaybe<Array<Team_User_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Team_User_Role_Enum>;
  _nin?: InputMaybe<Array<Team_User_Role_Enum>>;
};

/** input type for inserting data into table "team_user_role" */
export type Team_User_Role_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_User_Role_Max_Fields = {
  __typename?: 'team_user_role_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Team_User_Role_Min_Fields = {
  __typename?: 'team_user_role_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "team_user_role" */
export type Team_User_Role_Mutation_Response = {
  __typename?: 'team_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_User_Role>;
};

/** on conflict condition type for table "team_user_role" */
export type Team_User_Role_On_Conflict = {
  constraint: Team_User_Role_Constraint;
  update_columns?: Array<Team_User_Role_Update_Column>;
  where?: InputMaybe<Team_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "team_user_role". */
export type Team_User_Role_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_user_role */
export type Team_User_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "team_user_role" */
export enum Team_User_Role_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "team_user_role" */
export type Team_User_Role_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "team_user_role" */
export enum Team_User_Role_Update_Column {
  /** column name */
  Value = 'value'
}

/** select columns of table "team_user" */
export enum Team_User_Select_Column {
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "team_user" */
export type Team_User_Set_Input = {
  role?: InputMaybe<Team_User_Role_Enum>;
  status?: InputMaybe<Team_User_Status_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "team_user_status" */
export type Team_User_Status = {
  __typename?: 'team_user_status';
  value: Scalars['String'];
};

/** aggregated selection of "team_user_status" */
export type Team_User_Status_Aggregate = {
  __typename?: 'team_user_status_aggregate';
  aggregate?: Maybe<Team_User_Status_Aggregate_Fields>;
  nodes: Array<Team_User_Status>;
};

/** aggregate fields of "team_user_status" */
export type Team_User_Status_Aggregate_Fields = {
  __typename?: 'team_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_User_Status_Max_Fields>;
  min?: Maybe<Team_User_Status_Min_Fields>;
};


/** aggregate fields of "team_user_status" */
export type Team_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_user_status". All fields are combined with a logical 'AND'. */
export type Team_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Team_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Team_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Team_User_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_user_status" */
export enum Team_User_Status_Constraint {
  /** unique or primary key constraint */
  TeamUserStatusPkey = 'team_user_status_pkey'
}

export enum Team_User_Status_Enum {
  Basic = 'BASIC',
  Licensed = 'LICENSED'
}

/** Boolean expression to compare columns of type "team_user_status_enum". All fields are combined with logical 'AND'. */
export type Team_User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Team_User_Status_Enum>;
  _in?: InputMaybe<Array<Team_User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Team_User_Status_Enum>;
  _nin?: InputMaybe<Array<Team_User_Status_Enum>>;
};

/** input type for inserting data into table "team_user_status" */
export type Team_User_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_User_Status_Max_Fields = {
  __typename?: 'team_user_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Team_User_Status_Min_Fields = {
  __typename?: 'team_user_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "team_user_status" */
export type Team_User_Status_Mutation_Response = {
  __typename?: 'team_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_User_Status>;
};

/** on conflict condition type for table "team_user_status" */
export type Team_User_Status_On_Conflict = {
  constraint: Team_User_Status_Constraint;
  update_columns?: Array<Team_User_Status_Update_Column>;
  where?: InputMaybe<Team_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "team_user_status". */
export type Team_User_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_user_status */
export type Team_User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "team_user_status" */
export enum Team_User_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "team_user_status" */
export type Team_User_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "team_user_status" */
export enum Team_User_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "team_user" */
export enum Team_User_Update_Column {
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  auth_id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  image?: Maybe<File>;
  image_id?: Maybe<Scalars['uuid']>;
  notification_email?: Maybe<Scalars['String']>;
  /** An array relationship */
  notification_settings: Array<Settings_By_User>;
  /** An aggregate relationship */
  notification_settings_aggregate: Settings_By_User_Aggregate;
  personalization_passed?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  /** An object relationship */
  reminder_protocol?: Maybe<Reminder_Protocol>;
  reminder_protocol_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  team_users: Array<Team_User>;
  /** An aggregate relationship */
  team_users_aggregate: Team_User_Aggregate;
  username?: Maybe<Scalars['String']>;
};


/** columns and relationships of "user" */
export type UserNotification_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_By_User_Order_By>>;
  where?: InputMaybe<Settings_By_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserNotification_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_By_User_Order_By>>;
  where?: InputMaybe<Settings_By_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProject_UsersArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserProject_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserTeam_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserTeam_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Order_By>>;
  where?: InputMaybe<Team_User_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  auth_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<File_Bool_Exp>;
  image_id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_email?: InputMaybe<String_Comparison_Exp>;
  notification_settings?: InputMaybe<Settings_By_User_Bool_Exp>;
  personalization_passed?: InputMaybe<Boolean_Comparison_Exp>;
  project_users?: InputMaybe<Project_User_Bool_Exp>;
  reminder_protocol?: InputMaybe<Reminder_Protocol_Bool_Exp>;
  reminder_protocol_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_users?: InputMaybe<Team_User_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserAuthIdKey = 'user_auth_id_key',
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserImageIdKey = 'user_image_id_key',
  /** unique or primary key constraint */
  UserPhotoIdKey = 'user_photo_id_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  auth_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<File_Obj_Rel_Insert_Input>;
  image_id?: InputMaybe<Scalars['uuid']>;
  notification_email?: InputMaybe<Scalars['String']>;
  notification_settings?: InputMaybe<Settings_By_User_Arr_Rel_Insert_Input>;
  personalization_passed?: InputMaybe<Scalars['Boolean']>;
  project_users?: InputMaybe<Project_User_Arr_Rel_Insert_Input>;
  reminder_protocol?: InputMaybe<Reminder_Protocol_Obj_Rel_Insert_Input>;
  reminder_protocol_id?: InputMaybe<Scalars['uuid']>;
  team_users?: InputMaybe<Team_User_Arr_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  auth_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['uuid']>;
  notification_email?: Maybe<Scalars['String']>;
  reminder_protocol_id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  auth_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  notification_email?: InputMaybe<Order_By>;
  reminder_protocol_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  auth_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['uuid']>;
  notification_email?: Maybe<Scalars['String']>;
  reminder_protocol_id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  auth_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_id?: InputMaybe<Order_By>;
  notification_email?: InputMaybe<Order_By>;
  reminder_protocol_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  auth_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<File_Order_By>;
  image_id?: InputMaybe<Order_By>;
  notification_email?: InputMaybe<Order_By>;
  notification_settings_aggregate?: InputMaybe<Settings_By_User_Aggregate_Order_By>;
  personalization_passed?: InputMaybe<Order_By>;
  project_users_aggregate?: InputMaybe<Project_User_Aggregate_Order_By>;
  reminder_protocol?: InputMaybe<Reminder_Protocol_Order_By>;
  reminder_protocol_id?: InputMaybe<Order_By>;
  team_users_aggregate?: InputMaybe<Team_User_Aggregate_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AuthId = 'auth_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  NotificationEmail = 'notification_email',
  /** column name */
  PersonalizationPassed = 'personalization_passed',
  /** column name */
  ReminderProtocolId = 'reminder_protocol_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  auth_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image_id?: InputMaybe<Scalars['uuid']>;
  notification_email?: InputMaybe<Scalars['String']>;
  personalization_passed?: InputMaybe<Scalars['Boolean']>;
  reminder_protocol_id?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AuthId = 'auth_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  NotificationEmail = 'notification_email',
  /** column name */
  PersonalizationPassed = 'personalization_passed',
  /** column name */
  ReminderProtocolId = 'reminder_protocol_id',
  /** column name */
  Username = 'username'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "web_push_subscription" */
export type Web_Push_Subscription = {
  __typename?: 'web_push_subscription';
  auth: Scalars['String'];
  browser_fingerprint: Scalars['String'];
  endpoint: Scalars['String'];
  p256dh: Scalars['String'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "web_push_subscription" */
export type Web_Push_Subscription_Aggregate = {
  __typename?: 'web_push_subscription_aggregate';
  aggregate?: Maybe<Web_Push_Subscription_Aggregate_Fields>;
  nodes: Array<Web_Push_Subscription>;
};

/** aggregate fields of "web_push_subscription" */
export type Web_Push_Subscription_Aggregate_Fields = {
  __typename?: 'web_push_subscription_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Web_Push_Subscription_Max_Fields>;
  min?: Maybe<Web_Push_Subscription_Min_Fields>;
};


/** aggregate fields of "web_push_subscription" */
export type Web_Push_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Web_Push_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "web_push_subscription". All fields are combined with a logical 'AND'. */
export type Web_Push_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Web_Push_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Web_Push_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Web_Push_Subscription_Bool_Exp>>;
  auth?: InputMaybe<String_Comparison_Exp>;
  browser_fingerprint?: InputMaybe<String_Comparison_Exp>;
  endpoint?: InputMaybe<String_Comparison_Exp>;
  p256dh?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "web_push_subscription" */
export enum Web_Push_Subscription_Constraint {
  /** unique or primary key constraint */
  WebPushSubscriptionPkey = 'web_push_subscription_pkey'
}

/** input type for inserting data into table "web_push_subscription" */
export type Web_Push_Subscription_Insert_Input = {
  auth?: InputMaybe<Scalars['String']>;
  browser_fingerprint?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  p256dh?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Web_Push_Subscription_Max_Fields = {
  __typename?: 'web_push_subscription_max_fields';
  auth?: Maybe<Scalars['String']>;
  browser_fingerprint?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  p256dh?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Web_Push_Subscription_Min_Fields = {
  __typename?: 'web_push_subscription_min_fields';
  auth?: Maybe<Scalars['String']>;
  browser_fingerprint?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  p256dh?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "web_push_subscription" */
export type Web_Push_Subscription_Mutation_Response = {
  __typename?: 'web_push_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Web_Push_Subscription>;
};

/** on conflict condition type for table "web_push_subscription" */
export type Web_Push_Subscription_On_Conflict = {
  constraint: Web_Push_Subscription_Constraint;
  update_columns?: Array<Web_Push_Subscription_Update_Column>;
  where?: InputMaybe<Web_Push_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "web_push_subscription". */
export type Web_Push_Subscription_Order_By = {
  auth?: InputMaybe<Order_By>;
  browser_fingerprint?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  p256dh?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: web_push_subscription */
export type Web_Push_Subscription_Pk_Columns_Input = {
  auth: Scalars['String'];
  browser_fingerprint: Scalars['String'];
  p256dh: Scalars['String'];
  user_id: Scalars['String'];
};

/** select columns of table "web_push_subscription" */
export enum Web_Push_Subscription_Select_Column {
  /** column name */
  Auth = 'auth',
  /** column name */
  BrowserFingerprint = 'browser_fingerprint',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  P256dh = 'p256dh',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "web_push_subscription" */
export type Web_Push_Subscription_Set_Input = {
  auth?: InputMaybe<Scalars['String']>;
  browser_fingerprint?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  p256dh?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "web_push_subscription" */
export enum Web_Push_Subscription_Update_Column {
  /** column name */
  Auth = 'auth',
  /** column name */
  BrowserFingerprint = 'browser_fingerprint',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  P256dh = 'p256dh',
  /** column name */
  UserId = 'user_id'
}

export type AvailableTeamFragment = { __typename?: 'team', id: string, domain: string, name: string, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export type CustomerFragment = { __typename?: 'Customer', email?: string | null, id: string, payment_method?: { __typename?: 'PaymentMethod', id: string, last4?: string | null } | null };

export type FileFragment = { __typename?: 'file', id: string, name: string, url?: string | null };

export type InvoiceFragment = { __typename?: 'Invoice', id?: string | null, date: number, description?: string | null, status: InvoiceStatus, currency: string, total: number };

export type NotificationFragment = { __typename?: 'notification_receiver', checked_at?: any | null, notification: { __typename?: 'notification', id: string, old?: any | null, new?: any | null, type: Notification_Type_Enum, target?: string | null, created_at: any, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } } };

export type ProjectFragment = { __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null };

export type ProjectDashboardFragment = { __typename?: 'project_dashboard', id?: string | null, name?: string | null, deadline?: string | null, link?: string | null, status?: string | null, user_role?: string | null, user_status?: string | null, created_at?: any | null, display_status?: string | null, team_id?: string | null, external_name?: string | null, external_link?: string | null, driver?: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null };

export type ProjectDashboardAggregateFragment = { __typename?: 'project_dashboard_aggregate', aggregate?: { __typename?: 'project_dashboard_aggregate_fields', count: number } | null };

export type ProjectTeamFragment = { __typename?: 'project', project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, status: Project_User_Status_Enum, note?: string | null, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }> };

export type ProjectUserFragment = { __typename?: 'project_user', project_id: string, user_id: string, role: Project_User_Role_Enum, status: Project_User_Status_Enum, note?: string | null, display_status: Project_User_Display_Status_Enum };

export type ReminderProtocolFragment = { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null };

export type TeamFragment = { __typename?: 'team', id: string, name: string, domain: string, customer_id?: string | null, is_admin: boolean, subscription_status?: Subscription_Status_Enum | null, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', name: string, project_users: Array<{ __typename?: 'project_user', user_id: string, role: Project_User_Role_Enum }> }> };

export type TeamUserFragment = { __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } };

export type UserFragment = { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null };

export type CheckNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckNotificationsMutation = { __typename?: 'mutation_root', update_notification_receiver?: { __typename?: 'notification_receiver_mutation_response', affected_rows: number } | null };

export type UpdateCustomerEmailMutationVariables = Exact<{
  customerId: Scalars['String'];
  email: Scalars['String'];
}>;


export type UpdateCustomerEmailMutation = { __typename?: 'mutation_root', update_customer_email: { __typename?: 'Customer', id: string, email?: string | null } };

export type CreateCustomerMutationVariables = Exact<{
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  payment_method_id: Scalars['String'];
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
}>;


export type CreateCustomerMutation = { __typename?: 'mutation_root', create_customer_by_email: { __typename?: 'Customer', email?: string | null, id: string, payment_method?: { __typename?: 'PaymentMethod', id: string, last4?: string | null } | null } };

export type UpdatePaymentMethodMutationVariables = Exact<{
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  payment_method_id: Scalars['String'];
  postal_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
}>;


export type UpdatePaymentMethodMutation = { __typename?: 'mutation_root', update_customer_payment_method: { __typename?: 'Customer', email?: string | null, id: string, payment_method?: { __typename?: 'PaymentMethod', id: string, last4?: string | null } | null } };

export type DeleteProjectMutationVariables = Exact<{
  id: Uuid_Comparison_Exp;
}>;


export type DeleteProjectMutation = { __typename?: 'mutation_root', delete_project?: { __typename?: 'project_mutation_response', affected_rows: number } | null };

export type DeletePushSubscriptionMutationVariables = Exact<{
  where: Web_Push_Subscription_Bool_Exp;
}>;


export type DeletePushSubscriptionMutation = { __typename?: 'mutation_root', delete_web_push_subscription?: { __typename?: 'web_push_subscription_mutation_response', affected_rows: number } | null };

export type DeleteTeamMutationVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type DeleteTeamMutation = { __typename?: 'mutation_root', delete_team_and_invoice_now: { __typename?: 'UpdatedTeam', id: string } };

export type DeleteTeamUserMutationVariables = Exact<{
  where: Team_User_Bool_Exp;
}>;


export type DeleteTeamUserMutation = { __typename?: 'mutation_root', delete_team_user?: { __typename?: 'team_user_mutation_response', affected_rows: number } | null };

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = { __typename?: 'mutation_root', delete_account: { __typename?: 'DeletedUser', id: string } };

export type InsertNotificationSettingsMutationVariables = Exact<{
  data: Array<Notification_Setting_Insert_Input> | Notification_Setting_Insert_Input;
}>;


export type InsertNotificationSettingsMutation = { __typename?: 'mutation_root', insert_notification_setting?: { __typename?: 'notification_setting_mutation_response', affected_rows: number } | null };

export type InsertProjectUserOneMutationVariables = Exact<{
  object: Project_User_Insert_Input;
}>;


export type InsertProjectUserOneMutation = { __typename?: 'mutation_root', insert_project_user_one?: { __typename?: 'project_user', project_id: string } | null };

export type InsertPushSubscriptionMutationVariables = Exact<{
  data: Web_Push_Subscription_Insert_Input;
}>;


export type InsertPushSubscriptionMutation = { __typename?: 'mutation_root', insert_web_push_subscription_one?: { __typename?: 'web_push_subscription', auth: string } | null };

export type InsertReminderProtocolOneMutationVariables = Exact<{
  object: Reminder_Protocol_Insert_Input;
  onConflict?: InputMaybe<Reminder_Protocol_On_Conflict>;
}>;


export type InsertReminderProtocolOneMutation = { __typename?: 'mutation_root', insert_reminder_protocol_one?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null };

export type InsertTeamOneMutationVariables = Exact<{
  domain: Scalars['String'];
  name: Scalars['String'];
}>;


export type InsertTeamOneMutation = { __typename?: 'mutation_root', insert_team_one?: { __typename?: 'team', id: string, name: string, domain: string, customer_id?: string | null, is_admin: boolean, subscription_status?: Subscription_Status_Enum | null, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', name: string, project_users: Array<{ __typename?: 'project_user', user_id: string, role: Project_User_Role_Enum }> }> } | null };

export type InsertTeamUsersMutationVariables = Exact<{
  objects: Array<Team_User_Insert_Input> | Team_User_Insert_Input;
  onConflict?: InputMaybe<Team_User_On_Conflict>;
}>;


export type InsertTeamUsersMutation = { __typename?: 'mutation_root', insert_team_user?: { __typename?: 'team_user_mutation_response', affected_rows: number } | null };

export type SetProjectMutationVariables = Exact<{
  object: Project_Insert_Input;
  onConflict?: InputMaybe<Project_On_Conflict>;
}>;


export type SetProjectMutation = { __typename?: 'mutation_root', insert_project_one?: { __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null };

export type DeleteProjectUsersMutationVariables = Exact<{
  where: Project_User_Bool_Exp;
}>;


export type DeleteProjectUsersMutation = { __typename?: 'mutation_root', delete_project_user?: { __typename?: 'project_user_mutation_response', affected_rows: number } | null };

export type TransferOwnershipMutationVariables = Exact<{
  team_id: Scalars['String'];
  future_owner_user_id: Scalars['String'];
}>;


export type TransferOwnershipMutation = { __typename?: 'mutation_root', transfer_ownership: { __typename?: 'UpdatedTeam', id: string } };

export type UpdateProjectUserDisplayStatusMutationVariables = Exact<{
  where: Project_User_Bool_Exp;
  value: Project_User_Display_Status_Enum;
}>;


export type UpdateProjectUserDisplayStatusMutation = { __typename?: 'mutation_root', update_project_user?: { __typename?: 'project_user_mutation_response', affected_rows: number } | null };

export type UpdateProjectUserMutationVariables = Exact<{
  where: Project_User_Bool_Exp;
  data?: InputMaybe<Project_User_Set_Input>;
}>;


export type UpdateProjectUserMutation = { __typename?: 'mutation_root', update_project_user?: { __typename?: 'project_user_mutation_response', affected_rows: number } | null };

export type UpdateTeamMutationVariables = Exact<{
  _set?: InputMaybe<Team_Set_Input>;
  pk_columns: Team_Pk_Columns_Input;
}>;


export type UpdateTeamMutation = { __typename?: 'mutation_root', update_team_by_pk?: { __typename?: 'team', id: string, name: string } | null };

export type UpdateTeamUserRoleMutationVariables = Exact<{
  role: Team_User_Role_Enum;
  where: Team_User_Bool_Exp;
}>;


export type UpdateTeamUserRoleMutation = { __typename?: 'mutation_root', update_team_user?: { __typename?: 'team_user_mutation_response', affected_rows: number } | null };

export type UpdateTeamUserStatusMutationVariables = Exact<{
  status: Team_User_Status_Enum;
  where: Team_User_Bool_Exp;
}>;


export type UpdateTeamUserStatusMutation = { __typename?: 'mutation_root', update_team_user?: { __typename?: 'team_user_mutation_response', affected_rows: number } | null };

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  _set?: InputMaybe<User_Set_Input>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_user?: { __typename?: 'user_mutation_response', affected_rows: number } | null };

export type UploadFileMutationVariables = Exact<{
  content_type: Scalars['String'];
  name: Scalars['String'];
}>;


export type UploadFileMutation = { __typename?: 'mutation_root', upload_file?: { __typename?: 'file_payload', url: string, file_id: string } | null };

export type AccountByEmailQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type AccountByEmailQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export type AvailableTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableTeamsQuery = { __typename?: 'query_root', team: Array<{ __typename?: 'team', id: string, domain: string, name: string, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> }> };

export type CustomerQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type CustomerQuery = { __typename?: 'query_root', customer: { __typename?: 'Customer', email?: string | null, id: string, payment_method?: { __typename?: 'PaymentMethod', id: string, last4?: string | null } | null } };

export type InvoicesQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type InvoicesQuery = { __typename?: 'query_root', customer_invoices: Array<{ __typename?: 'Invoice', id?: string | null, date: number, description?: string | null, status: InvoiceStatus, currency: string, total: number }> };

export type UpcomingInvoiceQueryVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type UpcomingInvoiceQuery = { __typename?: 'query_root', customer_upcoming_invoice: { __typename?: 'Invoice', id?: string | null, date: number, description?: string | null, status: InvoiceStatus, currency: string, total: number } };

export type InvoiceWithPdfQueryVariables = Exact<{
  customer_id: Scalars['String'];
  invoice_id: Scalars['String'];
  team_id: Scalars['String'];
}>;


export type InvoiceWithPdfQuery = { __typename?: 'query_root', get_invoice_pdf: { __typename?: 'InvoiceWithPDF', id?: string | null, date: number, description?: string | null, status: InvoiceStatus, total: number, currency: string, invoice_pdf: string } };

export type NotificationSettingsQueryVariables = Exact<{
  where: Settings_By_User_Bool_Exp;
}>;


export type NotificationSettingsQuery = { __typename?: 'query_root', settings_by_user: Array<{ __typename?: 'settings_by_user', id?: string | null, user_id?: string | null, type?: string | null, action?: string | null, destination?: string | null, fields?: any | null, active?: boolean | null }> };

export type NotificationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type NotificationQuery = { __typename?: 'query_root', notification_receiver: Array<{ __typename?: 'notification_receiver', checked_at?: any | null, notification: { __typename?: 'notification', id: string, old?: any | null, new?: any | null, type: Notification_Type_Enum, target?: string | null, created_at: any, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } } }> };

export type ProjectQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ProjectQuery = { __typename?: 'query_root', project_by_pk?: { __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null };

export type ProjectDashboardByIdQueryVariables = Exact<{
  arId: Scalars['uuid'];
}>;


export type ProjectDashboardByIdQuery = { __typename?: 'query_root', project_dashboard: Array<{ __typename?: 'project_dashboard', id?: string | null, name?: string | null, deadline?: string | null, link?: string | null, status?: string | null, user_role?: string | null, user_status?: string | null, created_at?: any | null, display_status?: string | null, team_id?: string | null, external_name?: string | null, external_link?: string | null, driver?: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null }> };

export type ProjectDashboardQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Dashboard_Order_By> | Project_Dashboard_Order_By>;
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
}>;


export type ProjectDashboardQuery = { __typename?: 'query_root', project_dashboard: Array<{ __typename?: 'project_dashboard', id?: string | null, name?: string | null, deadline?: string | null, link?: string | null, status?: string | null, user_role?: string | null, user_status?: string | null, created_at?: any | null, display_status?: string | null, team_id?: string | null, external_name?: string | null, external_link?: string | null, driver?: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null }> };

export type ProjectTeamQueryVariables = Exact<{
  arId: Scalars['uuid'];
}>;


export type ProjectTeamQuery = { __typename?: 'query_root', project: Array<{ __typename?: 'project', project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, status: Project_User_Status_Enum, note?: string | null, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }> }> };

export type ProjectUserQueryVariables = Exact<{
  where: Project_User_Bool_Exp;
}>;


export type ProjectUserQuery = { __typename?: 'query_root', project_user: Array<{ __typename?: 'project_user', project_id: string, user_id: string, role: Project_User_Role_Enum, status: Project_User_Status_Enum, note?: string | null, display_status: Project_User_Display_Status_Enum }> };

export type TeamQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TeamQuery = { __typename?: 'query_root', team_by_pk?: { __typename?: 'team', id: string, name: string, domain: string, customer_id?: string | null, is_admin: boolean, subscription_status?: Subscription_Status_Enum | null, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', name: string, project_users: Array<{ __typename?: 'project_user', user_id: string, role: Project_User_Role_Enum }> }> } | null };

export type UserQueryVariables = Exact<{
  where: User_Bool_Exp;
}>;


export type UserQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export type UserByPkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserByPkQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, team_id: string }>, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null };

export type WebPushSubscriptionByFingerprintQueryVariables = Exact<{
  fingerprint: Scalars['String'];
}>;


export type WebPushSubscriptionByFingerprintQuery = { __typename?: 'query_root', web_push_subscription_aggregate: { __typename?: 'web_push_subscription_aggregate', aggregate?: { __typename?: 'web_push_subscription_aggregate_fields', count: number } | null } };

export type AccountSubscriptionVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type AccountSubscription = { __typename?: 'subscription_root', user: Array<{ __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export type Available_TeamsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type Available_TeamsSubscription = { __typename?: 'subscription_root', team: Array<{ __typename?: 'team', id: string, domain: string, name: string, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> }> };

export type DriverSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DriverSubscription = { __typename?: 'subscription_root', user: Array<{ __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export type NotificationsSubscriptionVariables = Exact<{
  where?: InputMaybe<Notification_Receiver_Bool_Exp>;
  shouldNotGetCreatedAt?: InputMaybe<Scalars['Boolean']>;
}>;


export type NotificationsSubscription = { __typename?: 'subscription_root', notification_receiver: Array<{ __typename?: 'notification_receiver', checked_at?: any | null, notification: { __typename?: 'notification', id: string, old?: any | null, new?: any | null, type: Notification_Type_Enum, target?: string | null, created_at: any, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } } }> };

export type UncheckedNotificationCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UncheckedNotificationCountSubscription = { __typename?: 'subscription_root', notification_receiver_aggregate: { __typename?: 'notification_receiver_aggregate', aggregate?: { __typename?: 'notification_receiver_aggregate_fields', count: number } | null } };

export type NotificationCountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationCountSubscription = { __typename?: 'subscription_root', notification_receiver_aggregate: { __typename?: 'notification_receiver_aggregate', aggregate?: { __typename?: 'notification_receiver_aggregate_fields', count: number } | null } };

export type Project_OneSubscriptionVariables = Exact<{
  arId: Scalars['uuid'];
}>;


export type Project_OneSubscription = { __typename?: 'subscription_root', project: Array<{ __typename?: 'project', project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, status: Project_User_Status_Enum, note?: string | null, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }> }> };

export type Project_DashboardSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Dashboard_Order_By> | Project_Dashboard_Order_By>;
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
}>;


export type Project_DashboardSubscription = { __typename?: 'subscription_root', project_dashboard: Array<{ __typename?: 'project_dashboard', id?: string | null, name?: string | null, deadline?: string | null, link?: string | null, status?: string | null, user_role?: string | null, user_status?: string | null, created_at?: any | null, display_status?: string | null, team_id?: string | null, external_name?: string | null, external_link?: string | null, driver?: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } | null }> };

export type Project_Dashboard_AggregateSubscriptionVariables = Exact<{
  where?: InputMaybe<Project_Dashboard_Bool_Exp>;
}>;


export type Project_Dashboard_AggregateSubscription = { __typename?: 'subscription_root', project_dashboard_aggregate: { __typename?: 'project_dashboard_aggregate', aggregate?: { __typename?: 'project_dashboard_aggregate_fields', count: number } | null } };

export type ProjectsSubscriptionVariables = Exact<{
  where: Project_Bool_Exp;
}>;


export type ProjectsSubscription = { __typename?: 'subscription_root', project: Array<{ __typename?: 'project', id: string, team_id: string, name: string, deadline: string, link: string, external_name?: string | null, external_link?: string | null, created_at: any, project_users: Array<{ __typename?: 'project_user', role: Project_User_Role_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export type Team_SubscriptionSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Team_SubscriptionSubscription = { __typename?: 'subscription_root', team_by_pk?: { __typename?: 'team', id: string, name: string, domain: string, customer_id?: string | null, is_admin: boolean, subscription_status?: Subscription_Status_Enum | null, created_at: any, team_users: Array<{ __typename?: 'team_user', role: Team_User_Role_Enum, status: Team_User_Status_Enum, user: { __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null } }>, projects: Array<{ __typename?: 'project', name: string, project_users: Array<{ __typename?: 'project_user', user_id: string, role: Project_User_Role_Enum }> }> } | null };

export type UsersSubscriptionVariables = Exact<{
  where: User_Bool_Exp;
}>;


export type UsersSubscription = { __typename?: 'subscription_root', user: Array<{ __typename?: 'user', id: string, username?: string | null, email: string, notification_email?: string | null, personalization_passed?: boolean | null, team_users: Array<{ __typename?: 'team_user', team_id: string }>, image?: { __typename?: 'file', id: string, name: string, url?: string | null } | null, reminder_protocol?: { __typename?: 'reminder_protocol', id: string, days: any, type: Reminder_Type_Enum, time?: any | null } | null }> };

export const FileFragmentDoc = gql`
    fragment File on file {
  id
  name
  url
}
    `;
export const ReminderProtocolFragmentDoc = gql`
    fragment ReminderProtocol on reminder_protocol {
  id
  days
  type
  time
}
    `;
export const UserFragmentDoc = gql`
    fragment User on user {
  id
  username
  email
  notification_email
  image {
    ...File
  }
  reminder_protocol {
    ...ReminderProtocol
  }
  personalization_passed
}
    ${FileFragmentDoc}
${ReminderProtocolFragmentDoc}`;
export const TeamUserFragmentDoc = gql`
    fragment TeamUser on team_user {
  user {
    ...User
  }
  role
  status
}
    ${UserFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment Project on project {
  id
  team_id
  name
  deadline
  link
  external_name
  external_link
  created_at
  project_users {
    user {
      ...User
    }
    role
  }
  reminder_protocol {
    ...ReminderProtocol
  }
}
    ${UserFragmentDoc}
${ReminderProtocolFragmentDoc}`;
export const AvailableTeamFragmentDoc = gql`
    fragment AvailableTeam on team {
  id
  domain
  name
  team_users {
    ...TeamUser
  }
  projects {
    ...Project
  }
  created_at
}
    ${TeamUserFragmentDoc}
${ProjectFragmentDoc}`;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  email
  id
  payment_method {
    id
    last4
  }
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on Invoice {
  id
  date
  description
  status
  currency
  total
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on notification_receiver {
  notification {
    id
    old
    new
    type
    target
    user {
      ...User
    }
    created_at
  }
  checked_at
}
    ${UserFragmentDoc}`;
export const ProjectDashboardFragmentDoc = gql`
    fragment ProjectDashboard on project_dashboard {
  id
  name
  deadline
  link
  driver {
    ...User
  }
  status
  user_role
  user_status
  created_at
  display_status
  team_id
  external_name
  external_link
}
    ${UserFragmentDoc}`;
export const ProjectDashboardAggregateFragmentDoc = gql`
    fragment ProjectDashboardAggregate on project_dashboard_aggregate {
  aggregate {
    count
  }
}
    `;
export const ProjectTeamFragmentDoc = gql`
    fragment ProjectTeam on project {
  project_users {
    role
    status
    note
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export const ProjectUserFragmentDoc = gql`
    fragment ProjectUser on project_user {
  project_id
  user_id
  role
  status
  note
  display_status
}
    `;
export const TeamFragmentDoc = gql`
    fragment Team on team {
  id
  name
  domain
  customer_id
  is_admin
  subscription_status
  created_at
  team_users {
    ...TeamUser
  }
  projects {
    name
    project_users {
      user_id
      role
    }
  }
}
    ${TeamUserFragmentDoc}`;
export const CheckNotificationsDocument = gql`
    mutation checkNotifications {
  update_notification_receiver(
    _set: {checked_at: now}
    where: {checked_at: {_is_null: true}}
  ) {
    affected_rows
  }
}
    `;
export type CheckNotificationsMutationFn = ApolloReactCommon.MutationFunction<CheckNotificationsMutation, CheckNotificationsMutationVariables>;

/**
 * __useCheckNotificationsMutation__
 *
 * To run a mutation, you first call `useCheckNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkNotificationsMutation, { data, loading, error }] = useCheckNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckNotificationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckNotificationsMutation, CheckNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CheckNotificationsMutation, CheckNotificationsMutationVariables>(CheckNotificationsDocument, options);
      }
export type CheckNotificationsMutationHookResult = ReturnType<typeof useCheckNotificationsMutation>;
export type CheckNotificationsMutationResult = ApolloReactCommon.MutationResult<CheckNotificationsMutation>;
export type CheckNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckNotificationsMutation, CheckNotificationsMutationVariables>;
export const UpdateCustomerEmailDocument = gql`
    mutation updateCustomerEmail($customerId: String!, $email: String!) {
  update_customer_email(customer_id: $customerId, email: $email) {
    id
    email
  }
}
    `;
export type UpdateCustomerEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>;

/**
 * __useUpdateCustomerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerEmailMutation, { data, loading, error }] = useUpdateCustomerEmailMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateCustomerEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>(UpdateCustomerEmailDocument, options);
      }
export type UpdateCustomerEmailMutationHookResult = ReturnType<typeof useUpdateCustomerEmailMutation>;
export type UpdateCustomerEmailMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerEmailMutation>;
export type UpdateCustomerEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerEmailMutation, UpdateCustomerEmailMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($city: String!, $country: String!, $email: String!, $line1: String!, $line2: String, $payment_method_id: String!, $postal_code: String, $state: String, $teamId: String!) {
  create_customer_by_email(
    city: $city
    country: $country
    email: $email
    line1: $line1
    line2: $line2
    payment_method_id: $payment_method_id
    postal_code: $postal_code
    state: $state
    team_id: $teamId
  ) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      email: // value for 'email'
 *      line1: // value for 'line1'
 *      line2: // value for 'line2'
 *      payment_method_id: // value for 'payment_method_id'
 *      postal_code: // value for 'postal_code'
 *      state: // value for 'state'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation updatePaymentMethod($city: String!, $country: String!, $line1: String!, $line2: String, $payment_method_id: String!, $postal_code: String, $state: String, $customerId: String!) {
  update_customer_payment_method(
    city: $city
    country: $country
    line1: $line1
    line2: $line2
    payment_method_id: $payment_method_id
    postal_code: $postal_code
    state: $state
    customer_id: $customerId
  ) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export type UpdatePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      line1: // value for 'line1'
 *      line2: // value for 'line2'
 *      payment_method_id: // value for 'payment_method_id'
 *      postal_code: // value for 'postal_code'
 *      state: // value for 'state'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, options);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($id: uuid_comparison_exp!) {
  delete_project(where: {id: $id}) {
    affected_rows
  }
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const DeletePushSubscriptionDocument = gql`
    mutation deletePushSubscription($where: web_push_subscription_bool_exp!) {
  delete_web_push_subscription(where: $where) {
    affected_rows
  }
}
    `;
export type DeletePushSubscriptionMutationFn = ApolloReactCommon.MutationFunction<DeletePushSubscriptionMutation, DeletePushSubscriptionMutationVariables>;

/**
 * __useDeletePushSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeletePushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePushSubscriptionMutation, { data, loading, error }] = useDeletePushSubscriptionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePushSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePushSubscriptionMutation, DeletePushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePushSubscriptionMutation, DeletePushSubscriptionMutationVariables>(DeletePushSubscriptionDocument, options);
      }
export type DeletePushSubscriptionMutationHookResult = ReturnType<typeof useDeletePushSubscriptionMutation>;
export type DeletePushSubscriptionMutationResult = ApolloReactCommon.MutationResult<DeletePushSubscriptionMutation>;
export type DeletePushSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePushSubscriptionMutation, DeletePushSubscriptionMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($teamId: String!) {
  delete_team_and_invoice_now(team_id: $teamId) {
    id
  }
}
    `;
export type DeleteTeamMutationFn = ApolloReactCommon.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = ApolloReactCommon.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const DeleteTeamUserDocument = gql`
    mutation deleteTeamUser($where: team_user_bool_exp!) {
  delete_team_user(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteTeamUserMutationFn = ApolloReactCommon.MutationFunction<DeleteTeamUserMutation, DeleteTeamUserMutationVariables>;

/**
 * __useDeleteTeamUserMutation__
 *
 * To run a mutation, you first call `useDeleteTeamUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamUserMutation, { data, loading, error }] = useDeleteTeamUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTeamUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTeamUserMutation, DeleteTeamUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTeamUserMutation, DeleteTeamUserMutationVariables>(DeleteTeamUserDocument, options);
      }
export type DeleteTeamUserMutationHookResult = ReturnType<typeof useDeleteTeamUserMutation>;
export type DeleteTeamUserMutationResult = ApolloReactCommon.MutationResult<DeleteTeamUserMutation>;
export type DeleteTeamUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTeamUserMutation, DeleteTeamUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser {
  delete_account {
    id
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const InsertNotificationSettingsDocument = gql`
    mutation insertNotificationSettings($data: [notification_setting_insert_input!]!) {
  insert_notification_setting(
    objects: $data
    on_conflict: {constraint: notification_setting_uniq_constraint, update_columns: [active]}
  ) {
    affected_rows
  }
}
    `;
export type InsertNotificationSettingsMutationFn = ApolloReactCommon.MutationFunction<InsertNotificationSettingsMutation, InsertNotificationSettingsMutationVariables>;

/**
 * __useInsertNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useInsertNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotificationSettingsMutation, { data, loading, error }] = useInsertNotificationSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertNotificationSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertNotificationSettingsMutation, InsertNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InsertNotificationSettingsMutation, InsertNotificationSettingsMutationVariables>(InsertNotificationSettingsDocument, options);
      }
export type InsertNotificationSettingsMutationHookResult = ReturnType<typeof useInsertNotificationSettingsMutation>;
export type InsertNotificationSettingsMutationResult = ApolloReactCommon.MutationResult<InsertNotificationSettingsMutation>;
export type InsertNotificationSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertNotificationSettingsMutation, InsertNotificationSettingsMutationVariables>;
export const InsertProjectUserOneDocument = gql`
    mutation insertProjectUserOne($object: project_user_insert_input!) {
  insert_project_user_one(object: $object) {
    project_id
  }
}
    `;
export type InsertProjectUserOneMutationFn = ApolloReactCommon.MutationFunction<InsertProjectUserOneMutation, InsertProjectUserOneMutationVariables>;

/**
 * __useInsertProjectUserOneMutation__
 *
 * To run a mutation, you first call `useInsertProjectUserOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectUserOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectUserOneMutation, { data, loading, error }] = useInsertProjectUserOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertProjectUserOneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertProjectUserOneMutation, InsertProjectUserOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InsertProjectUserOneMutation, InsertProjectUserOneMutationVariables>(InsertProjectUserOneDocument, options);
      }
export type InsertProjectUserOneMutationHookResult = ReturnType<typeof useInsertProjectUserOneMutation>;
export type InsertProjectUserOneMutationResult = ApolloReactCommon.MutationResult<InsertProjectUserOneMutation>;
export type InsertProjectUserOneMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertProjectUserOneMutation, InsertProjectUserOneMutationVariables>;
export const InsertPushSubscriptionDocument = gql`
    mutation insertPushSubscription($data: web_push_subscription_insert_input!) {
  insert_web_push_subscription_one(
    object: $data
    on_conflict: {constraint: web_push_subscription_pkey, update_columns: []}
  ) {
    auth
  }
}
    `;
export type InsertPushSubscriptionMutationFn = ApolloReactCommon.MutationFunction<InsertPushSubscriptionMutation, InsertPushSubscriptionMutationVariables>;

/**
 * __useInsertPushSubscriptionMutation__
 *
 * To run a mutation, you first call `useInsertPushSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPushSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPushSubscriptionMutation, { data, loading, error }] = useInsertPushSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertPushSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertPushSubscriptionMutation, InsertPushSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InsertPushSubscriptionMutation, InsertPushSubscriptionMutationVariables>(InsertPushSubscriptionDocument, options);
      }
export type InsertPushSubscriptionMutationHookResult = ReturnType<typeof useInsertPushSubscriptionMutation>;
export type InsertPushSubscriptionMutationResult = ApolloReactCommon.MutationResult<InsertPushSubscriptionMutation>;
export type InsertPushSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertPushSubscriptionMutation, InsertPushSubscriptionMutationVariables>;
export const InsertReminderProtocolOneDocument = gql`
    mutation insertReminderProtocolOne($object: reminder_protocol_insert_input!, $onConflict: reminder_protocol_on_conflict) {
  insert_reminder_protocol_one(object: $object, on_conflict: $onConflict) {
    ...ReminderProtocol
  }
}
    ${ReminderProtocolFragmentDoc}`;
export type InsertReminderProtocolOneMutationFn = ApolloReactCommon.MutationFunction<InsertReminderProtocolOneMutation, InsertReminderProtocolOneMutationVariables>;

/**
 * __useInsertReminderProtocolOneMutation__
 *
 * To run a mutation, you first call `useInsertReminderProtocolOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReminderProtocolOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReminderProtocolOneMutation, { data, loading, error }] = useInsertReminderProtocolOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertReminderProtocolOneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertReminderProtocolOneMutation, InsertReminderProtocolOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InsertReminderProtocolOneMutation, InsertReminderProtocolOneMutationVariables>(InsertReminderProtocolOneDocument, options);
      }
export type InsertReminderProtocolOneMutationHookResult = ReturnType<typeof useInsertReminderProtocolOneMutation>;
export type InsertReminderProtocolOneMutationResult = ApolloReactCommon.MutationResult<InsertReminderProtocolOneMutation>;
export type InsertReminderProtocolOneMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertReminderProtocolOneMutation, InsertReminderProtocolOneMutationVariables>;
export const InsertTeamOneDocument = gql`
    mutation insertTeamOne($domain: String!, $name: String!) {
  insert_team_one(object: {domain: $domain, name: $name}) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;
export type InsertTeamOneMutationFn = ApolloReactCommon.MutationFunction<InsertTeamOneMutation, InsertTeamOneMutationVariables>;

/**
 * __useInsertTeamOneMutation__
 *
 * To run a mutation, you first call `useInsertTeamOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeamOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeamOneMutation, { data, loading, error }] = useInsertTeamOneMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertTeamOneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTeamOneMutation, InsertTeamOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InsertTeamOneMutation, InsertTeamOneMutationVariables>(InsertTeamOneDocument, options);
      }
export type InsertTeamOneMutationHookResult = ReturnType<typeof useInsertTeamOneMutation>;
export type InsertTeamOneMutationResult = ApolloReactCommon.MutationResult<InsertTeamOneMutation>;
export type InsertTeamOneMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTeamOneMutation, InsertTeamOneMutationVariables>;
export const InsertTeamUsersDocument = gql`
    mutation insertTeamUsers($objects: [team_user_insert_input!]!, $onConflict: team_user_on_conflict) {
  insert_team_user(objects: $objects, on_conflict: $onConflict) {
    affected_rows
  }
}
    `;
export type InsertTeamUsersMutationFn = ApolloReactCommon.MutationFunction<InsertTeamUsersMutation, InsertTeamUsersMutationVariables>;

/**
 * __useInsertTeamUsersMutation__
 *
 * To run a mutation, you first call `useInsertTeamUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeamUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeamUsersMutation, { data, loading, error }] = useInsertTeamUsersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertTeamUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTeamUsersMutation, InsertTeamUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InsertTeamUsersMutation, InsertTeamUsersMutationVariables>(InsertTeamUsersDocument, options);
      }
export type InsertTeamUsersMutationHookResult = ReturnType<typeof useInsertTeamUsersMutation>;
export type InsertTeamUsersMutationResult = ApolloReactCommon.MutationResult<InsertTeamUsersMutation>;
export type InsertTeamUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTeamUsersMutation, InsertTeamUsersMutationVariables>;
export const SetProjectDocument = gql`
    mutation setProject($object: project_insert_input!, $onConflict: project_on_conflict) {
  insert_project_one(object: $object, on_conflict: $onConflict) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type SetProjectMutationFn = ApolloReactCommon.MutationFunction<SetProjectMutation, SetProjectMutationVariables>;

/**
 * __useSetProjectMutation__
 *
 * To run a mutation, you first call `useSetProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectMutation, { data, loading, error }] = useSetProjectMutation({
 *   variables: {
 *      object: // value for 'object'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useSetProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProjectMutation, SetProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetProjectMutation, SetProjectMutationVariables>(SetProjectDocument, options);
      }
export type SetProjectMutationHookResult = ReturnType<typeof useSetProjectMutation>;
export type SetProjectMutationResult = ApolloReactCommon.MutationResult<SetProjectMutation>;
export type SetProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<SetProjectMutation, SetProjectMutationVariables>;
export const DeleteProjectUsersDocument = gql`
    mutation deleteProjectUsers($where: project_user_bool_exp!) {
  delete_project_user(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteProjectUsersMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectUsersMutation, DeleteProjectUsersMutationVariables>;

/**
 * __useDeleteProjectUsersMutation__
 *
 * To run a mutation, you first call `useDeleteProjectUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectUsersMutation, { data, loading, error }] = useDeleteProjectUsersMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteProjectUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectUsersMutation, DeleteProjectUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteProjectUsersMutation, DeleteProjectUsersMutationVariables>(DeleteProjectUsersDocument, options);
      }
export type DeleteProjectUsersMutationHookResult = ReturnType<typeof useDeleteProjectUsersMutation>;
export type DeleteProjectUsersMutationResult = ApolloReactCommon.MutationResult<DeleteProjectUsersMutation>;
export type DeleteProjectUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectUsersMutation, DeleteProjectUsersMutationVariables>;
export const TransferOwnershipDocument = gql`
    mutation transferOwnership($team_id: String!, $future_owner_user_id: String!) {
  transfer_ownership(
    team_id: $team_id
    future_owner_user_id: $future_owner_user_id
  ) {
    id
  }
}
    `;
export type TransferOwnershipMutationFn = ApolloReactCommon.MutationFunction<TransferOwnershipMutation, TransferOwnershipMutationVariables>;

/**
 * __useTransferOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOwnershipMutation, { data, loading, error }] = useTransferOwnershipMutation({
 *   variables: {
 *      team_id: // value for 'team_id'
 *      future_owner_user_id: // value for 'future_owner_user_id'
 *   },
 * });
 */
export function useTransferOwnershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferOwnershipMutation, TransferOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<TransferOwnershipMutation, TransferOwnershipMutationVariables>(TransferOwnershipDocument, options);
      }
export type TransferOwnershipMutationHookResult = ReturnType<typeof useTransferOwnershipMutation>;
export type TransferOwnershipMutationResult = ApolloReactCommon.MutationResult<TransferOwnershipMutation>;
export type TransferOwnershipMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferOwnershipMutation, TransferOwnershipMutationVariables>;
export const UpdateProjectUserDisplayStatusDocument = gql`
    mutation updateProjectUserDisplayStatus($where: project_user_bool_exp!, $value: project_user_display_status_enum!) {
  update_project_user(where: $where, _set: {display_status: $value}) {
    affected_rows
  }
}
    `;
export type UpdateProjectUserDisplayStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectUserDisplayStatusMutation, UpdateProjectUserDisplayStatusMutationVariables>;

/**
 * __useUpdateProjectUserDisplayStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectUserDisplayStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectUserDisplayStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectUserDisplayStatusMutation, { data, loading, error }] = useUpdateProjectUserDisplayStatusMutation({
 *   variables: {
 *      where: // value for 'where'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateProjectUserDisplayStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectUserDisplayStatusMutation, UpdateProjectUserDisplayStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateProjectUserDisplayStatusMutation, UpdateProjectUserDisplayStatusMutationVariables>(UpdateProjectUserDisplayStatusDocument, options);
      }
export type UpdateProjectUserDisplayStatusMutationHookResult = ReturnType<typeof useUpdateProjectUserDisplayStatusMutation>;
export type UpdateProjectUserDisplayStatusMutationResult = ApolloReactCommon.MutationResult<UpdateProjectUserDisplayStatusMutation>;
export type UpdateProjectUserDisplayStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectUserDisplayStatusMutation, UpdateProjectUserDisplayStatusMutationVariables>;
export const UpdateProjectUserDocument = gql`
    mutation updateProjectUser($where: project_user_bool_exp!, $data: project_user_set_input) {
  update_project_user(where: $where, _set: $data) {
    affected_rows
  }
}
    `;
export type UpdateProjectUserMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectUserMutation, UpdateProjectUserMutationVariables>;

/**
 * __useUpdateProjectUserMutation__
 *
 * To run a mutation, you first call `useUpdateProjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectUserMutation, { data, loading, error }] = useUpdateProjectUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectUserMutation, UpdateProjectUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateProjectUserMutation, UpdateProjectUserMutationVariables>(UpdateProjectUserDocument, options);
      }
export type UpdateProjectUserMutationHookResult = ReturnType<typeof useUpdateProjectUserMutation>;
export type UpdateProjectUserMutationResult = ApolloReactCommon.MutationResult<UpdateProjectUserMutation>;
export type UpdateProjectUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectUserMutation, UpdateProjectUserMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation updateTeam($_set: team_set_input, $pk_columns: team_pk_columns_input!) {
  update_team_by_pk(_set: $_set, pk_columns: $pk_columns) {
    id
    name
  }
}
    `;
export type UpdateTeamMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      pk_columns: // value for 'pk_columns'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = ApolloReactCommon.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const UpdateTeamUserRoleDocument = gql`
    mutation updateTeamUserRole($role: team_user_role_enum!, $where: team_user_bool_exp!) {
  update_team_user(where: $where, _set: {role: $role}) {
    affected_rows
  }
}
    `;
export type UpdateTeamUserRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamUserRoleMutation, UpdateTeamUserRoleMutationVariables>;

/**
 * __useUpdateTeamUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateTeamUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamUserRoleMutation, { data, loading, error }] = useUpdateTeamUserRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateTeamUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamUserRoleMutation, UpdateTeamUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateTeamUserRoleMutation, UpdateTeamUserRoleMutationVariables>(UpdateTeamUserRoleDocument, options);
      }
export type UpdateTeamUserRoleMutationHookResult = ReturnType<typeof useUpdateTeamUserRoleMutation>;
export type UpdateTeamUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateTeamUserRoleMutation>;
export type UpdateTeamUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamUserRoleMutation, UpdateTeamUserRoleMutationVariables>;
export const UpdateTeamUserStatusDocument = gql`
    mutation updateTeamUserStatus($status: team_user_status_enum!, $where: team_user_bool_exp!) {
  update_team_user(where: $where, _set: {status: $status}) {
    affected_rows
  }
}
    `;
export type UpdateTeamUserStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamUserStatusMutation, UpdateTeamUserStatusMutationVariables>;

/**
 * __useUpdateTeamUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTeamUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamUserStatusMutation, { data, loading, error }] = useUpdateTeamUserStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateTeamUserStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamUserStatusMutation, UpdateTeamUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateTeamUserStatusMutation, UpdateTeamUserStatusMutationVariables>(UpdateTeamUserStatusDocument, options);
      }
export type UpdateTeamUserStatusMutationHookResult = ReturnType<typeof useUpdateTeamUserStatusMutation>;
export type UpdateTeamUserStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTeamUserStatusMutation>;
export type UpdateTeamUserStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamUserStatusMutation, UpdateTeamUserStatusMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($email: String!, $_set: user_set_input) {
  update_user(where: {email: {_eq: $email}}, _set: $_set) {
    affected_rows
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($content_type: String!, $name: String!) {
  upload_file(content_type: $content_type, name: $name) {
    url
    file_id
  }
}
    `;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      content_type: // value for 'content_type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const AccountByEmailDocument = gql`
    query accountByEmail($email: String) {
  user(where: {email: {_eq: $email}}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useAccountByEmailQuery__
 *
 * To run a query within a React component, call `useAccountByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountByEmailQuery, AccountByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AccountByEmailQuery, AccountByEmailQueryVariables>(AccountByEmailDocument, options);
      }
export function useAccountByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountByEmailQuery, AccountByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AccountByEmailQuery, AccountByEmailQueryVariables>(AccountByEmailDocument, options);
        }
export type AccountByEmailQueryHookResult = ReturnType<typeof useAccountByEmailQuery>;
export type AccountByEmailLazyQueryHookResult = ReturnType<typeof useAccountByEmailLazyQuery>;
export type AccountByEmailQueryResult = ApolloReactCommon.QueryResult<AccountByEmailQuery, AccountByEmailQueryVariables>;
export const AvailableTeamsDocument = gql`
    query availableTeams {
  team(order_by: {domain: asc}) {
    ...AvailableTeam
  }
}
    ${AvailableTeamFragmentDoc}`;

/**
 * __useAvailableTeamsQuery__
 *
 * To run a query within a React component, call `useAvailableTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableTeamsQuery, AvailableTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AvailableTeamsQuery, AvailableTeamsQueryVariables>(AvailableTeamsDocument, options);
      }
export function useAvailableTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableTeamsQuery, AvailableTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AvailableTeamsQuery, AvailableTeamsQueryVariables>(AvailableTeamsDocument, options);
        }
export type AvailableTeamsQueryHookResult = ReturnType<typeof useAvailableTeamsQuery>;
export type AvailableTeamsLazyQueryHookResult = ReturnType<typeof useAvailableTeamsLazyQuery>;
export type AvailableTeamsQueryResult = ApolloReactCommon.QueryResult<AvailableTeamsQuery, AvailableTeamsQueryVariables>;
export const CustomerDocument = gql`
    query customer($customerId: String!) {
  customer(customer_id: $customerId) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const InvoicesDocument = gql`
    query invoices($customerId: String!) {
  customer_invoices(customer_id: $customerId) {
    ...Invoice
  }
}
    ${InvoiceFragmentDoc}`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = ApolloReactCommon.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const UpcomingInvoiceDocument = gql`
    query upcomingInvoice($customerId: String!) {
  customer_upcoming_invoice(customer_id: $customerId) {
    ...Invoice
  }
}
    ${InvoiceFragmentDoc}`;

/**
 * __useUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `useUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingInvoiceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpcomingInvoiceQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>(UpcomingInvoiceDocument, options);
      }
export function useUpcomingInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>(UpcomingInvoiceDocument, options);
        }
export type UpcomingInvoiceQueryHookResult = ReturnType<typeof useUpcomingInvoiceQuery>;
export type UpcomingInvoiceLazyQueryHookResult = ReturnType<typeof useUpcomingInvoiceLazyQuery>;
export type UpcomingInvoiceQueryResult = ApolloReactCommon.QueryResult<UpcomingInvoiceQuery, UpcomingInvoiceQueryVariables>;
export const InvoiceWithPdfDocument = gql`
    query invoiceWithPDF($customer_id: String!, $invoice_id: String!, $team_id: String!) {
  get_invoice_pdf(
    customer_id: $customer_id
    invoice_id: $invoice_id
    team_id: $team_id
  ) {
    id
    date
    description
    status
    total
    currency
    invoice_pdf
  }
}
    `;

/**
 * __useInvoiceWithPdfQuery__
 *
 * To run a query within a React component, call `useInvoiceWithPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceWithPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceWithPdfQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      invoice_id: // value for 'invoice_id'
 *      team_id: // value for 'team_id'
 *   },
 * });
 */
export function useInvoiceWithPdfQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InvoiceWithPdfQuery, InvoiceWithPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InvoiceWithPdfQuery, InvoiceWithPdfQueryVariables>(InvoiceWithPdfDocument, options);
      }
export function useInvoiceWithPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceWithPdfQuery, InvoiceWithPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InvoiceWithPdfQuery, InvoiceWithPdfQueryVariables>(InvoiceWithPdfDocument, options);
        }
export type InvoiceWithPdfQueryHookResult = ReturnType<typeof useInvoiceWithPdfQuery>;
export type InvoiceWithPdfLazyQueryHookResult = ReturnType<typeof useInvoiceWithPdfLazyQuery>;
export type InvoiceWithPdfQueryResult = ApolloReactCommon.QueryResult<InvoiceWithPdfQuery, InvoiceWithPdfQueryVariables>;
export const NotificationSettingsDocument = gql`
    query notificationSettings($where: settings_by_user_bool_exp!) {
  settings_by_user(where: $where) {
    id
    user_id
    type
    action
    destination
    fields
    active
  }
}
    `;

/**
 * __useNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNotificationSettingsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NotificationSettingsQuery, NotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NotificationSettingsQuery, NotificationSettingsQueryVariables>(NotificationSettingsDocument, options);
      }
export function useNotificationSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationSettingsQuery, NotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NotificationSettingsQuery, NotificationSettingsQueryVariables>(NotificationSettingsDocument, options);
        }
export type NotificationSettingsQueryHookResult = ReturnType<typeof useNotificationSettingsQuery>;
export type NotificationSettingsLazyQueryHookResult = ReturnType<typeof useNotificationSettingsLazyQuery>;
export type NotificationSettingsQueryResult = ApolloReactCommon.QueryResult<NotificationSettingsQuery, NotificationSettingsQueryVariables>;
export const NotificationDocument = gql`
    query notification($limit: Int, $offset: Int) {
  notification_receiver(
    order_by: {notification: {created_at: desc}}
    limit: $limit
    offset: $offset
  ) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
      }
export function useNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
        }
export type NotificationQueryHookResult = ReturnType<typeof useNotificationQuery>;
export type NotificationLazyQueryHookResult = ReturnType<typeof useNotificationLazyQuery>;
export type NotificationQueryResult = ApolloReactCommon.QueryResult<NotificationQuery, NotificationQueryVariables>;
export const ProjectDocument = gql`
    query project($id: uuid!) {
  project_by_pk(id: $id) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = ApolloReactCommon.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectDashboardByIdDocument = gql`
    query projectDashboardById($arId: uuid!) {
  project_dashboard(where: {id: {_eq: $arId}}) {
    ...ProjectDashboard
  }
}
    ${ProjectDashboardFragmentDoc}`;

/**
 * __useProjectDashboardByIdQuery__
 *
 * To run a query within a React component, call `useProjectDashboardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDashboardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDashboardByIdQuery({
 *   variables: {
 *      arId: // value for 'arId'
 *   },
 * });
 */
export function useProjectDashboardByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProjectDashboardByIdQuery, ProjectDashboardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProjectDashboardByIdQuery, ProjectDashboardByIdQueryVariables>(ProjectDashboardByIdDocument, options);
      }
export function useProjectDashboardByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectDashboardByIdQuery, ProjectDashboardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProjectDashboardByIdQuery, ProjectDashboardByIdQueryVariables>(ProjectDashboardByIdDocument, options);
        }
export type ProjectDashboardByIdQueryHookResult = ReturnType<typeof useProjectDashboardByIdQuery>;
export type ProjectDashboardByIdLazyQueryHookResult = ReturnType<typeof useProjectDashboardByIdLazyQuery>;
export type ProjectDashboardByIdQueryResult = ApolloReactCommon.QueryResult<ProjectDashboardByIdQuery, ProjectDashboardByIdQueryVariables>;
export const ProjectDashboardDocument = gql`
    query projectDashboard($limit: Int = 10, $offset: Int, $order_by: [project_dashboard_order_by!], $where: project_dashboard_bool_exp) {
  project_dashboard(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...ProjectDashboard
  }
}
    ${ProjectDashboardFragmentDoc}`;

/**
 * __useProjectDashboardQuery__
 *
 * To run a query within a React component, call `useProjectDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDashboardQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectDashboardQuery, ProjectDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProjectDashboardQuery, ProjectDashboardQueryVariables>(ProjectDashboardDocument, options);
      }
export function useProjectDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectDashboardQuery, ProjectDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProjectDashboardQuery, ProjectDashboardQueryVariables>(ProjectDashboardDocument, options);
        }
export type ProjectDashboardQueryHookResult = ReturnType<typeof useProjectDashboardQuery>;
export type ProjectDashboardLazyQueryHookResult = ReturnType<typeof useProjectDashboardLazyQuery>;
export type ProjectDashboardQueryResult = ApolloReactCommon.QueryResult<ProjectDashboardQuery, ProjectDashboardQueryVariables>;
export const ProjectTeamDocument = gql`
    query projectTeam($arId: uuid!) {
  project(where: {id: {_eq: $arId}}) {
    ...ProjectTeam
  }
}
    ${ProjectTeamFragmentDoc}`;

/**
 * __useProjectTeamQuery__
 *
 * To run a query within a React component, call `useProjectTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTeamQuery({
 *   variables: {
 *      arId: // value for 'arId'
 *   },
 * });
 */
export function useProjectTeamQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProjectTeamQuery, ProjectTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProjectTeamQuery, ProjectTeamQueryVariables>(ProjectTeamDocument, options);
      }
export function useProjectTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTeamQuery, ProjectTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProjectTeamQuery, ProjectTeamQueryVariables>(ProjectTeamDocument, options);
        }
export type ProjectTeamQueryHookResult = ReturnType<typeof useProjectTeamQuery>;
export type ProjectTeamLazyQueryHookResult = ReturnType<typeof useProjectTeamLazyQuery>;
export type ProjectTeamQueryResult = ApolloReactCommon.QueryResult<ProjectTeamQuery, ProjectTeamQueryVariables>;
export const ProjectUserDocument = gql`
    query projectUser($where: project_user_bool_exp!) {
  project_user(where: $where) {
    ...ProjectUser
  }
}
    ${ProjectUserFragmentDoc}`;

/**
 * __useProjectUserQuery__
 *
 * To run a query within a React component, call `useProjectUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProjectUserQuery, ProjectUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProjectUserQuery, ProjectUserQueryVariables>(ProjectUserDocument, options);
      }
export function useProjectUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectUserQuery, ProjectUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProjectUserQuery, ProjectUserQueryVariables>(ProjectUserDocument, options);
        }
export type ProjectUserQueryHookResult = ReturnType<typeof useProjectUserQuery>;
export type ProjectUserLazyQueryHookResult = ReturnType<typeof useProjectUserLazyQuery>;
export type ProjectUserQueryResult = ApolloReactCommon.QueryResult<ProjectUserQuery, ProjectUserQueryVariables>;
export const TeamDocument = gql`
    query team($id: uuid!) {
  team_by_pk(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = ApolloReactCommon.QueryResult<TeamQuery, TeamQueryVariables>;
export const UserDocument = gql`
    query user($where: user_bool_exp!) {
  user(where: $where) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const UserByPkDocument = gql`
    query userByPk($id: String!) {
  user_by_pk(id: $id) {
    ...User
    team_users {
      role
      team_id
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserByPkQuery__
 *
 * To run a query within a React component, call `useUserByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByPkQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserByPkQuery, UserByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserByPkQuery, UserByPkQueryVariables>(UserByPkDocument, options);
      }
export function useUserByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserByPkQuery, UserByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserByPkQuery, UserByPkQueryVariables>(UserByPkDocument, options);
        }
export type UserByPkQueryHookResult = ReturnType<typeof useUserByPkQuery>;
export type UserByPkLazyQueryHookResult = ReturnType<typeof useUserByPkLazyQuery>;
export type UserByPkQueryResult = ApolloReactCommon.QueryResult<UserByPkQuery, UserByPkQueryVariables>;
export const WebPushSubscriptionByFingerprintDocument = gql`
    query webPushSubscriptionByFingerprint($fingerprint: String!) {
  web_push_subscription_aggregate(
    where: {browser_fingerprint: {_eq: $fingerprint}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useWebPushSubscriptionByFingerprintQuery__
 *
 * To run a query within a React component, call `useWebPushSubscriptionByFingerprintQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebPushSubscriptionByFingerprintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebPushSubscriptionByFingerprintQuery({
 *   variables: {
 *      fingerprint: // value for 'fingerprint'
 *   },
 * });
 */
export function useWebPushSubscriptionByFingerprintQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WebPushSubscriptionByFingerprintQuery, WebPushSubscriptionByFingerprintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WebPushSubscriptionByFingerprintQuery, WebPushSubscriptionByFingerprintQueryVariables>(WebPushSubscriptionByFingerprintDocument, options);
      }
export function useWebPushSubscriptionByFingerprintLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WebPushSubscriptionByFingerprintQuery, WebPushSubscriptionByFingerprintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WebPushSubscriptionByFingerprintQuery, WebPushSubscriptionByFingerprintQueryVariables>(WebPushSubscriptionByFingerprintDocument, options);
        }
export type WebPushSubscriptionByFingerprintQueryHookResult = ReturnType<typeof useWebPushSubscriptionByFingerprintQuery>;
export type WebPushSubscriptionByFingerprintLazyQueryHookResult = ReturnType<typeof useWebPushSubscriptionByFingerprintLazyQuery>;
export type WebPushSubscriptionByFingerprintQueryResult = ApolloReactCommon.QueryResult<WebPushSubscriptionByFingerprintQuery, WebPushSubscriptionByFingerprintQueryVariables>;
export const AccountDocument = gql`
    subscription account($email: String) {
  user(where: {email: {_eq: $email}}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useAccountSubscription__
 *
 * To run a query within a React component, call `useAccountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscription({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AccountSubscription, AccountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<AccountSubscription, AccountSubscriptionVariables>(AccountDocument, options);
      }
export type AccountSubscriptionHookResult = ReturnType<typeof useAccountSubscription>;
export type AccountSubscriptionResult = ApolloReactCommon.SubscriptionResult<AccountSubscription>;
export const Available_TeamsDocument = gql`
    subscription available_teams {
  team(order_by: {domain: asc}) {
    ...AvailableTeam
  }
}
    ${AvailableTeamFragmentDoc}`;

/**
 * __useAvailable_TeamsSubscription__
 *
 * To run a query within a React component, call `useAvailable_TeamsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAvailable_TeamsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailable_TeamsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAvailable_TeamsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Available_TeamsSubscription, Available_TeamsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Available_TeamsSubscription, Available_TeamsSubscriptionVariables>(Available_TeamsDocument, options);
      }
export type Available_TeamsSubscriptionHookResult = ReturnType<typeof useAvailable_TeamsSubscription>;
export type Available_TeamsSubscriptionResult = ApolloReactCommon.SubscriptionResult<Available_TeamsSubscription>;
export const DriverDocument = gql`
    subscription driver {
  user(where: {project_users: {role: {_eq: DRIVER}}}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useDriverSubscription__
 *
 * To run a query within a React component, call `useDriverSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDriverSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDriverSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<DriverSubscription, DriverSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<DriverSubscription, DriverSubscriptionVariables>(DriverDocument, options);
      }
export type DriverSubscriptionHookResult = ReturnType<typeof useDriverSubscription>;
export type DriverSubscriptionResult = ApolloReactCommon.SubscriptionResult<DriverSubscription>;
export const NotificationsDocument = gql`
    subscription notifications($where: notification_receiver_bool_exp, $shouldNotGetCreatedAt: Boolean = false) {
  notification_receiver(
    order_by: {notification: {created_at: desc}}
    where: $where
  ) {
    notification {
      id
      old
      new
      type
      target
      user {
        ...User
      }
      created_at
    }
    checked_at @skip(if: $shouldNotGetCreatedAt)
  }
}
    ${UserFragmentDoc}`;

/**
 * __useNotificationsSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      shouldNotGetCreatedAt: // value for 'shouldNotGetCreatedAt'
 *   },
 * });
 */
export function useNotificationsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NotificationsSubscription, NotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<NotificationsSubscription, NotificationsSubscriptionVariables>(NotificationsDocument, options);
      }
export type NotificationsSubscriptionHookResult = ReturnType<typeof useNotificationsSubscription>;
export type NotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<NotificationsSubscription>;
export const UncheckedNotificationCountDocument = gql`
    subscription uncheckedNotificationCount {
  notification_receiver_aggregate(where: {checked_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUncheckedNotificationCountSubscription__
 *
 * To run a query within a React component, call `useUncheckedNotificationCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUncheckedNotificationCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUncheckedNotificationCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUncheckedNotificationCountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<UncheckedNotificationCountSubscription, UncheckedNotificationCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<UncheckedNotificationCountSubscription, UncheckedNotificationCountSubscriptionVariables>(UncheckedNotificationCountDocument, options);
      }
export type UncheckedNotificationCountSubscriptionHookResult = ReturnType<typeof useUncheckedNotificationCountSubscription>;
export type UncheckedNotificationCountSubscriptionResult = ApolloReactCommon.SubscriptionResult<UncheckedNotificationCountSubscription>;
export const NotificationCountDocument = gql`
    subscription notificationCount {
  notification_receiver_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useNotificationCountSubscription__
 *
 * To run a query within a React component, call `useNotificationCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NotificationCountSubscription, NotificationCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<NotificationCountSubscription, NotificationCountSubscriptionVariables>(NotificationCountDocument, options);
      }
export type NotificationCountSubscriptionHookResult = ReturnType<typeof useNotificationCountSubscription>;
export type NotificationCountSubscriptionResult = ApolloReactCommon.SubscriptionResult<NotificationCountSubscription>;
export const Project_OneDocument = gql`
    subscription project_one($arId: uuid!) {
  project(where: {id: {_eq: $arId}}) {
    ...ProjectTeam
  }
}
    ${ProjectTeamFragmentDoc}`;

/**
 * __useProject_OneSubscription__
 *
 * To run a query within a React component, call `useProject_OneSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProject_OneSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProject_OneSubscription({
 *   variables: {
 *      arId: // value for 'arId'
 *   },
 * });
 */
export function useProject_OneSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<Project_OneSubscription, Project_OneSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Project_OneSubscription, Project_OneSubscriptionVariables>(Project_OneDocument, options);
      }
export type Project_OneSubscriptionHookResult = ReturnType<typeof useProject_OneSubscription>;
export type Project_OneSubscriptionResult = ApolloReactCommon.SubscriptionResult<Project_OneSubscription>;
export const Project_DashboardDocument = gql`
    subscription project_dashboard($limit: Int = 10, $offset: Int, $order_by: [project_dashboard_order_by!], $where: project_dashboard_bool_exp) {
  project_dashboard(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    ...ProjectDashboard
  }
}
    ${ProjectDashboardFragmentDoc}`;

/**
 * __useProject_DashboardSubscription__
 *
 * To run a query within a React component, call `useProject_DashboardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProject_DashboardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProject_DashboardSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProject_DashboardSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Project_DashboardSubscription, Project_DashboardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Project_DashboardSubscription, Project_DashboardSubscriptionVariables>(Project_DashboardDocument, options);
      }
export type Project_DashboardSubscriptionHookResult = ReturnType<typeof useProject_DashboardSubscription>;
export type Project_DashboardSubscriptionResult = ApolloReactCommon.SubscriptionResult<Project_DashboardSubscription>;
export const Project_Dashboard_AggregateDocument = gql`
    subscription project_dashboard_aggregate($where: project_dashboard_bool_exp) {
  project_dashboard_aggregate(where: $where) {
    ...ProjectDashboardAggregate
  }
}
    ${ProjectDashboardAggregateFragmentDoc}`;

/**
 * __useProject_Dashboard_AggregateSubscription__
 *
 * To run a query within a React component, call `useProject_Dashboard_AggregateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProject_Dashboard_AggregateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProject_Dashboard_AggregateSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProject_Dashboard_AggregateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<Project_Dashboard_AggregateSubscription, Project_Dashboard_AggregateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Project_Dashboard_AggregateSubscription, Project_Dashboard_AggregateSubscriptionVariables>(Project_Dashboard_AggregateDocument, options);
      }
export type Project_Dashboard_AggregateSubscriptionHookResult = ReturnType<typeof useProject_Dashboard_AggregateSubscription>;
export type Project_Dashboard_AggregateSubscriptionResult = ApolloReactCommon.SubscriptionResult<Project_Dashboard_AggregateSubscription>;
export const ProjectsDocument = gql`
    subscription projects($where: project_bool_exp!) {
  project(where: $where) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useProjectsSubscription__
 *
 * To run a query within a React component, call `useProjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectsSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<ProjectsSubscription, ProjectsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ProjectsSubscription, ProjectsSubscriptionVariables>(ProjectsDocument, options);
      }
export type ProjectsSubscriptionHookResult = ReturnType<typeof useProjectsSubscription>;
export type ProjectsSubscriptionResult = ApolloReactCommon.SubscriptionResult<ProjectsSubscription>;
export const Team_SubscriptionDocument = gql`
    subscription team_subscription($id: uuid!) {
  team_by_pk(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

/**
 * __useTeam_SubscriptionSubscription__
 *
 * To run a query within a React component, call `useTeam_SubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTeam_SubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeam_SubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeam_SubscriptionSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<Team_SubscriptionSubscription, Team_SubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<Team_SubscriptionSubscription, Team_SubscriptionSubscriptionVariables>(Team_SubscriptionDocument, options);
      }
export type Team_SubscriptionSubscriptionHookResult = ReturnType<typeof useTeam_SubscriptionSubscription>;
export type Team_SubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<Team_SubscriptionSubscription>;
export const UsersDocument = gql`
    subscription users($where: user_bool_exp!) {
  user(limit: 5, where: $where) {
    ...User
    team_users {
      team_id
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersSubscription__
 *
 * To run a query within a React component, call `useUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<UsersSubscription, UsersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<UsersSubscription, UsersSubscriptionVariables>(UsersDocument, options);
      }
export type UsersSubscriptionHookResult = ReturnType<typeof useUsersSubscription>;
export type UsersSubscriptionResult = ApolloReactCommon.SubscriptionResult<UsersSubscription>;