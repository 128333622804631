import { contains, isEmpty, isNil, keys, mapObjIndexed, reject } from 'ramda';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  NotificationFragment,
  Notification_Action_Enum,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { useUserContext } from '../store/contexts/UserContext';
import { allowerNotifications } from '../locales/en/notifications';

type UpdatedColumns = Record<
  string,
  {
    from: unknown;
    to: unknown;
  }
>;

export const useNotificationStrings = (
  item: NotificationFragment,
  action: Notification_Action_Enum,
) => {
  const { t } = useTranslation(LocalesKeys.Notifications);

  const { user } = useUserContext();

  const { updatedColumns, note } = useMemo<{
    updatedColumns: UpdatedColumns;
    note: string | undefined;
  }>(() => {
    const columns = reject(
      isNil,
      mapObjIndexed(
        (value, key) =>
          value !== item.notification.old?.[key] &&
          key !== 'note' &&
          contains(key, allowerNotifications[item.notification.type] ?? [])
            ? {
                from: item.notification.old?.[key],
                to: value,
              }
            : null,
        item.notification.new,
      ),
    ) as UpdatedColumns;

    const note =
      item.notification.new?.note !== item.notification.old?.note &&
      !isEmpty(columns)
        ? item.notification.new?.note
        : undefined;

    if (
      isEmpty(columns) &&
      item.notification.old?.note !== item.notification.new?.note
    ) {
      columns.note = {
        from: item.notification.old?.note,
        to: item.notification.new?.note,
      };
    }

    return {
      note: note && t('Note', { interpolation: { escapeValue: false }, note }),
      updatedColumns: columns,
    };
  }, [item.notification.new, item.notification.old, item.notification.type, t]);

  const transKey = `${item.notification.type}.${action}`;

  const field = keys(updatedColumns)[0];

  const changes = field ? updatedColumns[field] : undefined;

  const transValues = useMemo(
    () => ({
      deadline: moment(item.notification.new?.deadline).format('MMM D, YYYY'),
      field: keys(updatedColumns)[0] ?? note,
      newValue: String(changes?.to),
      oldValue: String(changes?.from),
      receiver: user?.username ?? user?.email,
      role: item.notification.new?.role,
      sender: item.notification.user.username ?? item.notification.user.email,
      target: item.notification.target,
    }),
    [
      changes?.from,
      changes?.to,
      item.notification.new?.deadline,
      item.notification.new?.role,
      item.notification.target,
      item.notification.user.email,
      item.notification.user.username,
      note,
      updatedColumns,
      user?.email,
      user?.username,
    ],
  );

  return {
    note,
    transKey,
    transValues,
  };
};
