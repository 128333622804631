import React, { SVGProps, memo } from 'react';

const ARFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={21} width={21} {...props}>
    <path
      d="M16.833 12.611v5.278A2.111 2.111 0 0 1 14.723 20H3.11A2.111 2.111 0 0 1 1 17.889V6.278c0-1.161.95-2.111 2.111-2.111H8.39M13.667 1H20v6.333M9.55 11.45l9.606-9.606"
      stroke="#454649"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
    />
  </svg>
);

export default memo(ARFileIcon);
