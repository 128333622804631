import React, { memo, useMemo } from 'react';
import {
  Box,
  Circle,
  HStack,
  Heading,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import { generatePath, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { find, pipe } from 'ramda';
import {
  AvailableTeamsQuery,
  NotificationFragment,
  Notification_Action_Enum,
  Notification_Type_Enum,
  ProjectFragment,
  ProjectUserFragment,
  Team_User,
} from '../generated/graphql';
import { useNotificationStrings } from '../hooks/useNotificationStrings';
import routes from '../navigation/routes';
import Avatar from './Avatar';
import { LocalesKeys } from '../locales/localesKeys';
import { useTeamContext } from '../store/contexts/TeamContext';
import { ArrayElement } from '../types/utils';

type Props = StackProps & {
  item: NotificationFragment;
};

const NotificationItem: React.FC<Props> = ({ item, ...props }) => {
  const username =
    item.notification.user?.username ?? item.notification.user.email;
  const imageUrl = item.notification.user.image?.url ?? undefined;
  const userId = item.notification.user.id;

  const action: Notification_Action_Enum = !item.notification.old
    ? Notification_Action_Enum.Insert
    : !item.notification.new
    ? Notification_Action_Enum.Delete
    : Notification_Action_Enum.Update;

  const { note, transKey, transValues } = useNotificationStrings(item, action);

  const date = format(new Date(item.notification.created_at), 'PPPp');

  const { changeTeam, availableTeams } = useTeamContext();

  const navigate = useNavigate();

  const routesFromNotification: {
    [key in Notification_Type_Enum]: (item: any) => () => void;
  } = useMemo(
    () => ({
      [Notification_Type_Enum.Project]: (item: ProjectFragment) => () =>
        navigate(
          generatePath(`/${routes.ARRoutes.ARDetails}`, {
            id: item.id,
          }),
        ),
      [Notification_Type_Enum.ProjectApproved]:
        (item: ProjectUserFragment) => () =>
          navigate(
            generatePath(`/${routes.ARRoutes.ARDetails}`, {
              id: item.project_id,
            }),
          ),
      [Notification_Type_Enum.ProjectAllApproversApproved]:
        (item: ProjectUserFragment) => () =>
          navigate(
            generatePath(`/${routes.ARRoutes.ARDetails}`, {
              id: item.project_id,
            }),
          ),
      [Notification_Type_Enum.ProjectReminder]:
        (item: Pick<ProjectFragment, 'id'>) => () =>
          navigate(
            generatePath(`/${routes.ARRoutes.ARDetails}`, {
              id: item.id,
            }),
          ),
      [Notification_Type_Enum.ProjectUser]: (item: ProjectUserFragment) => () =>
        navigate(
          generatePath(`/${routes.ARRoutes.ARDetails}`, {
            id: item.project_id,
          }),
        ),
      [Notification_Type_Enum.TeamUser]: (item: Team_User) => () =>
        pipe(
          find((team) => team.id === item.team_id),
          (team: ArrayElement<AvailableTeamsQuery['team']> | undefined) =>
            team ? changeTeam(team) : undefined,
        )(availableTeams),
      [Notification_Type_Enum.ProjectOverdueApprover]:
        (item: ProjectFragment) => () =>
          navigate(
            generatePath(`/${routes.ARRoutes.ARDetails}`, {
              id: item.id,
            }),
          ),
      [Notification_Type_Enum.ProjectOverdueDriver]:
        (item: ProjectFragment) => () =>
          navigate(
            generatePath(`/${routes.ARRoutes.ARDetails}`, {
              id: item.id,
            }),
          ),
    }),
    [availableTeams, changeTeam, navigate],
  );

  const onLinkClick = routesFromNotification[item.notification.type](
    item.notification.new ?? item.notification.old,
  );

  const { t } = useTranslation<LocalesKeys>(LocalesKeys.Notifications, {});

  return (
    <HStack align="flex-start" py={4} spacing={4} w="full" {...props}>
      <Avatar name={username} size="md" src={imageUrl} userId={userId} />
      <VStack align="flex-start" flex={1}>
        <Text size="body1">
          <Trans i18nKey={transKey} t={t} values={transValues}>
            <Heading
              as="span"
              cursor="pointer"
              onClick={onLinkClick}
              size="subhead1"
            />
            <br />
            <Heading as="span" color="green.600" size="subhead" />
            <Heading as="span" size="subhead1" />
            <Heading as="span" color="red" size="subhead1" />
          </Trans>
        </Text>
        {note && <Text size="body2">{note}</Text>}
        <Text color="black.400" size="body2">
          {date}
        </Text>
      </VStack>
      <Box pt={7}>
        <Circle bg={item.checked_at ? 'transparent' : 'primary.600'} size={2} />
      </Box>
    </HStack>
  );
};

export default memo(NotificationItem);
