import type {
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { IconClass, classSelector } from '../utils';

const baseStyle: SystemStyleObject = {
  _disabled: {
    boxShadow: 'none',
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
  borderRadius: 'lg',
  fontWeight: 'semibold',
  lineHeight: 'normal',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  width: '100%',
};

const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    return {
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      color: mode(`inherit`, `whiteAlpha.900`)(props),
    };
  }

  if (c === 'white') {
    return {
      _active: {
        boxShadow: `0px 10px 13px -10px ${theme.colors.black['500']}`,
      },
      _focus: {
        boxShadow: `0px 10px 13px -10px ${theme.colors.black['500']}`,
      },
      _hover: {
        boxShadow: `0px 10px 13px -10px ${theme.colors.black['300']}`,
      },
      color: `black.500`,
      transition: 'all .2s',
    };
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    bg: 'transparent',
    color: mode(`${c}.600`, `${c}.200`)(props),
  };
};

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props);
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
  };
};

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  cyan: {
    activeBg: 'cyan.600',
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
  },
  yellow: {
    activeBg: 'yellow.600',
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
  },
};

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  if (c === 'secondary') {
    return {
      _active: {
        bg: 'black.600',
        color: 'white',
      },
      _focus: { outline: 'none' },
      _hover: {
        [classSelector(IconClass.CustomFill)]: { path: { fill: 'white' } },
        [classSelector(IconClass.CustomStroke)]: { path: { stroke: 'white' } },
        _disabled: {
          bg: 'black.300',
          color: 'black.200',
        },
        bg: 'black.500',
        color: 'white',
      },
      bg: 'black.200',
      color: 'black.600',
    };
  }

  if (c === 'primary') {
    return {
      _active: { bg: `${c}.700` },
      _focus: { outline: 'none' },
      _hover: {
        _disabled: {
          bg: 'black.300',
          color: 'black.200',
        },
        bg: `${c}.500`,
      },
      bg: `${c}.600`,
      color: 'white',
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] ?? {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
    _hover: {
      _disabled: {
        bg: background,
      },
      bg: mode(hoverBg, `${c}.300`)(props),
    },
    bg: background,
    color: mode(color, `gray.800`)(props),
  };
};

const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    _active: {
      color: mode(`${c}.700`, `${c}.500`)(props),
    },
    _hover: {
      _disabled: {
        textDecoration: 'none',
      },
      textDecoration: 'underline',
    },
    color: mode(`${c}.500`, `${c}.200`)(props),
    height: 'auto',
    lineHeight: 'normal',
    padding: 0,
    verticalAlign: 'baseline',
  };
};

const variantUnstyled: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  const hoverColor = `${c}.600`;
  const activeColor = `black.600`;

  return {
    _active: {
      [classSelector(IconClass.CustomFill)]: {
        path: { fill: activeColor },
      },
      [classSelector(IconClass.CustomStroke)]: {
        path: { stroke: activeColor },
      },
    },
    _hover: {
      [classSelector(IconClass.CustomFill)]: {
        path: { fill: hoverColor },
      },
      [classSelector(IconClass.CustomStroke)]: {
        path: { stroke: hoverColor },
      },
    },
    align: 'center',
    bg: 'none',
    color: 'inherit',
    display: 'flex',
    h: 'initial',
    lineHeight: 'inherit',
    m: 0,
    p: 0,
    w: 'initial',
  };
};

const variants = {
  ghost: variantGhost,
  link: variantLink,
  outline: variantOutline,
  solid: variantSolid,
  unstyled: variantUnstyled,
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    fontSize: 'lg',
    h: 12,
    minW: 12,
    px: 6,
  },
  md: {
    fontSize: 'md',
    h: 12,
    minW: 10,
    px: 3,
  },
  sm: {
    fontSize: 'sm',
    h: 8,
    minW: 8,
    px: 3,
  },
  xs: {
    fontSize: 'xs',
    h: 6,
    minW: 6,
    px: 2,
  },
};

const defaultProps = {
  colorScheme: 'primary',
  size: 'md',
  variant: 'solid',
};

export default {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};
