import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import routes from '../navigation/routes';

export const useTeamSettingsNavigation = (id: string) => {
  const navigate = useNavigate();

  const openTransferOwnership = useCallback(
    () =>
      navigate(
        generatePath(routes.TeamSettingsRoutes.TransferOwnership, { id }),
      ),
    [id, navigate],
  );

  const openAddProjects = useCallback(
    () => navigate(generatePath(routes.TeamSettingsRoutes.AddProjects, { id })),
    [id, navigate],
  );

  const openChangePermission = useCallback(
    () =>
      navigate(
        generatePath(routes.TeamSettingsRoutes.ChangePermission, { id }),
      ),
    [id, navigate],
  );

  const openRemoveFromTeam = useCallback(
    () =>
      navigate(generatePath(routes.TeamSettingsRoutes.RemoveFromTeam, { id })),
    [id, navigate],
  );

  return {
    openAddProjects,
    openChangePermission,
    openRemoveFromTeam,
    openTransferOwnership,
  };
};
