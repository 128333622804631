import { Box, ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
// import i18n (needs to be bundled ;))
import './i18n';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './theme/foundations/scrollbar.css';
import theme from './theme/index';

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Box h="viewportH" w="viewportW">
        <Root />
      </Box>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
