import * as R from 'ramda';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react';
import {
  Order_By,
  Project_Dashboard_Order_By,
  Project_User_Display_Status_Enum,
  useDriverSubscription,
  useProjectDashboardQuery,
  useProject_DashboardSubscription,
  useProject_Dashboard_AggregateSubscription,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { getProjectDashboardQueryVariables } from '../utils/getProjectDashboardQueryVariables';
import useDashboardForm from './useDashboardForm';

const useProjectsData = (
  displayStatus: Project_User_Display_Status_Enum = Project_User_Display_Status_Enum.Dashboard,
) => {
  const {
    driver,
    nameQuery,
    offset,
    page,
    pageSize,
    setPage,
    setFieldValue,
    setSorting,
    sorting,
    status,
    driverEmailQuery,
  } = useDashboardForm();

  const { data: driverData } = useDriverSubscription();

  const drivers = useMemo(() => driverData?.user ?? [], [driverData?.user]);

  const mappedSorting = useMemo<Project_Dashboard_Order_By | undefined>(() => {
    if (!sorting) return undefined;

    const { key, orderBy } = sorting;

    switch (key) {
      case 'driver':
        return { driver: { email: orderBy } };
      default:
        return { [key]: orderBy };
    }
  }, [sorting]);

  const { team } = useTeamContext();

  const projectQueryVariables = useMemo(
    () =>
      getProjectDashboardQueryVariables({
        displayStatus,
        driverEmailQuery,
        mappedSorting,
        nameQuery,
        offset,
        pageSize,
        status,
        teamId: team?.id,
      }),
    [
      displayStatus,
      driverEmailQuery,
      mappedSorting,
      nameQuery,
      offset,
      pageSize,
      status,
      team?.id,
    ],
  );

  const { data: projectData, updateQuery } = useProjectDashboardQuery({
    fetchPolicy: 'cache-first',
    variables: projectQueryVariables,
  });

  useProject_DashboardSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const project_dashboard = subscriptionData.data?.project_dashboard;
      if (!project_dashboard) {
        return;
      }
      updateQuery((prev) => ({
        // eslint-disable-next-line no-underscore-dangle
        __typename: prev.__typename,
        project_dashboard,
      }));
    },
    variables: projectQueryVariables,
  });

  const projects = useMemo(
    () => projectData?.project_dashboard ?? [],
    [projectData?.project_dashboard],
  );

  const { data: projectAggregateData } =
    useProject_Dashboard_AggregateSubscription({
      variables: { where: projectQueryVariables.where },
    });

  const totalCount =
    projectAggregateData?.project_dashboard_aggregate.aggregate?.count ?? 0;

  const handleSortingClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    ({ currentTarget: { id } }) =>
      setSorting((prevSorting) => {
        const orderBy = prevSorting?.orderBy;

        switch (orderBy) {
          case Order_By.Asc:
            return { key: id, orderBy: Order_By.Desc };
          case Order_By.Desc:
            return undefined;
          default:
            return { key: id, orderBy: Order_By.Asc };
        }
      }),
    [setSorting],
  );

  const handleNextClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () =>
      setPage((prevPage) =>
        R.inc(prevPage) * pageSize >= totalCount ? prevPage : R.inc(prevPage),
      ),
    [pageSize, setPage, totalCount],
  );

  const handlePageSizeChange = useCallback<
    ChangeEventHandler<HTMLSelectElement>
  >(
    ({ target: { value } }) => setFieldValue('pageSize', Number(value)),
    [setFieldValue],
  );

  const handlePrevClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => setPage((prevPage) => (prevPage >= 1 ? R.dec(prevPage) : prevPage)),
    [setPage],
  );

  return {
    driver,
    drivers,
    handleNextClick,
    handlePageSizeChange,
    handlePrevClick,
    handleSortingClick,
    nameQuery,
    offset,
    page,
    pageSize,
    projects,
    sorting,
    status,
    totalCount,
  };
};

export default useProjectsData;
