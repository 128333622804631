import { Button, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useChangeProjectStatusFormik from '../hooks/useChangeProjectStatusFormik';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import ArStatusAndNoteFields from './ArStatusAndNoteFields';

const ChangeProjectStatus: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const {
    getFieldMeta,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    isUserInTeam,
    arId,
    isDriver,
  } = useChangeProjectStatusFormik(onClose);

  return (
    <>
      <chakra.form onSubmit={handleSubmit} w="full">
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="flex-start"
          w="full"
        >
          <Heading size="headline2">{t('updateYourStatus')}</Heading>
          <ArStatusAndNoteFields
            arId={arId}
            getFieldMeta={getFieldMeta}
            getFieldProps={getFieldProps}
            isDriver={isDriver}
            isUserInTeam={isUserInTeam}
          />
        </Flex>
        <Flex justifyContent="space-between" mt={10}>
          <Text color="black.400" size="body1" textAlign="start">
            {t('allUsersWillBeNotified')}
          </Text>
          <Flex gridGap={6}>
            <Button colorScheme="secondary" onClick={onClose} px={6}>
              {t('cancel')}
            </Button>
            <Button isLoading={isSubmitting} px={6} type="submit">
              {t('updateStatus')}
            </Button>
          </Flex>
        </Flex>
      </chakra.form>
    </>
  );
};

export default memo(ChangeProjectStatus);
