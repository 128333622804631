import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const ChevronDownIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m6 9 6 6 6-6"
      stroke="#737477"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
    />
  </Icon>
);

export default memo(ChevronDownIcon);
