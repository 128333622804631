import { Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import { ANIMATIONS } from '../pages/CreateTeam';

const CreateTeamStep3: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.CreateTeam);

  return (
    <>
      <motion.div
        animate="animate"
        exit="exit"
        initial="initial"
        transition={{ duration: 0.7 }}
        variants={ANIMATIONS}
      >
        <Heading mt={10} size="headline2">
          {t('thirdHeader')}
        </Heading>
        <Text mt={6} pb={20} size="body1" w={80}>
          {t('getOthers')}
        </Text>
      </motion.div>
    </>
  );
};

export default memo(CreateTeamStep3);
