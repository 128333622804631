import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput, { TextInputProps } from './TextInput';
import { LocalesKeys } from '../locales/localesKeys';

interface Props extends TextInputProps {}

const EmailInput: React.FC<Props> = (props) => {
  const { t } = useTranslation(LocalesKeys.Input);

  const placeholder = t('email.placeholder');

  return (
    <TextInput
      autoCapitalize="off"
      autoCorrect="off"
      placeholder={placeholder}
      type="email"
      {...props}
    />
  );
};

export default memo(EmailInput);
