import { useAuth } from '@stormotion-auth/react';
import UserType from '@stormotion-auth/react/dist/types/User';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useMemo } from 'react';
import { createCtx } from '../../utils/common';

export type AuthContextType = {
  cognitoUser: Maybe<UserType>;
  isLoading: boolean;
  isInitializing: boolean;
};

export const [useAuthContext, AuthContext] = createCtx<AuthContextType>();

export const AuthContextProvider: React.FC = ({ children }) => {
  const { user, isLoading, isInitializing } = useAuth();

  const value = useMemo<AuthContextType>(
    () => ({
      cognitoUser: user,
      isInitializing,
      isLoading,
    }),
    [user, isLoading, isInitializing],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
