import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDeleteAccount from '../hooks/useDeleteAccount';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import routes from '../navigation/routes';

const DeleteAccountWarning: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.AccountSettings);

  const navigate = useNavigate();

  const {
    projectsWhereUserDriver,
    projectsWhereUserDriverList,
    teamsWhereUserTeamOwner,
    teamsWhereUserTeamOwnerList,
  } = useDeleteAccount();

  const Header = useMemo(
    () => (
      <>
        <Heading size="headline3">{t('delete')}</Heading>
        <Text mt={6} textAlign="center">
          {t('deleteWarning')}
        </Text>
      </>
    ),
    [t],
  );

  const Content = useMemo(
    () => (
      <Flex flexDirection="column" mt={5} w="full">
        {!!teamsWhereUserTeamOwner.length && (
          <Text>
            {t('deletingTeams', { teams: teamsWhereUserTeamOwnerList })}
          </Text>
        )}
        {!!projectsWhereUserDriver.length && (
          <Text>
            {t('deletingProjects', { projects: projectsWhereUserDriverList })}
          </Text>
        )}
      </Flex>
    ),
    [
      projectsWhereUserDriver.length,
      projectsWhereUserDriverList,
      t,
      teamsWhereUserTeamOwner.length,
      teamsWhereUserTeamOwnerList,
    ],
  );

  const openDeleteConfirmation = useCallback(
    () =>
      navigate(
        `/${routes.MainRoute.Settings}/${routes.AccountSettingsRoutes.DeleteAccountConfirmation}`,
      ),
    [navigate],
  );

  const Footer = useMemo(
    () => (
      <Flex gridGap={6} justifyContent="space-evenly" mt={10} w="full">
        <Button
          colorScheme="secondary"
          onClick={onClose}
          px={6}
          w="fit-content"
        >
          {t('gotIt')}
        </Button>
        <Button onClick={openDeleteConfirmation} px={10} w="fit-content">
          {t('deleteAccountButton')}
        </Button>
      </Flex>
    ),
    [onClose, openDeleteConfirmation, t],
  );

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      pt={18}
      w="full"
    >
      {Header}
      {Content}
      {Footer}
    </Flex>
  );
};

export default memo(DeleteAccountWarning);
