import React, { memo } from 'react';
import useDeleteAccount from '../hooks/useDeleteAccount';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import BaseGuard, { BaseGuardProps } from './BaseGuard';
import routes from './routes';

interface Props extends BaseGuardProps {}

const DeleteAccountGuard: React.FC<Props> = (props) => {
  const { loading: teamLoading } = useTeamContext();

  const { loading: userLoading } = useUserContext();
  const loading = userLoading || teamLoading;

  const { isUserDriverOrTeamOwner } = useDeleteAccount();

  return (
    <BaseGuard
      loading={loading}
      rule={loading}
      to={`/${routes.MainRoute.Settings}/${
        isUserDriverOrTeamOwner
          ? routes.AccountSettingsRoutes.DeleteAccountWarning
          : routes.AccountSettingsRoutes.DeleteAccountConfirmation
      }`}
      {...props}
    />
  );
};

export default memo(DeleteAccountGuard);
