import { tableAnatomy as parts } from '@chakra-ui/anatomy';

import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
  caption: {
    fontFamily: 'heading',
    fontWeight: 'medium',
    mt: 4,
    textAlign: 'center',
  },
  table: {
    borderCollapse: 'collapse',
    borderRadius: 'lg',
    flexGrow: 100,
    fontVariantNumeric: 'normal',
    width: '100%',
  },
  td: {
    overflow: 'hidden',
    textAlign: 'start',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'semibold',
    letterSpacing: 'normal',
    textAlign: 'start',
    textTransform: 'none',
  },
  thead: {
    '>tr': {
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
    },
  },
};

const numericStyles: SystemStyleObject = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

const variantSimple: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c } = props;

  return {
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    td: {
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
    th: {
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      color: mode('gray.600', 'gray.400')(props),
      ...numericStyles,
    },
  };
};

// Error triggers on changing of odd to even in `nth-of-type` selector
// @ts-expect-error
const variantStripe: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c } = props;

  if (c === 'black') {
    return {
      caption: {
        color: 'black.600',
      },
      tbody: {
        tr: {
          '&:nth-of-type(even)': {
            td: {
              bgColor: 'gray.bg',
            },
          },
          '&:nth-of-type(n)': {
            '&:hover': {
              td: {
                bgColor: 'black.100',
                transitionDuration: 'fast',
                transitionProperty: 'colors',
                transitionTimingFunction: 'ease-in-out',
              },
            },
          },
          '&:nth-of-type(odd)': {
            td: {
              bgColor: 'white',
            },
          },
        },
      },
      thead: {
        th: {
          bgColor: 'white',
          borderBottom: '1px',
          borderColor: 'black.200',
          color: 'black.500',
          ...numericStyles,
        },
      },
    };
  }

  return {
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          td: {
            background: mode(`${c}.100`, `${c}.700`)(props),
          },
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: mode(`${c}.100`, `${c}.700`)(props),
          },
        },
      },
    },
    td: {
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
    th: {
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      color: mode('gray.600', 'gray.400')(props),
      ...numericStyles,
    },
  };
};

const variants = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: {},
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  lg: {
    caption: {
      fontSize: 'md',
      px: '6',
      py: '2',
    },
    td: {
      lineHeight: '6',
      px: '8',
      py: '5',
    },
    th: {
      fontSize: 'sm',
      lineHeight: '5',
      px: '8',
      py: '4',
    },
  },
  md: {
    caption: {
      fontSize: 'sm',
      px: '6',
      py: '2',
    },
    td: {
      fontSize: 'sm',
      lineHeight: '5',
      px: 4,
      py: 3.5,
    },
    th: {
      fontSize: 'md',
      lineHeight: '4',
      px: 4,
      py: 6,
    },
  },
  sm: {
    caption: {
      fontSize: 'xs',
      px: '4',
      py: '2',
    },
    td: {
      fontSize: 'sm',
      lineHeight: '4',
      px: '4',
      py: '2',
    },
    th: {
      fontSize: 'xs',
      lineHeight: '4',
      px: '4',
      py: '1',
    },
  },
};

const defaultProps = {
  colorScheme: 'gray',
  size: 'md',
  variant: 'striped',
};

export default {
  baseStyle,
  defaultProps,
  parts: parts.keys,
  sizes,
  variants,
};
