import React, { memo } from 'react';
import { RouteModalComponent } from '../navigation/Modals';
import { useCreateAR } from '../hooks/useCreateAR';
import { useProjectSubmit } from '../hooks/useProjectSubmit';
import Loading from '../components/Loading';
import ProjectEditorForm from '../components/ProjectEditorForm';
import CreateARGuard from '../navigation/CreateARGuard';

const CreateAR: RouteModalComponent = ({ onClose, ...props }) => {
  const { onCreateAR, initialValues, loading } = useCreateAR();

  const onSubmit = useProjectSubmit(onClose, onCreateAR);

  if (loading) return <Loading />;

  return (
    <CreateARGuard>
      <ProjectEditorForm
        {...props}
        initialValues={initialValues}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </CreateARGuard>
  );
};

export default memo(CreateAR);
