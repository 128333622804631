import { pipe } from 'fp-ts/lib/function';
import {
  always,
  find,
  identity,
  indexBy,
  mapObjIndexed,
  propEq,
  values,
} from 'ramda';
import {
  BooleanSchema,
  InferType,
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  ref,
  string,
} from 'yup';
import { CODE_CELLS_COUNT } from '../components/CodeInput';
import {
  Notification_Action_Enum,
  Notification_Destination_Enum,
  Notification_Type_Enum,
  Project_User_Role_Enum,
  Reminder_Type_Enum,
} from '../generated/graphql';

export const teamNameValidator = string().required();

export const emailValidator = string().trim().email().required();
export const passwordValidator = string()
  .trim()
  // Minimum six characters
  .min(6, 'wrongPassword');
export const linkValidator = string().trim().url();
export const googleDriveLinkValidator = linkValidator.matches(
  /^.*docs\.google\.com\/.*\/d\/.*$/,
);

export const authValidationSchema = object({
  email: emailValidator,
  password: passwordValidator,
});
export const forgetPasswordEmailValidationSchema = object({
  email: emailValidator,
});
export const forgetPasswordValidationSchema = object({
  password: passwordValidator.required(),
  password2: passwordValidator
    .required()
    .oneOf([ref('password')], 'passwordsShouldMatch'),
});

export type AuthValues = InferType<typeof authValidationSchema>;
export type ForgetPasswordEmailValues = InferType<
  typeof forgetPasswordEmailValidationSchema
>;
export type ForgetPasswordValues = InferType<
  typeof forgetPasswordValidationSchema
>;

export const verificationValidationSchema = object({
  code: string().trim().min(CODE_CELLS_COUNT).required(),
});

export type VerificationValues = InferType<typeof verificationValidationSchema>;

export const accountInfoValidationSchema = object({
  email: emailValidator,
  localImage: mixed().nullable(true),
  localUrl: string().nullable(true),
  username: string().trim().min(3).max(32),
});

export type AccountInfoValues = InferType<typeof accountInfoValidationSchema>;

export const newPasswordValidationSchema = object({
  newPassword: passwordValidator,
  oldPassword: passwordValidator.notOneOf(
    [ref('newPassword')],
    'differentPasswords',
  ),
});

export type NewPasswordValues = InferType<typeof newPasswordValidationSchema>;

export const createTeamValidationSchema = object({
  teamName: teamNameValidator,
});

export const remindersValidationSchema = object({
  reminder_days: array().of(string().required()),
  reminder_time: string().required(),
  reminder_type: mixed<Reminder_Type_Enum>().oneOf(values(Reminder_Type_Enum)),
});

export type RemindersValues = InferType<typeof remindersValidationSchema>;

export const createARStep1ValidationSchema = object({
  deadline: date().required(),
  driver_name: string().when(
    'invites',
    (invites: Record<'role', Project_User_Role_Enum>[], schema) =>
      find(propEq('role', Project_User_Role_Enum.Driver), invites)
        ? schema
        : schema.test('false', 'driverNotSelected', always(false)),
  ),
  external_link: linkValidator,
  external_name: string(),
  invites: array(
    object({
      email: string().required(),
      id: string(),
      role: mixed<Project_User_Role_Enum>().oneOf(
        values(Project_User_Role_Enum),
      ),
    }).required(),
  ).required(),
  link: linkValidator.required(),
  name: string().required(),
}).concat(remindersValidationSchema);

export type CreateARStep1Values = InferType<
  typeof createARStep1ValidationSchema
>;

export const createARStep2InviteValidationSchema = object({
  email: emailValidator,
});

export type CreateARStep2inviteValues = InferType<
  typeof createARStep2InviteValidationSchema
>;

export const billingMethodChangeValidationSchema = object({
  card: string(),
  city: string()
    .required('cityIsRequired')
    .matches(/^([^0-9]*)$/, 'mustBeOnlySymbols'),
  country: string().required('countryIsRequired'),
  line1: string().required('streetIsRequired'),
  line2: string(),
  postal_code: string()
    .required('zipIsRequired')
    .matches(/^[0-9A-Z]+$/, 'digitsAndLetters'),
  state: string().required('stateIsRequired'),
});

export type BillingMethodChangeValues = InferType<
  typeof billingMethodChangeValidationSchema
>;

export const deletingConfirmationValidationSchema = object({
  confirmation_input: string()
    .required('')
    .equals(['DELETE'], 'Value should be: DELETE'),
});

export type DeleteConfirmationValues = InferType<
  typeof deletingConfirmationValidationSchema
>;

export const billingContactChangeValidationSchema = object({
  email: emailValidator,
});

export type BillingContactChangeValues = InferType<
  typeof billingContactChangeValidationSchema
>;

export const dashboardValidationSchema = object({
  driver: string().nullable(true),
  name: string().nullable(true),
  pageSize: number().default(0),
  status: string().nullable(true),
});

export type DashboardValues = InferType<typeof dashboardValidationSchema>;

const NotificationDestinations = object<
  Record<Notification_Destination_Enum, BooleanSchema>
>(
  pipe(
    values(Notification_Destination_Enum),
    indexBy<Notification_Destination_Enum, Notification_Destination_Enum>(
      identity,
    ),
    mapObjIndexed(() => boolean().required()),
  ),
);

const NotificationSetting = object({
  action: mixed<Notification_Action_Enum>()
    .oneOf(values(Notification_Action_Enum))
    .nullable(),
  fields: array().of(string()),
  id: string(),
  type: mixed<Notification_Type_Enum>().oneOf(values(Notification_Type_Enum)),
});

export const notificationSettingsValidationSchema = object({
  notification_email: string().email(),
  reminders: array().of(NotificationDestinations.concat(NotificationSetting)),
  slack_workspace: string(),
}).concat(remindersValidationSchema);

export type NotificationSettingsValues = InferType<
  typeof notificationSettingsValidationSchema
>;
