import {
  Notification_Action_Enum,
  Notification_Type_Enum,
  ProjectFragment,
  ProjectUserFragment,
  Project_User_Role_Enum,
  Project_User_Status_Enum,
  TeamUserFragment,
  Team_User_Status_Enum,
} from '../../generated/graphql';

type ColumnsAllowedForNotifications =
  | keyof ProjectFragment
  | keyof TeamUserFragment
  | keyof ProjectUserFragment
  | 'driver';

export const allowerNotifications: Partial<
  Record<Notification_Type_Enum, ColumnsAllowedForNotifications[]>
> = {
  [Notification_Type_Enum.Project]: ['deadline'],
  [Notification_Type_Enum.TeamUser]: ['status'],
  [Notification_Type_Enum.ProjectUser]: ['status', 'role'],
  [Notification_Type_Enum.ProjectReminder]: ['driver'],
};

const notificationStringsBasedOnField: Pick<
  Record<ColumnsAllowedForNotifications, string>,
  'deadline' | 'note' | 'status'
> = {
  deadline:
    '⏱ {{sender}} changed the deadline for <0>{{target}}</0> to {{deadline}}.',
  note: '{{sender}} changed the note for <0>{{target}}</0> to "{{newValue}}".',
  status: '$t(stringsBasedOnValue.{{newValue}})',
};

const notificationStringsBasedOnValue: Record<
  Team_User_Status_Enum &
    Project_User_Role_Enum &
    Project_User_Status_Enum &
    keyof ProjectUserFragment,
  string
> = {
  [Team_User_Status_Enum.Licensed]:
    "<3>Let's finalize some assets!</3><1></1>You're now <2>Licensed</2>. You can create unlimited projects / assets in Completely (and get sooo much work approved).",
  [Team_User_Status_Enum.Basic]:
    "You've been moved to a <3>Basic account</3>. If you need to create projects, please contact your admin.",
  [Project_User_Status_Enum.Approved]:
    '<2>Yay! 🎉</2><1></1><0>{{target}}</0> just got approved by {{sender}}.',
  [Project_User_Status_Enum.Rejected]:
    '{{sender}} - who is $t(createAR:{{role}}) for <0>{{target}}</0> - did not approve this project. See comments below:',
  [Project_User_Status_Enum.UnderReview]:
    '{{sender}} updated his status to $t(status:{{newValue}}) for: <0>{{target}}</0>',
};

type NotificationStringAddition =
  | { [key in string]: NotificationStringAddition }
  | string;

type NotificationStrings = Record<
  Notification_Type_Enum,
  Record<Notification_Action_Enum, NotificationStringAddition>
>;

const notificationStrings: NotificationStrings = {
  [Notification_Type_Enum.Project]: {
    [Notification_Action_Enum.Delete]:
      'The following project has been <4>deleted</4> from Completely by {{sender}}: <0>{{target}}</0>.',
    [Notification_Action_Enum.Insert]: '',
    [Notification_Action_Enum.Update]: '$t(stringsBasedOnField.{{field}})',
  },
  [Notification_Type_Enum.ProjectApproved]: {
    [Notification_Action_Enum.Delete]: '',
    [Notification_Action_Enum.Insert]: '',
    [Notification_Action_Enum.Update]:
      '<2>Another one done! ⭐️</2><1></1>Guess what - <2>ev-er-y-one has approved {{target}}</2>. Well done, {{receiver}}.',
  },
  [Notification_Type_Enum.ProjectAllApproversApproved]: {
    [Notification_Action_Enum.Delete]: '',
    [Notification_Action_Enum.Insert]: '',
    [Notification_Action_Enum.Update]:
      '<2>Yay! 🎉</2><1></1><0>{{target}}</0> just got approved by {{sender}}.',
  },
  [Notification_Type_Enum.ProjectReminder]: {
    [Notification_Action_Enum.Delete]: '',
    [Notification_Action_Enum.Insert]: '',
    [Notification_Action_Enum.Update]:
      '<3>Help {{sender}} get their work done on time</3> - approve or reject <0>{{target}}</0> now. ➡️ ',
  },
  [Notification_Type_Enum.ProjectUser]: {
    [Notification_Action_Enum.Delete]:
      '{{sender}} has deleted you from <0>{{target}}</0>.',
    [Notification_Action_Enum.Insert]:
      "<3>You're invited! ✅</3><1></1>You are $t(createAR:{{newValue}}) on the <0>{{target}}</0> project. Your feedback is due {{deadline}}. Why not review it now and beat the deadline?",
    [Notification_Action_Enum.Update]: '$t(stringsBasedOnField.{{field}})',
  },
  [Notification_Type_Enum.TeamUser]: {
    [Notification_Action_Enum.Delete]:
      '{{sender}} has deleted you from <3>{{target}}</3>.',
    [Notification_Action_Enum.Insert]:
      '{{sender}} has invited you to <0>{{target}}</0> as a $t(createAR:{{newValue}}) user.',
    [Notification_Action_Enum.Update]: '$t(stringsBasedOnValue.{{newValue}})',
  },
  [Notification_Type_Enum.ProjectOverdueApprover]: {
    [Notification_Action_Enum.Delete]: '',
    [Notification_Action_Enum.Insert]:
      '<4>Missed deadline! 😩</4><1></1> Help out {{sender}} by leaving your feedback for <0>{{target}}</0> now.',
    [Notification_Action_Enum.Update]: '',
  },
  [Notification_Type_Enum.ProjectOverdueDriver]: {
    [Notification_Action_Enum.Delete]: '',
    [Notification_Action_Enum.Insert]:
      '<4>Missed deadline! 😳</4><1></1> <0>{{target}}</0> is now overdue. <3>Change the deadline</3> to automagically send reminders to your reviewers... or archive the project.',
    [Notification_Action_Enum.Update]: '',
  },
};

export default {
  ...notificationStrings,
  heading: 'Notifications',
  Note: 'Note: "{{note}}"',
  stringsBasedOnField: notificationStringsBasedOnField,
  stringsBasedOnValue: notificationStringsBasedOnValue,
};
