import { Box, FormControl, FormHelperText, Text } from '@chakra-ui/react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import typography from '../theme/foundations/typography';
import { TextInputProps } from './TextInput';

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  hideIcon: true,
  style: {
    base: {
      fontSize: typography.fontSizes.lg,
      lineHeight: '48px',
    },
  },
};

const CardInput: React.FC<TextInputProps> = ({
  boxProps,
  touched,
  helpingText,
  error,
}) => {
  const isInvalid = touched && !!error;

  const { t } = useTranslation(LocalesKeys.ValidationErrors);

  return (
    <FormControl isInvalid={isInvalid} {...boxProps}>
      <Box
        borderColor={error ? 'red.500' : 'black.200'}
        borderRadius={8}
        borderWidth={error ? '2px' : 'thin'}
        h={12}
        justifyContent="center"
        overflow="hidden"
        pl={3}
        w="full"
      >
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </Box>
      {error ? (
        <Text color="red" letterSpacing="tight" pl={3} pt={1} size="caption1">
          {t(error)}
        </Text>
      ) : (
        <FormHelperText
          color="black.400"
          letterSpacing="tight"
          pl={3}
          size="caption1"
        >
          {helpingText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(CardInput);
