import { CSSObject, Flex } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';

export type AutocompleteItemType<T> = {
  value: string;
} & T;

type Props<T> = {
  item: AutocompleteItemType<T>;
  onItemClick: (item: AutocompleteItemType<T>) => void;
  renderItemContent: (item: AutocompleteItemType<T>) => JSX.Element;
};

const ACTIVE: CSSObject = {
  bg: 'black.200',
};

const HOVER: CSSObject = {
  bg: 'black.100',
};

const AutocompleteItem = <T,>({
  item,
  onItemClick,
  renderItemContent,
}: Props<T>) => {
  const onClick = useCallback(() => onItemClick(item), [item, onItemClick]);

  return (
    <Flex
      key={item.value}
      _active={ACTIVE}
      _hover={HOVER}
      cursor="pointer"
      h={12}
      onClick={onClick}
      px={4}
      py={3}
      spacing={2}
      tabIndex={0}
      transitionDuration="ultra-fast"
      transitionProperty="background"
      transitionTimingFunction="ease-in"
      w="full"
    >
      {renderItemContent(item)}
    </Flex>
  );
};

export default memo(AutocompleteItem) as typeof AutocompleteItem;
