import React, { memo, useCallback, useMemo, useState } from 'react';
import { Interpolation } from '@chakra-ui/react';
import Autocomplete, { AutocompleteProps } from './Autocomplete';
import CrossIcon from './icons/CrossIcon';
import { TextInputProps } from './TextInput';
import ChevronDownIcon from './icons/ChevronDownIcon';

const AUTOCOMPLETE_CSS: Interpolation<{}> = {
  '::placeholder': {
    color: 'black',
  },
  ':focus': {
    cursor: 'text',
  },
};

const AutocompleteSelect = <T,>({
  placeholder,
  ...props
}: AutocompleteProps<T> & TextInputProps) => {
  const clearInputValue = useCallback(() => props.setFieldValue(''), [props]);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const focusInput = useCallback(() => setIsInputFocused(true), []);

  const unfocusInput = useCallback(() => setIsInputFocused(false), []);

  const InputRightIcon = useMemo(
    () =>
      isInputFocused ? (
        <CrossIcon cursor="pointer" onPointerDown={clearInputValue} />
      ) : (
        <ChevronDownIcon pointerEvents="none" w={5} />
      ),
    [clearInputValue, isInputFocused],
  );

  return (
    <Autocomplete
      bg="white"
      css={AUTOCOMPLETE_CSS}
      cursor="default"
      placeholder={isInputFocused ? '' : placeholder}
      {...props}
      onBlur={unfocusInput}
      onFocus={focusInput}
      rightElement={InputRightIcon}
    />
  );
};

export default memo(AutocompleteSelect) as typeof AutocompleteSelect;
