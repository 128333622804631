import { Flex, FlexProps } from '@chakra-ui/react';
import { OAuth2ProviderType } from '@stormotion-auth/core';
import React, { memo, useCallback } from 'react';
import CrossIcon from './icons/CrossIcon';
import SocialButton from './SocialButton';

export type OnAddSocial = (provider: OAuth2ProviderType) => void;
export type OnRemoveSocial = (provider: OAuth2ProviderType) => void;

interface AccountSocialProps extends FlexProps {
  providerType: OAuth2ProviderType;
  isEnabled: boolean;
  onAdd: OnAddSocial;
  onRemove: OnRemoveSocial;
}

const AccountSocial: React.FC<AccountSocialProps> = ({
  providerType,
  isEnabled,
  onAdd,
  onRemove,
  ...props
}) => {
  const onAddProvider = useCallback(() => {
    onAdd(providerType);
  }, [providerType, onAdd]);

  const onRemoveProvider = useCallback(() => {
    onRemove(providerType);
  }, [providerType, onRemove]);

  return (
    <Flex alignItems="center" {...props}>
      {isEnabled && <CrossIcon cursor="pointer" onClick={onRemoveProvider} />}
      <SocialButton
        isEnabled={isEnabled}
        marginX={1}
        onClick={isEnabled ? onRemoveProvider : onAddProvider}
        socialType={providerType}
      />
    </Flex>
  );
};

export default memo(AccountSocial);
