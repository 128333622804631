import { useParams } from 'react-router-dom';
import {
  Team_User_Status_Enum,
  useUpdateTeamUserStatusMutation,
} from '../generated/graphql';

const useCancelSubscriptions = () => {
  const { id } = useParams();

  const teamId = id || '';

  const [cancelSubscriptions] = useUpdateTeamUserStatusMutation({
    variables: {
      status: Team_User_Status_Enum.Basic,
      where: { team_id: { _eq: teamId } },
    },
  });

  return cancelSubscriptions;
};

export default useCancelSubscriptions;
