import React, { memo } from 'react';
import { CloseButton, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LocalesKeys } from '../locales/localesKeys';

import { RouteModalComponent } from '../navigation/Modals';

export enum CreateProjectError {
  UserNotLicensed = 'not-licensed-error',
  TeamNotBilled = 'not-paid-error',
}

const CreateProjectButton: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const params = useParams();

  return (
    <VStack maxW="xl" p={8} pb={20} spacing={6}>
      <Flex justify="flex-end" w="full">
        <CloseButton onClick={onClose} />
      </Flex>
      <Heading>{t('createProjectErrorHeader')}</Heading>
      <Text textAlign="center">
        {t(
          `createProjectErrorBody.${
            params?.error ?? CreateProjectError.TeamNotBilled
          }`,
        )}
      </Text>
    </VStack>
  );
};

export default memo(CreateProjectButton);
