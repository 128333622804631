import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const OverdueIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} fill="#EF8346" r={10} />
    <path
      d="M12 6v7h5"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Icon>
);

export default memo(OverdueIcon);
