import {
  Flex,
  HStack,
  Heading,
  IconButton,
  Spacer,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserFragment } from '../generated/graphql';
import useNavigation from '../hooks/useNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import { useTeamContext } from '../store/contexts/TeamContext';
import { IconClass } from '../theme/utils';
import ARListFilters from './ARListFilters';
import ChevronLeftIcon from './icons/ChevronLeftIcon';

const BACK_ICON = <ChevronLeftIcon className={IconClass.CustomStroke} />;

interface Props extends StackProps {
  drivers?: UserFragment[];
}

const ArchiveHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation(LocalesKeys.Archive);

  const { goBackOrReplace } = useNavigation();

  const { rootPath } = useTeamContext();

  const goBack = useCallback(() => {
    goBackOrReplace(rootPath);
  }, [goBackOrReplace, rootPath]);

  return (
    <Flex align="center" justify="space-between" w="full">
      <Stack flex={1} spacing={6}>
        <Flex align="center">
          <IconButton
            aria-label="back"
            boxSize={12}
            colorScheme="secondary"
            icon={BACK_ICON}
            onClick={goBack}
          />
          <Heading ml={8} size="headline1">
            {t('archivedProjects')}
          </Heading>
          <Spacer />
        </Flex>
        <HStack spacing={32}>
          <ARListFilters {...props} />
        </HStack>
      </Stack>
    </Flex>
  );
};

export default memo(ArchiveHeader);
