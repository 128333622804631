import React, { memo } from 'react';
import routes from './routes';
import BaseGuard, { BaseGuardProps } from './BaseGuard';
import { useUserContext } from '../store/contexts/UserContext';

interface Props extends BaseGuardProps {}

const AuthorizedGuard: React.FC<Props> = (props) => {
  const { cognitoUser, loading } = useUserContext();

  const rule = !!cognitoUser?.isValid;

  return (
    <BaseGuard
      loading={loading}
      rule={rule}
      to={routes.AuthRoute.SignUp}
      {...props}
    />
  );
};

export default memo(AuthorizedGuard);
