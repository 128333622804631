import React, { memo } from 'react';
import { Team_User_Role_Enum } from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import BaseGuard, { BaseGuardProps } from './BaseGuard';

interface Props extends BaseGuardProps {}

const TeamOwnerGuard: React.FC<Props> = (props) => {
  const { team, loading } = useTeamContext();

  const { user } = useUserContext();

  const isTeamOwner = team?.team_users.some(
    (teamUser) =>
      teamUser.role === Team_User_Role_Enum.Owner &&
      teamUser.user.id === user?.id,
  );

  return (
    <BaseGuard
      loading={loading}
      rule={isTeamOwner}
      to="/settings/team"
      {...props}
    />
  );
};

export default memo(TeamOwnerGuard);
