import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransferTeamOwnership } from '../hooks/useTransferTeamOwnership';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import TeamOwnerGuard from '../navigation/TeamOwnerGuard';

const TransferTeamOwnership: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const { userName, onChangeTeamUserRole } = useTransferTeamOwnership();

  const Content = useMemo(
    () => (
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
      >
        <Heading size="headline3">{t('transferOwnership')}</Heading>
        <Text mt={6} textAlign="center">
          {t('transferOwnershipToUser', { userName })}
        </Text>
        <Flex gridGap={6} mt={6}>
          <Button colorScheme="secondary" onClick={onClose} px={6}>
            {t('cancel')}
          </Button>
          <Button onClick={onChangeTeamUserRole} px={6}>
            {t('transfer')}
          </Button>
        </Flex>
      </Flex>
    ),
    [onChangeTeamUserRole, onClose, t, userName],
  );

  return <TeamOwnerGuard>{Content}</TeamOwnerGuard>;
};

export default memo(TransferTeamOwnership);
