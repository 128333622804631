import { Heading, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { motion } from 'framer-motion';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { LocalesKeys } from '../locales/localesKeys';
import { ANIMATIONS } from '../pages/CreateTeam';
import CreateTeamInputs from './CreateTeamInputs';

export const createTeamStep2InitialValues = {
  domain: '',
  teamName: '',
};

export type CreateTeamStep2Values = typeof createTeamStep2InitialValues;

const CreateTeamStep2: React.FC = () => {
  const {
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    values: { teamName },
  } = useFormikContext<CreateTeamStep2Values>();

  useEffect(() => {
    setFieldValue('domain', slugify(teamName, { lower: true }));
  }, [setFieldValue, teamName]);

  const { t } = useTranslation(LocalesKeys.CreateTeam);

  return (
    <>
      <motion.div
        animate="animate"
        exit="exit"
        initial="initial"
        transition={{ duration: 0.7 }}
        variants={ANIMATIONS}
      >
        <Heading mt={10} size="headline2">
          {t('secondHeader')}
        </Heading>
        <Text mt={6} size="body1" w={80}>
          {t('lockInYourTeam')}
        </Text>
        <CreateTeamInputs
          getFieldMeta={getFieldMeta}
          getFieldProps={getFieldProps}
        />
      </motion.div>
    </>
  );
};

export default memo(CreateTeamStep2);
