export default {
  add: 'Add to project',
  APPROVER: 'Approver',
  back: 'Back',
  BASIC: 'Basic',
  beforeDeadline: 'Two days before deadline',
  CONTRIBUTOR: 'Contributor',
  create: 'Create',
  createHeadline: 'Create Approval Request (AR)',
  daily: 'Daily (not recommended)',
  deadlineLabel: 'Deadline',
  detailsHeading: 'AR details',
  DRIVER: 'Driver',
  driverNameHelpingText: 'This cannot be changed',
  driverNameLabel: 'Created by',
  edit: 'Edit',
  editHeadline: 'Edit Approval Request (AR)',
  externalDescription:
    'What project or task is this asset a part of? Add its name here, and then link to the project wherever it’s being managed (e.g., Trello, Asana, Flow).',
  externalHeading: 'About the asset and project (optional)',
  externalLinkLabel: 'Link to project',
  externalNameLabel: 'Project name',
  INFORMED: 'Informed',
  invite: 'Invite',
  LICENSED: 'Licensed',
  linkLabel: 'Link to asset (online)',
  linkPlaceholder: 'Paste link or search Google Drive',
  nameLabel: 'AR name',
  namePlaceholder: 'Name',
  navbarDate: '{{month}} {{year}}',
  peopleDescription: 'Select from your team, or invite others by email.',
  peopleDetails: 'To make decisions faster, set DACI roles for each reviewer.',
  peopleHeading: 'People reviewing this asset',
  permission: 'Permission',
  projectExists: 'Project with this name already exists',
  radioDescription: 'Choose the time of day and the best days of the week.',
  remindersDescription:
    'Please tell us how frequently we should remind your reviewers that you’re awaiting their feedback.',
  remindersHeading: 'Sending reminders to reviewers',
  remove: 'Remove',
  reviewers: 'Reviewers',
  role: 'Role',
  setRole: 'Set role',
  step: 'Step',
  submit: 'Next',
  timeLabel: 'Reminder time',
  userAlreadyInvited: 'User with this email is already invited',
  weekly: 'Weekly',
  you: '(you)',
};
