import { CreateProjectError } from '../../components/CreateProjectErrorModal';

export default {
  allDrivers: 'All drivers',
  allProjectsStatus: 'All AR statuses',
  allUsersWillBeNotified: '* All Reviewers will be notified.',
  approvalStatus: 'Approval status',
  approved: 'Approved',
  archive: 'Archive',
  archiveToastMessage:
    'The link for this file will be transferred to the archive.',
  arDetails: 'AR details',
  cancel: 'Cancel',
  cancelAR: 'Cancel AR',
  changeMyStatus: 'Change My Status',
  close: 'Close',
  createProject: 'Create AR',
  createProjectErrorBody: {
    [CreateProjectError.TeamNotBilled]:
      'You have already used 3 free AR and now you must contact the admin to set up billing plan.',
    [CreateProjectError.UserNotLicensed]:
      'You have already used 3 free AR and now you must contact the admin to upgrade your permission to licensed User.',
  },
  createProjectErrorHeader: 'Upgrade your permission',
  deadline: 'Deadline',
  deleteAR: 'Delete AR',
  draft: 'Draft (Private)',
  driver: 'Driver',
  editProject: 'Edit AR',
  itemsCount: '{{ minIndex }}-{{ maxIndex }} of {{ count }} items',
  myRole: 'My role',
  myStatus: 'My Status',
  notApproved: 'Not approved',
  noteLabel: 'Note (optional)',
  notePlaceholder: 'Here you can add some desctiption',
  openARFile: 'Open AR file',
  projectName: 'AR name',
  projects: 'Approval requests',
  projectStatus: 'AR status',
  restoreAR: 'Restore AR',
  rowsPerPage: 'Rows per page:',
  underReview: 'Under review',
  updateStatus: 'Update Status',
  updateYourStatus: 'Update your status',
};
