import React, { SVGProps, memo } from 'react';

const TransferOwnershipIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={18} width={20} {...props}>
    <path
      clipRule="evenodd"
      d="M7.5 1.8a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4ZM3.2 4.5a4.3 4.3 0 1 1 8.6 0 4.3 4.3 0 0 1-8.6 0Zm-1.79 7.148c.788-.91 1.884-1.448 3.056-1.448h6.067c1.171 0 2.267.538 3.055 1.448.786.906 1.211 2.113 1.211 3.352v2a.8.8 0 0 1-1.6 0v-2c0-.883-.305-1.71-.82-2.304-.512-.59-1.18-.896-1.846-.896H4.466c-.667 0-1.335.305-1.847.896-.515.594-.82 1.421-.82 2.304v2a.8.8 0 0 1-1.6 0v-2c0-1.239.426-2.446 1.211-3.352Zm18.155-4.082a.8.8 0 0 0-1.132-1.131l-2.767 2.767-1.101-1.1a.8.8 0 0 0-1.131 1.13L15.1 10.9a.8.8 0 0 0 1.132 0l3.333-3.333Z"
      fill="#16181C"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(TransferOwnershipIcon);
