import { createIcon } from '@chakra-ui/react';
import React from 'react';

export const BillingIcon = createIcon({
  displayName: 'DetailsIcon',
  path: (
    <path
      clipRule="evenodd"
      d="M2.75 6C2.75 5.30964 3.30964 4.75 4 4.75H20C20.6904 4.75 21.25 5.30964 21.25 6V8.75H2.75V6ZM1.25 9.5V6C1.25 4.48122 2.48122 3.25 4 3.25H20C21.5188 3.25 22.75 4.48122 22.75 6V9.5V18C22.75 19.5188 21.5188 20.75 20 20.75H4C2.48122 20.75 1.25 19.5188 1.25 18V9.5ZM21.25 10.25V18C21.25 18.6904 20.6904 19.25 20 19.25H4C3.30964 19.25 2.75 18.6904 2.75 18V10.25H21.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});
