import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Heading,
  Link,
  SelectProps,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { last } from 'ramda';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ARDetailsTable from '../components/ARDetailsTable';
import ArStatusAndNoteFields from '../components/ArStatusAndNoteFields';
import CenterDialogModal from '../components/CenterDialogModal';
import ARDetailsEditIcon from '../components/icons/ARDetailsEditIcon';
import ARFileIcon from '../components/icons/ARFileIcon';
import DeadlineIcon from '../components/icons/DeadlineIcon';
import DeleteIcon from '../components/icons/DeleteIcon';
import Loading from '../components/Loading';
import StatusBadge from '../components/StatusBadge';
import { Project_Status_Enum } from '../generated/graphql';
import useARDetails from '../hooks/useARDetails';
import useChangeProjectStatusFormik from '../hooks/useChangeProjectStatusFormik';
import { LocalesKeys } from '../locales/localesKeys';
import Modals, {
  RouteModalComponent,
  RouteModalProps,
} from '../navigation/Modals';
import routes from '../navigation/routes';
import DeleteAR from './DeleteAR';

const SELECT_PROPS: SelectProps = {
  w: 300,
};

const BOTTOM_CONTAINER_FIX_STYLE: React.CSSProperties = {
  marginTop: 0,
};

const detailsModals: RouteModalProps[] = [
  {
    Component: DeleteAR,
    Modal: CenterDialogModal,
    route: routes.ARRoutes.ARDelete,
  },
];

const ARDetails: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.ARDetails);

  const navigate = useNavigate();

  const {
    getFieldMeta,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    pickedAR,
    deadline,
    teamMembers,
    projectStatus,
    isDriver,
    isUserInTeam,
    createdAt,
    name,
    arId,
  } = useChangeProjectStatusFormik(onClose);

  const { externalName, externalLink } = useARDetails();

  const openDeleteARModal = useCallback(
    () => navigate(routes.ARRoutes.ARDelete),
    [navigate],
  );

  const onOpenDoc = useCallback(
    () => (pickedAR?.link ? window.open(pickedAR?.link) : null),
    [pickedAR?.link],
  );

  const onEditAR = useCallback(
    () => navigate(last(routes.ARRoutes.AREdit.split('/')) ?? ''),
    [navigate],
  );

  const ExternalDetails = useMemo(
    () =>
      externalLink && (
        <Flex flexDirection="column" mt={12}>
          <Heading color="black.500" size="headline3">
            {t('assetsHeader')}
          </Heading>
          <Text mt={5}>{t('assetsBody')}</Text>
          <Link
            color="blue.600"
            display="flex"
            href={externalLink}
            mt={5}
            target="_blank"
            w="fit-content"
          >
            <ARFileIcon />
            <Heading ml={4} size="headline4">
              {externalName}
            </Heading>
          </Link>
        </Flex>
      ),
    [externalLink, externalName, t],
  );

  const Content = useMemo(
    () =>
      pickedAR ? (
        <chakra.form onSubmit={handleSubmit} overflow="scroll" w="full">
          <VStack w="full">
            <VStack
              align="flex-start"
              flex={1}
              h="full"
              minH={0}
              overflow="scroll"
              px={10}
              py={10}
              w="full"
            >
              <Flex alignItems="center" justify="space-between" w="full">
                <CloseIcon
                  color="black.500"
                  cursor="pointer"
                  onClick={onClose}
                />
                <Flex alignItems="center">
                  <Flex gridGap={7} mr={7}>
                    {isDriver && (
                      <>
                        <ARDetailsEditIcon
                          cursor="pointer"
                          onClick={onEditAR}
                        />
                        <DeleteIcon
                          cursor="pointer"
                          onClick={openDeleteARModal}
                        />
                      </>
                    )}
                  </Flex>
                  <Flex gridGap={4}>
                    <Flex
                      alignItems="center"
                      bgColor="black.100"
                      borderRadius={60}
                      justifyContent="center"
                      padding={9.5}
                    >
                      <DeadlineIcon />
                      <Text color="black.400" ml={3} size="subhead2">
                        {deadline}
                      </Text>
                    </Flex>
                    <StatusBadge name={projectStatus as Project_Status_Enum} />
                  </Flex>
                </Flex>
              </Flex>
              <Heading pt={12} size="headline2">
                {name}
              </Heading>
              <Flex flexDirection="column" w="full">
                <Text color="black.400" size="body2">
                  {t('created', {
                    createdAt,
                    username:
                      pickedAR.driver?.username ?? pickedAR.driver?.email,
                  })}
                </Text>
                <Text mt={6}>{t('setStatus')}</Text>
                <ArStatusAndNoteFields
                  arId={arId}
                  getFieldMeta={getFieldMeta}
                  getFieldProps={getFieldProps}
                  isDriver={isDriver}
                  isUserInTeam={isUserInTeam}
                  selectProps={SELECT_PROPS}
                />
                <Heading color="black.500" mb={5} mt={10} size="headline3">
                  {t('peopleReviewing', { count: teamMembers.length })}
                </Heading>
                <ARDetailsTable
                  isDriver={isDriver}
                  pickedAR={pickedAR}
                  teamMembers={teamMembers}
                />
                {ExternalDetails}
              </Flex>
            </VStack>
            {isUserInTeam ? (
              <Flex
                bg="white"
                borderColor="black.200"
                borderTop="1px"
                bottom={0}
                color="black.200"
                justify="space-between"
                position="sticky"
                px={9}
                py={8}
                style={BOTTOM_CONTAINER_FIX_STYLE}
                w="50vw"
              >
                <Flex>
                  <Button colorScheme="secondary" onClick={onOpenDoc} w={119}>
                    {t('openDoc')}
                  </Button>
                </Flex>
                <Flex gridGap={6}>
                  <Button colorScheme="secondary" onClick={onClose} w={119}>
                    {t('cancel')}
                  </Button>
                  {!isDriver && (
                    <Button isLoading={isSubmitting} type="submit" w={119}>
                      {t('save')}
                    </Button>
                  )}
                </Flex>
              </Flex>
            ) : null}
          </VStack>
        </chakra.form>
      ) : (
        <Loading />
      ),
    [
      pickedAR,
      handleSubmit,
      onClose,
      isDriver,
      onEditAR,
      openDeleteARModal,
      deadline,
      projectStatus,
      name,
      t,
      createdAt,
      arId,
      getFieldMeta,
      getFieldProps,
      isUserInTeam,
      teamMembers,
      ExternalDetails,
      onOpenDoc,
      isSubmitting,
    ],
  );

  return (
    <>
      {Content}
      <Modals modals={detailsModals} />
    </>
  );
};

export default memo(ARDetails);
