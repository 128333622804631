import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { IconClass } from '../../theme/utils';

const ArchiveIcon: React.FC<IconProps> = (props) => (
  <Icon
    className={IconClass.CustomFill}
    fill="none"
    h={6}
    w={6}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M5 2.2A2.8 2.8 0 0 0 2.2 5v14A2.8 2.8 0 0 0 5 21.8h14a2.8 2.8 0 0 0 2.8-2.8V5A2.8 2.8 0 0 0 19 2.2H5ZM3.8 5A1.2 1.2 0 0 1 5 3.8h14A1.2 1.2 0 0 1 20.2 5v6.2H3.8V5Zm0 7.8V19A1.2 1.2 0 0 0 5 20.2h14a1.2 1.2 0 0 0 1.2-1.2v-6.2H3.8ZM9.2 16a.8.8 0 0 1 .8-.8h4a.8.8 0 1 1 0 1.6h-4a.8.8 0 0 1-.8-.8Zm.8-9.8a.8.8 0 0 0 0 1.6h4a.8.8 0 0 0 0-1.6h-4Z"
      fill="#16181C"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(ArchiveIcon);
