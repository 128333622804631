import { find } from 'ramda';
import { Team_User_Role_Enum } from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import useUserEmail from './useUserEmail';

export const useIsTeamOwner = () => {
  const { team } = useTeamContext();

  const email = useUserEmail();

  return !!find(
    (user) =>
      user.user.email === email && user.role === Team_User_Role_Enum.Owner,
    team?.team_users ?? [],
  );
};
