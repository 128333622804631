export default {
  approverDescription:
    'The party with ultimate veto power. Typically the most senior member of the review team.',
  approverHeading: 'A - Approver',
  caption: 'To make decisions faster, set DACI roles for each reviewer.',
  contributorDescription:
    'The persons directly involved in and affected by this asset’s success.',
  contributorHeading: 'C - Contributor',
  driverDescription:
    'The person driving this AR. Typically this is the person who sets up the AR. ',
  driverHeading: 'D - Driver',
  informedDescription:
    'The persons indirectly affected by this asset’s success.',
  informedHeading: 'I - Informed',
};
