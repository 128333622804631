import { Flex, Heading, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { sentenceCase } from 'change-case';
import { format } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column, useTable } from 'react-table';
import Avatar from '../components/Avatar';
import LinkIcon from '../components/icons/LinkIcon';
import ProjectMenu from '../components/ProjectMenu';
import SortingButton from '../components/SortingButton';
import StatusBadge from '../components/StatusBadge';
import UserStatusProjectTableCell from '../components/UserStatusProjectTableCell';
import {
  ProjectDashboardFragment,
  Project_Status_Enum,
  Project_User_Display_Status_Enum,
  Project_User_Role_Enum,
  UserFragment,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { DashboardSorting } from './useDashboardForm';

export interface ProjectTableParams {
  projects: ProjectDashboardFragment[];
  onSortingClick?: React.MouseEventHandler<HTMLButtonElement>;
  sorting?: DashboardSorting;
}

const useProjectTable = ({
  projects,
  onSortingClick,
  sorting,
}: ProjectTableParams) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const columns = useMemo<Column<ProjectDashboardFragment>[]>(
    () => [
      {
        accessor: 'name',
        Cell: ({
          row: {
            original: { link },
          },
          value,
        }: CellProps<ProjectDashboardFragment, Maybe<string>>) => (
          <LinkBox align="center" as={Flex}>
            <LinkOverlay href={link ?? ''} target="_blank">
              <LinkIcon h={6} mr={4} w={6} />
            </LinkOverlay>
            <Text maxW="4xs" noOfLines={2} size="body2">
              {value}
            </Text>
          </LinkBox>
        ),
        Header: () => (
          <SortingButton
            activeSort={sorting}
            id="name"
            onClick={onSortingClick}
            title={t('projectName')}
          />
        ),
      },
      {
        accessor: 'deadline',
        Cell: ({ value }: CellProps<ProjectDashboardFragment, Maybe<string>>) =>
          value ? (
            <Text size="body2">{format(new Date(value), 'dd MMM, yyyy')}</Text>
          ) : null,
        Header: () => (
          <SortingButton
            activeSort={sorting}
            id="deadline"
            onClick={onSortingClick}
            title={t('deadline')}
          />
        ),
      },
      {
        accessor: 'driver',
        Cell: ({
          value,
        }: CellProps<ProjectDashboardFragment, Maybe<UserFragment>>) => {
          const username = value?.username ?? value?.email;
          const imageUrl = value?.image?.url ?? undefined;
          const userId = value?.id;

          return (
            <Flex align="center">
              <Avatar
                h={10}
                mr={2}
                name={username}
                src={imageUrl}
                userId={userId}
                w={10}
              />
              <Text size="body2">{username}</Text>
            </Flex>
          );
        },
        Header: () => (
          <SortingButton
            activeSort={sorting}
            id="driver"
            onClick={onSortingClick}
            title={t('driver')}
          />
        ),
      },
      {
        accessor: 'status',
        Cell: ({
          value,
        }: CellProps<ProjectDashboardFragment, Maybe<Project_Status_Enum>>) =>
          value ? <StatusBadge name={value} /> : null,
        Header: () => (
          <SortingButton
            activeSort={sorting}
            id="status"
            onClick={onSortingClick}
            title={t('projectStatus')}
          />
        ),
      },
      {
        accessor: 'user_role',
        Cell: ({
          value,
        }: CellProps<
          ProjectDashboardFragment,
          Maybe<Project_User_Role_Enum>
        >) => (value ? <Text size="body2">{sentenceCase(value)}</Text> : null),
        Header: () => (
          <SortingButton
            activeSort={sorting}
            id="user_role"
            onClick={onSortingClick}
            title={t('myRole')}
          />
        ),
      },
      {
        accessor: 'user_status',
        Cell: ({ value, row }: CellProps<ProjectDashboardFragment, string>) =>
          value ? (
            <Flex
              align="center"
              justifyContent="space-between"
              userSelect="none"
            >
              <UserStatusProjectTableCell
                arId={row.original.id}
                isArchived={
                  row.original.display_status ===
                  Project_User_Display_Status_Enum.Archive
                }
                userRole={row.original.user_role}
                value={value}
              />
              {row.original.id && (
                <ProjectMenu
                  id={row.original.id}
                  link={row.original.link}
                  project={row.original}
                />
              )}
            </Flex>
          ) : null,
        Header: () => (
          <Heading as="p" size="headline4">
            {t('approvalStatus')}
          </Heading>
        ),
      },
    ],
    [onSortingClick, sorting, t],
  );

  return useTable({ columns, data: projects });
};

export default useProjectTable;
