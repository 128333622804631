import React, { memo, useMemo } from 'react';
import { Badge, BadgeProps, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ApprovedIcon from './icons/ApprovedIcon';
import DraftIcon from './icons/DraftIcon';
import NotApprovedIcon from './icons/NotApprovedIcon';
import OverdueIcon from './icons/OverdueIcon';
import UnderReviewIcon from './icons/UnderReviewIcon';
import { LocalesKeys } from '../locales/localesKeys';
import { Project_Status_Enum } from '../generated/graphql';

interface Props extends BadgeProps {
  name: Project_Status_Enum;
}

interface ConfigurationItem {
  colorScheme?: string;
  icon?: JSX.Element;
  title?: string;
}

type Configuration = Record<Project_Status_Enum, ConfigurationItem>;

const StatusBadge: React.FC<Props> = ({ name, ...props }) => {
  const { t } = useTranslation(LocalesKeys.Status);

  const configuration = useMemo<Configuration>(
    () => ({
      [Project_Status_Enum.Draft]: {
        colorScheme: 'purple',
        icon: <DraftIcon />,
        title: t('draft'),
      },
      [Project_Status_Enum.UnderReview]: {
        colorScheme: 'blue',
        icon: <UnderReviewIcon />,
        title: t('underReview'),
      },
      [Project_Status_Enum.Approved]: {
        colorScheme: 'green',
        icon: <ApprovedIcon />,
        title: t('approved'),
      },
      [Project_Status_Enum.Rejected]: {
        colorScheme: 'red',
        icon: <NotApprovedIcon />,
        title: t('notApproved'),
      },
      [Project_Status_Enum.Cancelled]: {
        colorScheme: 'red',
        icon: <NotApprovedIcon />,
        title: t('cancelled'),
      },
      [Project_Status_Enum.Overdue]: {
        colorScheme: 'orange',
        icon: <OverdueIcon />,
        title: t('overdue'),
      },
    }),
    [t],
  );

  const { colorScheme, icon: Icon, title } = configuration[name];

  return (
    <Badge as={Flex} colorScheme={colorScheme} {...props}>
      {Icon}
      <Text ml={2} size="subhead2">
        {title}
      </Text>
    </Badge>
  );
};

export default memo(StatusBadge);
