import { useDisclosure } from '@chakra-ui/react';
import { head, values } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Project_User_Role_Enum,
  useInsertProjectUserOneMutation,
  useProjectsSubscription,
  useUserByPkQuery,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';

export const useAddProjectToUser = () => {
  const [userName, setUserName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [invitedMemberRole, setInvitedMemberRole] = useState(
    head(values(Project_User_Role_Enum)),
  );

  const {
    isOpen: isOpenRolePicker,
    onClose: onCloseRolePicker,
    onOpen: onOpenRolePicker,
  } = useDisclosure();

  const { id } = useParams();

  const { team } = useTeamContext();

  const { data: userData } = useUserByPkQuery({ variables: { id: id || '' } });

  const user = userData?.user_by_pk;

  const { data } = useProjectsSubscription({
    variables: {
      where: { team_id: { _eq: team?.id } },
    },
  });

  const projects = data?.project;

  const filteredProjects = useMemo(
    () =>
      projects
        ?.filter(
          (project) =>
            !project.project_users.some((user) => user.user.id === id),
        )
        .map((project) => ({ value: project.name, ...project })) || [],
    [id, projects],
  );

  const pickedProjectId = useMemo(
    () =>
      filteredProjects[
        filteredProjects.findIndex((project) => project.name === projectName)
      ]?.id || '',
    [filteredProjects, projectName],
  );

  const [insertUserIntoProject] = useInsertProjectUserOneMutation({
    variables: {
      object: {
        project_id: pickedProjectId,
        role: invitedMemberRole,
        user_id: user?.id,
      },
    },
  });

  const onChangeProjectName = useCallback(
    (e) => setProjectName(e.target.value),
    [],
  );

  const setProjectNameValue = useCallback<(value: string) => void>((value) => {
    setProjectName(value);
  }, []);

  const onChangeUserRole = useCallback(
    (value) => setInvitedMemberRole(value),
    [],
  );

  useEffect(() => {
    if (user) {
      setUserName(user.username || user.email);
    }
  }, [user]);

  return {
    filteredProjects,
    insertUserIntoProject,
    invitedMemberRole,
    isOpenRolePicker,
    onChangeProjectName,
    onChangeUserRole,
    onCloseRolePicker,
    onOpenRolePicker,
    pickedProjectId,
    projectName,
    setInvitedMemberRole,
    setProjectNameValue,
    userName,
  };
};
