import React, { memo } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Select as ChakraSelect,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  SelectProps,
  Text,
} from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/theme-tools';
import { useTranslation } from 'react-i18next';
import ChevronDownIcon from './icons/ChevronDownIcon';
import { LocalesKeys } from '../locales/localesKeys';

interface Props extends SelectProps {
  error?: string;
  touched?: boolean;
  boxProps?: FormControlProps;
  helpingText?: string;
  label?: string;
}

const FOCUS_STYLE: SystemStyleObject = {
  border: '1px',
  borderColor: 'primary.600',
  boxShadow: 'select',
};

const CHEVRON_DOWN_ICON = <ChevronDownIcon />;

const Select: React.FC<Props> = ({
  touched,
  error,
  helpingText,
  boxProps,
  label,
  ...props
}) => {
  const isInvalid = touched && !!error;

  const { t } = useTranslation(LocalesKeys.ValidationErrors);

  return (
    <FormControl isInvalid={isInvalid} {...boxProps}>
      <FormLabel color="black.400" letterSpacing="tight" pl={3} size="caption1">
        {label}
      </FormLabel>
      <ChakraSelect
        _focus={FOCUS_STYLE}
        bg="white"
        borderColor="black.200"
        borderRadius={8}
        h={12}
        icon={CHEVRON_DOWN_ICON}
        {...props}
      />
      {error ? (
        <Text color="red" letterSpacing="tight" pl={3} pt={2} size="body2">
          {t(error)}
        </Text>
      ) : (
        <FormHelperText
          color="black.400"
          letterSpacing="tight"
          pl={3}
          size="caption1"
        >
          {helpingText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(Select);
