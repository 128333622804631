import React, { memo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { AvatarProps, Avatar as ChakraAvatar } from '@chakra-ui/react';
import { isNil } from 'ramda';
import toMaterialStyle from 'material-color-hash';

interface Props extends AvatarProps {
  userId?: string;
}

const Avatar: React.FC<Props> = ({ userId, name, src, ...props }) => {
  const displayName = isNil(src) ? name : undefined;

  const { backgroundColor, color } = toMaterialStyle(userId || '', 500);

  return (
    <ChakraAvatar
      backgroundColor={backgroundColor}
      color={color}
      name={displayName}
      src={src}
      {...props}
    />
  );
};

export default memo(Avatar);
