import React, { memo, useEffect } from 'react';
import BaseGuard, { BaseGuardProps } from './BaseGuard';
import { useUserContext } from '../store/contexts/UserContext';
import { cookieStorageDomain } from '../env';
import { generateUrlWithSubdomain } from '../utils/navigation';

interface Props extends BaseGuardProps {}

const UnauthorizedGuard: React.FC<Props> = (props) => {
  const { cognitoUser, loading } = useUserContext();

  const rule = !cognitoUser?.isValid;

  const existingSubdomain = window.location.hostname.replace(
    cookieStorageDomain ?? '',
    '',
  );

  useEffect(() => {
    if (existingSubdomain) {
      window.location.replace(generateUrlWithSubdomain(true, ''));
    }
  }, [existingSubdomain]);

  return <BaseGuard loading={loading} rule={rule} to="/" {...props} />;
};

export default memo(UnauthorizedGuard);
