import React, { memo, useMemo } from 'react';
import {
  HStack,
  Heading,
  Text,
  VStack,
  useCheckboxGroup,
  useRadioGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { range, values, without } from 'ramda';
import moment from 'moment';
import { LocalesKeys } from '../locales/localesKeys';
import RemindersRadio from './RemindersRadio';
import ScrollableCollapse from './ScrollableCollapse';
import TextInput from './TextInput';
import DayRadio from './DayRadio';
import { RemindersValues } from '../utils/formValidations';
import { Reminder_Type_Enum } from '../generated/graphql';

const REMINDER_OPTIONS = values(Reminder_Type_Enum);

const COLLAPSE_STYLE_OVERRIDE = {
  marginTop: 0,
};

const weekdays = range(0, 6).map(String);

const SendingReminders: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const {
    getFieldMeta,
    getFieldProps,
    setFieldValue,
    values: { reminder_type, reminder_days },
  } = useFormikContext<RemindersValues>();

  const { getCheckboxProps: getDayProps } = useCheckboxGroup({
    onChange: (value) => {
      setFieldValue(
        'reminder_days',
        reminder_type === Reminder_Type_Enum.Weekly
          ? without(reminder_days ?? [], value)
          : value,
      );
    },
    value: reminder_days,
  });

  const DayRadios = useMemo(
    () =>
      weekdays.map((day) => {
        const checkbox = getDayProps({ value: day });
        return (
          <DayRadio key={day} {...checkbox}>
            {t(moment.weekdaysShort()[Number(day)] ?? '')}
          </DayRadio>
        );
      }),
    [getDayProps, t],
  );

  const { getRadioProps: getReminderProps } = useRadioGroup({
    onChange: (value) => {
      setFieldValue('reminder_type', value);
      setFieldValue(
        'reminder_days',
        value === Reminder_Type_Enum.Daily ? weekdays.slice(1, 6) : [],
      );
    },
    value: reminder_type,
  });

  const ReminderRadioGroup = useMemo(
    () =>
      REMINDER_OPTIONS.map((value) => {
        const radio = getReminderProps({ value });

        return (
          <React.Fragment key={value}>
            <RemindersRadio {...radio} reminderType={value} />
            <ScrollableCollapse
              in={radio.isChecked}
              style={COLLAPSE_STYLE_OVERRIDE}
              unmountOnExit
            >
              <VStack align="inherit" pl="8" pt="4">
                <Text pb={6} size="body1">
                  {t('radioDescription')}
                </Text>
                <TextInput
                  {...getFieldMeta('reminder_time')}
                  {...getFieldProps('reminder_time')}
                  label={t('timeLabel')}
                  type="time"
                  w="fit-content"
                />
                {value !== Reminder_Type_Enum.BeforeDeadline && (
                  <HStack pt={4}>{DayRadios}</HStack>
                )}
              </VStack>
            </ScrollableCollapse>
          </React.Fragment>
        );
      }),
    [DayRadios, getFieldMeta, getFieldProps, getReminderProps, t],
  );

  return (
    <VStack align="flex-start" w="full">
      <Heading color="black.500" pb={4} size="headline3">
        {t('remindersHeading')}
      </Heading>
      <Text pb={6} size="body1">
        {t('remindersDescription')}
      </Text>
      <VStack align="inherit" spacing={6}>
        {ReminderRadioGroup}
      </VStack>
    </VStack>
  );
};

export default memo(SendingReminders);
