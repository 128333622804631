const colors = {
  black: {
    100: '#EFF0F0',
    200: '#E1E2E3',
    250: '#C2C3C4',
    300: '#A2A3A4',
    400: '#737477',
    500: '#454649',
    600: '#16181C',
    700: '#000000',
    elevated: '#1C1C1E',
    wire: '#0C0E0F',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  blue: {
    50: '#ebf8ff',
    100: '#bee3f8',
    200: '#E1EDF4',
    300: '#63b3ed',
    400: '#4299e1',
    500: '#3182ce',
    600: '#0079BC',
    700: '#2c5282',
    800: '#2a4365',
    900: '#1A365D',
  },

  current: 'currentColor',

  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },

  facebook: {
    50: '#E8F4F9',
    100: '#D9DEE9',
    200: '#B7C2DA',
    300: '#6482C0',
    400: '#4267B2',
    500: '#385898',
    600: '#314E89',
    700: '#29487D',
    800: '#223B67',
    900: '#1E355B',
  },

  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
    bg: '#F7F8F9',
    global: '#FAFBFC',
  },

  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#E2F1E7',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#099B3B',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },

  linkedin: {
    50: '#E8F4F9',
    100: '#CFEDFB',
    200: '#9BDAF3',
    300: '#68C7EC',
    400: '#34B3E4',
    500: '#00A0DC',
    600: '#008CC9',
    700: '#0077B5',
    800: '#005E93',
    900: '#004471',
  },

  messenger: {
    50: '#D0E6FF',
    100: '#B9DAFF',
    200: '#A2CDFF',
    300: '#7AB8FF',
    400: '#2E90FF',
    500: '#0078FF',
    600: '#0063D1',
    700: '#0052AC',
    800: '#003C7E',
    900: '#002C5C',
  },

  orange: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#F9EEE8',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#EF8346',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },

  pink: {
    50: '#FFF5F7',
    100: '#FED7E2',
    200: '#FBB6CE',
    300: '#F687B3',
    400: '#ED64A6',
    500: '#D53F8C',
    600: '#B83280',
    700: '#97266D',
    800: '#702459',
    900: '#521B41',
  },

  primary: {
    100: '#FEEBF1',
    200: '#FDD6E2',
    300: '#FBAEC6',
    400: '#F985A9',
    500: '#F75D8D',
    600: '#F53470',
    700: '#DE235D',
  },

  purple: {
    50: '#FAF5FF',
    100: '#E9D8FD',
    200: '#F4E8F8',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#BA49E1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },

  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#F9D6D6',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#F44545',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },

  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },

  telegram: {
    50: '#E3F2F9',
    100: '#C5E4F3',
    200: '#A2D4EC',
    300: '#7AC1E4',
    400: '#47A9DA',
    500: '#0088CC',
    600: '#007AB8',
    700: '#006BA1',
    800: '#005885',
    900: '#003F5E',
  },

  transparent: 'transparent',

  twitter: {
    50: '#E5F4FD',
    100: '#C8E9FB',
    200: '#A8DCFA',
    300: '#83CDF7',
    400: '#57BBF5',
    500: '#1DA1F2',
    600: '#1A94DA',
    700: '#1681BF',
    800: '#136B9E',
    900: '#0D4D71',
  },

  whatsapp: {
    50: '#dffeec',
    100: '#b9f5d0',
    200: '#90edb3',
    300: '#65e495',
    400: '#3cdd78',
    500: '#22c35e',
    600: '#179848',
    700: '#0c6c33',
    800: '#01421c',
    900: '#001803',
  },

  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  yellow: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },
};

/**
 * @deprecated
 * You can derive the Colors type from the DefaultChakraTheme:
 *
 * type Colors = DefaultChakraTheme["colors"]
 */
export type Colors = typeof colors;

export default colors;
