import { Button, ButtonProps } from '@chakra-ui/react';
import { OAuth2ProviderType } from '@stormotion-auth/core';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import { IconClass } from '../theme/utils';
import GoogleIcon from './icons/GoogleIcon';
import SlackIcon from './icons/SlackIcon';

interface Props extends ButtonProps {
  socialType: OAuth2ProviderType;
  isEnabled?: boolean;
}

const GoogleButton: React.FC<Props> = ({
  socialType,
  isEnabled = false,
  ...props
}) => {
  const { t } = useTranslation(LocalesKeys.Button);

  const socialIcon = useMemo(() => {
    switch (socialType) {
      case OAuth2ProviderType.Google:
        return <GoogleIcon className={IconClass.CustomFill} />;
      default:
        return <SlackIcon className={IconClass.CustomFill} />;
    }
  }, [socialType]);

  return (
    <Button
      colorScheme={isEnabled ? 'black' : 'white'}
      leftIcon={socialIcon}
      variant={isEnabled ? 'solid' : 'outline'}
      {...props}
    >
      {t(`${socialType.toLowerCase()}_${isEnabled ? 'logout' : 'login'}`)}
    </Button>
  );
};

export default memo(GoogleButton);
