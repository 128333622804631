import React, { SVGProps, memo } from 'react';

const MenuDetailsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={21} width={21} {...props}>
    <path
      clipRule="evenodd"
      d="M10.996 2.595a8.4 8.4 0 1 0 0 16.8 8.4 8.4 0 0 0 0-16.8Zm-10 8.4c0-5.523 4.477-10 10-10s10 4.477 10 10c0 5.524-4.477 10-10 10s-10-4.476-10-10Zm10-.8a.8.8 0 0 1 .8.8v3.68a.8.8 0 1 1-1.6 0v-3.68a.8.8 0 0 1 .8-.8Zm0-3.68a.8.8 0 0 0 0 1.6h.01a.8.8 0 1 0 0-1.6h-.01Z"
      fill="#454649"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(MenuDetailsIcon);
