import React, { memo } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const Loading: React.FC = () => (
  <Center h="full" w="full">
    <Spinner size="xl" />
  </Center>
);

export default memo(Loading);
