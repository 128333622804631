import { HStack, Input, RadioProps, chakra, useRadio } from '@chakra-ui/react';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Reminder_Type_Enum } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import RadioButtonIcon from './icons/RadioButtonIcon';

interface Props extends Omit<RadioProps, 'children'> {
  reminderType: Reminder_Type_Enum;
}

const RemindersRadio: React.FC<Props> = ({ reminderType, ...props }) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = useMemo(() => getInputProps(), [getInputProps]);
  const checkbox = useMemo(() => getCheckboxProps(), [getCheckboxProps]);

  const text = useMemo(() => {
    switch (reminderType) {
      case Reminder_Type_Enum.Weekly:
        return t('weekly');
      case Reminder_Type_Enum.Daily:
        return t('daily');
      default:
        return t('beforeDeadline');
    }
  }, [reminderType, t]);

  return (
    <chakra.label cursor="pointer">
      <Input display="none" type="radio" {...input} />
      <HStack spacing={2}>
        <RadioButtonIcon color="primary.600" {...checkbox} />
        <chakra.span>{text}</chakra.span>
      </HStack>
    </chakra.label>
  );
};

export default memo(RemindersRadio);
