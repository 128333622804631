import React, { memo, useMemo } from 'react';
import * as R from 'ramda';
import {
  FormControl,
  FormErrorMessage,
  HStack,
  PinInput,
  PinInputField,
  PinInputProps,
} from '@chakra-ui/react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { TextInputProps } from './TextInput';

interface Props
  extends Omit<PinInputProps, 'children'>,
    Pick<TextInputProps, 'boxProps' | 'error' | 'touched'> {}

export const CODE_CELLS_COUNT = 6;

const CodeInput: React.FC<Props> = ({ boxProps, error, touched, ...props }) => {
  const isInvalid = touched && !!error;

  const Cells = useMemo(
    () =>
      pipe(
        CODE_CELLS_COUNT,
        R.repeat(PinInputField),
        A.mapWithIndex((index, Cell) => <Cell key={index} />),
      ),
    [],
  );

  return (
    <FormControl isInvalid={isInvalid} {...boxProps}>
      <HStack justify="space-around">
        <PinInput otp {...props}>
          {Cells}
        </PinInput>
      </HStack>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default memo(CodeInput);
