import React, { memo } from 'react';
import { Button, ButtonProps, Heading, useToken } from '@chakra-ui/react';
import { Order_By, Project_Dashboard_Order_By } from '../generated/graphql';
import TableSortIcon from './icons/TableSortIcon';
import { DashboardSorting } from '../hooks/useDashboardForm';

type ProjectDashboardOrderByKey = keyof Project_Dashboard_Order_By;

interface Props extends Omit<ButtonProps, 'children' | 'id'> {
  id: ProjectDashboardOrderByKey;
  activeSort?: DashboardSorting;
  title?: string;
}

enum SortingDirection {
  Up,
  Down,
}

const getActiveSortingColor = (
  direction: SortingDirection,
  id: ProjectDashboardOrderByKey,
  activeColor: string,
  inactiveColor: string,
  activeSort?: DashboardSorting,
) => {
  if (activeSort?.key !== id) return activeColor;

  switch (direction) {
    case SortingDirection.Down:
      switch (activeSort?.orderBy) {
        case Order_By.Asc:
          return inactiveColor;
        default:
          return activeColor;
      }

    default:
      switch (activeSort?.orderBy) {
        case Order_By.Desc:
          return inactiveColor;
        default:
          return activeColor;
      }
  }
};

const SortingButton: React.FC<Props> = ({
  activeSort,
  id,
  title,
  ...props
}) => {
  const [black500, primary600] = useToken('colors', [
    'black.500',
    'primary.600',
  ]);

  const downColor = getActiveSortingColor(
    SortingDirection.Down,
    id,
    black500,
    primary600,
    activeSort,
  );

  const upColor = getActiveSortingColor(
    SortingDirection.Up,
    id,
    black500,
    primary600,
    activeSort,
  );

  return (
    <Button id={id} variant="unstyled" {...props}>
      <Heading as="p" size="headline4">
        {title}
      </Heading>
      <TableSortIcon downColor={downColor} ml={2} upColor={upColor} />
    </Button>
  );
};

export default memo(SortingButton);
