import { useDisclosure } from '@chakra-ui/react';
import { compose, filter, head, not, propEq, values } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce/esm';
import {
  Team_User_Constraint,
  Team_User_Role_Enum,
  Team_User_Status_Enum,
  User_Constraint,
  User_Update_Column,
  useInsertTeamUsersMutation,
  useUsersSubscription,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { formatSearchQuery } from '../utils/hasura';
import { isEmailValid } from '../utils/isEmailValid';

interface InvitedUser {
  email: string;
  status: Team_User_Status_Enum | undefined;
  isEmailValid: boolean;
}

const QUERY_DEBOUNCE_MS = 300;

export const useInvitePeopleToTeam = () => {
  const [invitedMemberStatus, setInvitedMemberStatus] = useState(
    head(values(Team_User_Status_Enum)),
  );

  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);

  const [invitedUserEmail, setInvitedUserEmail] = useState('');

  const {
    isOpen: isOpenedRolePicker,
    onClose: onCloseRolePicker,
    onOpen: onOpenRolePicker,
  } = useDisclosure();

  const { team } = useTeamContext();

  const [debouncedEmail] = useDebounce(invitedUserEmail, QUERY_DEBOUNCE_MS);
  const emailQuery = formatSearchQuery(debouncedEmail);

  const where = useMemo(
    () => ({
      _and: [
        { team_users: { team_id: { _neq: team?.id } } },
        { email: { _ilike: emailQuery } },
      ],
    }),
    [emailQuery, team?.id],
  );

  const { data: usersData } = useUsersSubscription({
    variables: {
      where,
    },
  });

  const [insertTeamUsers] = useInsertTeamUsersMutation();

  const filteredTotalMembers = useMemo(() => {
    const filtered = usersData?.user
      ?.filter(
        (user) =>
          !invitedUsers.some(
            (invitedUser) => user.email === invitedUser.email,
          ) &&
          !user.team_users.some((teamUser) => teamUser.team_id === team?.id),
      )
      .map((member) => ({
        title: '',
        value: member.email,
        ...member,
      }));
    return filtered?.length ? filtered : [];
  }, [usersData?.user, invitedUsers, team?.id]);

  const setEmailValue = useCallback<(value: string) => void>(
    (value) => {
      setInvitedUserEmail(value);
      onOpenRolePicker();
    },
    [onOpenRolePicker],
  );

  const onChangeInvitedUserEmail = useCallback(
    (e) => setInvitedUserEmail(e.target.value),
    [],
  );

  const onChangeInvitedUserStatus = useCallback(
    (
      status: Team_User_Status_Enum | undefined,
    ): React.MouseEventHandler<HTMLDivElement> | undefined => {
      const isUserAlreadyInvited = invitedUsers.some(
        (user) => user.email === invitedUserEmail,
      );

      setInvitedMemberStatus(status);

      if (invitedUserEmail.trim() && !isUserAlreadyInvited) {
        setInvitedUsers([
          ...invitedUsers,
          {
            email: invitedUserEmail,
            isEmailValid: isEmailValid(invitedUserEmail),
            status,
          },
        ]);

        setInvitedUserEmail('');
      }

      return undefined;
    },
    [invitedUserEmail, invitedUsers],
  );

  const isAllInvitedUsersEmailValid = useMemo(
    () =>
      (invitedUsers.length
        ? !invitedUsers.filter((user) => !user.isEmailValid).length
        : false) && !invitedUserEmail.length,
    [invitedUserEmail, invitedUsers],
  );

  const onInviteUsers = useCallback(async () => {
    const teamUsers = invitedUsers.map((user) => ({
      role: Team_User_Role_Enum.Member,
      status: user.status,
      team_id: team?.id,
      user: {
        data: {
          email: user.email,
        },
        on_conflict: {
          constraint: User_Constraint.UserEmailKey,
          update_columns: [User_Update_Column.Email],
        },
      },
    }));

    await insertTeamUsers({
      variables: {
        objects: teamUsers,
        onConflict: {
          constraint: Team_User_Constraint.TeamUserPkey,
        },
      },
    });
  }, [insertTeamUsers, invitedUsers, team?.id]);

  const onDeleteInvitedUser = useCallback(
    async (email) => {
      const invitedUsersWithoutDeleted = filter(
        compose(not, propEq('email', email)),
        invitedUsers,
      );

      setInvitedUsers([...invitedUsersWithoutDeleted]);
    },
    [invitedUsers],
  );

  return {
    filteredTotalMembers,
    invitedMemberStatus,
    invitedUserEmail,
    invitedUsers,
    isAllInvitedUsersEmailValid,
    isOpenedRolePicker,
    onChangeInvitedUserEmail,
    onChangeInvitedUserStatus,
    onCloseRolePicker,
    onDeleteInvitedUser,
    onInviteUsers,
    onOpenRolePicker,
    setEmailValue,
    setInvitedUserEmail,
    setInvitedUsers,
  };
};
