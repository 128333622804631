import React, { memo } from 'react';
import AccountSettings from '../components/AccountSettings';
import { accountSettingsModals } from './AccountSettingsModals';
import Modals from './Modals';

const AccountSettingsNavigator: React.FC = () => (
  <>
    <AccountSettings />
    <Modals modals={accountSettingsModals} />
  </>
);

export default memo(AccountSettingsNavigator);
