import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const GoogleIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.04 10.494a5.431 5.431 0 0 1 5.169-3.727c1.297 0 2.47.46 3.39 1.215l2.68-2.68C16.647 3.88 14.555 3 12.21 3a9.185 9.185 0 0 0-8.258 5.104l3.09 2.39Z"
      fill="#EA4335"
    />
    <path
      d="M15.31 16.824c-.837.54-1.899.828-3.1.828a5.43 5.43 0 0 1-5.16-3.702l-3.1 2.354a9.183 9.183 0 0 0 8.26 5.115c2.25 0 4.4-.8 6.012-2.302l-2.911-2.293h-.001Z"
      fill="#34A853"
    />
    <path
      d="M18.221 19.118C19.906 17.546 21 15.208 21 12.211c0-.545-.083-1.13-.208-1.675h-8.582v3.56h4.94c-.244 1.195-.899 2.122-1.839 2.73l2.911 2.292Z"
      fill="#4A90E2"
    />
    <path
      d="M7.05 13.948a5.464 5.464 0 0 1-.282-1.74c0-.6.095-1.177.274-1.716l-3.09-2.39A9.159 9.159 0 0 0 3 12.206c0 1.474.342 2.863.95 4.095l3.1-2.354Z"
      fill="#FBBC05"
    />
  </Icon>
);

export default memo(GoogleIcon);
