import moment from 'moment';
import { equals, map, range, slice, values } from 'ramda';
import { useCallback } from 'react';
import {
  Maybe,
  ProjectFragment,
  Project_Insert_Input,
  Reminder_Protocol_Constraint,
  Reminder_Protocol_Update_Column,
  Reminder_Type_Enum,
  User_Constraint,
  User_Update_Column,
  useInsertReminderProtocolOneMutation,
} from '../generated/graphql';
import { useUserContext } from '../store/contexts/UserContext';
import { RemindersValues } from '../utils/formValidations';

export const useReminderProtocol = (project?: Maybe<ProjectFragment>) => {
  const { user } = useUserContext();

  const [insertReminderProtocol] = useInsertReminderProtocolOneMutation();

  const updateUserProtocol = useCallback<
    (values: RemindersValues) => Promise<void>
  >(
    async ({ reminder_days, reminder_time, reminder_type }) => {
      await insertReminderProtocol({
        variables: {
          object: {
            days: `{${reminder_days?.join(', ')}}`,
            id: user?.reminder_protocol?.id,
            time: `${reminder_time}${moment().format('Z')}`,
            type: reminder_type,
            user: {
              data: [
                {
                  email: user?.email,
                  id: user?.id,
                },
              ],
              on_conflict: {
                constraint: User_Constraint.UserPkey,
                update_columns: [User_Update_Column.ReminderProtocolId],
              },
            },
          },
          onConflict: {
            constraint: Reminder_Protocol_Constraint.ReminderProtocolPkey,
            update_columns: values(Reminder_Protocol_Update_Column),
          },
        },
      });
    },
    [
      insertReminderProtocol,
      user?.email,
      user?.id,
      user?.reminder_protocol?.id,
    ],
  );

  const getReminderProtocolInputProps = useCallback<
    (
      values: RemindersValues,
    ) => Pick<
      Project_Insert_Input,
      'reminder_protocol' | 'reminder_protocol_id'
    >
  >(
    ({ reminder_days, reminder_time, reminder_type }) => {
      if (
        equals(reminder_days?.map(Number), user?.reminder_protocol?.days) &&
        reminder_time === slice(0, -6, user?.reminder_protocol?.time ?? '') &&
        reminder_type === user?.reminder_protocol?.type
      ) {
        return {
          reminder_protocol_id: user?.reminder_protocol?.id,
        };
      }
      return {
        reminder_protocol: {
          data: {
            days: `{${reminder_days?.join(', ')}}`,
            id:
              project?.reminder_protocol?.id !== user?.reminder_protocol?.id
                ? project?.reminder_protocol?.id
                : undefined,
            time: `${reminder_time}${moment().format('Z')}`,
            type: reminder_type,
          },
          on_conflict: {
            constraint: Reminder_Protocol_Constraint.ReminderProtocolPkey,
            update_columns: values(Reminder_Protocol_Update_Column),
          },
        },
      };
    },
    [
      project?.reminder_protocol?.id,
      user?.reminder_protocol?.days,
      user?.reminder_protocol?.id,
      user?.reminder_protocol?.time,
      user?.reminder_protocol?.type,
    ],
  );

  const getInitialValues: () => RemindersValues = useCallback(() => {
    const protocol = (project ?? user)?.reminder_protocol;
    return {
      reminder_days: map(String, protocol?.days ?? range(1, 5)),
      reminder_time: protocol
        ? moment(`${moment().format('YYYY-MM-DD')}T${protocol.time}`).format(
            'HH:mm',
          )
        : '10:00',
      reminder_type: protocol?.type ?? Reminder_Type_Enum.Daily,
    };
  }, [project, user]);

  return {
    getInitialValues,
    getReminderProtocolInputProps,
    updateUserProtocol,
  };
};
