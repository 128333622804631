import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTeamStep2Values } from '../components/CreateTeamStep2';
import { useInsertTeamOneMutation } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { FormSubmit } from '../types/formik';
import {
  isApolloError,
  isHasuraError,
  isHasuraInternalError,
} from '../utils/predicates';

export const useCreateTeam = () => {
  const { t } = useTranslation(LocalesKeys.CreateTeam);

  const [insertTeam] = useInsertTeamOneMutation();

  const onCreateTeam = useCallback<FormSubmit<CreateTeamStep2Values>>(
    async ({ domain, teamName }, { setErrors }) => {
      try {
        return await insertTeam({ variables: { domain, name: teamName } });
      } catch (e) {
        console.log(e);
        if (!isApolloError(e))
          return setErrors({ domain: t('somethingWentWrong') });

        const hasuraError = e?.graphQLErrors[0]?.extensions;
        const hasuraInternalError = hasuraError?.internal;

        if (isHasuraInternalError(hasuraInternalError)) {
          switch (hasuraInternalError.error.message) {
            case 'TeamNameNotAllowedError':
              return setErrors({ teamName: t('teamNameNotAllowed') });
            case 'DomainNotAllowedError':
              return setErrors({ domain: t('domainNotAllowed') });
            case 'DomainTooShortError':
              return setErrors({ domain: t('domainTooShort') });
          }
        }

        if (isHasuraError(hasuraError)) {
          switch (hasuraError.code) {
            case 'constraint-violation':
              return setErrors({ domain: t('domainAlreadyExists') });
          }
        }

        return setErrors({ domain: t('domainInvalid') });
      }
    },
    [insertTeam, t],
  );
  return {
    onCreateTeam,
  };
};
