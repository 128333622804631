import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { values } from 'ramda';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Team_User_Status_Enum } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { isNotNil } from '../utils/logic';
import PlusIcon from './icons/PlusIcon';
import { RenderMenuContent } from './types';

const STATUS_OPTIONS = values(Team_User_Status_Enum);

const MENU_BUTTON_ACTIVE = {
  color: 'primary.500',
};

type Props = {
  status?: Team_User_Status_Enum;
  onChange: (status?: Team_User_Status_Enum) => void;
  onClick(
    status: Team_User_Status_Enum | undefined,
  ): React.MouseEventHandler<HTMLDivElement> | undefined;
  inProject?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};

const StatusSelectMenu: React.FC<Props> = ({
  status,
  onChange,
  inProject,
  onClick,
  ...props
}) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const setValue = useCallback(
    (value?: string | string[]) => {
      if (!value || typeof value === 'string') {
        onChange(value as Team_User_Status_Enum);
      }
    },
    [onChange],
  );

  const renderOption = useCallback<
    (option: Team_User_Status_Enum) => JSX.Element
  >(
    (option) => (
      <MenuItemOption
        key={option}
        onClick={() => onClick(option)}
        px={4}
        py={3}
        value={option}
      >
        <Text color="black.500">{t(option)}</Text>
      </MenuItemOption>
    ),
    [onClick, t],
  );

  const renderButton = useCallback(
    (isOpen: boolean) =>
      isNotNil(inProject) ? (
        <HStack>
          <Text size="body1">
            {status ? t(status) : t(inProject ? 'permission' : 'add')}
          </Text>
          {inProject || isOpen || status ? (
            <ChevronUpIcon
              boxSize={6}
              transform={`rotate(${isOpen ? '0deg' : '180deg'})`}
              transitionDuration="fast"
              transitionProperty="transform"
            />
          ) : (
            <PlusIcon />
          )}
        </HStack>
      ) : (
        <HStack>
          <Text size="body1">{t('permission')}</Text>
          <ChevronUpIcon
            boxSize={6}
            transform={`rotate(${isOpen ? '0deg' : '180deg'})`}
            transitionDuration="fast"
            transitionProperty="transform"
          />
        </HStack>
      ),
    [inProject, status, t],
  );

  const renderMenu = useCallback<RenderMenuContent>(
    ({ isOpen }) => (
      <>
        <MenuButton
          _active={MENU_BUTTON_ACTIVE}
          isActive={isOpen}
          spacing={2}
          type="button"
        >
          {renderButton(isOpen)}
        </MenuButton>
        <MenuList color="primary.500" zIndex={100}>
          <MenuOptionGroup onChange={setValue} type="radio" value={status}>
            {STATUS_OPTIONS.map(renderOption)}
            {isNotNil(inProject) && status && (
              <MenuItemOption px={4} py={3} value={undefined}>
                <Text color="alert">{t('remove')}</Text>
              </MenuItemOption>
            )}
          </MenuOptionGroup>
        </MenuList>
      </>
    ),
    [status, renderButton, setValue, renderOption, inProject, t],
  );
  return <Menu {...props}>{renderMenu}</Menu>;
};

export default memo(StatusSelectMenu);
