import { Flex, SelectProps, Text } from '@chakra-ui/react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Project_User_Status_Enum } from '../generated/graphql';
import { useProjectUserDisplayStatus } from '../hooks/useProjectUserDisplayStatus';
import { LocalesKeys } from '../locales/localesKeys';
import Select from './Select';
import TextInput from './TextInput';

const STATUSES = [
  { text: 'underReview', value: Project_User_Status_Enum.UnderReview },
  { text: 'approved', value: Project_User_Status_Enum.Approved },
  { text: 'notApproved', value: Project_User_Status_Enum.Rejected },
];

interface Props {
  selectProps?: SelectProps;
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  isUserInTeam: boolean;
  arId: string;
  isDriver: boolean;
}

const ArStatusAndNoteFields: React.FC<Props> = ({
  getFieldMeta,
  getFieldProps,
  selectProps,
  isDriver,
  isUserInTeam,
  arId,
}) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const { isArchived } = useProjectUserDisplayStatus(arId);

  const renderOption = useCallback(
    (status) => <option value={status.value}>{t(status.text)}</option>,
    [t],
  );

  const isUneditable = !isUserInTeam || isDriver || isArchived;

  return (
    <>
      <Flex flexDirection="column" mt={6} w="full">
        <Text color="black.400" ml={2} size="caption1">
          {t('myStatus')}
        </Text>
        <Select
          isDisabled={isUneditable}
          mt={-1}
          {...getFieldMeta('status')}
          {...getFieldProps('status')}
          {...selectProps}
        >
          {STATUSES.map(renderOption)}
        </Select>
      </Flex>
      <Flex flexDirection="column" mt={6} w="full">
        <Text color="black.400" ml={2} size="caption1">
          {t('noteLabel')}
        </Text>
        <TextInput
          {...getFieldMeta('note')}
          {...getFieldProps('note')}
          isDisabled={isUneditable}
          label=""
          mt={-1}
          placeholder={t('notePlaceholder')}
          touched
        />
      </Flex>
    </>
  );
};

export default memo(ArStatusAndNoteFields);
