import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  bg: 'black.100',
  borderRadius: 'lg',
  color: 'black',
  label: 'details',
  p: 4,
  top: 2,
};

export default {
  baseStyle,
};
