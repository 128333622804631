import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

interface Props extends Omit<FlexProps, 'onSubmit'> {
  onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void;
}

const AuthForm: React.FC<Props> = ({ onSubmit, ...props }) => (
  <Flex
    as="form"
    bgColor="white"
    borderRadius="lg"
    boxShadow="box"
    direction="column"
    flex={1}
    justify="space-between"
    maxW="smp"
    minH="lgp"
    // Need a chakra component as a form, but Flex's onSubmit is incompatible with the Formik one
    // @ts-expect-error
    onSubmit={onSubmit}
    p={8}
    {...props}
  />
);

export default AuthForm;
