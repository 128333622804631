import { Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTeamSettings } from '../hooks/useTeamSettings';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import { IconClass } from '../theme/utils';
import PlusIcon from './icons/PlusIcon';
import Loading from './Loading';
import TeamSettingsBillingWarning from './TeamSettingsBillingWarning';
import TeamSettingsEmptyState from './TeamSettingsEmptyState';
import TeamSettingsTable from './TeamSettingsTable';
import TextInput from './TextInput';

const ADD_LEFT_ICON = <PlusIcon className={IconClass.CustomFill} />;

const TeamSettings: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const navigate = useNavigate();

  const {
    teamMembers,
    teamName,
    teamProjects,
    isOwner,
    teamNameInputValue,
    setTeamNameInputValue,
    onChangeTeamName,
    onUpdateTeamName,
    isLicensedMemberInTeam,
    teamId,
    loading,
    isBillingWarningShow,
  } = useTeamSettings();

  const openInvitePeople = useCallback(
    () => navigate(routes.TeamSettingsRoutes.InvitePeople),
    [navigate],
  );

  const openDeleteTeam = useCallback(() => {
    if (teamId) {
      navigate(
        generatePath(routes.TeamSettingsRoutes.DeleteTeam, { id: teamId }),
      );
    }
  }, [navigate, teamId]);

  const openCancelSubscription = useCallback(
    () =>
      navigate(
        generatePath(routes.TeamSettingsRoutes.CancelSubscriptions, {
          id: teamId,
        }),
      ),
    [navigate, teamId],
  );

  const Header = useMemo(
    () => (
      <Flex flexDirection="column" mb={6} w="full">
        {isBillingWarningShow && <TeamSettingsBillingWarning />}
        <Heading size="headline3">
          {isOwner ? t('teamName') : teamNameInputValue}
        </Heading>
        {isOwner && (
          <>
            <TextInput
              onChange={onChangeTeamName}
              touched
              value={teamNameInputValue}
            />
            <Button
              leftIcon={ADD_LEFT_ICON}
              mt={5}
              onClick={openInvitePeople}
              w="fit-content"
            >
              {t('invitePeople')}
            </Button>
          </>
        )}
      </Flex>
    ),
    [
      isBillingWarningShow,
      isOwner,
      onChangeTeamName,
      openInvitePeople,
      t,
      teamNameInputValue,
    ],
  );

  const Footer = useMemo(
    () => (
      <Flex flexDirection="column" pt={10} w="full">
        {isOwner && (
          <>
            {isLicensedMemberInTeam && (
              <>
                <Heading size="headline3">{t('cancelSubscription')}</Heading>
                <Text pt={4} size="body1">
                  {t('cancelSubscriptionInfo')}
                </Text>
                <Button mt={4} onClick={openCancelSubscription} w="fit-content">
                  {t('cancelSubscription')}
                </Button>
              </>
            )}
            <Heading mt={10} size="headline3">
              {t('deleteTeamHeader')}
            </Heading>
            <Text pt={4} size="body1">
              {t('deleteTeamInfo')}
            </Text>
            <Button
              colorScheme="red"
              mt={4}
              onClick={openDeleteTeam}
              w="fit-content"
            >
              {t('deleteTeam')}
            </Button>
            <Button
              alignSelf="end"
              disabled={teamNameInputValue === teamName}
              mt={10}
              onClick={onUpdateTeamName}
              w="fit-content"
            >
              {t('updateChanges')}
            </Button>
          </>
        )}
      </Flex>
    ),
    [
      isOwner,
      isLicensedMemberInTeam,
      t,
      openCancelSubscription,
      openDeleteTeam,
      teamNameInputValue,
      teamName,
      onUpdateTeamName,
    ],
  );

  useEffect(() => {
    if (teamName) {
      setTeamNameInputValue(teamName);
    }
  }, [teamName, setTeamNameInputValue]);

  return (
    <VStack
      alignItems="flex-start"
      color="black.500"
      p={10}
      spacing={1}
      w="100%"
    >
      {loading ? (
        <Loading />
      ) : teamId ? (
        <>
          {Header}
          <TeamSettingsTable
            isOwner={isOwner}
            teamMembers={teamMembers}
            teamProjects={teamProjects}
          />
          {Footer}
        </>
      ) : (
        <TeamSettingsEmptyState />
      )}
    </VStack>
  );
};

export default memo(TeamSettings);
