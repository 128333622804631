import React, { memo } from 'react';
import { FieldMetaProps } from 'formik';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Interpolation,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { LocalesKeys } from '../locales/localesKeys';

export interface TextInputProps
  extends InputProps,
    Pick<FieldMetaProps<InputProps['value']>, 'error' | 'touched'> {
  boxProps?: FormControlProps;
  label?: string;
  helpingText?: string;
  leftElement?: JSX.Element;
  rightElement?: JSX.Element;
  autocompleteElement?: JSX.Element;
}

const SIDE_ELEMENTS_CSS: Interpolation<{}> = {
  '& > *': {
    pointerEvents: 'auto',
  },
  pointerEvents: 'none',
};

const TextInput: React.FC<TextInputProps> = ({
  boxProps,
  error,
  id: inputId,
  label,
  name,
  placeholder,
  touched,
  helpingText,
  leftElement,
  rightElement,
  autocompleteElement,
  ...props
}) => {
  const isInvalid = touched && !!error;

  const labelText = label === undefined ? placeholder : label;
  const id = inputId ?? `${v4()}-${name}`;

  const { t } = useTranslation(LocalesKeys.ValidationErrors);

  return (
    <FormControl isInvalid={isInvalid} {...boxProps}>
      <FormLabel
        color="black.400"
        htmlFor={id}
        letterSpacing="tight"
        pl={3}
        size="caption1"
      >
        {labelText}
      </FormLabel>
      <InputGroup>
        {leftElement && (
          <InputLeftElement
            css={SIDE_ELEMENTS_CSS}
            minW={12}
            pointerEvents="none"
            w="unset"
          >
            {leftElement}
          </InputLeftElement>
        )}
        <Input
          h={12}
          id={id}
          name={name}
          placeholder={placeholder}
          {...props}
        />
        {autocompleteElement}
        {rightElement && (
          <InputRightElement css={SIDE_ELEMENTS_CSS} minW={12} w="unset">
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
      {error ? (
        <FormErrorMessage
          htmlFor={id}
          letterSpacing="tight"
          pl={3}
          size="caption1"
        >
          {t(error)}
        </FormErrorMessage>
      ) : (
        <FormHelperText
          color="black.400"
          htmlFor={id}
          letterSpacing="tight"
          pl={3}
          size="caption1"
        >
          {helpingText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(TextInput);
