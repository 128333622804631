import React, { memo, useCallback, useMemo } from 'react';
import { Flex, Spacer, VStack } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import { MenuNavigator } from '../pages/Settings';
import SettingsNavItem from './SettingsNavItem';
import routes from '../navigation/routes';

type Props = {
  items: MenuNavigator[];
};

const SettingsContent: React.FC<Props> = ({ items }) => {
  const MenuItems = useMemo(
    () => items.map((item) => <SettingsNavItem key={item.route} item={item} />),
    [items],
  );

  const RouteItem = useCallback<
    (item: MenuNavigator, index: number) => JSX.Element
  >(
    (item, index) => (
      <Route
        key={item.route}
        element={item.component}
        index={index === 0}
        path={routes.navigator(item.route)}
      />
    ),
    [],
  );

  const RoutesFromItems = useMemo(
    () => items.map(RouteItem),
    [RouteItem, items],
  );

  return (
    <Flex w="full">
      <Spacer flex={1} />
      <VStack
        bg="white"
        borderRadius="lg"
        boxShadow="0px 4px 16px 0px #E8E8E8E5"
        flex={2}
        height="fit-content"
        mr={6}
        overflow="hidden"
        py={2}
        spacing={0}
        w="full"
      >
        {MenuItems}
      </VStack>
      <Flex
        bg="white"
        borderRadius="lg"
        boxShadow="0px 4px 16px 0px #E8E8E8E5"
        flex={6} 
        mb={16}
        minH="fit-content"
        overflow="hidden"
      >
        <Routes>{RoutesFromItems}</Routes>
      </Flex>
    </Flex>
  );
};

export default memo(SettingsContent);
