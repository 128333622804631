import { Flex, Heading } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { NavbarElementProps } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';

const DateInputNavbar: React.FC<NavbarElementProps> = ({
  onPreviousClick,
  onNextClick,
  month,
  localeUtils,
}: NavbarElementProps) => {
  const { i18n, t } = useTranslation(LocalesKeys.CreateAR);

  const onLeftClick = useCallback(() => onPreviousClick(), [onPreviousClick]);

  const onRightClick = useCallback(() => onNextClick(), [onNextClick]);

  return (
    <Flex align="center" bg="white" h={12} position="absolute" w="full">
      <ChevronLeftIcon
        color="black.400"
        cursor="pointer"
        flex={1}
        onClick={onLeftClick}
      />
      <Heading flex={3} size="headline4" textAlign="center">
        {t('navbarDate', {
          month: localeUtils.getMonths(i18n.language)[month.getMonth()],
          year: month.getFullYear(),
        })}
      </Heading>
      <ChevronRightIcon
        color="black.400"
        cursor="pointer"
        flex={1}
        onClick={onRightClick}
      />
    </Flex>
  );
};

export default memo(DateInputNavbar);
