import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddProjectToUser } from '../hooks/useAddProjectToUser';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import TeamOwnerGuard from '../navigation/TeamOwnerGuard';
import Autocomplete from './Autocomplete';
import RoleSelectMenu from './RoleSelectMenu';

const AUTOCOMPLETE_PROPS = {
  flex: 6,
  mr: 6,
};

const AddProjectToUser: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const {
    filteredProjects,
    userName,
    onChangeProjectName,
    setProjectNameValue,
    projectName,
    onChangeUserRole,
    isOpenRolePicker,
    onCloseRolePicker,
    onOpenRolePicker,
    invitedMemberRole,
    insertUserIntoProject,
    pickedProjectId,
  } = useAddProjectToUser();

  const renderItemContent = useCallback(
    (item) => (
      <Flex align="center">
        <Flex direction="column">
          <Text color="black.500" size="body1">
            {item.name}
          </Text>
        </Flex>
      </Flex>
    ),
    [],
  );

  const onAdd = useCallback(async () => {
    await insertUserIntoProject();
    onClose();
  }, [insertUserIntoProject, onClose]);

  const Header = useMemo(
    () => (
      <>
        <Heading size="headline3">
          {t('addProjectHeader', { userName })}
        </Heading>
        <Text mt={6} textAlign="center">
          {t('setRole')}
        </Text>
      </>
    ),
    [t, userName],
  );

  const Form = useMemo(
    () => (
      <Flex flexDirection="column" mt={5} w="full">
        <Flex
          alignItems="center"
          borderRadius={8}
          borderWidth="1px"
          justifyContent="space-between"
          py={3}
        >
          <Text pl={4}>{userName}</Text>
          <Box bg="white" mr={5}>
            <RoleSelectMenu
              isOpen={isOpenRolePicker}
              onChange={onChangeUserRole}
              onClose={onCloseRolePicker}
              onOpen={onOpenRolePicker}
              role={invitedMemberRole}
            />
          </Box>
        </Flex>
        <Autocomplete
          boxProps={AUTOCOMPLETE_PROPS}
          data={filteredProjects}
          mt={2}
          onChange={onChangeProjectName}
          renderItemContent={renderItemContent}
          setFieldValue={setProjectNameValue}
          touched
          value={projectName}
          w="full"
        />
      </Flex>
    ),
    [
      filteredProjects,
      invitedMemberRole,
      isOpenRolePicker,
      onChangeProjectName,
      onChangeUserRole,
      onCloseRolePicker,
      onOpenRolePicker,
      projectName,
      renderItemContent,
      setProjectNameValue,
      userName,
    ],
  );

  const Footer = useMemo(
    () => (
      <Flex gridGap={6} mt={6}>
        <Button colorScheme="secondary" onClick={onClose} px={6}>
          {t('cancel')}
        </Button>
        <Button isDisabled={!pickedProjectId.length} onClick={onAdd} px={6}>
          {t('add')}
        </Button>
      </Flex>
    ),
    [onAdd, onClose, pickedProjectId.length, t],
  );

  return (
    <TeamOwnerGuard>
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
        w="full"
      >
        {Header}
        {Form}
        {Footer}
      </Flex>
    </TeamOwnerGuard>
  );
};

export default memo(AddProjectToUser);
