import { Checkbox, Heading, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import { ANIMATIONS } from '../pages/CreateTeam';

const options: string[] = [
  'checkboxArt',
  'checkboxAudio',
  'checkboxLegal',
  'checkboxPlans',
  'checkboxPresentations',
  'checkboxUIUX',
  'checkboxWrittenContent',
  'checkboxOther',
];

const transition = { duration: 0.7 };

const CreateTeamStep1: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.CreateTeam);

  const CheckboxList = useMemo(
    () =>
      options.map((option) => (
        <Checkbox colorScheme="primary" size="lg">
          {t(option)}
        </Checkbox>
      )),
    [t],
  );

  return (
    <motion.div
      animate="animate"
      exit="exit"
      initial="initial"
      transition={transition}
      variants={ANIMATIONS}
    >
      <Heading mt={10} size="headline2" w={80}>
        {t('firstHeader')}
      </Heading>
      <Stack direction="column" mt={25} spacing={2}>
        {CheckboxList}
      </Stack>
    </motion.div>
  );
};

export default memo(CreateTeamStep1);
