import { useCallback } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { vapidPublicKey } from '../env';
import {
  useDeletePushSubscriptionMutation,
  useInsertPushSubscriptionMutation,
  useWebPushSubscriptionByFingerprintLazyQuery,
} from '../generated/graphql';
import { urlB64ToUint8Array } from '../utils/webPush';

export const usePushNotifications = () => {
  const [insertPushSubscription] = useInsertPushSubscriptionMutation();

  const [getSubscriptionsByFingerprint] =
    useWebPushSubscriptionByFingerprintLazyQuery();

  const subscribe = useCallback<(user_id: string) => Promise<void>>(
    async (user_id) => {
      const fingerprint = await FingerprintJS.load().then((fp) => fp.get());

      const { data } = await getSubscriptionsByFingerprint({
        variables: {
          fingerprint: fingerprint.visitorId,
        },
      });

      const count = data?.web_push_subscription_aggregate.aggregate?.count;

      if (count && count > 0) {
        return;
      }

      try {
        const pushPermission = await Notification.requestPermission();
        const registration = await navigator.serviceWorker?.getRegistration();

        if (vapidPublicKey && registration && pushPermission === 'granted') {
          const subscription = await registration.pushManager.subscribe({
            applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
            userVisibleOnly: true,
          });
          const { endpoint, keys } = subscription.toJSON();

          if (!endpoint || !keys?.auth || !keys?.p256dh) {
            return;
          }

          await insertPushSubscription({
            variables: {
              data: {
                auth: keys?.auth,
                browser_fingerprint: fingerprint.visitorId,
                endpoint,
                p256dh: keys?.p256dh,
                user_id,
              },
            },
          });
        }
      } catch (error) {
        console.log('Error on subscription creation', error);
      }
    },
    [getSubscriptionsByFingerprint, insertPushSubscription],
  );

  const [deleteSubscription] = useDeletePushSubscriptionMutation();

  const unsubscribe = useCallback(async () => {
    const registration = await navigator.serviceWorker?.getRegistration();
    const subscription = await registration?.pushManager.getSubscription();
    if (!subscription) {
      return;
    }
    const { endpoint, keys } = subscription.toJSON();

    if (!endpoint || !keys?.auth || !keys?.p256dh) {
      return;
    }
    await deleteSubscription({
      variables: {
        where: {
          auth: {
            _eq: keys?.auth,
          },
          endpoint: {
            _eq: endpoint,
          },
          p256dh: {
            _eq: keys?.p256dh,
          },
        },
      },
    });
  }, [deleteSubscription]);

  return { subscribe, unsubscribe };
};
