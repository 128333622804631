import { Flex, Text } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Project_User_Role_Enum } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import ChevronRightIcon from './icons/ChevronRightIcon';

interface Props {
  arId?: string | null;
  value: string;
  userRole?: string | null;
  isArchived: boolean;
}

const UserStatusProjectTableCell: React.FC<Props> = ({
  arId,
  value,
  userRole,
  isArchived,
}) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const { t: tStatus } = useTranslation(LocalesKeys.Status);

  const navigate = useNavigate();

  const statusText = value === 'DRAFT' ? t('draft') : tStatus(value);

  const isStatusEditable =
    userRole !== Project_User_Role_Enum.Driver && !isArchived;

  const isDriver = userRole === Project_User_Role_Enum.Driver;

  const openChangeStatusModal = useCallback(
    () =>
      isStatusEditable
        ? navigate(
            generatePath(routes.ARRoutes.ChangeProjectStatus, {
              id: arId || undefined,
            }),
          )
        : null,
    [arId, isStatusEditable, navigate],
  );

  return (
    <Flex
      align="center"
      cursor={isStatusEditable ? 'pointer' : undefined}
      onClick={openChangeStatusModal}
    >
      {!isDriver && (
        <Text color="primary.600" size="subhead2">
          {statusText}
        </Text>
      )}
      {isStatusEditable && <ChevronRightIcon color="primary.600" ml={2} />}
    </Flex>
  );
};

export default memo(UserStatusProjectTableCell);
