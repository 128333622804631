import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { IconColorSchemes } from './types';

interface Props extends IconProps {
  colorScheme?: IconColorSchemes;
}

const ChevronRightIcon: React.FC<Props> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m9 18 6-6-6-6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
    />
  </Icon>
);

export default memo(ChevronRightIcon);
