import { gte } from 'ramda';
import React, { memo, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { CreateProjectError } from '../components/CreateProjectErrorModal';
import {
  Subscription_Status_Enum,
  Team_User_Status_Enum,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import BaseGuard, { BaseGuardProps } from './BaseGuard';
import routes from './routes';

interface Props extends BaseGuardProps {}

const CreateARGuard: React.FC<Props> = (props) => {
  const { team, loading: teamLoading } = useTeamContext();

  const { user, loading: userLoading } = useUserContext();
  const loading = userLoading || teamLoading;

  const teamUser = useMemo(
    () => team?.team_users.find((team_user) => team_user.user.id === user?.id),
    [team?.team_users, user?.id],
  );

  const error = team?.is_admin
    ? undefined
    : gte(team?.projects.length ?? 0, 3)
    ? team?.subscription_status !== Subscription_Status_Enum.Active
      ? CreateProjectError.TeamNotBilled
      : teamUser?.status !== Team_User_Status_Enum.Licensed
      ? CreateProjectError.UserNotLicensed
      : undefined
    : undefined;

  return (
    <BaseGuard
      loading={loading}
      rule={!error}
      to={generatePath(`/${routes.ARRoutes.CreateProjectError}`, {
        error: error ?? '',
      })}
      {...props}
    />
  );
};

export default memo(CreateARGuard);
