import {
  Button,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Team_User_Status_Enum } from '../generated/graphql';
import useChangeTeamUserStatus from '../hooks/useChangeTeamUserStatus';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import TeamOwnerGuard from '../navigation/TeamOwnerGuard';

const ChangeTeamUserStatus: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const { pickedStatus, onChangeTeamUserStatus, onChangeStatus, status, user } =
    useChangeTeamUserStatus();

  const onConfirm = useCallback(async () => {
    await onChangeTeamUserStatus();
    onClose();
  }, [onChangeTeamUserStatus, onClose]);

  const Content = useMemo(
    () => (
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
      >
        <Heading justifyContent="flex-start" size="headline3" w="full">
          {t('changePermissionHeader')}
        </Heading>
        <Text mt={6}>
          {t('changePermissionInfo', {
            userName: user?.username || user?.email,
          })}
        </Text>
        <RadioGroup
          mt={6}
          onChange={onChangeStatus}
          value={pickedStatus}
          w="full"
        >
          <Stack>
            <Radio colorScheme="primary" value={Team_User_Status_Enum.Licensed}>
              {t('licensedUser')}
            </Radio>
            <Radio colorScheme="primary" value={Team_User_Status_Enum.Basic}>
              {t('basicUser')}
            </Radio>
          </Stack>
        </RadioGroup>
        <Flex gridGap={6} justifyContent="flex-end" mt={10} w="full">
          <Button colorScheme="secondary" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button disabled={pickedStatus === status} onClick={onConfirm}>
            {t('confirm')}
          </Button>
        </Flex>
      </Flex>
    ),
    [
      t,
      user?.username,
      user?.email,
      onChangeStatus,
      pickedStatus,
      onClose,
      status,
      onConfirm,
    ],
  );

  return <TeamOwnerGuard>{Content}</TeamOwnerGuard>;
};

export default memo(ChangeTeamUserStatus);
