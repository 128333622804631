import {
  Box,
  HStack,
  Heading,
  Interpolation,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import { Project_User_Role_Enum, UserFragment } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { DetailsIcon } from './icons/DetailsIcon';
import RoleSelectMenu from './RoleSelectMenu';
import RoleSelectTableUserCell from './RoleSelectTableUserCell';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type InvitedMember = Optional<UserFragment, 'id'> & {
  role?: Project_User_Role_Enum;
};

type Props = {
  teamMembers: InvitedMember[];
  setRoleByEmail: (email: string, role?: Project_User_Role_Enum) => void;
};

const TABLE_CSS: Interpolation<{}> = {
  tableLayout: 'fixed',
};

const RoleSelectTable: React.FC<Props> = ({ teamMembers, setRoleByEmail }) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const { t: tooltipT } = useTranslation(LocalesKeys.RolesDetails);

  const RolesDetailsLabel = useMemo(
    () => (
      <Stack spacing={6}>
        <Text size="caption2">{tooltipT('caption')}</Text>
        <Stack spacing={2}>
          <Heading size="headline4">{tooltipT('driverHeading')}</Heading>
          <Text size="caption2">{tooltipT('driverDescription')}</Text>
        </Stack>
        <Stack spacing={2}>
          <Heading size="headline4">{tooltipT('approverHeading')}</Heading>
          <Text size="caption2">{tooltipT('approverDescription')}</Text>
        </Stack>
        <Stack spacing={2}>
          <Heading size="headline4">{tooltipT('contributorHeading')}</Heading>
          <Text size="caption2">{tooltipT('contributorDescription')}</Text>
        </Stack>
        <Stack spacing={2}>
          <Heading size="headline4">{tooltipT('informedHeading')}</Heading>
          <Text size="caption2">{tooltipT('informedDescription')}</Text>
        </Stack>
      </Stack>
    ),
    [tooltipT],
  );

  const columns = useMemo<Column<InvitedMember>[]>(
    () => [
      {
        accessor: 'id',
        Cell: ({
          row: {
            original: { username, email, image, id },
          },
        }) => {
          const imageUrl = image?.url ?? undefined;

          return (
            <RoleSelectTableUserCell
              email={email}
              id={id}
              imageUrl={imageUrl}
              userName={username}
            />
          );
        },
        Header: () => (
          <Heading as="p" size="headline4" textAlign="left">
            {t('reviewers')}
          </Heading>
        ),
      },
      {
        accessor: 'email',
        Cell: ({ value, row }) => {
          const changeRole = (role?: Project_User_Role_Enum) =>
            setRoleByEmail(value, role);
          return (
            <RoleSelectMenu
              color="primary.500"
              inProject={!!row.original.role}
              onChange={changeRole}
              role={row.original.role}
            />
          );
        },
        Header: () => (
          <HStack>
            <Heading as="p" size="headline4">
              {t('role')}
            </Heading>
            <Tooltip label={RolesDetailsLabel} placement="bottom-start" w={64}>
              <DetailsIcon boxSize={6} />
            </Tooltip>
          </HStack>
        ),
      },
    ],
    [RolesDetailsLabel, setRoleByEmail, t],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: teamMembers });

  const renderHeaderColumn = useCallback<
    (column: HeaderGroup<InvitedMember>) => JSX.Element
  >(
    (column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>,
    [],
  );

  const renderTableHeader = useCallback<
    (headerGroup: HeaderGroup<InvitedMember>) => JSX.Element
  >(
    (headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(renderHeaderColumn)}
      </Tr>
    ),
    [renderHeaderColumn],
  );

  const TableHeaders = useMemo(
    () => headerGroups.map(renderTableHeader),
    [headerGroups, renderTableHeader],
  );

  const renderTableCell = useCallback<
    (cell: Cell<InvitedMember>) => JSX.Element
  >((cell) => <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>, []);

  const renderTableRow = useCallback<(row: Row<InvitedMember>) => JSX.Element>(
    (row) => {
      prepareRow(row);
      return (
        <Tr cursor="pointer" {...row.getRowProps()}>
          {row.cells.map(renderTableCell)}
        </Tr>
      );
    },
    [prepareRow, renderTableCell],
  );

  const TableRows = useMemo(
    () => rows.map(renderTableRow),
    [renderTableRow, rows],
  );

  return (
    <Box border="1px solid" borderColor="black.200" borderRadius="lg" w="full">
      <Table
        colorScheme="black"
        css={TABLE_CSS}
        variant="simple"
        {...getTableProps()}
      >
        <Thead>{TableHeaders}</Thead>
        <Tbody {...getTableBodyProps()}>{TableRows}</Tbody>
      </Table>
    </Box>
  );
};

export default RoleSelectTable;
