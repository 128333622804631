import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import routes from '../navigation/routes';
import { useTeamContext } from '../store/contexts/TeamContext';

const DeleteTeamSuccessful: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.DeleteTeamSuccessful);

  const { team, generateUrl } = useTeamContext();

  const navigate = useNavigate();

  const onCreateNewTeam = useCallback(() => {
    if (team?.id) {
      window.location.replace(
        `${generateUrl()}/${routes.ModalRoute.Personalization}`,
      );
    } else {
      navigate(`/${routes.ModalRoute.Personalization}`, { replace: true });
    }
  }, [generateUrl, navigate, team?.id]);

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      pt={18}
      w="full"
    >
      <Heading size="headline3">{t('header')}</Heading>
      <Text mb={5} mt={6} textAlign="center">
        {t('info')}
      </Text>
      <Flex gridGap={6} justifyContent="center" mt={10}>
        <Button colorScheme="secondary" onClick={onClose} px={6}>
          {t('close')}
        </Button>
        <Button onClick={onCreateNewTeam} px={10}>
          {t('createNewTeam')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(DeleteTeamSuccessful);
