export default {
  '2FAConfirm': 'Ask me OTP every time I Log In',
  '2FADescription1':
    'Ensure top-level security for your account with Two-Factor Authentication.',
  '2FADescription2':
    'Enable to get the One Time Password each time you log in.',
  '2FAHeading': 'Two-Factor Authentication (2FA)',
  changedPasswordFailureError: 'Something went wrong',
  changedPasswordFailureNoPassword: 'Please provide us with the passwords',
  changedPasswordFailureTitle: "You've failed to change a password",
  changedPasswordFailureUnknown: 'Make sure you entered correct old password',
  changedPasswordSuccessDescription: 'You can login with a new password',
  changedPasswordSuccessTitle: "You've successfully changed password",
  newPasswordLabel: 'New password',
  newPasswordPlaceholder: 'New password',
  oldPasswordLabel: 'Old password',
  oldPasswordPlaceholder: 'Enter your old password',
  passwordHeading: 'Change your password',
  passwordSubmit: 'Change password',
  securityUpdateFailureTitle: 'Security settings are not updated',
  securityUpdateFailutyDescription: 'There was an error',
  securityUpdateSuccessDescription: 'All changes are saved successfully',
  securityUpdateSuccessTitle: 'Security settings are updated',
  submit: 'Update changes',
};
