import React, { memo, useCallback, useMemo } from 'react';
import {
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { LocalesKeys } from '../locales/localesKeys';
import CrossIcon from './icons/CrossIcon';
import {
  BillingContactChangeValues,
  billingContactChangeValidationSchema,
} from '../utils/formValidations';
import { FormSubmit } from '../types/formik';
import { TeamFragment, TeamUserFragment } from '../generated/graphql';
import AutocompleteSelect from './AutocompleteSelect';
import Avatar from './Avatar';
import { AutocompleteItemType } from './AutocompleteItem';

const CROSS_ICON = <CrossIcon />;

type Props = {
  teamMembers: TeamFragment['team_users'];
  newCustomerEmail: string;
  setEmail: (email: string) => void;
};

const BillingContactChange: React.FC<Props> = ({
  teamMembers,
  newCustomerEmail,
  setEmail,
}) => {
  const { t } = useTranslation(LocalesKeys.Billing);

  const initialValues: BillingContactChangeValues = useMemo(
    () => ({
      email: newCustomerEmail,
    }),
    [newCustomerEmail],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onSubmit = useCallback<FormSubmit<BillingContactChangeValues>>(
    ({ email }) => {
      setEmail(email);
      onClose();
    },
    [onClose, setEmail],
  );

  const { handleSubmit, setFieldValue, getFieldProps, getFieldMeta, isValid } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: billingContactChangeValidationSchema,
    });

  const setEmailFieldValue = useCallback(
    (value: string) => setFieldValue('email', value),
    [setFieldValue],
  );

  const autocompleteData = useMemo(
    () =>
      teamMembers.map((user) => ({
        value: user.user.email,
        ...user,
      })),
    [teamMembers],
  );

  const renderItemContent = useCallback<
    (item: AutocompleteItemType<TeamUserFragment>) => JSX.Element
  >(
    ({ user: { email, username, id, image }, value }) => (
      <Flex align="center">
        <Avatar
          h={10}
          mr={2}
          name={value}
          src={image?.url ?? undefined}
          userId={id}
          w={10}
        />
        <Flex direction="column">
          <Text color="black.500" size="body1">
            {username}
          </Text>
          <Text color="black.400" size="body2">
            {email}
          </Text>
        </Flex>
      </Flex>
    ),
    [],
  );

  return (
    <>
      <Button colorScheme="secondary" onClick={onOpen}>
        {t('changeContact')}
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="xl" p={10}>
          <chakra.form onSubmit={handleSubmit} w="full">
            <VStack spacing={10} w="full">
              <Flex align="center" justify="space-between" w="full">
                <Heading size="headline2">{t('contactModalTitle')}</Heading>
                <IconButton
                  aria-label=""
                  icon={CROSS_ICON}
                  onClick={onClose}
                  variant="unstyled"
                />
              </Flex>
              <AutocompleteSelect
                data={autocompleteData}
                {...getFieldMeta('email')}
                {...getFieldProps('email')}
                renderItemContent={renderItemContent}
                setFieldValue={setEmailFieldValue}
              />
              <HStack justify="flex-end" spacing={6} w="full">
                <Button
                  colorScheme="secondary"
                  onClick={onClose}
                  w="fit-content"
                >
                  {t('cancel')}
                </Button>
                <Button disabled={!isValid} type="submit" w="fit-content">
                  {t('changeContact')}
                </Button>
              </HStack>
            </VStack>
          </chakra.form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(BillingContactChange);
