import { Text } from '@chakra-ui/react';
import React, { memo } from 'react';

const PrivacyPolicy = () => (
  <div style={{ margin: '0 auto', maxWidth: 1024, padding: '24px 10%' }}>
    <Text fontSize="2xl" style={{ margin: 32, textAlign: 'center' }}>
      <strong>Completely (Wiebe Marketing Ltd.) Privacy Policy</strong>
    </Text>
    <Text style={{ margin: 16, textAlign: 'center' }}>Software</Text>
    <Text style={{ margin: 16, textAlign: 'center' }}>
      Effective date: 9nd day of September, 2022
    </Text>
    <p />
    <Text style={{ margin: 8 }}>
      Completely (the &quot;Site&quot;) is owned and operated by Wiebe Marketing
      Ltd. Wiebe Marketing Ltd. is the data controller and can be contacted at:
    </Text>
    <Text style={{ margin: 8 }}>support@ohcompletely.com</Text>
    <Text style={{ margin: 8 }}>(778) 430-1281</Text>
    <Text style={{ margin: 8 }}>
      10158 103 Street Northwest, Suite 200, Edmonton, AB T5J 0X6
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Purpose</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      The purpose of this privacy policy (this &quot;Privacy Policy&quot;) is to
      inform users of our software of the following:
    </Text>
    <Text style={{ margin: 8 }}>1. The personal data we will collect;</Text>
    <Text style={{ margin: 8 }}>2. Use of collected data;</Text>
    <Text style={{ margin: 8 }}>
      3. Who has access to the data collected; 4. The rights of Site users; and
    </Text>
    <Text style={{ margin: 8 }}>5. The Site&apos;s cookie policy.</Text>
    <Text style={{ margin: 8 }}>
      This Privacy Policy applies in addition to the terms and conditions of our
      Site.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>GDPR</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      For users in the European Union, we adhere to the Regulation (EU) 2016/679
      of the European Parliament and of the Council of 27 April 2016, known as
      the General Data Protection Regulation (the &quot;GDPR&quot;). For users
      in the United Kingdom, we adhere to the GDPR as enshrined in the Data
      Protection Act 2018.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Consent</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      By using our Site users agree that they consent to:
    </Text>
    <Text style={{ margin: 8 }}>
      1. The conditions set out in this Privacy Policy.
    </Text>
    <Text style={{ margin: 8 }}>
      When the legal basis for us processing your personal data is that you have
      provided your consent to that processing, you may withdraw your consent at
      any time. If you withdraw your consent, it will not make processing which
      we completed before you withdrew your consent unlawful.
    </Text>
    <Text style={{ margin: 8 }}>
      You can withdraw your consent by: Contact the Data Protection Officer at
      our email address.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Legal Basis for Processing</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      We collect and process personal data about users in the EU only when we
      have a legal basis for doing so under Article 6 of the GDPR.
    </Text>
    <Text style={{ margin: 8 }}>
      We rely on the following legal basis to collect and process the personal
      data of users in the EU:
    </Text>
    <Text style={{ margin: 8 }}>
      1. Users have provided their consent to the processing of their data for
      one or more specific purposes.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Personal Data We Collect</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      We only collect data that helps us achieve the purpose set out in this
      Privacy Policy. We will not collect any additional data beyond the data
      listed below without notifying you first.
    </Text>
    <Text style={{ margin: 8 }}>Data Collected Automatically</Text>
    <Text style={{ margin: 8 }}>
      When you visit and use our Site, we may automatically collect and store
      the following information:
    </Text>
    <p />
    <Text style={{ margin: 8 }}>1. IP address;</Text>
    <Text style={{ margin: 8 }}>2. Location;</Text>
    <Text style={{ margin: 8 }}>3. Hardware and software details;</Text>
    <Text style={{ margin: 8 }}>4. Clicked links; and</Text>
    <Text style={{ margin: 8 }}>5. Content viewed.</Text>
    <p />
    <Text style={{ margin: 8 }}>Data Collected in a Non-Automatic Way</Text>
    <Text style={{ margin: 8 }}>
      We may also collect the following data when you perform certain functions
      on our Site:
    </Text>
    <Text style={{ margin: 8 }}>1. First and last name;</Text>
    <Text style={{ margin: 8 }}>2. Email address;</Text>
    <Text style={{ margin: 8 }}>3. Address; and</Text>
    <Text style={{ margin: 8 }}>4. Payment information.</Text>
    <p />
    <Text style={{ margin: 8 }}>
      This data may be collected using the following methods:
    </Text>
    <Text style={{ margin: 8 }}>
      1. Creating an account and using the product.
    </Text>
    <Text style={{ margin: 8 }}>2. Integrating with Google</Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>How We Use Personal Data</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      Data collected on our Site will only be used for the purposes specified in
      this Privacy Policy or indicated on the relevant pages of our Site. We
      will not use your data beyond what we disclose in this Privacy Policy.
    </Text>
    <Text style={{ margin: 8 }}>
      The data we collect automatically is used for the following purposes:
    </Text>
    <Text style={{ margin: 8 }}>1. To improve the user experience.</Text>
    <p />
    <Text style={{ margin: 8 }}>
      The data we collect when the user performs certain functions may be used
      for the following purposes:
    </Text>
    <Text style={{ margin: 8 }}>1. Communication and user experience.</Text>
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>How We Use Google User Data</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      Google Drive. If you choose to connect your Google Drive to your
      Completely account, we will access certain information obtained from
      Google regarding your account. Specifically, Completely Services accesses
      and pulls in the file and names and URLs from your Google Drive. This data
      will only be used by Completely to provide you with the services you
      direct and expect and will not be shared with any third parties.
    </Text>
    <Text style={{ margin: 8 }}>
      Visit{' '}
      <a href="https://policies.google.com/technologies/partner-sites">
        https://policies.google.com/technologies/partner-sites
      </a>{' '}
      to see how Google uses your data. You can also opt out if you wish.
    </Text>
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Who We Share Personal Data With</strong>
    </Text>
    <Text style={{ margin: 8 }}>Employees</Text>
    <Text style={{ margin: 8 }}>
      We may disclose user data to any member of our organization who reasonably
      needs access to user data to achieve the purposes set out in this Privacy
      Policy.
    </Text>
    <Text style={{ margin: 8 }}>Other Disclosures</Text>
    <Text style={{ margin: 8 }}>
      We will not sell or share your data with other third parties, except in
      the following cases:
    </Text>
    <Text style={{ margin: 8 }}>1. If the law requires it;</Text>
    <Text style={{ margin: 8 }}>
      2. If it is required for any legal proceeding;
    </Text>
    <Text style={{ margin: 8 }}>
      3. To prove or protect our legal rights; and
    </Text>
    <Text style={{ margin: 8 }}>
      4. To buyers or potential buyers of this company in the event that we seek
      to sell the company.
    </Text>
    <Text style={{ margin: 8 }}>
      If you follow hyperlinks from our Site to another Site, please note that
      we are not responsible for and have no control over their privacy policies
      and practices.
    </Text>
    <Text style={{ margin: 8 }}>
      By agreeing to use the services to connect a third party provider, you
      authorize us to access and save your name, your e-mail address(es), your
      current location, URL profile picture and other personal information
      provided to us by the third party provider and for use and disclosure in
      accordance with this policy. You should check your privacy settings on
      these third party services in order to ensure that you understand the
      information you grant to us and change if desired.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>How Long We Store Personal Data</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      User data will be stored until the purpose the data was collected for has
      been achieved. You will be notified if your data is kept for longer than
      this period.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>How We Protect Your Personal Data</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      In order to protect your security, we use the strongest available browser
      encryption and store all of our data on servers in secure facilities. All
      data is only accessible to our employees. Our employees are bound by
      strict confidentiality agreements and a breach of this agreement would
      result in the employee&apos;s termination.
    </Text>
    <Text style={{ margin: 8 }}>
      While we take all reasonable precautions to ensure that user data is
      secure and that users are protected, there always remains the risk of
      harm. The Internet as a whole can be insecure at times and therefore we
      are unable to guarantee the security of user data beyond what is
      reasonably practical.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>International Data Transfers</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      We transfer user personal data to the following countries:
    </Text>
    <Text style={{ margin: 8 }}>1. United States of America; and</Text>
    <Text style={{ margin: 8 }}>2. Canada.</Text>
    <Text style={{ margin: 8 }}>
      When we transfer user personal data we will protect that data as described
      in this Privacy Policy and comply with applicable legal requirements for
      transferring personal data internationally.
    </Text>
    <Text style={{ margin: 8 }}>
      If you are located in the United Kingdom or the European Union, we will
      only transfer your personal data if:
    </Text>
    <Text style={{ margin: 8 }}>
      1. The country your personal data is being transferred to has been deemed
      to have adequate data protection by the European Commission or, if you are
      in the United Kingdom, by the United Kingdom adequacy regulations; or
    </Text>
    <Text style={{ margin: 8 }}>
      2. We have implemented appropriate safeguards in respect of the transfer.
      For example, the recipient is a party to binding corporate rules, or we
      have entered into standard EU or United Kingdom data protection
      contractual clauses with the recipient..
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Your Rights as a User</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      Under the GDPR, you have the following rights:
    </Text>
    <Text style={{ margin: 8 }}>1. Right to be informed;</Text>
    <Text style={{ margin: 8 }}>2. Right of access;</Text>
    <Text style={{ margin: 8 }}>3. Right to rectification;</Text>
    <Text style={{ margin: 8 }}>4. Right to erasure;</Text>
    <Text style={{ margin: 8 }}>5. Right to restrict processing;</Text>
    <Text style={{ margin: 8 }}>6. Right to data portability; and</Text>
    <Text style={{ margin: 8 }}>7. Right to object.</Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Children</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      We do not knowingly collect or use personal data from children under 16
      years of age. If we learn that we have collected personal data from a
      child under 16 years of age, the personal data will be deleted as soon as
      possible. If a child under 16 years of age has provided us with personal
      data their parent or guardian may contact our data protection officer.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>
        How to Access, Modify, Delete, or Challenge the Data Collected
      </strong>
    </Text>
    <Text style={{ margin: 8 }}>
      If you would like to know if we have collected your personal data, how we
      have used your personal data, if we have disclosed your personal data and
      to who we disclosed your personal data, if you would like your data to be
      deleted or modified in any way, or if you would like to exercise any of
      your other rights under the GDPR, please contact our data protection
      officer here:
    </Text>
    <Text style={{ margin: 8 }}>Steven Luke</Text>
    <Text style={{ margin: 8 }}>steven@ohcompletely.com</Text>
    <Text style={{ margin: 8 }}>(778) 430-1281</Text>
    <Text style={{ margin: 8 }}>
      10158 103 Street Northwest, 200, Edmonton, AB T5J 0X6
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Do Not Track Notice</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      Do Not Track (&quot;DNT&quot;) is a privacy preference that you can set in
      certain web browsers. We do not track the users of our Site over time and
      across third party websites and therefore do not respond to browser-
      initiated DNT signals.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>How to Opt-Out of Data Collection, Use or Disclosure</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      In addition to the method(s) described in the{' '}
      <em>How to Access, Modify, Delete, or Challenge the Data Collected </em>
      section, we provide the following specific opt-out methods for the forms
      of collection, use, or disclosure of your personal data specified below:
    </Text>
    <Text style={{ margin: 8 }}>
      1. You can opt out of the use of your data for all marketing emails. You
      can also have your data permanently deleted on account end / deletion. You
      can opt-out by rejecting cookies or clicking &quot;unsubscribe&quot; on
      the bottom of any marketing email.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Cookie Policy</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      A cookie is a small file, stored on a user&apos;s hard drive by a website.
      Its purpose is to collect data relating to the user&apos;s browsing
      habits. You can choose to be notified each time a cookie is transmitted.
      You can also choose to disable cookies entirely in your internet browser,
      but this may decrease the quality of your user experience.
    </Text>
    <Text style={{ margin: 8 }}>
      We use the following types of cookies on our Site:
    </Text>
    <Text style={{ margin: 8 }}>1. Functional cookies</Text>
    <Text style={{ margin: 8 }}>
      Functional cookies are used to remember the selections you make on our
      Site so that your selections are saved for your next visits;
    </Text>
    <Text style={{ margin: 8 }}>2. Analytical cookies</Text>
    <Text style={{ margin: 8 }}>
      Analytical cookies allow us to improve the design and functionality of our
      Site by collecting data on how you access our Site, for example data on
      the content you access, how long you stay on our Site, etc;
    </Text>
    <Text style={{ margin: 8 }}>3. Targeting cookies</Text>
    <Text style={{ margin: 8 }}>
      Targeting cookies collect data on how you use the Site and your
      preferences. This allows us to personalize the information you see on our
      Site for you; and
    </Text>
    <Text style={{ margin: 8 }}>4. Third-Party Cookies</Text>
    <Text style={{ margin: 8 }}>
      Third-party cookies are created by a website other than ours. We may use
      third-party cookies to achieve the following purposes:
    </Text>
    <Text style={{ margin: 8 }}>
      a. Monitor user preferences to tailor advertisements around their
      interests.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Modifications</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      This Privacy Policy may be amended from time to time in order to maintain
      compliance with the law and to reflect any changes to our data collection
      process. When we amend this Privacy Policy we will update the
      &quot;Effective Date&quot; at the top of this Privacy Policy. We recommend
      that our users periodically review our Privacy Policy to ensure that they
      are notified of any updates. If necessary, we may notify users by email of
      changes to this Privacy Policy.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Complaints</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      If you have any complaints about how we process your personal data, please
      contact us through the contact methods listed in the{' '}
      <em>Contact Information </em>section so that we can, where possible,
      resolve the issue. If you feel we have not addressed your concern in a
      satisfactory manner you may contact a supervisory authority. You also have
      the right to directly make a complaint to a supervisory authority. You can
      lodge a complaint with a supervisory authority by contacting the The
      Office of the Privacy Commissioner of Canada.
    </Text>
    <p />
    <Text fontSize="lg" style={{ margin: 24, marginLeft: 8 }}>
      <strong>Contact Information</strong>
    </Text>
    <Text style={{ margin: 8 }}>
      If you have any questions, concerns or complaints, you can contact our
      data protection officer, Steven Luke, at:
    </Text>
    <Text style={{ margin: 8 }}>steven@ohcompletely.com</Text>
    <Text style={{ margin: 8 }}>(778) 430-1281</Text>
    <Text style={{ margin: 8 }}>
      10158 103 Street Northwest, 200, Edmonton, AB T5H 1R4
    </Text>
  </div>
);

export default memo(PrivacyPolicy);
