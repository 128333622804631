import React, { SVGProps, memo } from 'react';

const RestoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={22} width={22} {...props}>
    <path
      d="M1.5 1v6h6m-5.84 7.57a10 10 0 1 0 .57-8.38"
      stroke="#454649"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

export default memo(RestoreIcon);
