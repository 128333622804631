import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Verification from '../components/Verification';
import useAuth from '../hooks/useAppAuth';
import useEmailParam from '../hooks/useEmailParam';
import useResendCode from '../hooks/useResendCode';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import { FormSubmit } from '../types/formik';
import { VerificationValues } from '../utils/formValidations';

const ForgetPasswordVerification: React.FC = () => {
  const navigate = useNavigate();
  const [email] = useEmailParam();

  const { confirmSignUp } = useAuth();

  const { t } = useTranslation(LocalesKeys.ForgetPasswordVerification);

  const heading = t('heading');
  const subhead = t('subhead', { email });
  const submit = t('submit');

  const resendPayload = useResendCode(email);

  const handleSubmit = useCallback<FormSubmit<VerificationValues>>(
    async ({ code }, { setErrors }) => {
      if (!email) return null;

      const result = await confirmSignUp(email, code);

      if (!result) {
        return null;
      }

      if (result instanceof Error) {
        setErrors({ code: t('invalidCode') });
        return null;
      }

      return navigate(routes.AuthRoute.CreatePassword, { replace: true });
    },
    [confirmSignUp, email, navigate, t],
  );

  return (
    <Verification
      {...resendPayload}
      heading={heading}
      onResend={resendPayload.handleResend}
      onSubmit={handleSubmit}
      subhead={subhead}
      submit={submit}
    />
  );
};

export default memo(ForgetPasswordVerification);
