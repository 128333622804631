import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AuthForm from '../components/AuthForm';
import PasswordInput from '../components/PasswordInput';
import useAuth from '../hooks/useAppAuth';
import { useCustomToast } from '../hooks/useCustomToast';
import useNavigation from '../hooks/useNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import { IconClass } from '../theme/utils';
import { FormSubmit } from '../types/formik';
import {
  ForgetPasswordValues,
  forgetPasswordValidationSchema,
} from '../utils/formValidations';

const FORM_INITIAL_VALUES: ForgetPasswordValues = {
  password: '',
  password2: '',
};
const BACK_ICON = <ChevronLeftIcon className={IconClass.CustomStroke} />;

const ForgetPassword: React.FC = () => {
  const { goBack, pushWithQuery } = useNavigation();
  const { restorePassword, sendEmailCode } = useAuth();
  const { t } = useTranslation(LocalesKeys.ForgetPassword);

  const { successToast } = useCustomToast();

  const onSubmit = useCallback<FormSubmit<ForgetPasswordValues>>(
    async ({ password }, { setFieldError }) => {
      if (!password) return;

      const response = await restorePassword(password);

      if (response instanceof Error) {
        setFieldError('password2', t('forgetTimeExpired'));
      } else {
        successToast({
          headerMessage: t('restoreSuccessHeader'),
          toastMessage: t(
            response.isValid
              ? 'restoreSuccessDescription'
              : 'restoreSuccessDescriptionFurtherSteps',
          ),
        });

        if (!response.isValid && response.email) {
          sendEmailCode(response.email);

          pushWithQuery(
            `${routes.AuthRoute.Login}/${routes.AuthRoute.Verification}`,
            { email: response.email },
          );
        }
      }
    },
    [restorePassword, t, successToast, sendEmailCode, pushWithQuery],
  );

  const { getFieldProps, getFieldMeta, handleSubmit, isSubmitting, isValid } =
    useFormik({
      initialValues: FORM_INITIAL_VALUES,
      onSubmit,
      validateOnMount: true,
      validationSchema: forgetPasswordValidationSchema,
    });

  return (
    <Stack flex={1} maxW="smp" spacing={6}>
      <Button
        alignSelf="start"
        colorScheme="secondary"
        leftIcon={BACK_ICON}
        onClick={goBack}
        px={4.5}
        w="initial"
      >
        {t('back')}
      </Button>
      <AuthForm onSubmit={handleSubmit}>
        <Stack>
          <Heading as="h2">{t('heading')}</Heading>
          <Text mt={6}>{t('subhead')}</Text>
        </Stack>
        <Stack mt={4} spacing={6}>
          <PasswordInput
            autoFocus
            {...getFieldMeta('password')}
            {...getFieldProps('password')}
            label="Password"
          />
          <PasswordInput
            {...getFieldMeta('password2')}
            {...getFieldProps('password2')}
            label="Password once again"
          />
        </Stack>
        <Stack align="center" mt={8} spacing={4}>
          <Button isDisabled={!isValid} isLoading={isSubmitting} type="submit">
            {t('submit')}
          </Button>
        </Stack>
      </AuthForm>
    </Stack>
  );
};

export default memo(ForgetPassword);
