import AddProjectToUser from '../components/AddProjectToUser';
import CancelSubscriptions from '../components/CancelSubscriptions';
import CenterDialogModal from '../components/CenterDialogModal';
import ChangeTeamUserStatus from '../components/ChangeTeamUserStatus';
import DeleteTeam from '../components/DeleteTeam';
import InvitePeopleToTeam from '../components/InvitePeopleToTeam';
import RemoveFromTeam from '../components/RemoveFromTeam';
import TransferOwnership from '../components/TransferTeamOwnership';
import { RouteModalProps } from './Modals';
import routes from './routes';

export const teamSettingsModals: RouteModalProps[] = [
  {
    Component: AddProjectToUser,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.AddProjects,
  },
  {
    Component: ChangeTeamUserStatus,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.ChangePermission,
  },
  {
    Component: RemoveFromTeam,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.RemoveFromTeam,
  },
  {
    Component: TransferOwnership,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.TransferOwnership,
  },
  {
    Component: InvitePeopleToTeam,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.InvitePeople,
  },
  {
    Component: DeleteTeam,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.DeleteTeam,
  },
  {
    Component: CancelSubscriptions,
    Modal: CenterDialogModal,
    route: routes.TeamSettingsRoutes.CancelSubscriptions,
  },
];
