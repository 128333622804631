import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import {
  getMainDefinition,
  offsetLimitPagination,
} from '@apollo/client/utilities';
import { Auth } from '@stormotion-auth/core';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { useEffect, useState } from 'react';
import { hasuraApiUrl, hasuraSubscriptionsUrl } from '../env';
import useIdToken from './useIdToken';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        notification_receiver: offsetLimitPagination(),
        project_dashboard: {
          keyArgs: [
            'orderBy',
            'where',
            ['display_status', 'name', 'status', 'driver_id'],
          ],
          merge(existing, incoming, _a) {
            const { args } = _a;
            const merged = existing ? existing.slice(0) : [];
            if (args) {
              const offset = args?.offset ?? 0;
              const limit = args?.limit ?? 0;
              const beforeOffset = merged.slice(0, offset);
              const afterLimit = merged.slice(offset + limit);
              if (incoming.lentgh < limit) {
                return [...beforeOffset, ...incoming];
              }
              return [...beforeOffset, ...incoming, ...afterLimit];
            }
            return [...merged, ...incoming];
          },
          read(existing, { args }) {
            const offset = args?.offset;
            const limit = args?.limit;
            if (limit === undefined || offset === undefined) {
              return existing;
            }
            return existing && existing.slice(offset, offset + limit);
          },
        },
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: hasuraApiUrl,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await Auth.currentSession().getToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const generateClient = (token?: string) => {
  const wsLink = new WebSocketLink({
    options: {
      connectionParams: {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
      reconnect: true,
    },
    uri: hasuraSubscriptionsUrl,
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  return new ApolloClient<InMemoryCache>({
    // @ts-expect-error
    cache: apolloCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    link: splitLink,
  });
};

const useRootApolloClient = () => {
  const token = useIdToken();

  const [client, setClient] = useState<ApolloClient<InMemoryCache> | null>(
    null,
  );

  useEffect(() => {
    setClient(generateClient(token ?? undefined));
  }, [token]);

  useEffect(() => {
    persistCache({
      cache: apolloCache,
      debug: __DEV__,
      storage: new LocalStorageWrapper(window.localStorage),
    }).catch((error) => console.error('Error restoring Apollo cache', error));
  }, []);

  return client;
};

export default useRootApolloClient;
