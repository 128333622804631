import { Icon, IconProps } from '@chakra-ui/react';
import React, { memo } from 'react';

const ChromeIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" height="24px" width="24px" {...props}>
    <path
      d="M3.558 16.879a9.733 9.733 0 0 0 7.602 4.833l4.414-7.645L12 12.004 8.43 14.066l-1.19-2.062-.873-1.51-2.35-4.07a9.742 9.742 0 0 0-.458 10.455Z"
      fill="#00AC47"
    />
    <path
      d="M12 2.255a9.733 9.733 0 0 0-7.987 4.168l4.414 7.644L12 12.004V7.88h8.825A9.742 9.742 0 0 0 12 2.255Z"
      fill="#EA4435"
    />
    <path
      d="M20.442 16.879a9.733 9.733 0 0 0 .384-9H12v4.126l3.571 2.061-1.19 2.063-.872 1.51-2.35 4.07a9.742 9.742 0 0 0 9.284-4.83Z"
      fill="#FFBA00"
    />
    <path
      d="M12 16.13a4.125 4.125 0 1 0 0-8.25 4.125 4.125 0 0 0 0 8.25Z"
      fill="#fff"
    />
    <path
      d="M12 15.193a3.188 3.188 0 1 0 0-6.375 3.188 3.188 0 0 0 0 6.375Z"
      fill="#4285F4"
    />
  </Icon>
);

export default memo(ChromeIcon);
