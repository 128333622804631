import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Verification from '../components/Verification';
import useAuth from '../hooks/useAppAuth';
import useEmailParam from '../hooks/useEmailParam';
import useResendCode from '../hooks/useResendCode';
import { LocalesKeys } from '../locales/localesKeys';
import { FormSubmit } from '../types/formik';
import { VerificationValues } from '../utils/formValidations';

const LoginVerification: React.FC = () => {
  const [email] = useEmailParam();

  const { t } = useTranslation(LocalesKeys.LoginVerification);

  const { confirmSignUp } = useAuth();

  const heading = t('heading');
  const subhead = t('subhead', { email });
  const submit = t('submit');

  const resendPayload = useResendCode(email);

  const handleSubmit = useCallback<FormSubmit<VerificationValues>>(
    async ({ code }) => {
      if (!email) return null;

      return confirmSignUp(email, code);
    },
    [confirmSignUp, email],
  );

  return (
    <Verification
      {...resendPayload}
      heading={heading}
      onResend={resendPayload.handleResend}
      onSubmit={handleSubmit}
      subhead={subhead}
      submit={submit}
    />
  );
};

export default memo(LoginVerification);
