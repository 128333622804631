import { AuthErrorMessage } from '@stormotion-auth/core/dist/generated/graphql';

export default {
  accountUpdated: 'Account updated',
  accountUpdateError: 'Account update error',
  cancel: 'Cancel',
  changesSaved: 'All changes saved successfully',
  delete: 'Delete Account',
  deleteAccountBody:
    'By deleting my account, I accept that all data associated with this account - including data for me, my team and contributors of any kind - will be permanently deleted and that this action is irreversible.',
  deleteAccountButton: 'Permanently delete my account',
  deleteAccountHeader: 'Delete account',
  deleteButton: 'Delete',
  deleteDescription:
    'Delete your account and all account data. This cannot be undone.',
  deleteHeading: 'Close account',
  deleteUppercase: 'DELETE',
  deleteWarning:
    'By deleting account, you will be permanently deleting all teams where you are a team-owner and projects where you are a driver!',
  deletingProjects: 'Projects: {{projects}}',
  deletingTeams: 'Teams: {{teams}}',
  'email-associated-with-another-provider':
    'This email is already linked to a Completely account using Google/Slack. Please sign in using it',
  emailLabel: 'Email',
  gotIt: 'Got it',
  linkedAccounts: 'Linked accounts',
  signInDescription: 'One-click sign in only (not shown on profile).',
  slack: 'Connect to Slack',
  socialAddFailureExists: "We can't connect already connected account",
  socialAddFailureHeader: "We weren't able to add social",
  socialRemoveFailureHeader: "We weren't able to remove social",
  socialRemoveSuccessHeader: 'Congrats on succesfully removing social',
  socialRemoveSuccessMessage:
    'This social is no longer associated with your account',
  submit: 'Update changes',
  typeDeleteToConfirm: 'For security reasons, please type DELETE to confirm.',
  usernameHelpingText:
    'We`re big on real names around here, so people know who`s who.',
  [AuthErrorMessage.NotEnoughProviders]: "We can't remove your only social",
  usernamePlaceholder: 'Username',
};
