import { Box, Flex, Input, InputProps, chakra } from '@chakra-ui/react';
import React, { memo, useMemo } from 'react';

const Switch: React.FC<InputProps> = ({ checked, onChange, ...props }) => {
  const boxBg = checked ? 'primary.200' : 'black.200';

  const circleLeft = checked ? '37.5%' : 0;

  const circleStyle = useMemo(
    () => ({
      aspectRatio: '1',
    }),
    [],
  );

  return (
    <chakra.label cursor="pointer" h="fit-content">
      <Input
        display="none"
        type="checkbox"
        {...props}
        checked={checked}
        onChange={onChange}
      />
      <Flex align="center" bg={boxBg} borderRadius="full" height={4} width={8}>
        <Box
          bg="primary.700"
          borderRadius="full"
          h="125%"
          left={circleLeft}
          position="relative"
          style={circleStyle}
          transitionDuration=".15s"
          transitionProperty="left"
          transitionTimingFunction="linear"
        />
      </Flex>
    </chakra.label>
  );
};

export default memo(Switch);
