import React, {
  ChangeEventHandler,
  MouseEventHandler,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Box, Button, Flex, FlexProps, Select, Text } from '@chakra-ui/react';
import * as R from 'ramda';
import { LocalesKeys } from '../locales/localesKeys';
import ChevronDownIcon from './icons/ChevronDownIcon';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';

interface Props extends FlexProps {
  count: number;
  offset: number;
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  onNextClick?: MouseEventHandler<HTMLButtonElement>;
  onPageSizeChange?: ChangeEventHandler<HTMLSelectElement>;
  onPrevClick?: MouseEventHandler<HTMLButtonElement>;
}

const ACTIVE_BUTTON_COLOR = 'black.400';
const DISABLED_BUTTON_COLOR = 'black.200';

const CHEVRON_DOWN_ICON = <ChevronDownIcon />;

const DashboardPagination: React.FC<Props> = ({
  count,
  offset,
  page,
  pageSize,
  pageSizeOptions,
  onNextClick,
  onPrevClick,
  onPageSizeChange,
  ...props
}) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const maxIndex = Math.min(R.inc(page) * pageSize, count);
  const minIndex = Math.min(R.inc(offset), count);

  const nextButtonDisabled = R.inc(page) * pageSize >= count;
  const prevButtonDisabled = page < 1;

  const nextButtonColor = nextButtonDisabled
    ? DISABLED_BUTTON_COLOR
    : ACTIVE_BUTTON_COLOR;
  const prevButtonColor = prevButtonDisabled
    ? DISABLED_BUTTON_COLOR
    : ACTIVE_BUTTON_COLOR;

  const renderPageSizeOption = useCallback<(pageSize: number) => JSX.Element>(
    (pageSize) => (
      <option key={pageSize} value={pageSize}>
        {pageSize}
      </option>
    ),
    [],
  );

  const PageSizeOptions = useMemo(
    () => pageSizeOptions.map(renderPageSizeOption),
    [pageSizeOptions, renderPageSizeOption],
  );

  return (
    <Flex
      align="center"
      bgColor="white"
      borderTop="1px"
      borderTopColor="black.200"
      p={4}
      {...props}
    >
      <Text as={Flex} color="black.400" flex={1} size="subhead2">
        {t('rowsPerPage')}
        <Box ml={3}>
          <Select
            fontSize="sm"
            fontWeight="medium"
            icon={CHEVRON_DOWN_ICON}
            id="pageSize"
            onChange={onPageSizeChange}
            value={pageSize}
            variant="unstyled"
          >
            {PageSizeOptions}
          </Select>
        </Box>
      </Text>
      <Flex align="center">
        <Text color="black.400" size="subhead2">
          {t('itemsCount', { count, maxIndex, minIndex })}
        </Text>
        <Flex ml={3}>
          <Button onClick={onPrevClick} variant="unstyled">
            <ChevronLeftIcon color={prevButtonColor} cursor="pointer" />
          </Button>
          <Button onClick={onNextClick} variant="unstyled">
            <ChevronRightIcon color={nextButtonColor} cursor="pointer" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(DashboardPagination);
