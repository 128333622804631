import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const LogoIcon: React.FC<IconProps> = (props) => (
  <Icon
    height="32px"
    id="svg2"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 2560 492"
    width="165px"
    {...props}
  >
    <defs id="defs6">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath18">
        <path d="M 0,1080 H 1920 V 0 H 0 Z" id="path16" />
      </clipPath>
    </defs>
    <g id="g10" transform="matrix(1,0,0,-1,0,790)">
      <g id="g12">
        <g clipPath="url(#clipPath18)" id="g14">
          <g id="g20" transform="translate(495.2255,620.0527)">
            <path
              d="m 0,0 c 10.593,16.297 27.703,28.114 51.741,28.114 28.519,0 50.925,-13.034 62.334,-36.668 9.777,20.778 29.334,36.668 59.889,36.668 39.112,0 69.667,-30.963 69.667,-74.558 V -178.85 h -55.406 v 116.516 c 0,22.817 -13.037,36.26 -32.594,36.26 -19.962,0 -33.815,-13.443 -33.815,-36.26 V -178.85 H 66.407 v 116.516 c 0,22.817 -13.038,36.26 -32.592,36.26 C 14.258,-26.074 1.221,-38.293 0,-59.479 V -178.85 H -55.409 V 24.041 l 55.409,0 z"
              id="path22"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <g id="g24" transform="translate(877.3832,491.3122)">
            <path
              d="m 0,0 c 28.927,0 50.112,23.633 50.112,51.333 0,27.706 -21.185,50.931 -50.112,50.931 -29.332,0 -50.925,-23.225 -50.925,-50.931 C -50.925,23.633 -29.332,0 0,0 m -50.925,123.444 c 13.853,22.41 35.443,33.41 62.334,33.41 56.629,0 96.557,-46.036 96.557,-105.521 0,-59.479 -39.928,-105.923 -96.965,-105.923 -26.483,0 -48.073,11.403 -61.926,30.555 v -109.594 h -55.409 v 286.41 h 55.409 z"
              id="path26"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <path
            d="m 1063.578,441.203 h -55.817 v 281.114 h 55.817 z"
            id="path28"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
          />
          <g id="g30" transform="translate(1141.7982,567.0941)">
            <path
              d="M 0,0 H 87.595 C 86.78,20.778 69.262,35.036 45.632,35.036 21.186,35.036 2.852,19.146 0,0 m 0,-41.964 c 4.889,-23.225 25.262,-38.299 53.78,-38.299 25.259,0 50.518,7.336 68.851,19.147 v -53.367 c -17.925,-9.375 -44,-15.889 -73.334,-15.889 -60.298,0 -104.706,42.774 -104.706,105.923 0,59.485 42.78,105.521 101.449,105.521 58.666,0 94.925,-43.997 94.925,-96.554 0,-8.559 -1.629,-19.968 -3.26,-26.482 z"
              id="path32"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <g id="g34" transform="translate(1317.4,441.2028)">
            <path
              d="m 0,0 v 155.631 h -30.963 v 47.26 H 3.26 v 44.405 l 52.149,11.817 v -56.222 h 34.223 v -47.26 H 55.409 L 55.409,0 Z"
              id="path36"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <g id="g38" transform="translate(1464.8881,567.0941)">
            <path
              d="M 0,0 H 87.595 C 86.78,20.778 69.262,35.036 45.632,35.036 21.186,35.036 2.852,19.146 0,0 m 0,-41.964 c 4.889,-23.225 25.262,-38.299 53.78,-38.299 25.259,0 50.518,7.336 68.851,19.147 v -53.367 c -17.926,-9.375 -44,-15.889 -73.334,-15.889 -60.298,0 -104.706,42.774 -104.706,105.923 0,59.485 42.78,105.521 101.449,105.521 58.665,0 94.925,-43.997 94.925,-96.554 0,-8.559 -1.629,-19.968 -3.26,-26.482 z"
              id="path40"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <path
            d="m 1682.448,441.203 h -55.817 v 281.114 h 55.817 z"
            id="path42"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
          />
          <g id="g44" transform="translate(1769.6353,456.6842)">
            <path
              d="m 0,0 -74.558,187.409 h 62.334 L 30.555,70.072 75.779,187.409 h 62.742 L 17.926,-99.001 h -64.37 z"
              id="path46"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <g id="g48" transform="translate(208.1154,548.4701)">
            <path
              d="m 0,0 h 51.754 c 2.724,25.302 24.091,44.914 50.059,44.914 25.907,0 47.335,-19.612 50.059,-44.914 h 51.754 C 200.72,53.752 156.291,96.425 101.813,96.425 47.335,96.425 2.845,53.752 0,0"
              id="path50"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <g id="g52" transform="translate(309.9285,492.6608)">
            <path
              d="m 0,0 c 25.968,0 47.396,19.673 50.059,44.914 h 51.754 C 98.968,-8.838 54.478,-51.572 0,-51.572 c -54.478,0 -98.968,42.734 -101.813,96.486 h 51.754 C -47.396,19.673 -26.028,0 0,0"
              id="path54"
              style={{
                fill: '#22d490',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
          <g id="g56" transform="translate(119.0523,490.7926)">
            <path
              d="m 0,0 c -29.316,0 -53.082,23.767 -53.082,53.082 0,29.316 23.766,53.072 53.082,53.072 19.013,0 35.691,-9.993 45.064,-25.017 l 35.35,32.664 c -18.755,26.277 -49.486,43.4 -84.227,43.4 -57.102,0 -103.395,-46.294 -103.395,-103.396 0,-57.102 46.293,-103.395 103.395,-103.395 34.741,0 65.472,17.122 84.227,43.4 L 45.622,25.957 C 36.373,10.416 19.406,0 0,0"
              id="path58"
              style={{
                fill: '#1a1a1a',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export default memo(LogoIcon);
