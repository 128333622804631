import React, { memo, useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import { CreateTeamStep2Values } from './CreateTeamStep2';
import { TeamRoutes } from '../pages/CreateTeam';

interface Props {
  isLastStep: boolean;
  currentStepRoute: string;
  stepsCount: number;
}

const CreateTeamButton: React.FC<Props> = ({
  isLastStep,
  currentStepRoute,
  stepsCount,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const {
    values: { domain, teamName },
    isSubmitting,
  } = useFormikContext<CreateTeamStep2Values>();
  const { t } = useTranslation(LocalesKeys.CreateTeam);

  useEffect(() => {
    if (currentStepRoute === TeamRoutes.CreateTeam) {
      if (!(domain && teamName)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    if (isLastStep || currentStepRoute === TeamRoutes.Personalization) {
      setIsDisabled(false);
    }
  }, [currentStepRoute, domain, isLastStep, stepsCount, teamName]);

  return (
    <Button
      isDisabled={isDisabled}
      isLoading={isSubmitting}
      mt={5}
      type="submit"
    >
      {t(isLastStep ? 'createAR' : 'next')}
    </Button>
  );
};

export default memo(CreateTeamButton);
