import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, useFormik } from 'formik';

import {
  compose,
  filter,
  find,
  findIndex,
  head,
  isNil,
  not,
  prop,
  propSatisfies,
  values,
} from 'ramda';
import { LocalesKeys } from '../locales/localesKeys';
import {
  CreateARStep1Values,
  CreateARStep2inviteValues,
  createARStep2InviteValidationSchema,
} from '../utils/formValidations';
import RoleSelectTable, { InvitedMember } from './RoleSelectTable';
import RoleSelectMenu from './RoleSelectMenu';
import { Project_User_Role_Enum } from '../generated/graphql';
import Autocomplete from './Autocomplete';
import { AutocompleteItemType } from './AutocompleteItem';
import Avatar from './Avatar';
import { useTeamContext } from '../store/contexts/TeamContext';

const initialValues: CreateARStep2inviteValues = {
  email: '',
};

const AUTOCOMPLETE_PROPS = {
  flex: 6,
  mr: 6,
};

export type ProjectInviteArray = CreateARStep1Values['invites'];

type Props = {
  projectInvites: ProjectInviteArray;
  setProjectInvites: (value: ProjectInviteArray) => void;
};

export const filterByEmail =
  (email?: string) => (object: Record<'email', string>) =>
    object.email === email;

const CreateARStep2: React.FC<Props> = ({
  projectInvites,
  setProjectInvites,
}) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const { team } = useTeamContext();

  const totalMembers = useMemo(
    () =>
      team?.team_users
        .map<InvitedMember>((member) => ({
          ...member.user,
          role: find(filterByEmail(member.user.email), projectInvites)?.role,
        }))
        .concat(
          projectInvites.filter(
            (invite) =>
              !find(
                compose(filterByEmail(invite.email), prop('user')),
                team.team_users,
              ),
          ),
        ) ?? [],
    [projectInvites, team?.team_users],
  );

  const setRoleByEmail = useCallback(
    (email: string, role?: Project_User_Role_Enum) => {
      if (find(filterByEmail(email), projectInvites) && !role) {
        setProjectInvites([
          ...filter<ProjectInviteArray[0]>(
            compose(not, filterByEmail(email)),
            projectInvites,
          ),
        ]);
        return;
      }

      const previousValue = projectInvites;
      const previousIndex = findIndex(filterByEmail(email), previousValue);
      const id = team?.team_users.find(
        compose(filterByEmail(email), prop('user')),
      )?.user.id;
      if (previousIndex === -1) {
        setProjectInvites([
          ...projectInvites,
          {
            email,
            id,
            role,
          },
        ]);
        return;
      }
      previousValue[previousIndex] = {
        email,
        id,
        role,
      };
      setProjectInvites([...previousValue]);
    },
    [projectInvites, setProjectInvites, team?.team_users],
  );

  const [invitedMemberRole, setInvitedMemberRole] = useState(
    head(values(Project_User_Role_Enum)),
  );

  const inviteNewMember = useCallback<
    (
      values: CreateARStep2inviteValues,
      helpers: FormikHelpers<CreateARStep2inviteValues>,
    ) => void
  >(
    ({ email }, helpers) => {
      if (!email) {
        return;
      }

      const trimedEmail = email.trim();

      const isUserAlreadyInvited = projectInvites.some(
        (user) => user.email === trimedEmail,
      );

      if (isUserAlreadyInvited) {
        helpers.setFieldError('email', t('userAlreadyInvited'));
        return;
      }

      const id = team?.team_users?.find(
        compose(filterByEmail(trimedEmail), prop('user')),
      )?.user.id;
      setProjectInvites([
        ...projectInvites,
        {
          email: trimedEmail,
          id,
          role: invitedMemberRole,
        },
      ]);

      helpers.resetForm();
    },
    [invitedMemberRole, projectInvites, setProjectInvites, t, team?.team_users],
  );

  const { getFieldProps, getFieldMeta, submitForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit: inviteNewMember,
    validationSchema: createARStep2InviteValidationSchema,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const setEmailValue = useCallback<(value: unknown) => void>(
    (value) => {
      setFieldValue('email', value);
      onOpen();
    },
    [onOpen, setFieldValue],
  );

  const filteredData = useMemo(
    () =>
      totalMembers.filter(propSatisfies(isNil, 'role')).map((member) => ({
        value: member.email,
        ...member,
      })),
    [totalMembers],
  );

  const renderItemContent = useCallback<
    (item: AutocompleteItemType<InvitedMember>) => JSX.Element
  >((item) => {
    const imageUrl = item.image?.url ?? undefined;

    return (
      <Flex align="center">
        <Avatar
          h={10}
          mr={2}
          name={item.value}
          src={imageUrl}
          userId={item.id}
          w={10}
        />
        <Flex direction="column">
          <Text color="black.500" size="body1">
            {item.username}
          </Text>
          <Text color="black.400" size="body2">
            {item.email}
          </Text>
        </Flex>
      </Flex>
    );
  }, []);

  return (
    <Stack flex={1} w="full">
      <Heading pb={4} pt={6} size="headline3">
        {t('peopleHeading')}
      </Heading>
      <Text pb={6} variant="body1">
        {t('peopleDescription')}
      </Text>
      <Flex align="center">
        <Autocomplete
          data={filteredData}
          renderItemContent={renderItemContent}
          setFieldValue={setEmailValue}
          {...getFieldProps('email')}
          {...getFieldMeta('email')}
          boxProps={AUTOCOMPLETE_PROPS}
          rightElement={
            <Box bg="white" mr={5} pl={5}>
              <RoleSelectMenu
                isOpen={isOpen}
                onChange={setInvitedMemberRole}
                onClose={onClose}
                onOpen={onOpen}
                role={invitedMemberRole}
              />
            </Box>
          }
        />
        <Button colorScheme="secondary" flex={1} onClick={submitForm}>
          {t('invite')}
        </Button>
      </Flex>
      <RoleSelectTable
        setRoleByEmail={setRoleByEmail}
        teamMembers={totalMembers}
      />
    </Stack>
  );
};

export default memo(CreateARStep2);
