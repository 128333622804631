import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const NotApprovedIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" fill="#F44545" />
    <path
      clipRule="evenodd"
      d="M12.8 7a.8.8 0 0 0-1.6 0v6a.8.8 0 0 0 1.6 0V7Zm-1.6 10a.8.8 0 0 0 1.6 0v-1a.8.8 0 1 0-1.6 0v1Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(NotApprovedIcon);
