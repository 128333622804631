import React, { memo, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Heading,
  Interpolation,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { filter, keys } from 'ramda';
import { Notification_Destination_Enum } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import NotificationSettingButton from './NotificationSettingButton';
import { NotificationSettingsValues } from '../utils/formValidations';
import { ArrayElement } from '../types/utils';
import { settingToString } from '../hooks/useNotificationSettings';

const TABLE_CSS: Interpolation<{}> = {
  tableLayout: 'auto',
  width: '100%',
};

export type SettingByType = ArrayElement<
  NonNullable<NotificationSettingsValues['reminders']>
>;

type Props = {
  settings: SettingByType[];
  setSettings: (settings: SettingByType[]) => void;
};

const NotificationSettingsTable: React.FC<Props> = ({
  settings,
  setSettings,
}) => {
  const { t } = useTranslation(LocalesKeys.NotificationSettings);

  const updateSetting = useCallback<
    (setting: SettingByType, destination: Notification_Destination_Enum) => void
  >(
    (setting, destination) => {
      setSettings([
        ...filter((s) => s.id !== setting.id, settings),
        { ...setting, [destination]: !setting[destination] },
      ]);
    },
    [setSettings, settings],
  );

  const columns = useMemo<Column<SettingByType>[]>(
    () => [
      {
        accessor: 'type',
        Cell: ({ row }) => (
          <Text size="body2">{t(settingToString(row.original))}</Text>
        ),
        Header: () => (
          <Heading as="p" size="headline4" textAlign="left">
            {t('reminder')}
          </Heading>
        ),
      },
      ...keys(Notification_Destination_Enum).map(
        (key): Column<SettingByType> => ({
          accessor: Notification_Destination_Enum[key],
          Cell: ({
            value,
            row,
          }: {
            value: boolean;
            row: Row<SettingByType>;
          }) => (
            <Flex justify="center">
              <NotificationSettingButton
                active={value}
                destination={Notification_Destination_Enum[key]}
                setting={row.original}
                updateSetting={updateSetting}
              />
            </Flex>
          ),
          Header: () => (
            <Flex justify="center">
              <Heading as="p" size="headline4" textAlign="left">
                {t(key)}
              </Heading>
            </Flex>
          ),
        }),
      ),
    ],
    [t, updateSetting],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: settings });

  const renderHeaderColumn = useCallback<
    (column: HeaderGroup<SettingByType>) => JSX.Element
  >(
    (column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>,
    [],
  );

  const renderTableHeader = useCallback<
    (headerGroup: HeaderGroup<SettingByType>) => JSX.Element
  >(
    (headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(renderHeaderColumn)}
      </Tr>
    ),
    [renderHeaderColumn],
  );

  const TableHeaders = useMemo(
    () => headerGroups.map(renderTableHeader),
    [headerGroups, renderTableHeader],
  );

  const renderTableCell = useCallback<
    (cell: Cell<SettingByType>) => JSX.Element
  >((cell) => <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>, []);

  const renderTableRow = useCallback<(row: Row<SettingByType>) => JSX.Element>(
    (row) => {
      prepareRow(row);
      return (
        <Tr {...row.getRowProps()} h={14}>
          {row.cells.map(renderTableCell)}
        </Tr>
      );
    },
    [prepareRow, renderTableCell],
  );

  const TableRows = useMemo(
    () => rows.map(renderTableRow),
    [renderTableRow, rows],
  );

  return (
    <Box
      border="1px solid"
      borderColor="black.200"
      borderRadius="lg"
      overflow="hidden"
      w="full"
    >
      <Table
        colorScheme="black"
        css={TABLE_CSS}
        variant="simple"
        {...getTableProps()}
      >
        <Thead>{TableHeaders}</Thead>
        <Tbody {...getTableBodyProps()}>{TableRows}</Tbody>
      </Table>
    </Box>
  );
};

export default memo(NotificationSettingsTable);
