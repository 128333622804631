import Button from './button';
import Input from './input';
import Heading from './heading';
import Text from './text';
import Link from './link';
import Table from './table';
import Menu from './menu';
import Badge from './badge';
import Tooltip from './tooltip';

export default {
  Badge,
  Button,
  Heading,
  Input,
  Link,
  Menu,
  Table,
  Text,
  Tooltip,
};
