import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import {
  useCreateCustomerMutation,
  useUpdatePaymentMethodMutation,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { FormSubmit } from '../types/formik';
import { BillingMethodChangeValues } from '../utils/formValidations';

export const useBillingMethodChange = (
  newCostumerEmail: string | undefined,
): FormSubmit<BillingMethodChangeValues> => {
  const elements = useElements();

  const stripe = useStripe();

  const [createCostumer] = useCreateCustomerMutation();

  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();

  const { team } = useTeamContext();

  const onSubmit = useCallback<FormSubmit<BillingMethodChangeValues>>(
    async (values) => {
      if (!stripe || !elements || !team) {
        throw new Error();
      }
      const card = elements.getElement('card');
      if (!card) {
        throw new Error();
      }

      const result = await stripe.createPaymentMethod({
        card,
        type: 'card',
      });

      if (!result.paymentMethod) {
        throw new Error();
      }

      if (newCostumerEmail) {
        await createCostumer({
          variables: {
            ...values,
            email: newCostumerEmail,
            payment_method_id: result.paymentMethod.id,
            teamId: team.id,
          },
        });
      }
      if (team.customer_id) {
        await updatePaymentMethod({
          variables: {
            ...values,
            customerId: team.customer_id,
            payment_method_id: result.paymentMethod.id,
          },
        });
      }
    },
    [
      createCostumer,
      elements,
      newCostumerEmail,
      stripe,
      team,
      updatePaymentMethod,
    ],
  );

  return onSubmit;
};
