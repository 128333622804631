import { useCallback, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { canGoBack } from '../utils/navigation';

export const useRouteDisclosure = (modalRoute: string) => {
  const [goingBack, setGoingBack] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const isOpen = !!useMatch({
    end: false,
    path: modalRoute,
  });

  useEffect(() => {
    if (!goingBack) {
      return;
    }

    if (!isOpen) {
      setGoingBack(false);
      return;
    }

    if (canGoBack()) {
      navigate(-1);
    } else {
      navigate('..', {
        replace: true,
      });
    }
  }, [goingBack, isOpen, navigate, location.key]);

  const onClose = useCallback(() => setGoingBack(true), []);

  return { isOpen, onClose };
};
