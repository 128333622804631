import { VStack, useDisclosure } from '@chakra-ui/react';
import {
  compose,
  contains,
  filter,
  isEmpty,
  prop,
  propSatisfies,
  toLower,
  uniqBy,
} from 'ramda';
import React, { useCallback, useMemo } from 'react';
import AutocompleteItem, { AutocompleteItemType } from './AutocompleteItem';
import TextInput, { TextInputProps } from './TextInput';

export type AutocompleteProps<T> = {
  data: AutocompleteItemType<T>[];
  renderItemContent: (item: AutocompleteItemType<T>) => JSX.Element;
  setFieldValue: (value: any) => void;
  value: any;
};

const Autocomplete = <T,>({
  data,
  renderItemContent,
  setFieldValue,
  onClick: parentOnClick,
  onFocus: parentOnFocus,
  onBlur: parentOnBlur,
  onInput: parentOnInput,
  ...props
}: AutocompleteProps<T> & TextInputProps) => {
  const filteredData = useMemo(
    () =>
      filter(
        propSatisfies(
          compose(contains(toLower(props.value?.toString())), toLower),
          'value',
        ),
        uniqBy(prop('value'), data),
      ),
    [data, props.value],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onItemClick = useCallback(
    (item: AutocompleteItemType<T>) => {
      setFieldValue(item.value);
      onClose();
    },
    [onClose, setFieldValue],
  );

  const renderItems = useCallback<(item: AutocompleteItemType<T>) => void>(
    (item) => (
      <AutocompleteItem
        key={item.value}
        item={item}
        onItemClick={onItemClick}
        renderItemContent={renderItemContent}
      />
    ),
    [onItemClick, renderItemContent],
  );

  const showItemList = isOpen && !isEmpty(filteredData);

  const preventFromFocus = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => e.preventDefault(),
    [],
  );

  const Items = useMemo(
    () => (
      <VStack
        bg="white"
        borderRadius="lg"
        boxShadow="box"
        display={showItemList ? 'flex' : 'none'}
        flex={2}
        height="fit-content"
        maxH="sm"
        mt={2}
        onMouseDown={preventFromFocus}
        overflow="hidden"
        position="absolute"
        spacing={0}
        top="100%"
        w="full"
        zIndex="overlay"
      >
        {filteredData.map(renderItems)}
      </VStack>
    ),
    [filteredData, preventFromFocus, renderItems, showItemList],
  );

  const onClick = useCallback<React.MouseEventHandler<HTMLInputElement>>(
    (event) => {
      parentOnClick?.(event);
      onOpen();
    },
    [onOpen, parentOnClick],
  );

  const onFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      parentOnFocus?.(event);
      onOpen();
    },
    [onOpen, parentOnFocus],
  );

  const onBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      parentOnBlur?.(event);
      onClose();
    },
    [onClose, parentOnBlur],
  );

  const onInput = useCallback<React.FormEventHandler<HTMLInputElement>>(
    (event) => {
      parentOnInput?.(event);
      onOpen();
    },
    [onOpen, parentOnInput],
  );

  return (
    <TextInput
      {...props}
      autoComplete="off"
      autocompleteElement={Items}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onInput={onInput}
    />
  );
};

export default React.memo(Autocomplete) as typeof Autocomplete;
