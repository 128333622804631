export default {
  back: 'Back',
  forgetTimeExpired:
    'Your access to restore password has expired. Please go-back and try restoring once again',
  heading: 'Restore password',
  restoreSuccessDescription: 'Enjoy using the app',
  restoreSuccessDescriptionFurtherSteps: 'Please proceed signing in',
  restoreSuccessHeader: "You've successfully restored password",
  subhead: 'Please enter new password and verify it',
  submit: 'Continue',
};
