import { HStack, Text } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuNavigator } from '../pages/Settings';
import colors from '../theme/foundations/colors';

type Props = {
  item: MenuNavigator;
};

const ACTIVE: CSSObject = {
  bg: 'black.200',
};

const HOVER: CSSObject = {
  bg: 'black.100',
};

const activeStyle: (props: { isActive: boolean }) => React.CSSProperties = ({
  isActive,
}) => ({
  color: isActive ? colors.primary[700] : colors.black[400],
  width: '100%',
});

const SettingsNavItem: React.FC<Props> = ({ item: { Icon, name, route } }) => (
  <NavLink key={route} end replace style={activeStyle} to={route}>
    <HStack
      _active={ACTIVE}
      _hover={HOVER}
      aria-hidden={false}
      cursor="pointer"
      h={12}
      px={4}
      py={3}
      spacing={2}
      tabIndex={0}
      transitionDuration="ultra-fast"
      transitionProperty="background"
      transitionTimingFunction="ease-in"
      w="full"
    >
      <Icon boxSize={6} />
      <Text size="subhead1">{name}</Text>
    </HStack>
  </NavLink>
);

export default memo(SettingsNavItem);
