import {
  Button,
  ButtonProps,
  Flex,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Country, State } from 'country-state-city';
import { LocalesKeys } from '../locales/localesKeys';
import {
  BillingMethodChangeValues,
  billingMethodChangeValidationSchema,
} from '../utils/formValidations';
import CrossIcon from './icons/CrossIcon';
import Select from './Select';
import TextInput from './TextInput';
import { getStateByCity } from '../utils/cities';
import { FormSubmit } from '../types/formik';
import CardInput from './CardInput';

const CROSS_ICON = <CrossIcon />;

const initialValues: BillingMethodChangeValues = {
  card: '',
  city: '',
  country: '',
  line1: '',
  line2: '',
  postal_code: '',
  state: '',
};

const HIDDEN_ELEMENT: React.CSSProperties = {
  display: 'none',
};

type Props = {
  setBillingMethod: FormSubmit<BillingMethodChangeValues>;
};

const BillingContactChange: React.FC<Props & ButtonProps> = ({
  setBillingMethod,
  ...props
}) => {
  const { t } = useTranslation(LocalesKeys.Billing);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const onSubmit: FormSubmit<BillingMethodChangeValues> = useCallback(
    async (values, helpers) => {
      try {
        await setBillingMethod(values, helpers);
      } catch {
        helpers.setFieldError('card', 'cardDeclined');
        return;
      }
      onClose();
    },
    [onClose, setBillingMethod],
  );

  const {
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: billingMethodChangeValidationSchema,
  });

  const countryProps = useMemo(() => getFieldProps('country'), [getFieldProps]);
  const cityProps = useMemo(() => getFieldProps('city'), [getFieldProps]);

  const onCityChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      cityProps.onChange(e);
      const { value } = e.target;
      if (!value) {
        return;
      }
      const state = getStateByCity(value, countryProps.value);
      if (!state) {
        return;
      }
      setFieldValue('state', state.name);
    },
    [cityProps, countryProps.value, setFieldValue],
  );

  const CountryOptions = useMemo(
    () =>
      Country.getAllCountries().map((country) => (
        <option key={country.isoCode} value={country.isoCode}>
          {country.name}
        </option>
      )),
    [],
  );

  const StateOptions = useMemo(
    () =>
      State.getStatesOfCountry(countryProps.value).map((state) => (
        <option key={`${state.isoCode}-${state.name}`} value={state.name}>
          {state.name}
        </option>
      )),
    [countryProps.value],
  );

  return (
    <>
      <Button {...props} onClick={onOpen} />
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="xl" p={10}>
          <VStack spacing={10} w="full">
            <Flex align="center" justify="space-between" w="full">
              <Heading size="headline2">{t('billingMethodModalTitle')}</Heading>
              <IconButton
                aria-label=""
                icon={CROSS_ICON}
                onClick={onClose}
                variant="unstyled"
              />
            </Flex>
            <chakra.form onSubmit={handleSubmit} w="full">
              <VStack spacing={6}>
                <CardInput
                  {...getFieldProps('card')}
                  {...getFieldMeta('card')}
                />
                <TextInput
                  {...getFieldMeta('line1')}
                  {...getFieldProps('line1')}
                  label=""
                  placeholder={t('line1Placeholder')}
                />
                <TextInput
                  {...getFieldMeta('line2')}
                  {...getFieldProps('line2')}
                  label=""
                  placeholder={t('line2Placeholder')}
                />
                <Select {...countryProps} {...getFieldMeta('country')}>
                  <option disabled style={HIDDEN_ELEMENT} value="">
                    {t('selectCountry')}
                  </option>
                  {CountryOptions}
                </Select>
                <HStack align="start" spacing={2}>
                  <TextInput
                    {...getFieldMeta('city')}
                    {...cityProps}
                    label=""
                    onChange={onCityChange}
                    placeholder={t('cityPlaceholder')}
                  />
                  <Select
                    {...getFieldProps('state')}
                    {...getFieldMeta('state')}
                  >
                    <option disabled style={HIDDEN_ELEMENT} value="">
                      {t('selectState')}
                    </option>
                    {StateOptions}
                  </Select>
                  <TextInput
                    {...getFieldMeta('postal_code')}
                    {...getFieldProps('postal_code')}
                    label=""
                    placeholder={t('postalPlaceholder')}
                  />
                </HStack>
                <HStack justify="flex-end" spacing={6} w="full">
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    w="fit-content"
                  >
                    {t('changeMethod')}
                  </Button>
                </HStack>
              </VStack>
            </chakra.form>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(BillingContactChange);
