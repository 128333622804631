import { Button, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import routes from '../navigation/routes';
import { useTeamContext } from '../store/contexts/TeamContext';
import { FormSubmit } from '../types/formik';
import {
  DeleteConfirmationValues,
  deletingConfirmationValidationSchema,
} from '../utils/formValidations';
import TextInput from './TextInput';

const initialValues: DeleteConfirmationValues = {
  confirmation_input: '',
};

const DeleteTeam: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const { deleteTeam } = useTeamContext();

  const onSubmit: FormSubmit<DeleteConfirmationValues> =
    useCallback(async () => {
      await deleteTeam(routes.ModalRoute.DeleteTeamSuccessful);
    }, [deleteTeam]);

  const { getFieldProps, getFieldMeta, handleSubmit, isSubmitting, isValid } =
    useFormik({
      initialValues,
      onSubmit,
      validateOnMount: true,
      validationSchema: deletingConfirmationValidationSchema,
    });

  return (
    <chakra.form onSubmit={handleSubmit} w="full">
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
        w="full"
      >
        <Heading size="headline3">{t('deleteTeamHeader')}</Heading>
        <Text mb={5} mt={6} textAlign="center">
          {t('deleteTeamBody')}
        </Text>
        <Text
          fontWeight="bold"
          mb={5}
          textAlign="center"
          textColor="red"
          textTransform="uppercase"
        >
          {t('deleteTeamWarning')}
        </Text>
        <TextInput
          {...getFieldMeta('confirmation_input')}
          {...getFieldProps('confirmation_input')}
          label={t('typeDeleteToConfirm')}
          placeholder={t('deleteUppercase')}
          touched
        />
        <Flex gridGap={6} justifyContent="center" mt={10}>
          <Button colorScheme="secondary" onClick={onClose} px={6}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!isValid}
            isLoading={isSubmitting}
            px={6}
            type="submit"
          >
            {t('delete')}
          </Button>
        </Flex>
      </Flex>
    </chakra.form>
  );
};

export default memo(DeleteTeam);
