import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useTransferOwnershipMutation,
  useUserByPkQuery,
} from '../generated/graphql';
import routes from '../navigation/routes';
import { useTeamContext } from '../store/contexts/TeamContext';

export const useTransferTeamOwnership = () => {
  const { id } = useParams();

  const { team } = useTeamContext();

  const navigate = useNavigate();

  const pickedUserId = id || '';

  const teamId = team?.id ?? '';

  const { data: userData } = useUserByPkQuery({
    variables: { id: pickedUserId },
  });

  const user = userData?.user_by_pk;

  const userName = user?.username || user?.email;

  const [transferOwnership] = useTransferOwnershipMutation();

  const onChangeTeamUserRole = useCallback(async () => {
    await transferOwnership({
      variables: {
        future_owner_user_id: pickedUserId,
        team_id: teamId,
      },
    });

    navigate(`/${routes.RoutePrefixes.TeamSettings}`, { replace: true });
  }, [navigate, pickedUserId, teamId, transferOwnership]);

  return {
    onChangeTeamUserRole,
    user,
    userName,
  };
};
