import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  ColorProps,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { equals, reject, values } from 'ramda';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Project_User_Role_Enum } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { isNotNil } from '../utils/logic';
import PlusIcon from './icons/PlusIcon';
import { RenderMenuContent } from './types';

const ROLE_OPTIONS = reject(
  equals(Project_User_Role_Enum.Driver),
  values(Project_User_Role_Enum),
);

const MENU_BUTTON_ACTIVE = {
  color: 'primary.500',
};

type Props = {
  role?: Project_User_Role_Enum;
  onChange: (role?: Project_User_Role_Enum) => void;
  inProject?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  color?: ColorProps['color'];
};

const RoleSelectMenu: React.FC<Props> = ({
  role,
  onChange,
  inProject,
  color,
  ...props
}) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const setValue = useCallback(
    (value?: string | string[]) => {
      if (!value || typeof value === 'string') {
        onChange(value as Project_User_Role_Enum);
      }
    },
    [onChange],
  );

  const renderOption = useCallback<
    (option: Project_User_Role_Enum) => JSX.Element
  >(
    (option) => (
      <MenuItemOption key={option} px={4} py={3} value={option}>
        <Text color="black.500">{t(option)}</Text>
      </MenuItemOption>
    ),
    [t],
  );

  const renderButton = useCallback(
    (isOpen: boolean) => {
      if (role === Project_User_Role_Enum.Driver) {
        return (
          <Text color="black.500" size="body1">
            {t(role)}
          </Text>
        );
      }
      return isNotNil(inProject) ? (
        <HStack>
          <Text size="body1">
            {role ? t(role) : t(inProject ? 'setRole' : 'add')}
          </Text>
          {inProject || isOpen || role ? (
            <ChevronUpIcon
              boxSize={6}
              transform={`rotate(${isOpen ? '0deg' : '180deg'})`}
              transitionDuration="fast"
              transitionProperty="transform"
            />
          ) : (
            <PlusIcon />
          )}
        </HStack>
      ) : (
        <HStack>
          <Text size="body1">{t('setRole')}</Text>
          <ChevronUpIcon
            boxSize={6}
            transform={`rotate(${isOpen ? '0deg' : '180deg'})`}
            transitionDuration="fast"
            transitionProperty="transform"
          />
        </HStack>
      );
    },
    [inProject, role, t],
  );

  const renderMenu = useCallback<RenderMenuContent>(
    ({ isOpen }) => (
      <>
        <MenuButton
          _active={MENU_BUTTON_ACTIVE}
          color={color || 'black.500'}
          disabled={role === Project_User_Role_Enum.Driver}
          isActive={isOpen}
          spacing={2}
          type="button"
        >
          {renderButton(isOpen)}
        </MenuButton>
        <MenuList color="primary.500" zIndex={100}>
          <MenuOptionGroup onChange={setValue} type="radio" value={role}>
            {ROLE_OPTIONS.map(renderOption)}
            {isNotNil(inProject) && role && (
              <MenuItemOption px={4} py={3} value={undefined}>
                <Text color="alert">{t('remove')}</Text>
              </MenuItemOption>
            )}
          </MenuOptionGroup>
        </MenuList>
      </>
    ),
    [color, role, renderButton, setValue, renderOption, inProject, t],
  );
  return <Menu {...props}>{renderMenu}</Menu>;
};

export default memo(RoleSelectMenu);
