import React, { memo, useEffect, useMemo } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import useIsUserOnTeam from '../hooks/useIsUserOnTeam';
import Settings from '../pages/Settings';
import { useUserContext } from '../store/contexts/UserContext';
import DashboardNavigator from './DashboardNavigator';
import routes from './routes';

const MainNavigator: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useUserContext();

  const { isUserOnTeam, loading } = useIsUserOnTeam();

  const alreadyOnPersonalization = useMatch(
    `${routes.ModalRoute.Personalization}/*`,
  );
  const location = useLocation();

  useEffect(() => {
    if (
      (!loading &&
        !isUserOnTeam &&
        !alreadyOnPersonalization &&
        !location.pathname.endsWith(routes.ModalRoute.DeleteTeamSuccessful)) ||
      !user?.personalization_passed
    ) {
      if (location.pathname === '/') {
        navigate(routes.ModalRoute.Personalization);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboardPath = routes.navigator(routes.MainRoute.Dashboard);

  const DashboardElement = useMemo(() => <DashboardNavigator />, []);

  const settingsPath = routes.navigator(routes.MainRoute.Settings);

  const SettingsElement = useMemo(() => <Settings />, []);

  return (
    <>
      <Routes>
        <Route element={DashboardElement} path={dashboardPath} />
        <Route element={SettingsElement} path={settingsPath} />
      </Routes>
    </>
  );
};

export default memo(MainNavigator);
