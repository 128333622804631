import { AnimatePresence } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import { Route, Routes, useResolvedPath } from 'react-router-dom';
import CenterDialogModal from '../components/CenterDialogModal';
import CenterModal from '../components/CenterModal';
import ChangeProjectStatus from '../components/ChangeProjectStatus';
import CreateProjectErrorModal from '../components/CreateProjectErrorModal';
import RouteModal from '../components/RouteModal';
import Sidebar from '../components/Sidebar';
import ThinSidebar from '../components/ThinSidebar';
import ARDetails from '../pages/ARDetails';
import CreateAR from '../pages/CreateAR';
import CreateTeam from '../pages/CreateTeam';
import DeleteTeamSuccessful from '../pages/DeleteTeamSuccessful';
import EditAR from '../pages/EditAR';
import Notifications from '../pages/Notifications';
import routes from './routes';

export type RouteModalComponent = React.FC<{
  onClose: () => void;
}>;

export type ModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

export type RouteModalProps = {
  route: string;
  Component: RouteModalComponent;
  Modal: React.FC<ModalProps>;
};

export const generalModals: RouteModalProps[] = [
  {
    Component: CreateTeam,
    Modal: CenterModal,
    route: routes.ModalRoute.Personalization,
  },
  {
    Component: CreateAR,
    Modal: Sidebar,
    route: routes.ARRoutes.ARCreate,
  },
  {
    Component: EditAR,
    Modal: Sidebar,
    route: routes.ARRoutes.AREdit,
  },
  {
    Component: ARDetails,
    Modal: Sidebar,
    route: routes.ARRoutes.ARDetails,
  },
  {
    Component: Notifications,
    Modal: ThinSidebar,
    route: routes.ModalRoute.Notifications,
  },
  {
    Component: DeleteTeamSuccessful,
    Modal: CenterDialogModal,
    route: routes.ModalRoute.DeleteTeamSuccessful,
  },
  {
    Component: CreateProjectErrorModal,
    Modal: CenterModal,
    route: routes.ARRoutes.CreateProjectError,
  },
  {
    Component: ChangeProjectStatus,
    Modal: CenterDialogModal,
    route: routes.ARRoutes.ChangeProjectStatus,
  },
];

export const archiveModals: RouteModalProps[] = [
  {
    Component: ARDetails,
    Modal: Sidebar,
    route: routes.ARRoutes.ARDetails,
  },
  {
    Component: EditAR,
    Modal: Sidebar,
    route: routes.ARRoutes.AREdit,
  },
];

interface ModalsProps {
  modals: RouteModalProps[];
  hideLocation?: boolean;
}

const Modals: React.FC<ModalsProps> = ({ modals }) => {
  const prefix = useResolvedPath('').pathname;

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes>
        {modals.map((modal) => (
          <Route
            key={modal.route}
            element={
              <RouteModal
                {...modal}
                key={modal.route}
                route={`${prefix}/${modal.route}`}
              />
            }
            path={routes.navigator(`/${modal.route}`)}
          />
        ))}
      </Routes>
    </AnimatePresence>
  );
};

export default React.memo(Modals);
