import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Project_Status_Enum, UserFragment } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { DashboardValues } from '../utils/formValidations';
import {
  PersistedVariables,
  persistVariable,
} from '../utils/persistedVariable';
import { AutocompleteItemType } from './AutocompleteItem';
import AutocompleteSelect from './AutocompleteSelect';
import Avatar from './Avatar';
import SearchIcon from './icons/SearchIcon';
import Select from './Select';

interface Props extends StackProps {
  drivers?: UserFragment[];
}

const SEARCH_ICON = <SearchIcon />;

const ARListFilters: React.FC<Props> = ({ drivers, ...props }) => {
  const { t } = useTranslation([LocalesKeys.Dashboard, LocalesKeys.ARDetails]);

  const {
    handleChange: onFilterChange,
    setFieldValue,
    getFieldProps,
    getFieldMeta,
    values: { driver, pageSize },
  } = useFormikContext<DashboardValues>();

  useEffect(() => {
    persistVariable(PersistedVariables.PageSize, pageSize);
  }, [pageSize]);

  const renderDriverOption = useCallback<
    (item: AutocompleteItemType<UserFragment>) => JSX.Element
  >(
    ({ id, email, image, username }) => (
      <HStack key={id}>
        <Avatar
          name={username ?? email}
          size="sm"
          src={image?.url ?? undefined}
          userId={id}
        />
        <Text color="black.500">{email}</Text>
      </HStack>
    ),
    [],
  );

  const driversAutocompleteData: AutocompleteItemType<UserFragment>[] = useMemo(
    () =>
      R.map((driver) => R.assoc('value', driver.email, driver), drivers ?? []),
    [drivers],
  );

  const setDriverValue = useCallback(
    (value: string) => setFieldValue('driver', value),
    [setFieldValue],
  );

  const renderStatusOption = useCallback<
    (item: Project_Status_Enum) => JSX.Element
  >(
    (item) => (
      <option key={item} value={item}>
        {t(item, {
          ns: LocalesKeys.ARDetails,
        })}
      </option>
    ),
    [t],
  );

  const statusOptions = useMemo(
    () =>
      R.map(renderStatusOption)(
        R.without(
          [Project_Status_Enum.Cancelled, Project_Status_Enum.Draft],
          R.values(Project_Status_Enum),
        ),
      ),
    [renderStatusOption],
  );

  return (
    <HStack justify="space-between" w="full" {...props}>
      <InputGroup bgColor="white">
        <InputLeftElement>{SEARCH_ICON}</InputLeftElement>
        <Input
          h={12}
          id="name"
          name="name"
          onChange={onFilterChange}
          placeholder="Search"
        />
      </InputGroup>
      <AutocompleteSelect
        data={driversAutocompleteData}
        label=""
        placeholder={t('allDrivers')}
        renderItemContent={renderDriverOption}
        setFieldValue={setDriverValue}
        {...getFieldProps('driver')}
        {...getFieldMeta('driver')}
        value={driver ?? ''}
      />
      <Select
        id="status"
        name="status"
        onChange={onFilterChange}
        placeholder={t('allProjectsStatus')}
      >
        {statusOptions}
      </Select>
    </HStack>
  );
};

export default memo(ARListFilters);
