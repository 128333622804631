import { Button, Text, VStack } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvoiceStatus,
  Maybe,
  useInvoiceWithPdfLazyQuery,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { useTeamContext } from '../store/contexts/TeamContext';

interface Props {
  invoiceId?: Maybe<string>;
  value: InvoiceStatus;
}

const BillingTableStatusCell: React.FC<Props> = ({ invoiceId, value }) => {
  const { t } = useTranslation(LocalesKeys.Billing);

  const [getInvoice] = useInvoiceWithPdfLazyQuery();

  const { team } = useTeamContext();

  const teamId = team?.id ?? '';

  const customerId = team?.customer_id ?? '';

  const onOpenInvoicePDF = useCallback(async () => {
    const invoice = await getInvoice({
      variables: {
        customer_id: customerId,
        invoice_id: invoiceId || '',
        team_id: teamId,
      },
    });

    if (!invoice.data?.get_invoice_pdf.invoice_pdf) {
      return null;
    }

    return window.open(invoice.data.get_invoice_pdf.invoice_pdf);
  }, [customerId, getInvoice, invoiceId, teamId]);

  return (
    <VStack align="flex-start" spacing={0}>
      <Text size="body2">{t(value)}</Text>
      <Button onClick={onOpenInvoicePDF} variant="unstyled">
        <Text color="red" size="caption2">
          {t('viewInvoice')}
        </Text>
      </Button>
    </VStack>
  );
};

export default memo(BillingTableStatusCell);
