import moment from 'moment';
import { find, includes } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { cookieStorageDomain, navigationType } from '../env';
import {
  AvailableTeamFragment,
  useAvailableTeamsQuery,
  useAvailable_TeamsSubscription,
} from '../generated/graphql';
import routes from '../navigation/routes';
import { useAuthContext } from '../store/contexts/AuthContext';
import {
  generateUrlWithSubdomain,
  getTeamBySubdomain,
  getTeamByUrl,
} from '../utils/navigation';

export enum TeamNavigation {
  Path = 'path',
  Subdomain = 'subdomain',
}

export type TeamNavigationDetails = {
  domain: string;
  type: TeamNavigation;
};

export const useTeamNavigation = () => {
  const {
    data: availableTeamsData,
    updateQuery,
    loading: queryLoading,
  } = useAvailableTeamsQuery();

  const availableTeams = useMemo(
    () =>
      availableTeamsData?.team
        ? [...availableTeamsData.team].sort((prevTeam, nextTeam) =>
            moment(nextTeam.created_at).diff(prevTeam.created_at),
          )
        : [],
    [availableTeamsData?.team],
  );

  const { loading: subscriptionLoading } = useAvailable_TeamsSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      updateQuery((prev) => ({
        // eslint-disable-next-line no-underscore-dangle
        __typename: prev.__typename,
        team: subscriptionData.data?.team ?? prev.team,
      }));
    },
  });

  const location = useLocation();

  const { cognitoUser } = useAuthContext();

  const navigationDetails: TeamNavigationDetails = useMemo(() => {
    const teamBySubdomain = find(
      (team) => team.domain === getTeamBySubdomain(),
      availableTeams,
    );

    if (teamBySubdomain && cognitoUser?.userInfo?.isValid) {
      return {
        domain: teamBySubdomain.domain,
        type: TeamNavigation.Subdomain,
      };
    }

    return {
      domain: cognitoUser?.userInfo?.isValid
        ? find((team) => team.domain === getTeamByUrl(location), availableTeams)
            ?.domain ??
          availableTeams[0]?.domain ??
          ''
        : '',
      type: TeamNavigation.Path,
    };
  }, [availableTeams, cognitoUser, location]);

  const rootPath = `/${
    navigationDetails.type === TeamNavigation.Path &&
    !location.pathname.includes(routes.ModalRoute.Personalization)
      ? navigationDetails.domain
      : ''
  }`;

  const generateUrl = useCallback((teamDomain?: string) => {
    const existingSubdomain = window.location.hostname.replace(
      cookieStorageDomain ?? '',
      '',
    );

    return generateUrlWithSubdomain(!!existingSubdomain, teamDomain);
  }, []);

  const changeTeam = useCallback<
    (team: AvailableTeamFragment, nextPath?: string) => void
  >(
    (team, nextPath = '') => {
      const teamAlreadyPicked = includes(
        nextPath?.split('/')[1],
        availableTeams.map((team) => team.domain),
      );
      const path = teamAlreadyPicked
        ? nextPath.substring(nextPath.substring(1).indexOf('/') + 1)
        : nextPath;

      const pathOnly =
        navigationDetails.type === TeamNavigation.Path &&
        navigationType === 'path-only';

      window.location.replace(
        `${generateUrl(pathOnly ? undefined : team.domain)}${
          pathOnly ? `/${team.domain}` : ''
        }${path.startsWith('/') ? path : `/${path}`}`,
      );
    },
    [availableTeams, generateUrl, navigationDetails.type],
  );

  const loading = queryLoading || (!availableTeamsData && subscriptionLoading);

  return {
    availableTeams: cognitoUser?.userInfo?.isValid ? availableTeams : [],
    changeTeam,
    generateUrl,
    loading,
    navigationDetails,
    rootPath,
  };
};
