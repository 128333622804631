import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const DoneIcon: React.FC<IconProps> = (props) => (
  <Icon
    fill="none"
    h={6}
    viewBox="0 0 24 24"
    w={6}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4688 6L9.46875 17L4.46875 12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
  </Icon>
);

export default memo(DoneIcon);
