import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { LinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Heading,
  Text,
  Stack as VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { compose, filter, find, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { FieldInputProps, useFormikContext } from 'formik';
import { LocalesKeys } from '../locales/localesKeys';
import DateInput from './DateInput';
import ChevronUpIcon from './icons/ChevronUpIcon';
import TextInput from './TextInput';
import Autocomplete from './Autocomplete';
import { Project_User_Role_Enum } from '../generated/graphql';
import useUserEmail from '../hooks/useUserEmail';
import { AutocompleteItemType } from './AutocompleteItem';
import { InvitedMember } from './RoleSelectTable';
import { filterByEmail } from './CreateARStep2';
import { CreateARStep1Values } from '../utils/formValidations';
import { useDisplayName } from '../hooks/useDisplayName';
import Avatar from './Avatar';
import { useTeamContext } from '../store/contexts/TeamContext';
import SendingReminders from './SendingReminders';

const LINK_ICON = <LinkIcon />;

const INPUT_BOX_PROPS = {
  width: '100%',
};

type Props = {
  editMode?: boolean;
};

const CreateARStep1: React.FC<Props> = ({ editMode }) => {
  const { t } = useTranslation(LocalesKeys.CreateAR);

  const { isOpen: isAssetOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });

  const { getFieldProps, setFieldValue, getFieldMeta } =
    useFormikContext<CreateARStep1Values>();

  const email = useUserEmail();

  const { team } = useTeamContext();

  const renderItemContent = useCallback<
    (item: AutocompleteItemType<InvitedMember>) => JSX.Element
  >(({ value, image, username, email, id }) => {
    const imageUrl = image?.url ?? undefined;

    return (
      <Flex align="center">
        <Avatar h={10} mr={2} name={value} src={imageUrl} userId={id} w={10} />
        <Flex direction="column">
          <Text color="black.500" size="body1">
            {username}
          </Text>
          <Text color="black.400" size="body2">
            {email}
          </Text>
        </Flex>
      </Flex>
    );
  }, []);

  const getDisplayName = useDisplayName();

  const invitesProps: FieldInputProps<CreateARStep1Values['invites']> = useMemo(
    () => getFieldProps('invites'),
    [getFieldProps],
  );

  const invitesWithoutDriver = useMemo(
    () =>
      filter(
        (value) => value.role !== Project_User_Role_Enum.Driver,
        invitesProps.value,
      ),
    [invitesProps.value],
  );

  const currentDriver = useMemo(() => {
    const invite = find(
      (value) => value.role === Project_User_Role_Enum.Driver,
      invitesProps.value,
    );
    if (!invite) {
      return undefined;
    }
    return find(
      (user) => user.user.email === invite.email,
      team?.team_users ?? [],
    )?.user;
  }, [invitesProps.value, team?.team_users]);

  const setDriver = useCallback<(value: string) => void>(
    (value) => {
      const driver = team?.team_users.find(
        compose(filterByEmail(value), prop('user')),
      )?.user;
      if (!driver) {
        return;
      }
      setFieldValue('driver_name', getDisplayName(driver));
      setFieldValue('invites', [
        ...filter(
          (invite) => invite.email !== driver.email,
          invitesWithoutDriver,
        ),
        {
          email: driver.email,
          id: driver.id,
          role: Project_User_Role_Enum.Driver,
        },
      ]);
    },
    [getDisplayName, invitesWithoutDriver, setFieldValue, team?.team_users],
  );

  const driverNameProps: FieldInputProps<CreateARStep1Values['driver_name']> =
    useMemo(() => getFieldProps('driver_name'), [getFieldProps]);

  useEffect(() => {
    if (!currentDriver) {
      return;
    }
    if (getDisplayName(currentDriver) !== driverNameProps.value) {
      setFieldValue('invites', invitesWithoutDriver);
    }
  }, [
    currentDriver,
    driverNameProps.value,
    getDisplayName,
    invitesWithoutDriver,
    setFieldValue,
  ]);

  const isCreatedByMe = currentDriver?.email === email;

  const driverNameAutocompleteData = useMemo(
    () =>
      team?.team_users.map((member) => ({
        ...member.user,
        value: member.user.email,
      })),
    [team?.team_users],
  );

  const setDeadlineValue = useCallback(
    (value: Date) => setFieldValue('deadline', value),
    [setFieldValue],
  );

  const AutocompleteRightElement = useMemo(
    () => (
      <Text
        display={currentDriver?.email === email ? 'unset' : 'none'}
        mr={8}
        pointerEvents="none"
      >
        {t('you')}
      </Text>
    ),
    [currentDriver?.email, email, t],
  );

  const ARDetails = useMemo(
    () => (
      <>
        <Heading color="black.500" pb={4} pt={6} size="headline3">
          {t('detailsHeading')}
        </Heading>
        <TextInput
          {...getFieldMeta('name')}
          {...getFieldProps('name')}
          boxProps={INPUT_BOX_PROPS}
          label={t('nameLabel')}
          placeholder={t('namePlaceholder')}
        />
        <HStack align="flex-start" py={6} spacing={6} w="100%">
          <DateInput
            {...getFieldMeta('deadline')}
            {...getFieldProps('deadline')}
            boxProps={INPUT_BOX_PROPS}
            label={t('deadlineLabel')}
            setDateValue={setDeadlineValue}
          />
          <TextInput
            {...getFieldMeta('link')}
            boxProps={INPUT_BOX_PROPS}
            label={t('linkLabel')}
            placeholder={t('linkPlaceholder')}
            {...getFieldProps('link')}
            isDisabled={editMode}
          />
        </HStack>
        <Autocomplete
          {...getFieldMeta('driver_name')}
          {...driverNameProps}
          boxProps={INPUT_BOX_PROPS}
          data={driverNameAutocompleteData ?? []}
          helpingText={isCreatedByMe ? undefined : t('driverNameHelpingText')}
          label={t('driverNameLabel')}
          renderItemContent={renderItemContent}
          rightElement={AutocompleteRightElement}
          setFieldValue={setDriver}
          spellCheck={false}
        />
      </>
    ),
    [
      driverNameAutocompleteData,
      driverNameProps,
      AutocompleteRightElement,
      editMode,
      getFieldMeta,
      getFieldProps,
      isCreatedByMe,
      renderItemContent,
      setDeadlineValue,
      setDriver,
      t,
    ],
  );

  const ARAssets = useMemo(
    () => (
      <>
        <Flex
          align="center"
          cursor="pointer"
          justify="space-between"
          onClick={onToggle}
          pt={11}
          w="full"
        >
          <Heading color="black.500" size="headline3">
            {t('externalHeading')}
          </Heading>
          <ChevronUpIcon
            transform={`rotate(${isAssetOpen ? '0' : '180deg'})`}
            transitionDuration="fast"
            transitionProperty="transform"
          />
        </Flex>
        <Box>
          <VStack
            maxH={isAssetOpen ? 500 : 0}
            mx={-1}
            overflow="hidden"
            pb={isAssetOpen ? 10 : 0}
            pt={isAssetOpen ? 5 : 0}
            px={1}
            transition="all 0.5s ease-in-out 0s"
          >
            <Text pb={6} size="body1">
              {t('externalDescription')}
            </Text>
            <TextInput
              {...getFieldMeta('external_name')}
              {...getFieldProps('external_name')}
              boxProps={INPUT_BOX_PROPS}
              label={t('externalNameLabel')}
            />
            <TextInput
              {...getFieldMeta('external_link')}
              {...getFieldProps('external_link')}
              boxProps={INPUT_BOX_PROPS}
              label={t('externalLinkLabel')}
              rightElement={LINK_ICON}
            />
          </VStack>
        </Box>
      </>
    ),
    [getFieldMeta, getFieldProps, isAssetOpen, onToggle, t],
  );

  return (
    <>
      {ARDetails}
      {ARAssets}
      <SendingReminders />
    </>
  );
};

export default memo(CreateARStep1);
