export default {
  allDrivers: 'All drivers',
  allProjectsStatus: 'All AR statuses',
  approvalStatus: 'Approval status',
  archivedProjects: 'Archive of Approval Requests',
  createProject: 'Create AR',
  dashboard: 'Dashboard',
  deadline: 'Deadline',
  draft: 'Draft (Private)',
  driver: 'Driver',
  editProject: 'Edit AR',
  itemsCount: '{{ minIndex }}-{{ maxIndex }} of {{ count }} items',
  myRole: 'My role',
  projectName: 'AR name',
  projectStatus: 'AR status',
  rowsPerPage: 'Rows per page:',
};
