import { spacing } from './spacing';

const largeSizes = {
  '2xl': '42rem',
  '2xs': '16rem',
  '3xl': '48rem',
  '3xs': '14rem',
  '4xl': '56rem',
  '4xs': '12rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  full: '100%',
  lg: '32rem',
  lgp: '33rem',
  max: 'max-content',
  md: '28rem',
  min: 'min-content',
  sm: '24rem',
  smp: '25rem',
  xl: '36rem',
  xs: '20rem',
};

const container = {
  lg: '1024px',
  md: '768px',
  sm: '640px',
  xl: '1280px',
};

const custom = {
  logoH: '32px',
  logoW: '165px',
  searchLogoH: '79px',
  viewportH: '100vh',
  viewportW: '100vw',
};

const sizes = {
  ...spacing,
  ...largeSizes,
  ...custom,
  container,
};

/**
 * @deprecated
 * You can derive the Sizes type from the DefaultChakraTheme
 *
 * type Sizes = DefaultChakraTheme['sizes']
 */
export type Sizes = typeof spacing &
  typeof largeSizes & { container: typeof container };

export default sizes;
