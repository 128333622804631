export default {
  amount: 'Invoice total',
  billingMethod: 'Billing method',
  billingMethodModalTitle: 'Enter credit or debit card',
  cancel: 'Cancel',
  change: 'Change',
  changeContact: 'Change contact',
  changeMethod: 'Use this card',
  cityPlaceholder: 'City',
  contactModalTitle: 'Change contact for invoice',
  customerNotSetBody:
    'If you want to have Licensed permission of OhCompletely, please fulfill the billing method information.',
  date: 'Date',
  description: 'Description',
  draft: 'Rejected',
  email: 'Email',
  invoices: 'Invoices',
  invoicesEmptyState: 'Here your invoices history will be saved.',
  invoiceTotal: 'Invoice total',
  last4: '*{{last4}}',
  line1Placeholder: 'Street address',
  line2Placeholder: 'Apt, unit, suite, ets. (optional)',
  nextInvoiceDate: 'Next invoice issue date',
  nextInvoiceError:
    "It seems like we can't get information on your next invoice. Make sure you've paid the previous one",
  paid: 'Paid',
  postalPlaceholder: 'Zip code',
  pricingContact: 'Contact for new pricing',
  pricingUpdateBanner:
    'Your team already has over 20 members. We have comfortable pricing conditions for enterprises, contact us to find out an offer.',
  selectCountry: 'Country',
  selectState: 'State',
  sentTo: 'Invoices sent to',
  setBillingMethod: 'Fulfill billing details',
  status: 'Status',
  teamSeats: 'Total team seats',
  tooltipCaption:
    'When you upgrade a reviewer status from Basic to Licenced it costs 25 usd/month. After upgrading you will be charged immediately for the next period on the amount that will be calculated considering prorating based on the quantity of days left to your monthly invoice. All subsequent monthly chargings for the Licenced reviewer will be done automatically in general invoice at the end of the period.',
  tooltipHeading: 'Invoice total',
  viewInvoice: 'View invoice',
};
