import React, { SVGProps, memo } from 'react';

const ARDetailsEditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={22} width={22} {...props}>
    <path
      clipRule="evenodd"
      d="M17.566.435a.8.8 0 0 0-1.131 0l-10 10A.8.8 0 0 0 6.2 11v4a.8.8 0 0 0 .8.8h4a.8.8 0 0 0 .566-.234l10-10a.8.8 0 0 0 0-1.131l-4-4ZM7.8 14.2v-2.868l9.2-9.2L19.87 5l-9.2 9.2H7.8ZM2.152 4.152A1.2 1.2 0 0 1 3 3.8h5.34a.8.8 0 0 0 0-1.6H3A2.8 2.8 0 0 0 .2 5v14A2.8 2.8 0 0 0 3 21.8h14a2.8 2.8 0 0 0 2.8-2.8v-5.34a.8.8 0 0 0-1.6 0V19a1.2 1.2 0 0 1-1.2 1.2H3A1.2 1.2 0 0 1 1.8 19V5a1.2 1.2 0 0 1 .352-.848Z"
      fill="#454649"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(ARDetailsEditIcon);