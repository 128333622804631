import React, { memo, useCallback, useMemo } from 'react';
import { BoxProps, Button, Flex, HStack, Link, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LogoIcon from './icons/LogoIcon';
import { LocalesKeys } from '../locales/localesKeys';
import NotificationButton from './NotificationButton';
import AccountMenu, { AccountMenuOption } from './AccountMenu';
import useAuth from '../hooks/useAppAuth';
import SettingsIcon from './icons/SettingsIcon';
import ClockIcon from './icons/ClockIcon';
import LogoutIcon from './icons/LogoutIcon';
import routes from '../navigation/routes';
import useUserEmail from '../hooks/useUserEmail';
import ChromeIcon from './icons/ChromeIcon';
import { useUserContext } from '../store/contexts/UserContext';
import Avatar from './Avatar';
import { useUncheckedNotifications } from '../hooks/useUncheckedNotifications';
import TeamMenu from './TeamMenu';
import { SettingsNavigationKeys } from '../navigation/settings';

interface Props extends BoxProps {
  nested?: boolean;
}

const CHROME_ICON = <ChromeIcon />;

const NavigationHeader: React.FC<Props> = ({ nested, ...props }) => {
  const { logout } = useAuth();
  const { t } = useTranslation(LocalesKeys.Header);
  const navigate = useNavigate();

  const email = useUserEmail();
  const { user, cognitoUser } = useUserContext();

  const username = user?.username ?? email;
  const imageUrl = user?.image?.url ?? undefined;
  const userId = user?.id;
  const isAuthorized = cognitoUser?.isValid;

  const { count } = useUncheckedNotifications();
  const openNotifications = useCallback(
    () => navigate(routes.ModalRoute.Notifications),
    [navigate],
  );

  const openRemindersAndNotifications = useCallback(
    () =>
      navigate(
        `${routes.MainRoute.Settings}/${SettingsNavigationKeys.Notifications}`,
      ),
    [navigate],
  );

  const options = useMemo<AccountMenuOption[]>(
    () => [
      {
        icon: (
          <Avatar name={username} size="xs" src={imageUrl} userId={userId} />
        ),
        title: email,
        truncate: true,
      },
      {
        icon: <SettingsIcon />,
        onClick: () => navigate(routes.MainRoute.Settings),
        title: t('settings'),
      },
      {
        divider: true,
        icon: <ClockIcon />,
        onClick: openRemindersAndNotifications,
        title: t('reminderAndNotifications'),
      },
      { icon: <LogoutIcon />, onClick: logout, title: t('logout') },
    ],
    [
      email,
      imageUrl,
      logout,
      navigate,
      openRemindersAndNotifications,
      t,
      userId,
      username,
    ],
  );

  const AuthorizedContent = useMemo(
    () =>
      !nested && isAuthorized ? (
        <HStack spacing={7}>
          <Button colorScheme="secondary" leftIcon={CHROME_ICON} px={8}>
            {t('extension')}
          </Button>
          <NotificationButton
            aria-label={t('notifications')}
            count={count}
            onClick={openNotifications}
          />
          <AccountMenu
            image={imageUrl}
            options={options}
            userId={userId}
            username={username}
          />
        </HStack>
      ) : null,
    [
      count,
      isAuthorized,
      imageUrl,
      nested,
      openNotifications,
      options,
      t,
      userId,
      username,
    ],
  );

  return (
    <Flex
      align="center"
      bgColor="gray.bg"
      borderBottom="1px"
      borderBottomColor="black.200"
      justify={nested ? 'center' : undefined}
      px={10}
      py={5}
      {...props}
    >
      {nested && isAuthorized ? (
        <TeamMenu left={0} pl={20} position="absolute" />
      ) : null}
      <Link href={window.location.origin}>
        <LogoIcon />
      </Link>
      {!nested && isAuthorized ? <TeamMenu /> : null}
      {!nested ? <Spacer /> : null}
      {AuthorizedContent}
    </Flex>
  );
};

export default memo(NavigationHeader);
