import React, { memo, useMemo } from 'react';
import {
  Badge,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Text,
} from '@chakra-ui/react';
import NotificationIcon from './icons/NotificationIcon';
import { IconClass } from '../theme/utils';

interface Props extends IconButtonProps {
  boxProps?: BoxProps;
  count?: number;
}

const BADGE_BOTTOM_OFFSET = '50%';
const BADGE_LEFT_OFFSET = '30%';

const NotificationButton: React.FC<Props> = ({ boxProps, count, ...props }) => {
  const NotificationBadge = useMemo(
    () =>
      count ? (
        <Badge
          bottom={BADGE_BOTTOM_OFFSET}
          colorScheme="primary"
          left={BADGE_LEFT_OFFSET}
          position="absolute"
          size="xs"
          variant="solid"
        >
          <Text size="caption1">{count}</Text>
        </Badge>
      ) : null,
    [count],
  );

  const Icon = useMemo(
    () => (
      <Box position="relative" {...boxProps}>
        <NotificationIcon className={IconClass.CustomStroke} />
        {NotificationBadge}
      </Box>
    ),
    [NotificationBadge, boxProps],
  );

  return <IconButton icon={Icon} size="none" variant="unstyled" {...props} />;
};

export default memo(NotificationButton);
