import * as R from 'ramda';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProjectDashboardDocument,
  ProjectDashboardFragment,
  ProjectDashboardQuery,
  ProjectDashboardQueryVariables,
  Project_User_Display_Status_Enum,
  useProjectUserQuery,
  useUpdateProjectUserDisplayStatusMutation,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import { getProjectDashboardQueryVariables } from '../utils/getProjectDashboardQueryVariables';
import { useCustomToast } from './useCustomToast';
import useUpdateCache from './useUpdateCache';

export const useProjectUserDisplayStatus = (
  id: string,
  project?: ProjectDashboardFragment,
) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const { user } = useUserContext();

  const { warningToast } = useCustomToast();

  const { updateQuery } = useUpdateCache();

  const { team } = useTeamContext();

  const userId = user?.id;

  const { data: projectUserData } = useProjectUserQuery({
    variables: {
      where: {
        project_id: { _eq: id },
        user_id: { _eq: userId },
      },
    },
  });

  const isArchived = useMemo(
    () =>
      projectUserData?.project_user[0]?.display_status ===
      Project_User_Display_Status_Enum.Archive,
    [projectUserData?.project_user],
  );

  const [switchProjectDisplayStatus] =
    useUpdateProjectUserDisplayStatusMutation({
      variables: {
        value: isArchived
          ? Project_User_Display_Status_Enum.Dashboard
          : Project_User_Display_Status_Enum.Archive,
        where: {
          project_id: { _eq: id },
          user_id: { _eq: userId },
        },
      },
    });

  const onSwitchProjectDisplayStatus = useCallback(async () => {
    await switchProjectDisplayStatus();

    const projectQueryVariables = getProjectDashboardQueryVariables({
      displayStatus: isArchived
        ? Project_User_Display_Status_Enum.Dashboard
        : Project_User_Display_Status_Enum.Archive,
      teamId: team?.id,
    });

    updateQuery<ProjectDashboardQuery, ProjectDashboardQueryVariables>(
      {
        query: ProjectDashboardDocument,
        variables: projectQueryVariables,
      },
      R.over(R.lensProp('project_dashboard'), R.append(project)),
    );

    if (!isArchived) {
      warningToast({
        headerMessage: t('cancelAR'),
        toastMessage: t('archiveToastMessage'),
      });
    }
  }, [
    isArchived,
    project,
    switchProjectDisplayStatus,
    t,
    team?.id,
    updateQuery,
    warningToast,
  ]);

  return {
    isArchived,
    onSwitchProjectDisplayStatus,
  };
};
