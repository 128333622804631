import { CognitoUser } from 'amazon-cognito-identity-js';
import { ApolloError } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { HasuraError, HasuraInternalError } from './hasura';
import { ProviderIdentity } from '../types/cognito';

export function isCognitoUser(item: CognitoUser | any): item is CognitoUser {
  return !!(item as CognitoUser)?.getSignInUserSession;
}

export function isApolloError(
  item: ApolloError | unknown,
): item is ApolloError {
  return !!(item as ApolloError).graphQLErrors;
}

export function isHasuraInternalError(
  item: HasuraInternalError | unknown,
): item is HasuraInternalError {
  return !!(item as Maybe<HasuraInternalError>)?.error.message;
}

export function isHasuraError(
  item: HasuraError | unknown,
): item is HasuraError {
  return !!(item as Maybe<HasuraError>)?.code;
}

export function isProviderIdentities(
  item: ProviderIdentity[] | unknown,
): item is ProviderIdentity[] {
  return !!(item as ProviderIdentity[])[0]?.providerName;
}
