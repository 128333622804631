import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const CreateProjectIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M2.2 6a.8.8 0 0 1 .8-.8h18a.8.8 0 0 1 0 1.6H3a.8.8 0 0 1-.8-.8Zm.8 5.2a.8.8 0 1 0 0 1.6h9a.8.8 0 0 0 0-1.6H3Zm18.615 2.312a.8.8 0 0 0-1.23-1.024l-4.314 5.177-1.405-2.109a.8.8 0 0 0-1.332.888l2 3a.8.8 0 0 0 1.28.068l5-6ZM3 17.2a.8.8 0 1 0 0 1.6h5a.8.8 0 0 0 0-1.6H3Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(CreateProjectIcon);
