import React, { memo, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import routes from './routes';
import LoginVerification from '../pages/LoginVerification';
import LoginCredentials from '../pages/LoginCredentials';
import EmailGuard from './EmailGuard';
import NavigationHeader from '../components/NavigationHeader';

const LoginNavigator: React.FC = () => {
  const Verification = useMemo(() => <LoginVerification />, []);

  const VerificationElement = useMemo(
    () => <EmailGuard>{Verification}</EmailGuard>,
    [Verification],
  );

  const IndexElement = useMemo(() => <LoginCredentials />, []);

  return (
    <Flex flexDirection="column" h="full">
      <NavigationHeader />
      <Flex align="center" flex={1} justify="center" p={4}>
        <Routes>
          <Route
            element={VerificationElement}
            path={routes.AuthRoute.Verification}
          />
          <Route element={IndexElement} index />
        </Routes>
      </Flex>
    </Flex>
  );
};

export default memo(LoginNavigator);
