import React, { memo, useCallback } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { RenderMenuContent } from './types';
import { AvailableTeamFragment } from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import routes from '../navigation/routes';
import PlusIcon from './icons/PlusIcon';
import { IconClass } from '../theme/utils';
import { LocalesKeys } from '../locales/localesKeys';
import TeamMenuItem from './TeamMenuItem';

export interface AccountMenuOption {
  divider?: boolean;
  icon?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  truncate?: boolean;
}

const CHEVRON_UP_ICON = <ChevronUpIcon className={IconClass.CustomStroke} />;
const CHEVRON_DOWN_ICON = (
  <ChevronDownIcon className={IconClass.CustomStroke} />
);

const PLUS_ICON = <PlusIcon />;

const TeamMenu: React.FC<BoxProps> = (props) => {
  const { changeTeam, availableTeams, team, generateUrl } = useTeamContext();

  const { t } = useTranslation(LocalesKeys.TeamMenu);

  const navigate = useNavigate();

  const switchTeam = useCallback(
    (pickedTeam: AvailableTeamFragment) => {
      if (pickedTeam.id !== team?.id) {
        changeTeam(pickedTeam);
      }
    },
    [changeTeam, team?.id],
  );

  const createTeam = useCallback(
    () =>
      !team?.id
        ? navigate(`/${routes.ModalRoute.Personalization}`)
        : window.location.replace(
            `${generateUrl()}/${routes.ModalRoute.Personalization}`,
          ),
    [generateUrl, navigate, team?.id],
  );

  const renderOption = useCallback<
    (option: AvailableTeamFragment) => JSX.Element
  >(
    (teamOption) => (
      <TeamMenuItem
        key={teamOption.id}
        switchTeam={switchTeam}
        teamOption={teamOption}
      />
    ),
    [switchTeam],
  );

  const renderButtonAndOptions = useCallback<RenderMenuContent>(
    ({ isOpen }) => (
      <>
        <MenuButton
          as={Button}
          color="black"
          colorScheme="secondary"
          pl={6}
          rightIcon={isOpen ? CHEVRON_DOWN_ICON : CHEVRON_UP_ICON}
          variant="solid"
          w="fit-content"
        >
          {team?.name}
        </MenuButton>
        <MenuList maxHeight={60} overflow="overlay" py={0}>
          <MenuItem
            as={Button}
            borderRadius={0}
            leftIcon={PLUS_ICON}
            onClick={createTeam}
            variant="ghost"
          >
            {t('addTeam')}
          </MenuItem>
          <MenuDivider m={0} />
          {availableTeams.map(renderOption)}
        </MenuList>
      </>
    ),
    [availableTeams, createTeam, renderOption, t, team?.name],
  );

  return (
    <Box {...props}>
      {team ? (
        <Menu autoSelect={false}>{renderButtonAndOptions}</Menu>
      ) : (
        <Button
          color="black.400"
          leftIcon={PLUS_ICON}
          onClick={createTeam}
          variant="ghost"
          w="fit-content"
        >
          {t('addTeam')}
        </Button>
      )}
    </Box>
  );
};

export default memo(TeamMenu);
