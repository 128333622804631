import React, { memo } from 'react';
import routes from './routes';
import useEmailParam from '../hooks/useEmailParam';
import BaseGuard, { BaseGuardProps } from './BaseGuard';

interface Props extends BaseGuardProps {}

const EmailGuard: React.FC<Props> = (props) => {
  const [email] = useEmailParam();

  const rule = !!email;

  return <BaseGuard rule={rule} to={routes.AuthRoute.SignUp} {...props} />;
};

export default memo(EmailGuard);
