import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { ProjectDashboardFragment } from '../generated/graphql';
import { useIsARDriver } from '../hooks/useIsARDriver';
import { useIsTeamOwner } from '../hooks/useIsTeamOwner';
import { useProjectUserDisplayStatus } from '../hooks/useProjectUserDisplayStatus';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import ARDetailsEditIcon from './icons/ARDetailsEditIcon';
import ARFileIcon from './icons/ARFileIcon';
import CancelIcon from './icons/CancelIcon';
import ChangeMyStatusIcon from './icons/ChangeMyStatusIcon';
import DeleteIcon from './icons/DeleteIcon';
import MenuDetailsIcon from './icons/MenuDetailsIcon';
import MoreIcon from './icons/MoreIcon';
import RestoreIcon from './icons/RestoreIcon';

type ProjectMenuItem = {
  icon: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  rule?: boolean;
  link?: string | null | undefined;
  isExternal?: boolean;
};

type Props = {
  id: string;
  link?: string | null;
  project: ProjectDashboardFragment;
};

const ProjectMenu: React.FC<Props> = ({ id, link, project }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const { t } = useTranslation(LocalesKeys.Dashboard);

  const { isArchived, onSwitchProjectDisplayStatus } =
    useProjectUserDisplayStatus(id, project);

  const isARDriver = useIsARDriver(id);

  const isTeamOwner = useIsTeamOwner();

  const stopPropagation = useCallback<
    (e: { stopPropagation: () => void }) => void
  >((e) => e.stopPropagation(), []);

  const switchProjectDisplayStatus = useCallback(async () => {
    await onSwitchProjectDisplayStatus();
  }, [onSwitchProjectDisplayStatus]);

  const menuItems: ProjectMenuItem[] = useMemo(
    () => [
      {
        icon: <MenuDetailsIcon />,
        link: generatePath(routes.ARRoutes.ARDetails, { id }),
        title: t('arDetails'),
      },
      {
        icon: <ChangeMyStatusIcon />,
        link: generatePath(routes.ARRoutes.ChangeProjectStatus, { id }),
        rule: !isArchived && !isARDriver,
        title: t('changeMyStatus'),
      },
      {
        icon: <ARFileIcon />,
        isExternal: true,
        link,
        rule: !!link,
        title: t('openARFile'),
      },
      {
        icon: <ARDetailsEditIcon />,
        link: generatePath(routes.ARRoutes.AREdit, { id }),
        rule: isARDriver || isTeamOwner,
        title: t('editProject'),
      },
      {
        icon: isArchived ? <RestoreIcon /> : <CancelIcon />,
        onClick: switchProjectDisplayStatus,
        title: t(isArchived ? 'restoreAR' : 'cancelAR'),
      },
      {
        icon: <DeleteIcon />,
        link: generatePath(
          `${routes.ARRoutes.ARDetails}/${routes.ARRoutes.ARDelete}`,
          { id },
        ),
        rule: isARDriver,
        title: t('deleteAR'),
      },
    ],
    [
      id,
      isARDriver,
      isArchived,
      isTeamOwner,
      link,
      switchProjectDisplayStatus,
      t,
    ],
  );

  const renderItem = useCallback<
    (option: ProjectMenuItem) => JSX.Element | undefined
  >(
    ({ icon: Icon, onClick, title, rule, link, isExternal }) => (
      <React.Fragment key={title}>
        {rule === false ? undefined : isExternal ? (
          <MenuItem
            as="a"
            href={link ?? undefined}
            onClick={onClick}
            px={4}
            py={3}
            target="_blank"
          >
            <HStack minW={0} spacing={2}>
              {Icon}
              <Text color="black.500">{title}</Text>
            </HStack>
          </MenuItem>
        ) : (
          <Link to={link || ''}>
            <MenuItem key={title} onClick={onClick} px={4} py={3}>
              <HStack minW={0} spacing={2}>
                {Icon}
                <Text color="black.500">{title}</Text>
              </HStack>
            </MenuItem>
          </Link>
        )}
      </React.Fragment>
    ),
    [],
  );

  const onButtonClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      stopPropagation(e);
      onToggle();
    },
    [onToggle, stopPropagation],
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <IconButton
        aria-label=""
        as="div"
        borderRadius="full"
        boxSize="fit-content"
        colorScheme="gray"
        icon={
          <MenuButton
            borderRadius="full"
            boxSize="fit-content"
            colorScheme="gray"
            minW="fit-content"
            onClick={onButtonClick}
            p={2}
            variant="ghost"
          >
            <MoreIcon />
          </MenuButton>
        }
        minW="fit-content"
        onClick={onToggle}
        p={2}
        variant="ghost"
      />
      <MenuList onClick={stopPropagation} overflow="hidden" p={0}>
        {menuItems.map(renderItem)}
      </MenuList>
    </Menu>
  );
};

export default memo(ProjectMenu);
