import { SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import React, { memo } from 'react';
import colors from '../theme/foundations/colors';
import sizes from '../theme/foundations/sizes';

interface Props {
  headline: string;
  isSearchIcon?: boolean;
  onPress?: () => void;
  buttonLabel?: string;
}

const EmptyState: React.FC<Props> = ({
  headline,
  isSearchIcon,
  onPress,
  buttonLabel,
}) => (
  <Flex alignItems="center" justifyContent="center" w="full">
    <Flex
      alignItems="center"
      border="2px dashed"
      borderColor={colors.primary[300]}
      flexDirection="column"
      justifyContent="center"
      marginY={150}
      paddingX={50}
      paddingY={25}
    >
      {isSearchIcon && (
        <Flex
          alignItems="center"
          backgroundColor={colors.primary[100]}
          borderRadius={50}
          h={sizes.searchLogoH}
          justifyContent="center"
          w={sizes.searchLogoH}
        >
          <SearchIcon />
        </Flex>
      )}
      <Text mt={isSearchIcon ? 10 : 0} textAlign="center" w="30vw">
        {headline}
      </Text>
      {onPress && (
        <Button inlineSize="fit-content" mt={25} onClick={onPress}>
          {buttonLabel}
        </Button>
      )}
    </Flex>
  </Flex>
);

export default memo(EmptyState);
