import React, { useCallback, useRef } from 'react';
import { Collapse, CollapseProps } from '@chakra-ui/react';

enum AnimationDefinition {
  ENTER = 'enter',
  EXIT = 'exit',
}

const ScrollableCollapse: React.FC<CollapseProps> = (props) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const onAnimationComplete = useCallback<(value: AnimationDefinition) => void>(
    (value) => {
      if (value === AnimationDefinition.EXIT) return;

      scrollableRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    [],
  );

  return (
    <Collapse
      ref={scrollableRef}
      {...props}
      onAnimationComplete={onAnimationComplete}
    />
  );
};

export default ScrollableCollapse;
