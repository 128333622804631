import { Flex, Text } from '@chakra-ui/react';
import React, { memo } from 'react';
import { useUserQuery } from '../generated/graphql';
import Avatar from './Avatar';

interface Props {
  imageUrl?: string;
  id?: string;
  email: string;
  userName?: string | null;
}

const RoleSelectTableUserCell: React.FC<Props> = ({
  imageUrl,
  id,
  email,
  userName,
}) => {
  const { data: userData } = useUserQuery({
    fetchPolicy: 'cache-first',
    skip: !!userName || !!id,
    variables: { where: { email: { _eq: email } } },
  });

  const user = userData?.user[0];

  const name = userName || user?.username || email;

  const userId = user?.id || id;

  const userImageUrl = user?.image?.url || undefined;

  return (
    <Flex align="center">
      <Avatar
        h={10}
        mr={2}
        name={name}
        src={imageUrl || userImageUrl}
        userId={userId || email}
        w={10}
      />
      <Text size="body2">{name}</Text>
    </Flex>
  );
};

export default memo(RoleSelectTableUserCell);
