import { useParams } from 'react-router-dom';
import { useDeleteTeamUserMutation } from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';

export const useRemoveFromTeam = () => {
  const { id } = useParams();
  const { team } = useTeamContext();

  const [deleteUser] = useDeleteTeamUserMutation({
    variables: { where: { team_id: { _eq: team?.id }, user_id: { _eq: id } } },
  });

  return {
    deleteUser,
  };
};
