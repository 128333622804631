import { useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce/esm';
import { useState } from 'react';
import { DashboardValues } from '../utils/formValidations';
import { formatSearchQuery } from '../utils/hasura';
import { Order_By } from '../generated/graphql';

export interface DashboardSorting {
  key: string;
  orderBy: Order_By;
}

const QUERY_DEBOUNCE_MS = 300;
export const PAGE_SIZE_OPTIONS = [10, 15, 20];

const useDashboardForm = () => {
  const {
    setFieldValue,
    values: { driver, name, pageSize, status },
  } = useFormikContext<DashboardValues>();

  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState<DashboardSorting | undefined>(
    undefined,
  );

  const [debouncedName] = useDebounce(name, QUERY_DEBOUNCE_MS);
  const nameQuery = formatSearchQuery(debouncedName);

  const [debouncedDriverEmail] = useDebounce(driver, QUERY_DEBOUNCE_MS);
  const driverEmailQuery = formatSearchQuery(debouncedDriverEmail);

  const offset = page * pageSize;

  return {
    driver,
    driverEmailQuery,
    nameQuery,
    offset,
    page,
    pageSize,
    setFieldValue,
    setPage,
    setSorting,
    sorting,
    status,
  };
};

export default useDashboardForm;
