import { chakra } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { memo } from 'react';
import ReactRemoveScroll from 'react-remove-scroll/dist/es5/Combination';
import { useRouteDisclosure } from '../hooks/useRouteDisclosure';
import { RouteModalProps } from '../navigation/Modals';

const UNMOUNT_DELAY = {
  opacity: 0,
  transition: { delay: 2 },
};

const RouteModal: React.FC<RouteModalProps> = ({ route, Component, Modal }) => {
  const { isOpen, onClose } = useRouteDisclosure(route);

  return (
    <motion.div exit={UNMOUNT_DELAY}>
      <chakra.div pointerEvents={isOpen ? 'unset' : 'none'}>
        <ReactRemoveScroll enabled={isOpen}>
          <Modal isOpen={isOpen} onClose={onClose}>
            <Component onClose={onClose} />
          </Modal>
        </ReactRemoveScroll>
      </chakra.div>
    </motion.div>
  );
};

export default memo(RouteModal);
