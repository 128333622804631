import React, { SVGProps, memo } from 'react';

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={22} width={20} {...props}>
    <path
      clipRule="evenodd"
      d="M7.152 2.152A1.2 1.2 0 0 1 8 1.8h4A1.2 1.2 0 0 1 13.2 3v1.2H6.8V3a1.2 1.2 0 0 1 .352-.848ZM5.2 4.2V3A2.8 2.8 0 0 1 8 .2h4A2.8 2.8 0 0 1 14.8 3v1.2H19a.8.8 0 0 1 0 1.6h-1.2V19a2.8 2.8 0 0 1-2.8 2.8H5A2.8 2.8 0 0 1 2.2 19V5.8H1a.8.8 0 0 1 0-1.6h4.2ZM3.8 5.8V19A1.2 1.2 0 0 0 5 20.2h10a1.2 1.2 0 0 0 1.2-1.2V5.8H3.8ZM8 9.2a.8.8 0 0 1 .8.8v6a.8.8 0 0 1-1.6 0v-6a.8.8 0 0 1 .8-.8Zm4.8.8a.8.8 0 0 0-1.6 0v6a.8.8 0 0 0 1.6 0v-6Z"
      fill="#454649"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(DeleteIcon);
