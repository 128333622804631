export default {
  activeReviewerIn: 'Active reviewer in',
  add: 'Add',
  addProjectHeader: `Let's add {{userName}} to this project.`,
  addProjects: 'Add projects',
  ar: '1 AR',
  ars: '{{count}} ARs',
  back: 'Back',
  BASIC: 'Basic {{isMe}}',
  basicUser: 'Basic User',
  billingWarningButtonLabel: 'Go to billing details',
  billingWarningInfo: `To activate licenses for your team (so they can create projects), you'll first need to add a method of payment.`,
  cancel: 'Cancel',
  cancelSubscription: 'Cancel subscription',
  cancelSubscriptionDetails:
    'Are you sure? If you cancel, the permission settings for all team members will automatically change to Basic at the end of the payment period.',
  cancelSubscriptionInfo: `By cancelling this subscription, I accept that the permission settings for all team members will automatically change to Basic at the end of the payment period.`,
  changePermission: 'Change permission',
  changePermissionHeader: 'Change permission',
  changePermissionInfo: `Grant {{userName}} the ability to create unlimited projects by switching them to a Licensed User for $25 USD / mo (prorated and charged immediately).`,
  changesSaved: 'All changes saved successfully',
  confirm: 'Confirm',
  delete: 'Delete',
  deleteTeam: 'Delete Team',
  deleteTeamBody:
    'By deleting my team, I accept that all data associated with this account - including data for me, my team and contributors of any kind - will be permanently deleted and that this action is irreversible.',
  deleteTeamHeader: 'Delete team',
  deleteTeamInfo: `By deleting my team, I accept that all data associated with this account - including data for me, my team and contributors of any kind - will be permanently deleted and that this action is irreversible.`,
  deleteTeamWarning:
    'If you have unpaid invoices, you will be charged instantly',
  deleteUppercase: 'DELETE',
  domain: 'Team domain',
  domainAlreadyExists: 'The specified domain already exists',
  domainInvalid: 'Domain invalid',
  domainNotAllowed:
    'Team domain should not include special characters and uppercase letters',
  domainTooShort: 'Team domain should be at least 3 characters',
  emptyStateDescription: `You can create multiple teams in Completely. Let's start with the name of your business, whether you're freelance, at an agency or in-house.`,
  emptyStateHeader: 'Give your team a name.',
  invite: 'Invite',
  invitePeople: 'Invite people',
  invitePeopleHeader: 'Invite people to the team',
  LICENSED: 'Licensed {{isMe}}',
  licensedUser: 'Licensed User',
  memberName: 'Member name',
  OWNER: 'Owner {{isMe}}',
  permission: 'Permission',
  remove: 'Remove',
  removeFromTeam: 'Remove from team',
  removeFromTeamHeader: 'Remove from team',
  removeFromTeamInfo: `Are you sure? If you remove participants from the team, they will no longer be able to work on tasks.`,
  selectPermissionForUser: 'Select the permission for the user {{userName}}',
  setRole: 'Give them a role for the project.',
  somethingWentWrong: 'Something went wrong',
  teamCreatedSuccessfully: 'Team created successfully',
  teamName: 'Team name',
  teamNameNotAllowed:
    'Team name should include letters, numbers, dashes and underscores only',
  teamNameUpdated: 'Team name updated',
  teamSettings: 'Team settings',
  transfer: 'Transfer',
  transferOwnership: 'Transfer ownership',
  transferOwnershipToUser: `Do you wish to transfer ownership to {{userName}}? If you do, you will no longer be owner or have owner rights.`,
  typeDeleteToConfirm: 'For security reasons, please type DELETE to confirm.',
  updateChanges: 'Update changes',
  you: '(you)',
};
