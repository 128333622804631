import { Button, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { OAuth2ProviderType, SignInUpError } from '@stormotion-auth/core';
import { useFormik } from 'formik';
import * as R from 'ramda';
import React, { memo, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import AuthForm from '../components/AuthForm';
import EmailInput from '../components/EmailInput';
import PasswordInput from '../components/PasswordInput';
import SocialButton from '../components/SocialButton';
import useAuth from '../hooks/useAppAuth';
import useEmailParam from '../hooks/useEmailParam';
import useNavigation from '../hooks/useNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import { FormSubmit } from '../types/formik';
import { AuthValues, authValidationSchema } from '../utils/formValidations';

const INIT_VALUES: AuthValues = {
  email: '',
  password: '',
};

const SignupCredentials: React.FC = () => {
  const { pushWithQuery } = useNavigation();
  const [emailParam, setEmailParam] = useEmailParam();

  const { signInWithGoogle, signUp, sendEmailCode, signInWithSlack } =
    useAuth();
  const { t } = useTranslation(LocalesKeys.Signup);

  const initialValues = useMemo<AuthValues>(
    () => R.mergeRight(INIT_VALUES, { email: emailParam ?? '' }),
    [emailParam],
  );

  const onSubmit = useCallback<FormSubmit<AuthValues>>(
    async ({ email, password }, { setErrors }) => {
      if (!email) return null;

      if (!emailParam) return setEmailParam(email);

      if (!password) return null;

      const result = await signUp(email, password);

      if (result instanceof SignInUpError) {
        setErrors({ email: t(result.message) });
        return undefined;
      }
      await sendEmailCode(email);

      return pushWithQuery(routes.AuthRoute.Verification, { email });
    },
    [emailParam, setEmailParam, signUp, sendEmailCode, pushWithQuery, t],
  );

  const {
    getFieldProps,
    getFieldMeta,
    handleSubmit,
    isSubmitting,
    values: { email, password },
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: authValidationSchema,
  });

  const isDisabled = !(emailParam ? email && password : email);

  const Password = useMemo(
    () =>
      emailParam ? (
        <PasswordInput
          autoFocus
          {...getFieldMeta('password')}
          {...getFieldProps('password')}
        />
      ) : null,
    [emailParam, getFieldMeta, getFieldProps],
  );

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Heading as="h2">{t('heading')}</Heading>
      <Text mt={6}>{t('subhead')}</Text>
      <Stack mt={4} spacing={6}>
        <EmailInput
          autoFocus
          {...getFieldMeta('email')}
          {...getFieldProps('email')}
        />
        {Password}
      </Stack>
      <Stack align="center" mt={8} spacing={4}>
        <Button isDisabled={isDisabled} isLoading={isSubmitting} type="submit">
          {t('submit')}
        </Button>
        <Text color="black.400">{t('or')}</Text>
        <SocialButton
          onClick={signInWithGoogle}
          socialType={OAuth2ProviderType.Google}
        />
        <SocialButton
          onClick={signInWithSlack}
          socialType={OAuth2ProviderType.Slack}
        />
      </Stack>
      <Stack align="center" mt={6} spacing={6}>
        <Text>
          <Trans i18nKey="login" t={t}>
            <Link as={RouterLink} to={routes.AuthRoute.Login} />
          </Trans>
        </Text>
        <Text
          color="black.400"
          fontWeight="normal"
          size="caption1"
          textAlign="center"
        >
          <Trans i18nKey="agreement" t={t}>
            <Link
              color="black.600"
              href={routes.MainRoute.Privacy}
              size="caption1"
              target="_blank"
            />
            <Link
              color="black.600"
              href={routes.MainRoute.Privacy}
              size="caption1"
              target="_blank"
            />
          </Trans>
        </Text>
      </Stack>
    </AuthForm>
  );
};

export default memo(SignupCredentials);
