import React, { memo, useCallback, useMemo } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Text } from '@chakra-ui/react';
import '../theme/foundations/datepicker.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import { DayModifiers, DayPickerProps } from 'react-day-picker';
import { CalendarIcon } from '@chakra-ui/icons';
import TextInput, { TextInputProps } from './TextInput';
import DateInputNavbar from './DateInputNavbar';

const disabledDays = [
  {
    before: new Date(),
  },
];

type Props = {
  value: Date;
  setDateValue: (value: Date) => void;
};

const CALENDAR_ICON = <CalendarIcon pointerEvents="none" />;

const DateInput: React.FC<TextInputProps & Props> = ({
  boxProps,
  value,
  name,
  setDateValue,
  ...props
}) => {
  const renderInput = useCallback(
    (props) => (
      <TextInput
        {...props}
        autoComplete="off"
        name={name}
        rightElement={CALENDAR_ICON}
      />
    ),
    [name],
  );

  const renderDay = useCallback<
    (date: Date, modifiers: DayModifiers) => React.ReactNode
  >((date) => <Text size="body2">{date.getDate()}</Text>, []);

  const dayPickerProps: DayPickerProps = useMemo(
    () => ({
      disabledDays,
      fromMonth: new Date(),
      localeUtils: MomentLocaleUtils,
      navbarElement: DateInputNavbar,
      onDayClick: setDateValue,
      renderDay,
    }),
    [renderDay, setDateValue],
  );

  const onChange = useCallback<(day: Date) => void>(
    (day) => name && setDateValue(day),
    [name, setDateValue],
  );

  return (
    <DayPickerInput
      component={renderInput}
      dayPickerProps={dayPickerProps}
      format="DD.MM.YYYY"
      formatDate={MomentLocaleUtils.formatDate}
      inputProps={props}
      onDayChange={onChange}
      parseDate={MomentLocaleUtils.parseDate}
      style={boxProps}
      value={value}
    />
  );
};

export default memo(DateInput);
