import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BaseGuard, { BaseGuardProps } from './BaseGuard';
import { useTeamContext } from '../store/contexts/TeamContext';
import { TeamNavigation } from '../hooks/useTeamNavigation';
import { navigationType } from '../env';
import { useUserContext } from '../store/contexts/UserContext';
import routes from './routes';

interface Props extends BaseGuardProps {}

const RootTeamGuard: React.FC<Props> = ({ children, ...props }) => {
  const { generateUrl, loading, navigationDetails, rootPath } =
    useTeamContext();

  const location = useLocation();

  const pathOnly = navigationType === 'path-only';

  const { user } = useUserContext();

  useEffect(() => {
    if (
      !pathOnly &&
      !loading &&
      navigationDetails.type !== TeamNavigation.Subdomain &&
      navigationDetails.domain &&
      !location.pathname.includes(routes.ModalRoute.Personalization)
    ) {
      window.location.replace(
        `${generateUrl(navigationDetails.domain)}${location.pathname}`,
      );
    }
  }, [
    generateUrl,
    loading,
    location.pathname,
    navigationDetails,
    pathOnly,
    user,
  ]);

  return pathOnly ? (
    <BaseGuard
      loading={loading}
      rule={rootPath === '/'}
      to={`${rootPath}${location.pathname}`}
      {...props}
    />
  ) : (
    <>{children}</>
  );
};

export default memo(RootTeamGuard);
