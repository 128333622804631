import { Flex } from '@chakra-ui/react';
import React, { memo, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import NavigationHeader from '../components/NavigationHeader';
import ForgetPassword from '../pages/ForgetPassword';
import ForgetPasswordVerification from '../pages/ForgetPasswordVerification';
import routes from './routes';

const SignupNavigator: React.FC = () => {
  const VerificationElement = useMemo(() => <ForgetPasswordVerification />, []);
  const ForgetPasswordElement = useMemo(() => <ForgetPassword />, []);

  return (
    <Flex flexDirection="column" h="full">
      <NavigationHeader />
      <Flex align="center" flex={1} justify="center" p={4}>
        <Routes>
          <Route
            element={ForgetPasswordElement}
            path={routes.AuthRoute.CreatePassword}
          />
          <Route element={VerificationElement} index />
        </Routes>
      </Flex>
    </Flex>
  );
};

export default memo(SignupNavigator);
