import { pipe } from 'fp-ts/lib/function';
import {
  difference,
  find,
  flatten,
  groupBy,
  identity,
  indexBy,
  join,
  mapObjIndexed,
  values,
} from 'ramda';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingByType } from '../components/NotificationSettingsTable';
import {
  Notification_Destination_Enum,
  Settings_By_User,
  useInsertNotificationSettingsMutation,
  useNotificationSettingsQuery,
  useUpdateUserMutation,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { useUserContext } from '../store/contexts/UserContext';
import { FormSubmit } from '../types/formik';
import { NotificationSettingsValues } from '../utils/formValidations';
import { useReminderProtocol } from './useReminderProtocol';
import { useCustomToast } from './useCustomToast';

export const settingToString = (setting: Settings_By_User) =>
  `${setting.type}-${setting.action}-${join(
    '-',
    setting.fields?.map((field: string) => field) ?? ['all'],
  )}`;

export const useNotificationSettings = () => {
  const { user } = useUserContext();

  const { t } = useTranslation(LocalesKeys.AccountSettings);

  const { data: settings, loading } = useNotificationSettingsQuery({
    variables: {
      where: {
        user_id: {
          _eq: user?.id,
        },
      },
    },
  });

  const [updateUser] = useUpdateUserMutation();

  const settingsByType: SettingByType[] = useMemo(
    () =>
      values(
        mapObjIndexed(
          (settingsByDetails): SettingByType =>
            ({
              ...pipe(
                values(Notification_Destination_Enum),
                indexBy<
                  Notification_Destination_Enum,
                  Notification_Destination_Enum
                >(identity),
                mapObjIndexed(
                  (destination) =>
                    !!find(
                      (setting) => setting.destination === destination,
                      settingsByDetails,
                    )?.active,
                ),
              ),
              ...settingsByDetails[0],
            } as SettingByType),
          groupBy<Settings_By_User, string>(
            settingToString,
            settings?.settings_by_user ?? [],
          ),
        ),
      ),
    [settings?.settings_by_user],
  );

  const [updateSettings] = useInsertNotificationSettingsMutation();

  const { successToast, warningToast } = useCustomToast();

  const { updateUserProtocol, getInitialValues } = useReminderProtocol();

  const onSubmit = useCallback<FormSubmit<NotificationSettingsValues>>(
    async ({ reminders, notification_email, ...reminderValues }) => {
      if (!user) {
        return;
      }

      try {
        const updatedSettings = difference(reminders ?? [], settingsByType);

        await updateSettings({
          variables: {
            data: flatten(
              updatedSettings.map((setting) =>
                flatten(
                  setting.fields?.map((field) =>
                    values(Notification_Destination_Enum).map(
                      (destination) => ({
                        active: setting[destination],
                        field,
                        notification_action: setting.action,
                        notification_destination: destination,
                        notification_type: setting.type,
                        user_id: user.id,
                      }),
                    ),
                  ) ?? [],
                ),
              ),
            ),
          },
        });

        await updateUserProtocol(reminderValues);

        await updateUser({
          variables: {
            _set: {
              notification_email,
            },
            email: user.email,
          },
        });

        successToast({
          headerMessage: t('accountUpdated'),
          toastMessage: t('changesSaved'),
        });
      } catch (e) {
        if (!(e instanceof Error)) return;

        warningToast({
          headerMessage: t('accountUpdateError'),
          toastMessage: e.message,
        });
      } finally {
        window.scroll({ behavior: 'smooth', left: 0, top: 0 });
      }
    },
    [
      settingsByType,
      successToast,
      t,
      updateSettings,
      updateUser,
      updateUserProtocol,
      user,
      warningToast,
    ],
  );

  const initialValues: NotificationSettingsValues = useMemo(
    () => ({
      notification_email: user?.notification_email ?? user?.email,
      reminders: settingsByType,
      slack_workspace: '',
      ...getInitialValues(),
    }),
    [getInitialValues, settingsByType, user?.email, user?.notification_email],
  );

  return {
    initialValues,
    loading,
    onSubmit,
  };
};
