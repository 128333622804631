import React, { SVGProps, memo } from 'react';

const WarningIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    fill="none"
    height={20}
    width={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M9.638.376a2.693 2.693 0 0 1 2.725 0c.413.242.756.59.998 1.004l.002.004 8.27 14.368.006.011a2.896 2.896 0 0 1 .008 2.795 2.795 2.795 0 0 1-.99 1.043c-.416.255-.891.393-1.378.399H2.721a2.695 2.695 0 0 1-1.377-.399 2.795 2.795 0 0 1-.99-1.043 2.897 2.897 0 0 1 .008-2.795l.006-.01 8.27-14.369.693.4-.691-.404A2.79 2.79 0 0 1 9.638.376Zm.385 1.808v.002L1.757 16.544a1.296 1.296 0 0 0 0 1.246c.103.188.25.341.422.447.17.105.362.16.556.163h16.529c.193-.003.385-.058.556-.163.172-.106.319-.259.422-.447a1.297 1.297 0 0 0 0-1.246L11.979 2.186l-.001-.002a1.191 1.191 0 0 0-.424-.428 1.093 1.093 0 0 0-1.107 0c-.17.1-.318.247-.424.428ZM11 6.206a.8.8 0 0 1 .8.8v4.065a.8.8 0 1 1-1.6 0V7.006a.8.8 0 0 1 .8-.8Zm0 8.13a.8.8 0 0 0 0 1.6h.01a.8.8 0 1 0 0-1.6H11Z"
      fill={props.color || '#fff'}
      fillRule="evenodd"
    />
  </svg>
);

export default memo(WarningIcon);
