import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const DraftIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} fill="#BA49E1" r={9.5} stroke="#BA49E1" />
    <path
      d="M10 9v6m4-6v6"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Icon>
);

export default memo(DraftIcon);
