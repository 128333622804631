import { useUncheckedNotificationCountSubscription } from '../generated/graphql';

export const useUncheckedNotifications = () => {
  const { data: uncheckedCountData, loading } =
    useUncheckedNotificationCountSubscription();

  return {
    count:
      uncheckedCountData?.notification_receiver_aggregate.aggregate?.count ?? 0,
    loading,
  };
};
