import { ApolloProvider } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import React, { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';
import Loading from './components/Loading';
import { stripePublickey } from './env';
import useRootApolloClient from './hooks/useRootApolloClient';
import RootNavigator from './navigation/RootNavigator';
import { TeamContextProvider } from './store/contexts/TeamContext';
import { UserContextProvider } from './store/contexts/UserContext';

// scroll into view doesn't work on safari :(
elementScrollIntoViewPolyfill();

const stripePromise = loadStripe(stripePublickey ?? '');

const options: StripeElementsOptions = {
  fonts: [
    {
      family: "'Inter', sans-serif",
      src: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap',
    },
  ],
};

const App: React.FC = () => {
  const client = useRootApolloClient();

  if (!client) return <Loading />;

  return (
    <ApolloProvider client={client}>
      <Elements options={options} stripe={stripePromise}>
        <UserContextProvider>
          <BrowserRouter>
            <TeamContextProvider>
              <RootNavigator />
            </TeamContextProvider>
          </BrowserRouter>
        </UserContextProvider>
      </Elements>
    </ApolloProvider>
  );
};

export default memo(App);
