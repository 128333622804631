import { Maybe } from 'graphql/jsutils/Maybe';

export interface HasuraError {
  code: string;
}

export interface HasuraInternalError extends HasuraError {
  error: { message: string };
}

export const formatSearchQuery = (query: Maybe<string> = '') => `%${query}%`;
