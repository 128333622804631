export enum LocalesKeys {
  Button = 'button',
  Common = 'common',
  Dashboard = 'dashboard',
  DeleteTeamSuccessful = 'deleteTeamSuccessful',
  Header = 'header',
  Input = 'input',
  Login = 'login',
  LoginVerification = 'loginVerification',
  Signup = 'signup',
  ForgetPassword = 'forgetPassword',
  SignupVerification = 'signupVerification',
  ForgetPasswordVerification = 'forgetPasswordVerification',
  Status = 'status',
  Verification = 'verification',
  CreateAR = 'createAR',
  CreateTeam = 'createTeam',
  Settings = 'settings',
  AccountSettings = 'accountSettings',
  SecuritySettings = 'securitySettings',
  RolesDetails = 'rolesDetails',
  ARDetails = 'arDetails',
  ValidationErrors = 'validationErrors',
  TeamSettings = 'teamSettings',
  EmptyStates = 'emptyStates',
  Archive = 'archive',
  Billing = 'billing',
  Notifications = 'notifications',
  Roles = 'roles',
  NotificationSettings = 'notificationSettings',
  TeamMenu = 'teamMenu',
}
