import React, { memo } from 'react';
import useARDetails from '../hooks/useARDetails';
import BaseGuard, { BaseGuardProps } from './BaseGuard';

interface Props extends BaseGuardProps {}

const DriverGuard: React.FC<Props> = (props) => {
  const { isDriver, loading } = useARDetails();

  return <BaseGuard loading={loading} rule={isDriver} to="/" {...props} />;
};

export default memo(DriverGuard);
