import { SettingsIcon } from '@chakra-ui/icons';
import {
  ComponentWithAs,
  Flex,
  IconProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { filter } from 'ramda';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingIcon } from '../components/icons/BillingIcon';
import ClockIcon from '../components/icons/ClockIcon';
import { SecurityIcon } from '../components/icons/SecurityIcon';
import { TeamMembersIcon } from '../components/icons/TeamMembersIcon';
import NavigationHeader from '../components/NavigationHeader';
import NotificationSettingsForm from '../components/NotificationSettingsForm';
import SecuritySettings from '../components/SecuritySettings';
import SettingsContent from '../components/SettingsContent';
import SettingsHeader from '../components/SettingsHeader';
import { projectVersion } from '../env';
import { useIsTeamOwner } from '../hooks/useIsTeamOwner';
import { LocalesKeys } from '../locales/localesKeys';
import AccountSettingsNavigator from '../navigation/AccountSettingsNavigator';
import { SettingsNavigationKeys } from '../navigation/settings';
import TeamSettingsNavigator from '../navigation/TeamSettingsNavigator';
import Billing from './Billing';

export type MenuNavigator = {
  Icon: ComponentWithAs<'svg', IconProps> | React.FC;
  name: string;
  route: SettingsNavigationKeys;
  component: JSX.Element;
  rule?: boolean;
};

const Settings: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.Settings);

  const isTeamOwner = useIsTeamOwner();

  const items: MenuNavigator[] = useMemo(
    () => [
      {
        component: <AccountSettingsNavigator />,
        Icon: SettingsIcon,
        name: t('account'),
        route: SettingsNavigationKeys.AccountSettings,
      },
      {
        component: <SecuritySettings />,
        Icon: SecurityIcon,
        name: t('security'),
        route: SettingsNavigationKeys.SecuritySettings,
      },
      {
        component: <TeamSettingsNavigator />,
        Icon: TeamMembersIcon,
        name: t('teamMembers'),
        route: SettingsNavigationKeys.TeamMembers,
      },
      {
        component: <Billing />,
        Icon: BillingIcon,
        name: t('billing'),
        route: SettingsNavigationKeys.Billing,
        rule: isTeamOwner,
      },
      {
        component: <NotificationSettingsForm />,
        Icon: ClockIcon,
        name: t('notifications'),
        route: SettingsNavigationKeys.Notifications,
      },
    ],
    [isTeamOwner, t],
  );

  const filteredItems = useMemo(
    () =>
      filter((item) => item.rule === undefined || item.rule === true, items),
    [items],
  );

  return (
    <Flex flexDirection="column" h="full">
      <NavigationHeader nested />
      <Flex align="flex-start" h="100%" justify="center" px={20} w="100%">
        <VStack w="full">
          <SettingsHeader items={filteredItems} />
          <SettingsContent items={filteredItems} />
        </VStack>
      </Flex>
      <Text bottom={3} opacity={0.3} pos="fixed" right={3}>
        {projectVersion}
      </Text>
    </Flex>
  );
};

export default memo(Settings);
