import {
  Box,
  Flex,
  Heading,
  Interpolation,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import {
  ProjectDashboardFragment,
  ProjectTeamFragment,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import Avatar from './Avatar';
import DeletedProjectUserCell from './DeletedProjectUserCell';

export type ProjectMembers = ProjectTeamFragment['project_users'][0] & {
  user: {
    isUserInTeam?: boolean;
  };
};

type Props = {
  teamMembers: ProjectMembers[];
  isDriver: boolean;
  pickedAR: ProjectDashboardFragment;
};

const TABLE_CSS: Interpolation<{}> = {
  tableLayout: 'fixed',
};

const ARDetailsTable: React.FC<Props> = ({
  teamMembers,
  isDriver,
  pickedAR,
}) => {
  const { t } = useTranslation(LocalesKeys.ARDetails);

  const columns = useMemo<Column<ProjectMembers>[]>(
    () => [
      {
        accessor: 'user',
        Cell: ({
          row: {
            original: {
              user: { email, username, image, id },
            },
          },
        }) => {
          const displayName = username ?? email;
          const imageUrl = image?.url ?? undefined;

          return (
            <Flex align="center">
              <Avatar
                h={10}
                mr={2}
                name={displayName}
                src={imageUrl}
                userId={id}
                w={10}
              />
              <Text size="body2">{displayName}</Text>
            </Flex>
          );
        },
        Header: () => (
          <Heading as="p" size="headline4" textAlign="left">
            {t('memberName')}
          </Heading>
        ),
      },
      {
        accessor: 'role',
        Cell: ({ row }) => <Text>{t(row.original.role)}</Text>,
        Header: () => (
          <Heading as="p" size="headline4">
            {t('role')}
          </Heading>
        ),
      },
      {
        accessor: 'status',
        Cell: ({ row }) =>
          row.original.user.isUserInTeam ? (
            <Text>
              {pickedAR?.driver?.email !== row.original.user.email
                ? t(row.original.status || 'UNDER_REVIEW')
                : null}
            </Text>
          ) : isDriver ? (
            <DeletedProjectUserCell
              userId={row.original.user.id}
              userStatus={t(row.original.status || 'UNDER_REVIEW')}
            />
          ) : (
            <Text color="red">
              {pickedAR?.driver?.email !== row.original.user.email
                ? t(row.original.status || 'UNDER_REVIEW')
                : null}
            </Text>
          ),
        Header: () => (
          <Heading as="p" size="headline4">
            {t('status')}
          </Heading>
        ),
      },
    ],
    [isDriver, pickedAR?.driver?.email, t],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: teamMembers });

  const renderHeaderColumn = useCallback<
    (column: HeaderGroup<ProjectMembers>) => JSX.Element
  >(
    (column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>,
    [],
  );

  const renderTableHeader = useCallback<
    (headerGroup: HeaderGroup<ProjectMembers>) => JSX.Element
  >(
    (headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(renderHeaderColumn)}
      </Tr>
    ),
    [renderHeaderColumn],
  );

  const TableHeaders = useMemo(
    () => headerGroups.map(renderTableHeader),
    [headerGroups, renderTableHeader],
  );

  const renderTableCell = useCallback<
    (cell: Cell<ProjectMembers>) => JSX.Element
  >((cell) => <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>, []);

  const renderTableRow = useCallback<(row: Row<ProjectMembers>) => JSX.Element>(
    (row) => {
      prepareRow(row);
      return (
        <Tr cursor="pointer" {...row.getRowProps()}>
          {row.cells.map(renderTableCell)}
        </Tr>
      );
    },
    [prepareRow, renderTableCell],
  );

  const TableRows = useMemo(
    () => rows.map(renderTableRow),
    [renderTableRow, rows],
  );

  return (
    <Box border="1px solid" borderColor="black.200" borderRadius="lg" w="full">
      <Table
        colorScheme="black"
        css={TABLE_CSS}
        variant="simple"
        {...getTableProps()}
      >
        <Thead>{TableHeaders}</Thead>
        <Tbody {...getTableBodyProps()}>{TableRows}</Tbody>
      </Table>
    </Box>
  );
};

export default ARDetailsTable;
