/* eslint-disable no-nested-ternary */
import { Flex } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../components/DashboardHeader';
import DashboardPagination from '../components/DashboardPagination';
import DashboardTable from '../components/DashboardTable';
import EmptyState from '../components/EmptyState';
import { Project_User_Display_Status_Enum } from '../generated/graphql';
import { PAGE_SIZE_OPTIONS } from '../hooks/useDashboardForm';
import useProjectsData from '../hooks/useProjectsData';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';

const Dashboard: React.FC = () => {
  const {
    drivers,
    handleNextClick,
    handlePageSizeChange,
    handlePrevClick,
    handleSortingClick,
    projects,
    page,
    offset,
    pageSize,
    sorting,
    totalCount,
    nameQuery,
    status,
    driver,
  } = useProjectsData(Project_User_Display_Status_Enum.Dashboard);

  const { t } = useTranslation(LocalesKeys.EmptyStates);

  const navigate = useNavigate();

  const openCreateAR = useCallback(
    () => navigate(routes.ARRoutes.ARCreate),
    [navigate],
  );

  const arNameQuery = nameQuery.replaceAll('%', '').trim();

  const ProjectsEmptyState = useMemo(
    () =>
      arNameQuery.length || status?.length ? (
        <EmptyState headline={t('noARsMatched')} isSearchIcon />
      ) : driver?.length ? (
        <EmptyState headline={t('noDriversMatched')} isSearchIcon />
      ) : (
        <EmptyState
          buttonLabel={t('createProject')}
          headline={t('dashboard')}
          onPress={openCreateAR}
        />
      ),
    [arNameQuery.length, driver?.length, openCreateAR, status?.length, t],
  );

  const Content = useMemo(
    () => (
      <Flex direction="column" flex={1} px={24} py={12}>
        <DashboardHeader drivers={drivers} />
        <Flex
          bg="white"
          borderRadius="lg"
          boxShadow="box"
          direction="column"
          flex={1}
          mt={6}
          overflow="hidden"
        >
          {projects.length ? (
            <DashboardTable
              onSortingClick={handleSortingClick}
              projects={projects}
              sorting={sorting}
            />
          ) : (
            ProjectsEmptyState
          )}
          <DashboardPagination
            count={totalCount}
            offset={offset}
            onNextClick={handleNextClick}
            onPageSizeChange={handlePageSizeChange}
            onPrevClick={handlePrevClick}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
          />
        </Flex>
      </Flex>
    ),
    [
      ProjectsEmptyState,
      drivers,
      handleNextClick,
      handlePageSizeChange,
      handlePrevClick,
      handleSortingClick,
      offset,
      page,
      pageSize,
      projects,
      sorting,
      totalCount,
    ],
  );

  return Content;
};

export default memo(Dashboard);
