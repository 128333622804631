export enum PersistedVariables {
  PageSize = 'pageSize',
}

export const persistVariable = <T>(key: PersistedVariables, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getPersistedVariable = <T>(key: PersistedVariables) => {
  const value = localStorage.getItem(key);
  if (value !== null) {
    try {
      return JSON.parse(value) as T;
    } catch {
      return value as unknown as T;
    }
  }
  return value;
};
