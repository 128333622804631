import React, { memo, useCallback, useMemo } from 'react';
import { ButtonProps, IconButton } from '@chakra-ui/react';
import DoneIcon from './icons/DoneIcon';
import { SettingByType } from './NotificationSettingsTable';
import { Notification_Destination_Enum } from '../generated/graphql';

type Props = {
  active: boolean;
  setting: SettingByType;
  destination: Notification_Destination_Enum;
  updateSetting: (
    setting: SettingByType,
    destination: Notification_Destination_Enum,
  ) => void;
};

const NotificationSettingButton: React.FC<Props & ButtonProps> = ({
  active,
  setting,
  destination,
  updateSetting,
  ...props
}) => {
  const Icon = useMemo(
    () => (
      <DoneIcon
        color={active ? 'black.700' : 'black.300'}
        transition="color .3s"
      />
    ),
    [active],
  );

  const onClick = useCallback(
    () => updateSetting(setting, destination),
    [destination, setting, updateSetting],
  );

  return (
    <IconButton
      aria-label=""
      icon={Icon}
      onClick={onClick}
      variant="ghost"
      {...props}
    />
  );
};
export default memo(NotificationSettingButton);
