import React, { memo } from 'react';
import TeamSettings from '../components/TeamSettings';
import Modals from './Modals';
import { teamSettingsModals } from './TeamSettingsModals';

const TeamSettingsNavigator: React.FC = () => (
  <>
    <TeamSettings />
    <Modals modals={teamSettingsModals} />
  </>
);

export default memo(TeamSettingsNavigator);
