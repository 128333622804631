import React, { memo, useMemo } from 'react';
import Loading from '../components/Loading';
import ProjectEditorForm from '../components/ProjectEditorForm';
import { useEditAR } from '../hooks/useEditAR';
import { useProjectSubmit } from '../hooks/useProjectSubmit';
import { RouteModalComponent } from '../navigation/Modals';

const EditAR: RouteModalComponent = ({ onClose, ...props }) => {
  const { onEditAR, initialValues, loading, arId } = useEditAR();

  const onSubmit = useProjectSubmit(onClose, onEditAR);

  const Content = useMemo(
    () =>
      !loading ? (
        <ProjectEditorForm
          {...props}
          id={arId}
          initialValues={initialValues}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <Loading />
      ),
    [arId, initialValues, loading, onClose, onSubmit, props],
  );

  return Content;
};

export default memo(EditAR);
