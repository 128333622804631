import { useAuth } from '@stormotion-auth/react';
import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import { useCustomToast } from '../hooks/useCustomToast';
import { TeamNavigation } from '../hooks/useTeamNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import { useTeamContext } from '../store/contexts/TeamContext';
import AuthorizedGuard from './AuthorizedGuard';
import ForgetNavigator from './ForgetNavigator';
import LoginNavigator from './LoginNavigator';
import MainNavigator from './MainNavigator';
import RootTeamGuard from './RootTeamGuard';
import routes from './routes';
import SignupNavigator from './SignupNavigator';
import UnauthorizedGuard from './UnauthorizedGuard';

const RootNavigator: React.FC = () => {
  const location = useLocation();

  const signupPath = routes.navigator(routes.AuthRoute.SignUp);
  const loginPath = routes.navigator(routes.AuthRoute.Login);
  const forgetPath = routes.navigator(routes.AuthRoute.Forget);
  const privacyPath = routes.navigator(routes.MainRoute.Privacy);
  const { navigationDetails } = useTeamContext();
  const mainPath = routes.navigator(
    navigationDetails.type === TeamNavigation.Subdomain ||
      location.pathname.includes(routes.ModalRoute.Personalization)
      ? ''
      : navigationDetails.domain,
  );
  const indexPath = routes.navigator('');

  const Signup = useMemo(() => <SignupNavigator />, []);

  const SignupElement = useMemo(
    () => <UnauthorizedGuard>{Signup}</UnauthorizedGuard>,
    [Signup],
  );

  const Login = useMemo(() => <LoginNavigator />, []);

  const LoginElement = useMemo(
    () => <UnauthorizedGuard>{Login}</UnauthorizedGuard>,
    [Login],
  );

  const Forget = useMemo(() => <ForgetNavigator />, []);

  const ForgetElement = useMemo(
    () => <UnauthorizedGuard>{Forget}</UnauthorizedGuard>,
    [Forget],
  );

  const Main = useMemo(() => <MainNavigator />, []);

  const MainElement = useMemo(
    () => <AuthorizedGuard>{Main}</AuthorizedGuard>,
    [Main],
  );

  const IndexElement = useMemo(
    () => (
      <RootTeamGuard>
        <Loading />
      </RootTeamGuard>
    ),
    [],
  );

  const { warningToast } = useCustomToast();
  const { t } = useTranslation(LocalesKeys.AccountSettings);

  const { oAuth2Error } = useAuth();

  useEffect(() => {
    if (oAuth2Error) {
      warningToast({
        headerMessage: t('socialAddFailureHeader'),
        toastMessage: t(oAuth2Error),
      });
    }

    // eslint-disable-next-line
  }, [oAuth2Error]);

  return (
    <Routes>
      <Route element={<PrivacyPolicy />} path={privacyPath} />
      <Route element={SignupElement} path={signupPath} />
      <Route element={LoginElement} path={loginPath} />
      <Route element={ForgetElement} path={forgetPath} />
      <Route element={MainElement} path={mainPath} />
      <Route element={IndexElement} path={indexPath} />
    </Routes>
  );
};

export default memo(RootNavigator);
