import { createSearchParams } from 'react-router-dom';
import { PathWithQuery } from './types';

const navigator = (path: string) => `${path}/*`;

const pathWithQuery: PathWithQuery = (path, params = {}) => {
  const searchParams = createSearchParams(params);

  return `${path}?${searchParams}`;
};

enum ModalRoute {
  Personalization = 'personalization',
  Notifications = 'notifications',
  DeleteTeamSuccessful = 'delete-team-successful',
}

enum AuthRoute {
  Login = '/login',
  SignUp = '/signup',
  Forget = '/forget',
  Verification = 'verification',
  CreatePassword = 'password',
}

enum MainRoute {
  Archive = 'archive',
  Dashboard = '',
  Root = '/',
  Settings = 'settings',
  Privacy = '/privacy',
}

enum ARRoutes {
  ARCreate = 'ar/create',
  ARDetails = 'ar/:id',
  ARDelete = 'delete',
  AREdit = 'ar/:id/edit',
  ChangeProjectStatus = 'ar/:id/change-status',
  CreateProjectError = 'ar/create/error/:error',
}

enum TeamSettingsRoutes {
  TransferOwnership = 'transfer-ownership/:id',
  AddProjects = 'add-projects/:id',
  ChangePermission = 'change-permission/:id',
  RemoveFromTeam = 'remove-from-team/:id',
  DeleteTeam = 'delete-team',
  InvitePeople = 'invite-people',
  CancelSubscriptions = 'cancel-subscriptions/:id',
}

enum AccountSettingsRoutes {
  DeleteAccount = 'delete',
  DeleteAccountWarning = 'delete/warning',
  DeleteAccountConfirmation = 'delete/confirmation',
}

enum RoutePrefixes {
  Archive = 'archive',
  TeamSettings = 'settings/team',
}

export default {
  AccountSettingsRoutes,
  ARRoutes,
  AuthRoute,
  MainRoute,
  ModalRoute,
  navigator,
  pathWithQuery,
  RoutePrefixes,
  TeamSettingsRoutes,
};
