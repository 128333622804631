import { pipe } from 'fp-ts/function';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../navigation/routes';
import { SearchParams } from '../navigation/types';
import { canGoBack } from '../utils/navigation';
import useGoBack from './useGoBack';

const useNavigation = () => {
  const navigate = useNavigate();

  const goBack = useGoBack();

  const goBackOrReplace = useCallback<(defaultRoute?: string) => void>(
    (defaultRoute) => {
      if (defaultRoute) {
        navigate(defaultRoute, { replace: true });
        return;
      }

      if (canGoBack()) {
        navigate(-1);
      }
    },
    [navigate],
  );

  const pushWithQuery = useCallback<
    (path: string, params?: SearchParams) => void
  >(
    (path, params) =>
      pipe(routes.pathWithQuery(path, params), (to) => navigate(to)),
    [navigate],
  );

  return { goBack, goBackOrReplace, pushWithQuery };
};

export default useNavigation;
