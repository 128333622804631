import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spacer,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserFragment } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import ARListFilters from './ARListFilters';
import ArchiveIcon from './icons/ArchiveIcon';
import CreateProjectIcon from './icons/CreateProjectIcon';

const ARCHIVE_ICON = <ArchiveIcon />;

const CREATE_ICON = <CreateProjectIcon />;

interface Props extends StackProps {
  drivers?: UserFragment[];
}

const DashboardHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation(LocalesKeys.Dashboard);

  const navigate = useNavigate();

  const navigateToCreateProject = useCallback(
    () => navigate(routes.ARRoutes.ARCreate),
    [navigate],
  );

  const openArchive = useCallback(
    () => navigate(routes.MainRoute.Archive),
    [navigate],
  );

  return (
    <Flex align="center" justify="space-between" w="full">
      <Stack flex={1} spacing={6}>
        <Flex align="center">
          <Heading size="headline1">{t('projects')}</Heading>
          <Spacer />
          <Box>
            <Button
              colorScheme="secondary"
              leftIcon={ARCHIVE_ICON}
              onClick={openArchive}
              px={6}
              w="initial"
            >
              {t('archive')}
            </Button>
          </Box>
        </Flex>
        <HStack spacing={32}>
          <ARListFilters {...props} />
          <Button
            colorScheme="primary"
            leftIcon={CREATE_ICON}
            onClick={navigateToCreateProject}
            px={10}
            w="initial"
          >
            {t('createProject')}
          </Button>
        </HStack>
      </Stack>
    </Flex>
  );
};

export default memo(DashboardHeader);
