import { DataProxy, useApolloClient } from '@apollo/client';
import * as R from 'ramda';
import { useCallback } from 'react';

const useUpdateCache = () => {
  const client = useApolloClient();

  const updateFragment = useCallback<
    <T>(
      options: DataProxy.ReadFragmentOptions<T, any>,
      update: (data: T) => T,
    ) => T | null
  >(
    (options, update) => {
      const fragment = client.readFragment(options);

      if (!fragment) {
        return null;
      }

      const updatedFragment = update(fragment);

      client.writeFragment(R.assoc('data', updatedFragment, options));

      return updatedFragment;
    },
    [client],
  );

  const updateQuery = useCallback<
    <Q, V>(
      options: DataProxy.ReadQueryOptions<Q, V>,
      update: (data: Q) => Q,
    ) => Q | null
  >(
    (options, update) => {
      const query = client.readQuery(options);

      if (!query) {
        return null;
      }

      const updatedQuery = update(query);

      client.writeQuery(R.assoc('data', updatedQuery, options));

      return updatedQuery;
    },
    [client],
  );

  return { updateFragment, updateQuery };
};

export default useUpdateCache;
