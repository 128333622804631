import { Route, Routes } from 'react-router-dom';
import React, { memo, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { Formik } from 'formik';
import routes from './routes';
import NavigationHeader from '../components/NavigationHeader';
import devLog from '../utils/devLog';
import {
  DashboardValues,
  dashboardValidationSchema,
} from '../utils/formValidations';
import Modals, { archiveModals, generalModals } from './Modals';
import Dashboard from '../pages/Dashboard';
import Archive from '../pages/Archive';
import {
  PersistedVariables,
  getPersistedVariable,
} from '../utils/persistedVariable';
import { PAGE_SIZE_OPTIONS } from '../hooks/useDashboardForm';

const INIT_VALUES: DashboardValues = {
  driver: undefined,
  name: undefined,
  pageSize:
    getPersistedVariable(PersistedVariables.PageSize) ??
    PAGE_SIZE_OPTIONS[0] ??
    0,
  status: undefined,
};

const DashboardNavigator: React.FC = () => {
  const DashboardElement = useMemo(() => <Dashboard />, []);

  const ArchiveElement = useMemo(() => <Archive />, []);

  const DashboardRoutes = useMemo(
    () => (
      <Routes>
        <Route
          element={
            <>
              {DashboardElement}
              <Modals modals={generalModals} />
            </>
          }
          path={routes.navigator(routes.MainRoute.Dashboard)}
        />
        <Route
          element={
            <>
              {ArchiveElement}
              <Modals modals={archiveModals} />
            </>
          }
          path={routes.navigator(routes.MainRoute.Archive)}
        />
      </Routes>
    ),
    [ArchiveElement, DashboardElement],
  );

  return (
    <Flex flexDirection="column">
      <NavigationHeader />
      <Formik
        initialValues={INIT_VALUES}
        onSubmit={devLog}
        validationSchema={dashboardValidationSchema}
      >
        {DashboardRoutes}
      </Formik>
    </Flex>
  );
};

export default memo(DashboardNavigator);
