import { initAuth } from '@stormotion-auth/react';
import { useEffect, useState } from 'react';
import { authUrl } from '../env';

const useRootAuth = () => {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    initAuth(authUrl).finally(() => setReady(true));
  }, []);

  return isReady;
};

export default useRootAuth;
