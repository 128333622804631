import { useState } from 'react';
import {
  useNotificationCountSubscription,
  useNotificationQuery,
  useNotificationsSubscription,
} from '../generated/graphql';

export const useNotifications = () => {
  const {
    data: notifications,
    loading: notificationsLoading,
    fetchMore,
    updateQuery,
  } = useNotificationQuery({
    variables: {
      limit: 10,
      offset: 0,
    },
  });

  const [baseDate, setBaseDate] = useState(new Date());

  useNotificationsSubscription({
    onSubscriptionData({ subscriptionData }) {
      const newNotifications =
        subscriptionData.data?.notification_receiver.map((item) => ({
          ...item,
          checked_at: item.checked_at !== undefined ? item.checked_at : null,
        })) ?? [];
      if (!newNotifications.length) {
        return;
      }
      updateQuery((prev) => ({
        // eslint-disable-next-line no-underscore-dangle
        __typename: prev.__typename,
        notification_receiver: newNotifications,
      }));
      setBaseDate(new Date());
    },
    shouldResubscribe: true,
    variables: {
      shouldNotGetCreatedAt: true,
      where: {
        notification: {
          created_at: {
            _gt: baseDate.toUTCString(),
          },
        },
      },
    },
  });

  const { data: countData, loading: rowsCountLoading } =
    useNotificationCountSubscription();

  return {
    fetchMore,
    loading:
      !notifications?.notification_receiver.length &&
      (rowsCountLoading || notificationsLoading),
    notifications,
    rowsCount: countData?.notification_receiver_aggregate.aggregate?.count ?? 0,
  };
};
