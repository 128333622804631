import { UserInfo } from '@stormotion-auth/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useMemo } from 'react';
import {
  UserFragment,
  useAccountByEmailQuery,
  useAccountSubscription,
} from '../../generated/graphql';
import { usePushNotifications } from '../../hooks/usePushNotifications';
import useUserEmail from '../../hooks/useUserEmail';
import { createCtx } from '../../utils/common';
import { useAuthContext } from './AuthContext';

interface UserContextType {
  user: Maybe<UserFragment>;
  cognitoUser?: UserInfo;
  loading: boolean;
}

export const [useUserContext, UserContext] = createCtx<UserContextType>();

export const UserContextProvider: React.FC = ({ children }) => {
  const email = useUserEmail();
  const { cognitoUser, isInitializing } = useAuthContext();

  const { subscribe } = usePushNotifications();

  const {
    data,
    loading: isUserLoading,
    updateQuery,
  } = useAccountByEmailQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const user = data.user[0];
      if (user) {
        subscribe(user.id);
      }
    },
    variables: { email },
  });

  useAccountSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      updateQuery((prev) => {
        const user = subscriptionData.data?.user;
        if (!user) {
          return prev;
        }
        // eslint-disable-next-line no-underscore-dangle
        return { __typename: prev.__typename, user };
      });
    },
    variables: { email },
  });

  const user = data?.user[0];

  const value = useMemo<UserContextType>(
    () => ({
      cognitoUser: cognitoUser?.userInfo ?? undefined,
      loading: isInitializing || isUserLoading,
      user,
    }),
    [cognitoUser, isUserLoading, user, isInitializing],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
