import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Team_User_Status_Enum,
  useUpdateTeamUserStatusMutation,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';

const useChangeTeamUserStatus = () => {
  const [pickedStatus, setPickedStatus] = useState<Team_User_Status_Enum>();
  const { id } = useParams();
  const { team } = useTeamContext();

  const teamUser = team?.team_users.find(
    (team_user) => team_user.user.id === id,
  );

  const status = teamUser?.status;

  const [changeTeamUserStatus] = useUpdateTeamUserStatusMutation();

  const onChangeTeamUserStatus = useCallback(async () => {
    if (pickedStatus) {
      await changeTeamUserStatus({
        variables: {
          status: pickedStatus,
          where: { team_id: { _eq: team?.id }, user_id: { _eq: id } },
        },
      });
    }
  }, [changeTeamUserStatus, id, pickedStatus, team?.id]);

  const onChangeStatus = useCallback((value) => {
    setPickedStatus(value);
  }, []);

  useEffect(() => {
    if (status) {
      setPickedStatus(status);
    }
  }, [status]);

  return {
    onChangeStatus,
    onChangeTeamUserStatus,
    pickedStatus,
    setPickedStatus,
    status,
    user: teamUser?.user,
  };
};

export default useChangeTeamUserStatus;
