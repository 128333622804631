import React, { useCallback, useMemo } from 'react';
import { find } from 'ramda';
import { createCtx } from '../../utils/common';
import {
  AvailableTeamFragment,
  AvailableTeamsQuery,
  TeamFragment,
  useDeleteTeamMutation,
  useTeamQuery,
  useTeam_SubscriptionSubscription,
} from '../../generated/graphql';
import {
  TeamNavigationDetails,
  useTeamNavigation,
} from '../../hooks/useTeamNavigation';

type TeamContextType = {
  team: TeamFragment | undefined;
  loading: boolean;
  availableTeams: AvailableTeamsQuery['team'];
  rootPath: string;
  navigationDetails: TeamNavigationDetails;
  changeTeam: (team: AvailableTeamFragment, nextPath?: string) => void;
  deleteTeam: (nextPath?: string) => Promise<void>;
  generateUrl: (teamDomain?: string) => string;
};

export const [useTeamContext, TeamContext] = createCtx<TeamContextType>();

export const TeamContextProvider: React.FC = ({ children }) => {
  const {
    availableTeams,
    navigationDetails,
    loading: navigationLoading,
    changeTeam,
    rootPath,
    generateUrl,
  } = useTeamNavigation();

  const currentTeamId = find(
    (team) => navigationDetails.domain === team.domain,
    availableTeams,
  )?.id;

  const {
    data: currentTeamData,
    loading: teamLoading,
    updateQuery,
  } = useTeamQuery({
    fetchPolicy: 'cache-first',
    skip: !currentTeamId,
    variables: {
      id: currentTeamId ?? '',
    },
  });

  useTeam_SubscriptionSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      updateQuery((prev) => ({
        // eslint-disable-next-line no-underscore-dangle
        __typename: prev.__typename,
        team_by_pk: subscriptionData.data?.team_by_pk ?? prev.team_by_pk,
      }));
    },
    skip: !currentTeamId,
    variables: {
      id: currentTeamId ?? '',
    },
  });

  const team = useMemo(
    () => currentTeamData?.team_by_pk ?? undefined,
    [currentTeamData?.team_by_pk],
  );

  const [deleteTeamMutation] = useDeleteTeamMutation();

  const deleteTeam = useCallback(
    async (nextPath?: string) => {
      if (!team) {
        return;
      }
      const redirectUrl = generateUrl();

      await deleteTeamMutation({
        variables: {
          teamId: team?.id,
        },
      });

      window.location.replace(`${redirectUrl}/${nextPath ?? ''}`);
    },
    [deleteTeamMutation, generateUrl, team],
  );

  const loading = teamLoading || navigationLoading;

  const value = useMemo<TeamContextType>(
    () => ({
      availableTeams,
      changeTeam,
      deleteTeam,
      generateUrl,
      loading,
      navigationDetails,
      rootPath,
      team,
    }),
    [
      availableTeams,
      changeTeam,
      deleteTeam,
      generateUrl,
      loading,
      navigationDetails,
      rootPath,
      team,
    ],
  );

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};
