import { Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDeleteAR from '../hooks/useDeleteAR';
import { LocalesKeys } from '../locales/localesKeys';
import DriverGuard from '../navigation/DriverGuard';
import { RouteModalComponent } from '../navigation/Modals';

const DeleteAR: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.ARDetails);
  const { isArchived, onDelete, onArchiveAR } = useDeleteAR();

  const navigate = useNavigate();

  const deleteAr = useCallback(async () => {
    await onDelete();
    navigate(-2);
  }, [navigate, onDelete]);

  const archiveAr = useCallback(async () => {
    onClose();
    await onArchiveAR();
  }, [onClose, onArchiveAR]);

  const Content = useMemo(
    () => (
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
      >
        <Heading size="headline3">{t('deleteModalHeader')}</Heading>
        <Text mt={6}>{t('deleteModalBody')}</Text>
        <Flex gridGap={6} mt={6}>
          {!isArchived && (
            <Button colorScheme="secondary" onClick={archiveAr} px={4}>
              {t('deleteModalCancelButton')}
            </Button>
          )}
          <Button onClick={deleteAr}>{t('deleteModalDeleteButton')}</Button>
        </Flex>
      </Flex>
    ),
    [isArchived, archiveAr, t, deleteAr],
  );

  return (
    <DriverGuard>
      <VStack w="full">{Content}</VStack>
    </DriverGuard>
  );
};

export default memo(DeleteAR);
