import { CloseIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { ModalProps } from '../navigation/Modals';

const CenterDialogModal: React.FC<ModalProps> = ({
  isOpen,
  children,
  onClose,
}) => (
  <AlertDialog
    isCentered
    isOpen={isOpen}
    leastDestructiveRef={undefined}
    onClose={onClose}
  >
    <AlertDialogOverlay />

    <AlertDialogContent borderRadius={8} minW="40vw" p={7}>
      <Flex justifyContent="flex-end">
        <CloseIcon color="black.500" cursor="pointer" onClick={onClose} />
      </Flex>
      <AlertDialogFooter>{children}</AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
);

export default CenterDialogModal;
