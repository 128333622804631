import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { InputMaybe, Project_User_Status_Enum } from '../generated/graphql';
import { FormSubmit } from '../types/formik';
import useARDetails from './useARDetails';

export const changeStatusAndAddNoteInitialValues = {
  note: '',
  status: undefined,
};

export type ChangeStatusAndAddNoteInitialValues = {
  note: string;
  status?: Project_User_Status_Enum;
};

const useChangeProjectStatusFormik = (onClose: () => void) => {
  const { updateProjectUser, status, ...arDetails } = useARDetails(onClose);

  const updateStatus = useCallback(
    async (note?: InputMaybe<string>, status?: Project_User_Status_Enum) => {
      await updateProjectUser({
        note,
        status,
      });
      onClose();
    },
    [onClose, updateProjectUser],
  );

  const onSubmit = useCallback<FormSubmit<ChangeStatusAndAddNoteInitialValues>>(
    async ({ note, status }) => updateStatus(note, status),
    [updateStatus],
  );

  const {
    getFieldMeta,
    getFieldProps,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik<ChangeStatusAndAddNoteInitialValues>({
    initialValues: changeStatusAndAddNoteInitialValues,
    onSubmit,
  });

  useEffect(() => {
    setFieldValue('status', status ?? undefined);
  }, [setFieldValue, status]);

  return {
    getFieldMeta,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...arDetails,
  };
};

export default useChangeProjectStatusFormik;
