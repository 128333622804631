import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const ApprovedIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} fill="#099B3B" r={10} />
    <path
      d="m15.333 9.083-3.99 5.834-2.677-2.633"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Icon>
);

export default memo(ApprovedIcon);
