import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRemoveFromTeam } from '../hooks/useRemoveFromTeam';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';

const RemoveFromTeam: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const { deleteUser } = useRemoveFromTeam();

  const onRemove = useCallback(async () => {
    await deleteUser();
    onClose();
  }, [deleteUser, onClose]);

  const Content = useMemo(
    () => (
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
      >
        <Heading size="headline3">{t('removeFromTeamHeader')}</Heading>
        <Text mt={6} textAlign="center">
          {t('removeFromTeamInfo')}
        </Text>
        <Flex gridGap={6} mt={6}>
          <Button colorScheme="secondary" onClick={onClose} px={6}>
            {t('cancel')}
          </Button>
          <Button onClick={onRemove} px={6}>
            {t('remove')}
          </Button>
        </Flex>
      </Flex>
    ),
    [t, onClose, onRemove],
  );

  return Content;
};

export default memo(RemoveFromTeam);
