import { Flex } from '@chakra-ui/react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import TextInput from './TextInput';

interface Props {
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
}

const CreateTeamInputs: React.FC<Props> = ({ getFieldMeta, getFieldProps }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  return (
    <Flex flexDirection="column" gridGap={4}>
      <TextInput
        {...getFieldMeta('teamName')}
        {...getFieldProps('teamName')}
        label={t('teamName')}
        placeholder={t('teamName')}
        touched
      />
      <TextInput
        {...getFieldMeta('domain')}
        {...getFieldProps('domain')}
        mt={-2}
        placeholder={t('domain')}
      />
    </Flex>
  );
};

export default CreateTeamInputs;
