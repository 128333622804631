import { Button, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useARDetails from '../hooks/useARDetails';
import { LocalesKeys } from '../locales/localesKeys';
import { DetailsIcon } from './icons/DetailsIcon';

interface Props {
  userId: string;
  userStatus: string;
}

const DeletedProjectUserCell: React.FC<Props> = ({ userId, userStatus }) => {
  const { t } = useTranslation(LocalesKeys.ARDetails);

  const { id } = useParams();

  const projectId = id || '';

  const { onDeleteProjectUser } = useARDetails();

  const onDelete = useCallback(async () => {
    onDeleteProjectUser(userId, projectId);
  }, [onDeleteProjectUser, projectId, userId]);

  const DeletedUserFromTeamLabel = useMemo(
    () => (
      <VStack align="flex-start" spacing={2}>
        <Text size="caption2">{t('deletedUserFromTeamLabel')}</Text>
        <Text size="caption1">{t('userStatus', { status: userStatus })}</Text>
      </VStack>
    ),
    [t, userStatus],
  );

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Tooltip label={DeletedUserFromTeamLabel} placement="bottom-start" w={64}>
        <DetailsIcon boxSize={6} color="red" />
      </Tooltip>
      <Button
        colorScheme="red"
        onClick={onDelete}
        px={5}
        py={0}
        w="fit-content"
      >
        {t('remove')}
      </Button>
    </Flex>
  );
};

export default memo(DeletedProjectUserCell);
