import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useAuth } from '@stormotion-auth/react';
import { OAuth2ProviderType } from '@stormotion-auth/core';
import { usePushNotifications } from './usePushNotifications';

const useAppAuth = () => {
  const {
    signOut,
    signUpWithPassword,
    signInWithPassword,
    signInWithOAuth2,
    requestSignInWithOTP,
    requestRestorePassword,
    signInWithEmail,
    changePassword: changeUserPassword,
    updateUserOTP,
    removeUser,
    restorePassword: restoreUserPassword,
  } = useAuth();

  const confirmSignUp = useCallback<
    (email: string, verification: string) => ReturnType<typeof signInWithEmail>
  >(
    (email, verification) => signInWithEmail(email, verification),
    [signInWithEmail],
  );

  const { unsubscribe } = usePushNotifications();

  const client = useApolloClient();

  const logout = useCallback(async () => {
    await unsubscribe();

    await signOut();

    await client.cache.reset();
  }, [client.cache, unsubscribe, signOut]);

  const sendEmailCode = useCallback<
    (email: string) => ReturnType<typeof requestSignInWithOTP>
  >((email) => requestSignInWithOTP(email), [requestSignInWithOTP]);

  const sendRestoreEmailCode = useCallback<
    (email: string) => ReturnType<typeof requestRestorePassword>
  >((email) => requestRestorePassword(email), [requestRestorePassword]);

  const signIn = useCallback<
    (email: string, password: string) => ReturnType<typeof signInWithPassword>
  >(
    (email, password) => signInWithPassword(email, password),
    [signInWithPassword],
  );

  const signInWithGoogle = useCallback<() => Promise<void>>(async () => {
    await signOut();
    signInWithOAuth2(OAuth2ProviderType.Google, window.location.href);
  }, [signInWithOAuth2, signOut]);

  const signInWithSlack = useCallback<() => Promise<void>>(async () => {
    await signOut();
    signInWithOAuth2(OAuth2ProviderType.Slack, window.location.href);
  }, [signInWithOAuth2, signOut]);

  const signUp = useCallback<
    (email: string, password: string) => ReturnType<typeof signUpWithPassword>
  >(
    (email, password) => signUpWithPassword(email, password),
    [signUpWithPassword],
  );

  const changePassword = useCallback<typeof changeUserPassword>(
    (oldPassword, newPassword) => changeUserPassword(oldPassword, newPassword),
    [changeUserPassword],
  );

  const deleteUser = useCallback<typeof removeUser>(
    () => removeUser(),
    [removeUser],
  );

  const updateOTP = useCallback<typeof updateUserOTP>(
    (otpEnabled) => updateUserOTP(otpEnabled),
    [updateUserOTP],
  );

  const restorePassword = useCallback<typeof restoreUserPassword>(
    (password) => restoreUserPassword(password),
    [restoreUserPassword],
  );

  return {
    changePassword,
    confirmSignUp,
    deleteUser,
    logout,
    restorePassword,
    sendEmailCode,
    sendRestoreEmailCode,
    signIn,
    signInWithGoogle,
    signInWithSlack,
    signUp,
    updateOTP,
  };
};

export default useAppAuth;
