import { AuthProvider } from '@stormotion-auth/react';
import React, { memo } from 'react';
import App from './App';
import Loading from './components/Loading';
import useRootAuth from './hooks/useRootAuth';
import { AuthContextProvider } from './store/contexts/AuthContext';

const Root = () => {
  const isReady = useRootAuth();

  if (!isReady) {
    return <Loading />;
  }

  return (
    <AuthProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </AuthProvider>
  );
};

export default memo(Root);
