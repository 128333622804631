import React, { memo, useCallback, useMemo } from 'react';
import {
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuNavigator } from '../pages/Settings';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import { IconClass } from '../theme/utils';
import useNavigation from '../hooks/useNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import ChromeIcon from './icons/ChromeIcon';
import useUserEmail from '../hooks/useUserEmail';
import { useUserContext } from '../store/contexts/UserContext';
import Avatar from './Avatar';
import { useTeamContext } from '../store/contexts/TeamContext';

type Props = {
  items: MenuNavigator[];
};

const BACK_ICON = <ChevronLeftIcon className={IconClass.CustomStroke} />;
const CHROME_ICON = <ChromeIcon />;

const SettingsHeader: React.FC<Props> = ({ items }) => {
  const { goBackOrReplace } = useNavigation();
  const { t } = useTranslation(LocalesKeys.Settings);

  const email = useUserEmail();
  const { user } = useUserContext();

  const username = user?.username ?? undefined;
  const imageUrl = user?.image?.url ?? undefined;
  const displayName = username ?? email;
  const userId = user?.id;

  const renderHeading = useCallback<
    (item: MenuNavigator, index: number) => JSX.Element
  >(
    (item, index) => (
      <Route
        key={item.route}
        element={
          <Heading color="black.600" size="headline2">
            {item.name}
          </Heading>
        }
        index={index === 0}
        path={item.route}
      />
    ),
    [],
  );

  const { rootPath } = useTeamContext();

  const goBack = useCallback(() => {
    goBackOrReplace(rootPath);
  }, [goBackOrReplace, rootPath]);

  const headingsFromItems = useMemo(
    () => items.map(renderHeading),
    [items, renderHeading],
  );

  return (
    <Flex h={24} w="full">
      <Flex align="center" flex={1}>
        <IconButton
          aria-label="back"
          boxSize={12}
          colorScheme="secondary"
          icon={BACK_ICON}
          onClick={goBack}
        />
      </Flex>
      <HStack align="center" flex={8} spacing={6}>
        <Avatar name={displayName} src={imageUrl} userId={userId} />
        <Flex flexDir="column">
          <HStack>
            {username ? (
              <Heading color="primary.700" size="headline2">
                {`${username} / `}
              </Heading>
            ) : null}
            <Routes>{headingsFromItems}</Routes>
          </HStack>
          <Text color="black.500" size="body1">
            {email}
          </Text>
        </Flex>
      </HStack>
      <Flex align="center" flex={2}>
        <Button colorScheme="secondary" leftIcon={CHROME_ICON} px={8}>
          {t('extension')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(SettingsHeader);
