import { Location } from 'react-router-dom';

export const canGoBack = () => window.history.state.idx > 1;

export const getTeamByUrl = (location: Location) =>
  location.pathname.split('/')[1];

export const getTeamBySubdomain = () => window.location.hostname.split('.')[0];

export const generateUrlWithSubdomain = (
  subdomainToReplace: boolean,
  teamDomain?: string,
) =>
  `${window.location.protocol}//${teamDomain ? `${teamDomain}.` : ''}${
    subdomainToReplace
      ? window.location.host.substring(
          window.location.hostname.indexOf('.') + 1,
        )
      : window.location.host
  }`;
