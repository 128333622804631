import { CloseButton, Flex, Text } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';

interface Props {
  invitedUserEmail: string;
  onDeleteInvitedUser: (email: any) => Promise<void>;
  isEmailValid: boolean;
}

const InvitedUserListItem: React.FC<Props> = ({
  invitedUserEmail,
  onDeleteInvitedUser,
  isEmailValid,
}) => {
  const onClick = useCallback(
    () => onDeleteInvitedUser(invitedUserEmail),
    [invitedUserEmail, onDeleteInvitedUser],
  );

  return (
    <Flex
      alignItems="center"
      backgroundColor={isEmailValid ? 'white' : 'red.200'}
      borderRadius={8}
      borderWidth="1px"
      justifyContent="center"
      mb={2}
      mr={2}
      px={2}
      py={1}
    >
      <Text>{invitedUserEmail}</Text>
      <CloseButton onClick={onClick} />
    </Flex>
  );
};

export default memo(InvitedUserListItem);
