import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  fontFamily: 'body',
  fontWeight: 'normal',
};

const sizes: Record<string, SystemStyleObject> = {
  body1: {
    fontSize: 'md',
    lineHeight: 'base',
  },
  body2: {
    fontSize: 'sm',
    lineHeight: 'shorter',
  },
  button: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'shorter',
  },
  caption1: {
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: 'shorter',
  },
  caption2: {
    fontSize: 'xs',
  },
  subhead1: {
    fontSize: 'md',
    fontWeight: 'medium',
    lineHeight: 'short',
  },
  subhead2: {
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: 'shorter',
  },
};

const defaultProps = {
  size: 'body1',
};

export default {
  baseStyle,
  defaultProps,
  sizes,
};
