import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const UnderReviewIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} fill="#0079BC" r={10} />
    <circle cx={12} cy={12} fill="#fff" r={4} />
  </Icon>
);

export default memo(UnderReviewIcon);
