import { Td, Tr } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { Cell, Row } from 'react-table';
import { ProjectDashboardFragment } from '../generated/graphql';

interface Props {
  id?: string | null;
  row: Row<ProjectDashboardFragment>;
}

const DashboardTableRow: React.FC<Props> = ({ row }) => {
  const renderTableCell = useCallback<
    (cell: Cell<ProjectDashboardFragment>) => JSX.Element
  >((cell) => <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>, []);

  return <Tr {...row.getRowProps()}>{row.cells.map(renderTableCell)}</Tr>;
};

export default memo(DashboardTableRow);
