export const authRegion = process.env.REACT_APP_AUTH_REGION;
export const cookieStorageDomain = process.env.REACT_APP_COOKIE_STORAGE_DOMAIN;

export const googleDrivePickerDeveloperKey =
  process.env.REACT_APP_GOOGLE_PICKER_DEVELOPER_KEY;
export const googleOauthClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

export const hasuraApiUrl = process.env.REACT_APP_HASURA_API_URL;
export const hasuraSubscriptionsUrl =
  process.env.REACT_APP_HASURA_SUBSCRIPTIONS_URL!;
export const authUrl = process.env.REACT_APP_AUTH_URL!;

export const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;

export const oauthDomain = process.env.REACT_APP_OAUTH_DOMAIN;
export const oauthRedirectUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL;

export const userPoolId = process.env.REACT_APP_USER_POOL_ID;
export const userPoolWebClientId =
  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;

export const stripePublickey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const projectVersion = process.env.REACT_APP_VERSION_NUMBER;

export const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

export const navigationType = process.env.REACT_APP_TEAM_NAVIGATION;
