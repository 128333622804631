import React, { FormEventHandler, memo, useCallback, useMemo } from 'react';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { sortBy } from 'ramda';
import { LocalesKeys } from '../locales/localesKeys';
import { SettingByType } from './NotificationSettingsTable';
import { NotificationSettingsValues } from '../utils/formValidations';
import { settingToString } from '../hooks/useNotificationSettings';
import CommunicationMethod from './CommunicationMethod';
import SendingReminders from './SendingReminders';

const NotificationSettings: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.NotificationSettings);

  const {
    handleSubmit,
    setFieldValue,
    values,
    getFieldProps,
    getFieldMeta,
    isSubmitting,
  } = useFormikContext<NotificationSettingsValues>();

  const remindersValue = useMemo<NotificationSettingsValues['reminders']>(
    () =>
      sortBy<SettingByType>((a) => settingToString(a), values.reminders ?? []),
    [values.reminders],
  );

  const setRemindersValue = useCallback(
    (settings: NotificationSettingsValues['reminders']) =>
      setFieldValue('reminders', settings),
    [setFieldValue],
  );

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit as FormEventHandler}
      p={10}
      spacing={10}
      w="full"
    >
      <SendingReminders />
      <CommunicationMethod
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        setSettings={setRemindersValue}
        settings={remindersValue ?? []}
      />
      <Flex justify="flex-end" w="full">
        <Button isLoading={isSubmitting} type="submit" w="fit-content">
          {t('submit')}
        </Button>
      </Flex>
    </VStack>
  );
};

export default memo(NotificationSettings);
