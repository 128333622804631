import { Formik, FormikConfig } from 'formik';
import React, { memo } from 'react';
import { PropsOf } from '@chakra-ui/react';
import ProjectEditor from './ProjectEditor';
import {
  CreateARStep1Values,
  createARStep1ValidationSchema,
} from '../utils/formValidations';

type Props = PropsOf<typeof ProjectEditor> & FormikConfig<CreateARStep1Values>;

const ProjectEditorForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  ...props
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnMount
    validationSchema={createARStep1ValidationSchema}
  >
    <ProjectEditor {...props} />
  </Formik>
);

export default memo(ProjectEditorForm);
