import * as R from 'ramda';
import { useMemo } from 'react';
import {
  Project_User_Role_Enum,
  Team_User_Role_Enum,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';

const useDeleteAccount = () => {
  const { availableTeams } = useTeamContext();

  const { user } = useUserContext();

  const projectsWhereUserDriver = useMemo(
    () =>
      R.flatten(
        availableTeams.map((team) =>
          team.projects.filter((project) =>
            project.project_users.some(
              (projectUser) =>
                projectUser.role === Project_User_Role_Enum.Driver &&
                projectUser.user.id === user?.id,
            ),
          ),
        ),
      ),
    [availableTeams, user?.id],
  );

  const projectsWhereUserDriverList = `${projectsWhereUserDriver
    .map((project) => project.name)
    .join(', ')}.`;

  const teamsWhereUserTeamOwner = useMemo(
    () =>
      availableTeams.filter((team) =>
        team.team_users.some(
          (teamUser) =>
            teamUser.role === Team_User_Role_Enum.Owner &&
            teamUser.user.id === user?.id,
        ),
      ),
    [availableTeams, user?.id],
  );

  const teamsWhereUserTeamOwnerList = `${teamsWhereUserTeamOwner
    .map((project) => project.name)
    .join(', ')}.`;

  const isUserDriverOrTeamOwner = !!(
    projectsWhereUserDriver.length + teamsWhereUserTeamOwner.length
  );

  return {
    isUserDriverOrTeamOwner,
    projectsWhereUserDriver,
    projectsWhereUserDriverList,
    teamsWhereUserTeamOwner,
    teamsWhereUserTeamOwnerList,
  };
};

export default useDeleteAccount;
