import type {
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  alignItems: 'center',
  borderRadius: '4xl',
  display: 'inline-flex',
  justifyContent: 'center',
  textTransform: 'none',
};

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return { bg: `${c}.600`, color: 'white' };
};

const variantSubtle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return { bg: `${c}.200`, color: `${c}.600` };
};

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c, theme } = props;
  const darkColor = transparentize(`${c}.200`, 0.8)(theme);
  const lightColor = getColor(theme, `${c}.500`);
  const color = mode(lightColor, darkColor)(props);

  return {
    boxShadow: `inset 0 0 0px 1px ${color}`,
    color,
  };
};

const variants = {
  outline: variantOutline,
  solid: variantSolid,
  subtle: variantSubtle,
};

const sizes: Record<string, SystemStyleObject> = {
  md: {
    px: 3,
    py: 1.5,
  },
  xs: {
    borderRadius: 'full',
    h: 6,
    minW: 6,
  },
};

const defaultProps = {
  colorScheme: 'gray',
  size: 'md',
  variant: 'subtle',
};

export default {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};
