import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface Props extends IconProps {
  downColor: string;
  upColor: string;
}

const TableSortIcon: React.FC<Props> = ({ downColor, upColor, ...props }) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.6}>
      <path d="M8 16L12 20L16 16" stroke={upColor} />
      <path d="M16 8L12 4L8 8" stroke={downColor} />
    </g>
  </Icon>
);

export default memo(TableSortIcon);
