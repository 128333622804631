import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput, { TextInputProps } from './TextInput';
import { LocalesKeys } from '../locales/localesKeys';

interface Props extends TextInputProps {}

const PasswordInput: React.FC<Props> = (props) => {
  const { t } = useTranslation(LocalesKeys.Input);

  const placeholder = t('password.placeholder');

  return <TextInput placeholder={placeholder} type="password" {...props} />;
};

export default memo(PasswordInput);
