import { AddIcon } from '@chakra-ui/icons';
import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsTeamOwner } from '../hooks/useIsTeamOwner';
import { useTeamSettings } from '../hooks/useTeamSettings';
import { useTeamSettingsNavigation } from '../hooks/useTeamSettingsNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import ChangeUserPermissionIcon from './icons/ChangeUserPermissionIcon';
import DeleteIcon from './icons/DeleteIcon';
import MoreIcon from './icons/MoreIcon';
import TransferOwnershipIcon from './icons/TransferOwnershipIcon';

type TeamSettingsMenuItem = {
  icon: JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  rule?: boolean;
};

type Props = {
  id: string;
};

const TeamSettingsMenu: React.FC<Props> = ({ id }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const isTeamOwner = useIsTeamOwner();

  const { currentUserId } = useTeamSettings();

  const stopPropagation = useCallback<
    (e: { stopPropagation: () => void }) => void
  >((e) => e.stopPropagation(), []);

  const {
    openAddProjects,
    openChangePermission,
    openRemoveFromTeam,
    openTransferOwnership,
  } = useTeamSettingsNavigation(id);

  const menuItems: TeamSettingsMenuItem[] = useMemo(
    () => [
      {
        icon: <TransferOwnershipIcon />,
        onClick: openTransferOwnership,
        rule: isTeamOwner && currentUserId !== id,
        title: t('transferOwnership'),
      },
      {
        icon: <AddIcon w={5} />,
        onClick: openAddProjects,
        rule: isTeamOwner,
        title: t('addProjects'),
      },
      {
        icon: <ChangeUserPermissionIcon />,
        onClick: openChangePermission,
        rule: isTeamOwner,
        title: t('changePermission'),
      },
      {
        icon: <DeleteIcon />,
        onClick: openRemoveFromTeam,
        rule: isTeamOwner && currentUserId !== id,
        title: t('removeFromTeam'),
      },
    ],
    [
      isTeamOwner,
      openAddProjects,
      openChangePermission,
      openRemoveFromTeam,
      openTransferOwnership,
      currentUserId,
      id,
      t,
    ],
  );

  const availableMenuItems = useMemo(
    () => menuItems.filter((item) => item.rule),
    [menuItems],
  );

  const renderItem = useCallback<
    (option: TeamSettingsMenuItem) => JSX.Element | undefined
  >(
    ({ icon: Icon, onClick, title }) => (
      <MenuItem key={title} onClick={onClick} px={4} py={3}>
        <HStack minW={0} spacing={2}>
          {Icon}
          <Text color="black.500">{title}</Text>
        </HStack>
      </MenuItem>
    ),
    [],
  );

  const onButtonClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      stopPropagation(e);
      onToggle();
    },
    [onToggle, stopPropagation],
  );

  return (
    <>
      <Menu isOpen={isOpen} onClose={onClose}>
        <IconButton
          aria-label=""
          borderRadius="full"
          boxSize="fit-content"
          colorScheme="gray"
          icon={
            <MenuButton
              borderRadius="full"
              boxSize="fit-content"
              colorScheme="gray"
              minW="fit-content"
              onClick={onButtonClick}
              p={2}
              variant="ghost"
            >
              <MoreIcon />
            </MenuButton>
          }
          minW="fit-content"
          onClick={onToggle}
          p={2}
          variant="ghost"
        />
        <MenuList onClick={stopPropagation} overflow="hidden" p="0">
          {availableMenuItems.map(renderItem)}
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(TeamSettingsMenu);
