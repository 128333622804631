import React, { memo } from 'react';
import { AvatarProps, Box, CSSObject, Input, chakra } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Avatar from './Avatar';

interface Props extends AvatarProps {
  onImageSelected?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  userId?: string;
}

const AVATAR_STYLE: CSSObject = {
  '> *': {
    transition: '.3s ease',
  },
};

const AVATAR_HOVER_STYLE: CSSObject = {
  '+ .hidden': { opacity: 1 },
  '> *': {
    filter: 'blur(5px)',
  },
};

const ImagePicker: React.FC<Props> = ({
  onImageSelected,
  userId,
  ...props
}) => (
  <chakra.label borderRadius="full" position="relative">
    <Avatar
      _hover={AVATAR_HOVER_STYLE}
      cursor="pointer"
      size="xl"
      sx={AVATAR_STYLE}
      userId={userId}
      {...props}
    />
    <Box
      className="hidden"
      left="50%"
      opacity={0}
      pointerEvents="none"
      position="absolute"
      top="50%"
      transform="translate(-50%, -50%)"
      transition=".3s ease"
    >
      <EditIcon color="white" h={7} w={7} />
    </Box>
    <Input
      accept="image/*"
      display="none"
      name="image"
      onChange={onImageSelected}
      type="file"
    />
  </chakra.label>
);

export default memo(ImagePicker);
