import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import button from './locales/en/button';
import input from './locales/en/input';
import signup from './locales/en/signup';
import login from './locales/en/login';
import verification from './locales/en/verification';
import loginVerification from './locales/en/loginVerification';
import signupVerification from './locales/en/signupVerification';
import createAR from './locales/en/createAR';
import createTeam from './locales/en/createTeam';
import status from './locales/en/status';
import header from './locales/en/header';
import dashboard from './locales/en/dashboard';
import settings from './locales/en/settings';
import accountSettings from './locales/en/accountSettings';
import securitySettings from './locales/en/securitySettings';
import rolesDetails from './locales/en/rolesDetails';
import arDetails from './locales/en/arDetails';
import validationErrors from './locales/en/validationErrors';
import emptyStates from './locales/en/emptyStates';
import archive from './locales/en/archive';
import billing from './locales/en/billing';
import common from './locales/en/common';
import teamSettings from './locales/en/teamSettings';
import forgetPassword from './locales/en/forgetPassword';
import forgetPasswordVerification from './locales/en/forgetPasswordVerification';
import notifications from './locales/en/notifications';
import notificationSettings from './locales/en/notificationSettings';
import deleteTeamSuccessful from './locales/en/deleteTeamSuccessful';
import teamMenu from './locales/en/teamMenu';

const resources = {
  en: {
    accountSettings,
    archive,
    arDetails,
    billing,
    button,
    common,
    createAR,
    createTeam,
    dashboard,
    deleteTeamSuccessful,
    emptyStates,
    forgetPassword,
    forgetPasswordVerification,
    header,
    input,
    login,
    loginVerification,
    notifications,
    notificationSettings,
    rolesDetails,
    securitySettings,
    settings,
    signup,
    signupVerification,
    status,
    teamMenu,
    teamSettings,
    validationErrors,
    verification,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    resources,
  });

export default i18n;
