import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const SearchIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M3.8 11a7.2 7.2 0 1 1 12.374 5.007.808.808 0 0 0-.167.167A7.2 7.2 0 0 1 3.8 11Zm12.831 6.763A8.8 8.8 0 1 1 19.8 11a8.764 8.764 0 0 1-2.037 5.631l3.803 3.803a.8.8 0 1 1-1.132 1.132l-3.803-3.803Z"
      fill="#A2A3A4"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(SearchIcon);
