import { Button, Flex, Heading, Text, VStack, chakra } from '@chakra-ui/react';
import { OAuth2ProviderType } from '@stormotion-auth/core';
import { useAuth } from '@stormotion-auth/react';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCustomToast } from '../hooks/useCustomToast';
import useUpdateUser from '../hooks/useUpdateUser';
import useUpdateUserForm from '../hooks/useUpdateUserForm';
import useUserEmail from '../hooks/useUserEmail';
import { LocalesKeys } from '../locales/localesKeys';
import routes from '../navigation/routes';
import { useUserContext } from '../store/contexts/UserContext';
import { FormSubmit } from '../types/formik';
import {
  AccountInfoValues,
  accountInfoValidationSchema,
} from '../utils/formValidations';
import AccountSocial, { OnAddSocial, OnRemoveSocial } from './AccountSocial';
import EmailInput from './EmailInput';
import ImagePicker from './ImagePicker';
import TextInput from './TextInput';

const usernameBoxProps = {
  pb: 4,
  w: 'full',
};

const emailBoxProps = {
  pb: 12,
  w: 'full',
};

const ALL_PROVIDERS = [OAuth2ProviderType.Google, OAuth2ProviderType.Slack];

interface UserSocialProvider {
  providerType: OAuth2ProviderType;
  isEnabled: boolean;
}

const AccountSettings: React.FC = () => {
  const { user } = useUserContext();
  const { updateUser } = useUpdateUser();
  const {
    oAuth2Providers,
    getOAuth2UserProviders,
    removeOAuth2UserProvider,
    signInWithOAuth2,
  } = useAuth();

  useEffect(() => {
    getOAuth2UserProviders();

    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation(LocalesKeys.AccountSettings);

  const { successToast, warningToast } = useCustomToast();

  const email = useUserEmail() ?? '';
  const username = user?.username ?? undefined;

  const initialValues = useMemo<AccountInfoValues>(
    () => ({ email, localImage: null, localUrl: null, username }),
    [email, username],
  );

  const userProviders = useMemo(
    () =>
      ALL_PROVIDERS.map((providerType) => ({
        isEnabled: oAuth2Providers.some(
          (oauth2Provider) => oauth2Provider.provider === providerType,
        ),
        providerType,
      })),
    [oAuth2Providers],
  );

  const removeSocial = useCallback<OnRemoveSocial>(
    async (provider) => {
      const response = await removeOAuth2UserProvider(provider);

      // eslint-disable-next-line no-underscore-dangle
      if (response.__typename === 'AuthError') {
        warningToast({
          headerMessage: t('socialRemoveFailureHeader'),
          toastMessage: t(response.message),
        });
      } else {
        successToast({
          headerMessage: t('socialRemoveSuccessHeader'),
          toastMessage: t('socialRemoveSuccessMessage'),
        });
      }
    },
    [removeOAuth2UserProvider, successToast, t, warningToast],
  );

  const addSocial = useCallback<OnAddSocial>(
    (provider) => {
      signInWithOAuth2(provider, window.location.href);
    },
    [signInWithOAuth2],
  );

  const onSubmit = useCallback<FormSubmit<AccountInfoValues>>(
    async ({ username, localImage, localUrl }, { setFieldValue }) => {
      try {
        await updateUser(username, localImage);

        successToast({
          headerMessage: t('accountUpdated'),
          toastMessage: t('changesSaved'),
        });
      } catch (e) {
        if (!(e instanceof Error)) return;

        warningToast({
          headerMessage: t('accountUpdateError'),
          toastMessage: e.message,
        });
      } finally {
        if (localUrl) {
          URL.revokeObjectURL(localUrl);
          setFieldValue('localImage', null);
        }
        window.scroll({ behavior: 'smooth', left: 0, top: 0 });
      }
    },
    [successToast, t, updateUser, warningToast],
  );

  const renderSocial = useCallback<
    (provider: UserSocialProvider) => React.ReactElement
  >(
    ({ providerType, isEnabled }) => (
      <AccountSocial
        isEnabled={isEnabled}
        mr={2}
        onAdd={addSocial}
        onRemove={removeSocial}
        providerType={providerType}
      />
    ),
    [addSocial, removeSocial],
  );

  const {
    avatarName,
    getFieldMeta,
    getFieldProps,
    handleImageSelect,
    handleSubmit,
    isSubmitDisabled,
    isSubmitting,
    values: { localUrl },
  } = useUpdateUserForm({
    initialValues,
    onSubmit,
    validationSchema: accountInfoValidationSchema,
  });

  const imageUrl = localUrl ?? user?.image?.url ?? undefined;

  const navigate = useNavigate();

  const Info = useMemo(
    () => (
      <>
        <Flex align="center" flex={1} justify="flex-start" mb={6}>
          <ImagePicker
            name={avatarName}
            onImageSelected={handleImageSelect}
            src={imageUrl}
            userId={user?.id}
          />
        </Flex>
        <TextInput
          boxProps={usernameBoxProps}
          {...getFieldMeta('username')}
          {...getFieldProps('username')}
          helpingText={t('usernameHelpingText')}
          placeholder={t('usernamePlaceholder')}
        />
        <EmailInput
          {...getFieldMeta('email')}
          {...getFieldProps('email')}
          boxProps={emailBoxProps}
          isDisabled
          label={t('emailLabel')}
        />
      </>
    ),
    [
      avatarName,
      handleImageSelect,
      imageUrl,
      user?.id,
      getFieldMeta,
      getFieldProps,
      t,
    ],
  );

  const LinkedAccounts = useMemo(
    () => (
      <>
        <Heading pb={4} size="headline3">
          {t('linkedAccounts')}
        </Heading>
        <Text pb={6} size="body1">
          {t('signInDescription')}
        </Text>
        <Flex align="center" pb={10}>
          {userProviders.map(renderSocial)}
        </Flex>
      </>
    ),
    [t, userProviders, renderSocial],
  );

  const openDeleteModal = useCallback(
    () => navigate(routes.AccountSettingsRoutes.DeleteAccount),
    [navigate],
  );

  const Delete = useMemo(
    () => (
      <>
        <Heading pb={4} size="headline3">
          {t('deleteHeading')}
        </Heading>
        <Text pb={4} size="body1">
          {t('deleteDescription')}
        </Text>
        <Flex pb={10}>
          <Button colorScheme="red" onClick={openDeleteModal} w="fit-content">
            {t('delete')}
          </Button>
        </Flex>
      </>
    ),
    [openDeleteModal, t],
  );

  const Submit = useMemo(
    () => (
      <>
        <Flex justify="flex-end" w="full">
          <Button
            colorScheme="primary"
            isDisabled={isSubmitDisabled}
            isLoading={isSubmitting}
            type="submit"
            w="fit-content"
          >
            {t('submit')}
          </Button>
        </Flex>
      </>
    ),
    [isSubmitDisabled, isSubmitting, t],
  );

  return (
    <VStack
      alignItems="flex-start"
      color="black.500"
      p={10}
      spacing={0}
      w="100%"
    >
      <chakra.form onSubmit={handleSubmit} w="full">
        {Info}
        {LinkedAccounts}
        {Delete}
        {Submit}
      </chakra.form>
    </VStack>
  );
};

export default memo(AccountSettings);
