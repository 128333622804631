/* eslint-disable no-nested-ternary */
import { Flex } from '@chakra-ui/react';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArchiveHeader from '../components/ArchiveHeader';
import DashboardPagination from '../components/DashboardPagination';
import DashboardTable from '../components/DashboardTable';
import EmptyState from '../components/EmptyState';
import { Project_User_Display_Status_Enum } from '../generated/graphql';
import { PAGE_SIZE_OPTIONS } from '../hooks/useDashboardForm';
import useProjectsData from '../hooks/useProjectsData';
import { LocalesKeys } from '../locales/localesKeys';

const Archive: React.FC = () => {
  const {
    drivers,
    handleNextClick,
    handlePageSizeChange,
    handlePrevClick,
    handleSortingClick,
    projects,
    page,
    offset,
    pageSize,
    sorting,
    totalCount,
    nameQuery,
    status,
    driver,
  } = useProjectsData(Project_User_Display_Status_Enum.Archive);

  const { t } = useTranslation(LocalesKeys.EmptyStates);

  const arNameQuery = nameQuery.replaceAll('%', '').trim();

  const ProjectsEmptyState = useMemo(
    () =>
      arNameQuery.length || status?.length ? (
        <EmptyState headline={t('noARsMatched')} isSearchIcon />
      ) : driver?.length ? (
        <EmptyState headline={t('noDriversMatched')} isSearchIcon />
      ) : (
        <EmptyState headline={t('archive')} />
      ),
    [arNameQuery.length, driver?.length, status?.length, t],
  );

  return (
    <Flex direction="column" flex={1} px={24} py={12}>
      <ArchiveHeader drivers={drivers} />
      <Flex
        bg="white"
        borderRadius="lg"
        boxShadow="box"
        direction="column"
        flex={1}
        mt={6}
        overflow="hidden"
      >
        {projects.length ? (
          <DashboardTable
            onSortingClick={handleSortingClick}
            projects={projects}
            sorting={sorting}
          />
        ) : (
          ProjectsEmptyState
        )}
        <DashboardPagination
          count={totalCount}
          offset={offset}
          onNextClick={handleNextClick}
          onPageSizeChange={handlePageSizeChange}
          onPrevClick={handlePrevClick}
          page={page}
          pageSize={pageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
        />
      </Flex>
    </Flex>
  );
};

export default memo(Archive);
