import React, { memo } from 'react';
import routes from './routes';
import BaseGuard, { BaseGuardProps } from './BaseGuard';
import useIsUserOnTeam from '../hooks/useIsUserOnTeam';

interface Props extends BaseGuardProps {}

const TeamGuard: React.FC<Props> = (props) => {
  const { isUserOnTeam, loading } = useIsUserOnTeam();

  return (
    <BaseGuard
      loading={loading}
      rule={isUserOnTeam}
      to={`/${routes.ModalRoute.Personalization}`}
      {...props}
    />
  );
};

export default memo(TeamGuard);
