import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const CrossIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M18.5649 6.56588C18.8773 6.25346 18.8773 5.74693 18.5649 5.43451C18.2525 5.12209 17.746 5.12209 17.4335 5.43451L11.9992 10.8688L6.5649 5.43451C6.25248 5.12209 5.74595 5.12209 5.43353 5.43451C5.12111 5.74693 5.12111 6.25346 5.43353 6.56588L10.8678 12.0002L5.43353 17.4345C5.12111 17.7469 5.12111 18.2535 5.43353 18.5659C5.74595 18.8783 6.25248 18.8783 6.5649 18.5659L11.9992 13.1316L17.4335 18.5659C17.746 18.8783 18.2525 18.8783 18.5649 18.5659C18.8773 18.2535 18.8773 17.7469 18.5649 17.4345L13.1306 12.0002L18.5649 6.56588Z"
      fill="#16181C"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(CrossIcon);
