import React, { memo } from 'react';
import { Box, Flex, Slide, Spacer } from '@chakra-ui/react';
import { ModalProps } from '../navigation/Modals';

const SLIDE_STYLE = { zIndex: 10 };

interface Props extends ModalProps {
  ratio: number;
}

const BaseSidebar: React.FC<Props> = ({ isOpen, onClose, children, ratio }) => (
  <>
    <Box
      bg="black.600"
      h="full"
      left={0}
      opacity={isOpen ? '.4' : '0'}
      position="fixed"
      top={0}
      transitionDuration="300ms"
      transitionProperty="opacity"
      w="full"
    />
    <Slide direction="right" in={isOpen} style={SLIDE_STYLE}>
      <Flex h="full" position="relative">
        <Spacer cursor="pointer" flex={ratio} onClick={onClose} />
        <Flex bg="white" flex={1}>
          {children}
        </Flex>
      </Flex>
    </Slide>
  </>
);
export default memo(BaseSidebar);
