import React from 'react';
import * as R from 'ramda';

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A extends {} | null>() {
  const ctx = React.createContext<A | undefined>(undefined);

  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined)
      throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }

  return [useCtx, ctx] as const; // 'as const' makes TypeScript infer a tuple
}

export const objOrEmpty = <T extends object>(
  object?: T,
  condition?: unknown | null,
): T | object => {
  if (!object) {
    return {};
  }

  return condition ? object : R.empty<T>(object);
};
