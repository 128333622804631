import { Formik } from 'formik';
import React, { memo } from 'react';
import { useNotificationSettings } from '../hooks/useNotificationSettings';
import { notificationSettingsValidationSchema } from '../utils/formValidations';
import Loading from './Loading';
import NotificationSettings from './NotificationSettings';

const ProjectEditorForm: React.FC = () => {
  const { initialValues, onSubmit, loading } = useNotificationSettings();

  return !loading ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={notificationSettingsValidationSchema}
    >
      <NotificationSettings />
    </Formik>
  ) : (
    <Loading />
  );
};

export default memo(ProjectEditorForm);
