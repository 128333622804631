import { Button, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteUserMutation } from '../generated/graphql';
import useAppAuth from '../hooks/useAppAuth';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import { FormSubmit } from '../types/formik';
import {
  DeleteConfirmationValues,
  deletingConfirmationValidationSchema,
} from '../utils/formValidations';
import TextInput from './TextInput';

const initialValues: DeleteConfirmationValues = {
  confirmation_input: '',
};

const DeleteAccountConfirmation: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.AccountSettings);

  const { deleteUser: deleteUserAuth } = useAppAuth();

  const [deleteUser] = useDeleteUserMutation();

  const deleteAccount: FormSubmit<DeleteConfirmationValues> =
    useCallback(async () => {
      const deletedUser = await deleteUser();

      if (deletedUser.data?.delete_account.id) {
        await deleteUserAuth();
      }
    }, [deleteUser, deleteUserAuth]);

  const { getFieldProps, getFieldMeta, handleSubmit, isSubmitting, isValid } =
    useFormik({
      initialValues,
      onSubmit: deleteAccount,
      validateOnMount: true,
      validationSchema: deletingConfirmationValidationSchema,
    });

  return (
    <chakra.form onSubmit={handleSubmit} w="full">
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pt={18}
        w="full"
      >
        <Heading size="headline3">{t('deleteAccountHeader')}</Heading>
        <Text mb={5} mt={6} textAlign="center">
          {t('deleteAccountBody')}
        </Text>
        <TextInput
          {...getFieldMeta('confirmation_input')}
          {...getFieldProps('confirmation_input')}
          label={t('typeDeleteToConfirm')}
          placeholder={t('deleteUppercase')}
          touched
        />
        <Flex gridGap={6} justifyContent="space-evenly" mt={10} w="full">
          <Button
            colorScheme="secondary"
            onClick={onClose}
            px={10}
            w="fit-content"
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={!isValid}
            isLoading={isSubmitting}
            px={10}
            type="submit"
            w="fit-content"
          >
            {t('deleteAccountButton')}
          </Button>
        </Flex>
      </Flex>
    </chakra.form>
  );
};

export default memo(DeleteAccountConfirmation);
