import { Box, Fade, Flex } from '@chakra-ui/react';
import React, { memo } from 'react';
import { ModalProps } from '../navigation/Modals';

const CenterModal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => (
  <>
    <Box
      bg="black.600"
      h="full"
      left={0}
      opacity={isOpen ? '.4' : '0'}
      position="fixed"
      top={0}
      transitionDuration="300ms"
      transitionProperty="opacity"
      w="full"
    />
    <Fade in={isOpen}>
      <Box
        cursor="pointer"
        h="full"
        left={0}
        onClick={onClose}
        position="fixed"
        top={0}
        w="full"
      />
      <Flex
        left="50%"
        maxH="fit-content"
        maxW="fit-content"
        position="absolute"
        top="50%"
        transform="translate(-50%, -50%)"
        zIndex="modal"
      >
        <Flex bg="white" borderRadius={8} zIndex={10}>
          {children}
        </Flex>
      </Flex>
    </Fade>
  </>
);

export default memo(CenterModal);
