import { ThemeConfig, ThemeDirection, extendTheme } from '@chakra-ui/react';
import components from './components';
import foundations from './foundations';
import styles from './styles';

const direction: ThemeDirection = 'ltr';

const config: ThemeConfig = {
  cssVarPrefix: 'chakra',
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const overrides = {
  config,
  direction,
  ...foundations,
  components,
  styles,
};

export type Theme = typeof overrides;

export default extendTheme(overrides);
