import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const ApprovedIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.73 21a2 2 0 0 1-3.46 0M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3Z"
      stroke="#16181C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </Icon>
);

export default memo(ApprovedIcon);
