import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const MoreIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={6} fill="#737477" r={2} />
    <circle cx={12} cy={12} fill="#737477" r={2} />
    <circle cx={12} cy={18} fill="#737477" r={2} />≈
  </Icon>
);

export default memo(MoreIcon);
