import {
  Box,
  Flex,
  Heading,
  Interpolation,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import { TeamFragment, Team_User_Role_Enum } from '../generated/graphql';
import useUserEmail from '../hooks/useUserEmail';
import { LocalesKeys } from '../locales/localesKeys';
import { stringToNumber } from '../utils/stringToNumber';
import Avatar from './Avatar';
import TeamSettingsMenu from './TeamSettingsMenu';

export type TeamMembers = TeamFragment['team_users'][0];
export type TeamProjects = TeamFragment['projects'][0];

type Props = {
  teamMembers: TeamMembers[];
  teamProjects: TeamProjects[];
  isOwner: boolean;
};

const TABLE_CSS: Interpolation<{}> = {
  tableLayout: 'fixed',
};

const TeamSettingsTable: React.FC<Props> = ({
  teamMembers,
  teamProjects,
  isOwner,
}) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const email = useUserEmail();

  const countOfARsOfUser = useCallback(
    (row) => {
      const count = R.length(
        R.filter(
          (project) =>
            project.project_users.some(
              (user) => user.user_id === row.original.user.id,
            ),
          teamProjects,
        ),
      );

      return t(count === 1 ? 'ar' : 'ars', {
        count,
      });
    },
    [t, teamProjects],
  );

  const getCurrentUserIndicator = useCallback(
    (isMe: boolean) => (isMe ? t('you') : null),
    [t],
  );

  const columns = useMemo<Column<TeamMembers>[]>(
    () => [
      {
        accessor: 'user',
        Cell: ({
          row: {
            original: {
              user: { email, username, image, id },
            },
          },
        }) => {
          const displayName = username ?? email;
          const imageUrl = image?.url ?? undefined;

          return (
            <Flex align="center">
              <Avatar
                h={10}
                mr={2}
                name={displayName}
                src={imageUrl}
                userId={id}
                w={10}
              />
              <Flex
                direction="column"
                gridGap={username ? 1 : 0}
                justifyContent="center"
              >
                <Text color="black.500" size="subhead2">
                  {username}
                </Text>
                <Text color="black.400" size="body2">
                  {email}
                </Text>
              </Flex>
            </Flex>
          );
        },
        Header: () => (
          <Heading as="p" size="headline4" textAlign="left">
            {t('memberName')}
          </Heading>
        ),
      },
      {
        accessor: 'role',
        Cell: ({ row }) => (
          <Text>
            {t(
              row.original.role === Team_User_Role_Enum.Owner
                ? row.original.role
                : row.original.status,
              {
                isMe: getCurrentUserIndicator(
                  email === row.original.user.email,
                ),
              },
            )}
          </Text>
        ),
        Header: () => (
          <Heading as="p" size="headline4">
            {t('permission')}
          </Heading>
        ),
        id: 'permission',
      },
      {
        accessor: 'status',
        Cell: ({ row }) => (
          <Text>
            <Flex align="center" justifyContent="space-between">
              <Flex align="center" cursor="pointer">
                <Text>{countOfARsOfUser(row)}</Text>
              </Flex>
              {row.original.user.id && isOwner && (
                <TeamSettingsMenu id={row.original.user.id} />
              )}
            </Flex>
          </Text>
        ),
        Header: () => (
          <Heading as="p" size="headline4">
            {t('activeReviewerIn')}
          </Heading>
        ),
      },
    ],
    [countOfARsOfUser, getCurrentUserIndicator, email, isOwner, t],
  );

  const sortedTeamMembers = useMemo(
    () =>
      [...teamMembers].sort(
        (prevUser, nextUser) =>
          stringToNumber(prevUser.user.id) - stringToNumber(nextUser.user.id),
      ),
    [teamMembers],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: sortedTeamMembers });

  const renderHeaderColumn = useCallback<
    (column: HeaderGroup<TeamMembers>) => JSX.Element
  >(
    (column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>,
    [],
  );

  const renderTableHeader = useCallback<
    (headerGroup: HeaderGroup<TeamMembers>) => JSX.Element
  >(
    (headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(renderHeaderColumn)}
      </Tr>
    ),
    [renderHeaderColumn],
  );

  const TableHeaders = useMemo(
    () => headerGroups.map(renderTableHeader),
    [headerGroups, renderTableHeader],
  );

  const renderTableCell = useCallback<(cell: Cell<TeamMembers>) => JSX.Element>(
    (cell) => <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>,
    [],
  );

  const renderTableRow = useCallback<(row: Row<TeamMembers>) => JSX.Element>(
    (row) => {
      prepareRow(row);
      return (
        <Tr cursor="pointer" {...row.getRowProps()}>
          {row.cells.map(renderTableCell)}
        </Tr>
      );
    },
    [prepareRow, renderTableCell],
  );

  const TableRows = useMemo(
    () => rows.map(renderTableRow),
    [renderTableRow, rows],
  );

  return (
    <>
      <Box
        border="1px solid"
        borderColor="black.200"
        borderRadius="lg"
        w="full"
      >
        <Table
          colorScheme="black"
          css={TABLE_CSS}
          variant="simple"
          {...getTableProps()}
        >
          <Thead>{TableHeaders}</Thead>
          <Tbody {...getTableBodyProps()}>{TableRows}</Tbody>
        </Table>
      </Box>
    </>
  );
};

export default TeamSettingsTable;
