import React, { useCallback } from 'react';
import { FormikConfig, useFormik } from 'formik';
import { AccountInfoValues } from '../utils/formValidations';

const useUpdateUserForm = (config: FormikConfig<AccountInfoValues>) => {
  const { email, username } = config.initialValues;
  const avatarName = username ?? email;

  const formik = useFormik(config);

  const { isValid, setFieldValue, values } = formik;
  const { localUrl } = values;

  const isSubmitDisabled = !(
    isValid &&
    (values.localImage || values.username !== username)
  );

  const handleImageSelect = useCallback<
    (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  >(
    async (event) => {
      const image = event.currentTarget.files?.item(0);

      if (!image) return;
      if (localUrl) URL.revokeObjectURL(localUrl);

      const url = URL.createObjectURL(image);

      await Promise.all([
        setFieldValue('localUrl', url),
        setFieldValue('localImage', image),
      ]);
    },
    [localUrl, setFieldValue],
  );

  return {
    ...formik,
    avatarName,
    handleImageSelect,
    isSubmitDisabled,
  };
};

export default useUpdateUserForm;
