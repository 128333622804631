import { Flex, Heading } from '@chakra-ui/react';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IndexRange } from 'react-virtualized';
import CrossIcon from '../components/icons/CrossIcon';
import Loading from '../components/Loading';
import NotificationsList from '../components/NotificationsList';
import { useCheckNotificationsMutation } from '../generated/graphql';
import { useNotifications } from '../hooks/useNotifications';
import { useUncheckedNotifications } from '../hooks/useUncheckedNotifications';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';

const Notifications: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.Notifications);

  const { notifications, fetchMore, loading, rowsCount } = useNotifications();

  const { count: uncheckedCount } = useUncheckedNotifications();

  const [setChecked] = useCheckNotificationsMutation({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (uncheckedCount) {
      setChecked();
    }
  }, [setChecked, uncheckedCount]);

  const onLoadMore = useCallback<(params: IndexRange) => Promise<any>>(
    async ({ startIndex, stopIndex }) =>
      fetchMore({
        variables: {
          limit: stopIndex,
          offset: startIndex,
        },
      }),
    [fetchMore],
  );

  return (
    <Flex flexDirection="column" pt={12} px={10} w="full">
      <CrossIcon cursor="pointer" mb={12} onClick={onClose} />
      <Heading mb={3}>{t('heading')}</Heading>
      {loading ? (
        <Loading />
      ) : (
        <NotificationsList
          notifications={notifications?.notification_receiver ?? []}
          onLoadMore={onLoadMore}
          totalRows={rowsCount}
        />
      )}
    </Flex>
  );
};

export default memo(Notifications);
