import CenterDialogModal from '../components/CenterDialogModal';
import DeleteAccount from '../components/DeleteAccount';
import DeleteAccountConfirmation from '../components/DeleteAccountConfirmation';
import DeleteAccountWarning from '../components/DeleteAccountWarning';
import { RouteModalProps } from './Modals';
import routes from './routes';

export const accountSettingsModals: RouteModalProps[] = [
  {
    Component: DeleteAccount,
    Modal: CenterDialogModal,
    route: routes.AccountSettingsRoutes.DeleteAccount,
  },
  {
    Component: DeleteAccountWarning,
    Modal: CenterDialogModal,
    route: routes.AccountSettingsRoutes.DeleteAccountWarning,
  },
  {
    Component: DeleteAccountConfirmation,
    Modal: CenterDialogModal,
    route: routes.AccountSettingsRoutes.DeleteAccountConfirmation,
  },
];
