import { useCallback } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';
import useUploadFile from './useUploadFile';
import { useUpdateUserMutation } from '../generated/graphql';
import { isApolloError, isHasuraInternalError } from '../utils/predicates';
import { LocalesKeys } from '../locales/localesKeys';
import useUserEmail from './useUserEmail';

const useUpdateUser = () => {
  const email = useUserEmail() ?? '';
  const { t } = useTranslation(LocalesKeys.Common);

  const { uploadFile } = useUploadFile();
  const [updateUserMutation] = useUpdateUserMutation({
    ignoreResults: true,
  });

  const updateUser = useCallback<
    (username: Maybe<string>, image: Maybe<File>) => Promise<void>
  >(
    async (username, image) => {
      try {
        if (!image) {
          await updateUserMutation({
            variables: { _set: { username: username || null }, email },
          });
          return;
        }

        const image_id = await uploadFile(image);

        await updateUserMutation({
          variables: {
            _set: { image_id, username: username || null },
            email,
          },
        });
      } catch (e) {
        if (!isApolloError(e)) throw new Error(t('somethingWentWrong'));

        const internalError = e?.graphQLErrors[0]?.extensions?.internal;
        if (isHasuraInternalError(internalError)) {
          throw new Error(internalError.error.message);
        }

        throw new Error(e.message);
      }
    },
    [email, t, updateUserMutation, uploadFile],
  );

  return { updateUser };
};

export default useUpdateUser;
