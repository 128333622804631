import { values, without } from 'ramda';
import { useCallback, useMemo } from 'react';
import {
  Project_Constraint,
  Project_Insert_Input,
  Project_Update_Column,
  Project_User_Role_Enum,
  Team_User_Constraint,
  Team_User_Role_Enum,
  Team_User_Status_Enum,
  Team_User_Update_Column,
  User_Constraint,
  User_Update_Column,
  useSetProjectMutation,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import { FormSubmit } from '../types/formik';
import { CreateARStep1Values } from '../utils/formValidations';
import { useDisplayName } from './useDisplayName';
import { useReminderProtocol } from './useReminderProtocol';

export const useCreateAR = (): {
  onCreateAR: FormSubmit<CreateARStep1Values>;
  initialValues: CreateARStep1Values;
  loading: boolean;
} => {
  const getDisplayName = useDisplayName();

  const [setProject] = useSetProjectMutation();

  const { user, loading } = useUserContext();

  const { getInitialValues, getReminderProtocolInputProps } =
    useReminderProtocol();

  const initialValues = useMemo<CreateARStep1Values>(
    () => ({
      deadline: new Date(),
      driver_name: getDisplayName(user ?? undefined),
      external_link: '',
      external_name: '',
      invites: user
        ? [
            {
              email: user.email,
              id: user.id,
              role: Project_User_Role_Enum.Driver,
            },
          ]
        : [],
      link: '',
      name: '',
      ...getInitialValues(),
    }),
    [getDisplayName, getInitialValues, user],
  );

  const { team } = useTeamContext();

  const onCreateAR = useCallback<FormSubmit<CreateARStep1Values>>(
    async ({
      name,
      link,
      external_link,
      external_name,
      deadline,
      invites,
      ...reminderValues
    }) => {
      if (!user || !team?.id) {
        return;
      }

      const newProject: Project_Insert_Input = {
        deadline: deadline.toISOString(),
        external_link,
        external_name,
        link,
        ...getReminderProtocolInputProps(reminderValues),
        name,
        project_users: {
          data: invites.map((invite) => ({
            role: invite.role,
            user: {
              data: {
                email: invite.email,
                team_users: {
                  data: [
                    {
                      role: Team_User_Role_Enum.Guest,
                      status: Team_User_Status_Enum.Basic,
                      team_id: team?.id,
                    },
                  ],
                  on_conflict: {
                    constraint: Team_User_Constraint.TeamUserPkey,
                    update_columns: [Team_User_Update_Column.TeamId],
                  },
                },
              },
              on_conflict: {
                constraint: User_Constraint.UserEmailKey,
                update_columns: [User_Update_Column.Email],
              },
            },
          })),
        },
        team_id: team?.id,
      };

      await setProject({
        variables: {
          object: newProject,
          onConflict: {
            constraint: Project_Constraint.ProjectPkey,
            update_columns: without(
              [
                Project_Update_Column.Link,
                Project_Update_Column.Status,
                Project_Update_Column.CreatedAt,
              ],
              values(Project_Update_Column),
            ),
          },
        },
      });
    },
    [getReminderProtocolInputProps, setProject, team?.id, user],
  );

  return { initialValues, loading, onCreateAR };
};
