import React, { SVGProps, memo } from 'react';

const ChangeUserPermissionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height={20} width={20} {...props}>
    <path
      d="M20.5 1.001V7h-5.998M1.5 20.999V15h5.998m-6.497-4.498A9.966 9.966 0 0 1 3.31 4.533 9.968 9.968 0 0 1 8.76 1.186c2.136-.49 4.37-.262 6.368.64A10.003 10.003 0 0 1 19.8 6.2m1.199 5.298a9.982 9.982 0 0 1-2.346 5.934 10.006 10.006 0 0 1-5.457 3.305c-2.132.472-4.362.24-6.347-.67A9.978 9.978 0 0 1 2.2 15.7"
      style={{
        fill: 'none',
        stroke: '#454649',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 4,
        strokeOpacity: 1,
        strokeWidth: 1.5,
      }}
      transform="scale(.90909)"
    />
  </svg>
);

export default memo(ChangeUserPermissionIcon);
