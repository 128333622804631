import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  _focus: {
    boxShadow: 'outline',
  },
  _hover: {
    textDecoration: 'underline',
  },
  color: 'primary.500',
  cursor: 'pointer',
  outline: 'none',
  textDecoration: 'none',
  transitionDuration: 'fast',
  transitionProperty: 'common',
  transitionTimingFunction: 'ease-out',
};

const sizes: Record<string, SystemStyleObject> = {
  caption1: {
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: 'shorter',
  },
  subhead1: {
    fontSize: 'md',
    fontWeight: 'medium',
    lineHeight: 'short',
  },
};

const defaultProps = {
  size: 'subhead1',
};

export default {
  baseStyle,
  defaultProps,
  sizes,
};
