export default {
  communicationMethodHeader: 'Preferred communication method',
  emailCheckbox: 'By Email',
  'project_approved-UPDATE-status': 'To Driver once the AR is approved',
  'project_reminder-UPDATE-all': 'To Everyone - they have an AR to review',
  'project_user-DELETE-all': 'To Everyone - they are deleted from the AR',
  'project_user-INSERT-all': 'To Everyone - they are invited to the AR',
  'project_user-UPDATE-status':
    'To Driver letting them know of any status change',
  'project-DELETE-all': 'To Everyone once the Driver closes out the AR',
  pushCheckbox: 'By Push Notifications',
  reminder: 'Reminder',
  slackCheckbox: 'In Slack',
  slackNamespacePlaceholder: 'Workspace name',
  submit: 'Update changes',
};
