import { ApolloError, isApolloError } from '@apollo/client';
import { findLastIndex, values } from 'ramda';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormSubmit } from '../types/formik';
import { CreateARStep1Values } from '../utils/formValidations';
import { validateGraphQLErrors } from '../utils/graphqlErrors';

export enum ARRoutes {
  step1 = '',
  step2 = 'step2',
}

const routes = values(ARRoutes);

export const useProjectSubmit = (
  onClose: () => void,
  onRequestAR: FormSubmit<CreateARStep1Values>,
): FormSubmit<CreateARStep1Values> => {
  const navigate = useNavigate();

  const location = useLocation();

  const currentStep = findLastIndex(
    (step) => location.pathname.endsWith(step.toString()),
    routes,
  );

  const navigateToNextStep = useCallback(() => {
    if (currentStep !== routes.length - 1) {
      navigate(routes[currentStep + 1] ?? ARRoutes.step2);
    }
  }, [currentStep, navigate]);

  const onSubmit = useCallback<FormSubmit<CreateARStep1Values>>(
    async (values, helpers) => {
      if (currentStep !== routes.length - 1) {
        navigateToNextStep();
        return;
      }
      try {
        await onRequestAR(values, helpers);

        onClose();
      } catch (e) {
        if (!isApolloError(e as Error)) {
          return;
        }
        const error = e as ApolloError;
        validateGraphQLErrors(
          helpers,
          [
            {
              match: 'project_name_team_id_key',
              message: 'projectExists',
              name: 'name',
            },
          ],
          error.graphQLErrors,
        );
        if (currentStep !== 0) {
          navigate(`${routes[0]}`, {
            replace: true,
          });
        }
      }
    },
    [currentStep, navigate, navigateToNextStep, onClose, onRequestAR],
  );

  return onSubmit;
};
