import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  fontFamily: 'heading',
  fontWeight: 'semibold',
};

const sizes: Record<string, SystemStyleObject> = {
  headline1: {
    fontSize: '3xlp',
    lineHeight: 'shorter',
  },
  headline2: {
    fontSize: '2xl',
    lineHeight: 'short',
  },
  headline3: {
    fontSize: 'xl',
  },
  headline4: {
    fontSize: 'md',
    lineHeight: 'shorter',
  },
};

const defaultProps = {
  size: 'headline2',
};

export default {
  baseStyle,
  defaultProps,
  sizes,
};
