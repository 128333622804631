import { Button, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { useCreateTeam } from '../hooks/useCreateTeam';
import { useCustomToast } from '../hooks/useCustomToast';
import { LocalesKeys } from '../locales/localesKeys';
import { FormSubmit } from '../types/formik';
import CreateTeamInputs from './CreateTeamInputs';

export const createTeamInitialValues = {
  domain: '',
  teamName: '',
};

export type CreateTeamInitialValues = typeof createTeamInitialValues;

const TeamSettingsEmptyState: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const { successToast } = useCustomToast();

  const { onCreateTeam } = useCreateTeam();

  const onSubmit = useCallback<FormSubmit<CreateTeamInitialValues>>(
    async (values, helpers) => {
      const team = await onCreateTeam(values, helpers);

      if (team) {
        successToast({
          headerMessage: t('teamSettings'),
          toastMessage: t('teamCreatedSuccessfully'),
        });

        window.scrollTo({ behavior: 'smooth', top: 0 });
      }

      return team;
    },
    [onCreateTeam, successToast, t],
  );

  const {
    getFieldMeta,
    getFieldProps,
    handleSubmit,
    setFieldValue,
    values: { teamName },
  } = useFormik<CreateTeamInitialValues>({
    initialValues: createTeamInitialValues,
    onSubmit,
  });

  useEffect(() => {
    setFieldValue('domain', slugify(teamName, { lower: true }));
  }, [setFieldValue, teamName]);

  return (
    <Flex flexDirection="column" w="full">
      <chakra.form onSubmit={handleSubmit} w="full">
        <Heading mb={4} size="headline3">
          {t('emptyStateHeader')}
        </Heading>
        <Text mb={6} size="body1">
          {t('emptyStateDescription')}
        </Text>
        <CreateTeamInputs
          getFieldMeta={getFieldMeta}
          getFieldProps={getFieldProps}
        />
        <Flex justifyContent="end" mt="20vh">
          <Button px={6} type="submit" w="fit-content">
            {t('updateChanges')}
          </Button>
        </Flex>
      </chakra.form>
    </Flex>
  );
};

export default memo(TeamSettingsEmptyState);
