import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const LogoutIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 3H6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4m6-4 5-5m0 0-5-5m5 5H8.5"
      stroke="#454649"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
    />
  </Icon>
);

export default memo(LogoutIcon);
