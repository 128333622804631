import React, { memo } from 'react';
import { Icon, IconProps, chakra } from '@chakra-ui/react';

const CHECKED_PROPS = {
  display: 'unset',
};

const DraftIcon: React.FC<IconProps> = ({ color, ...props }) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <chakra.circle
      cx="12"
      cy="12"
      r="10"
      stroke={color ?? 'black'}
      strokeWidth="1.6"
    />
    <chakra.circle
      cx="12"
      cy="12"
      display="none"
      fill={color ?? 'black'}
      r="6"
      {...props}
      _checked={CHECKED_PROPS}
    />
  </Icon>
);

export default memo(DraftIcon);
