import { filter, find, propEq, values, without } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Project_Constraint,
  Project_Insert_Input,
  Project_Update_Column,
  Project_User_Constraint,
  Project_User_Role_Enum,
  Project_User_Update_Column,
  Team_User_Constraint,
  Team_User_Role_Enum,
  Team_User_Status_Enum,
  Team_User_Update_Column,
  User_Constraint,
  User_Update_Column,
  useDeleteProjectUsersMutation,
  useProjectQuery,
  useSetProjectMutation,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { FormSubmit } from '../types/formik';
import { CreateARStep1Values } from '../utils/formValidations';
import { useDisplayName } from './useDisplayName';
import { useReminderProtocol } from './useReminderProtocol';

export const useEditAR = () => {
  const { id } = useParams();

  const arId = id || '';

  const { data: projectData, loading } = useProjectQuery({
    variables: {
      id: arId,
    },
  });

  const getDisplayName = useDisplayName();

  const [setProject] = useSetProjectMutation();

  const [deleteProjectUsers] = useDeleteProjectUsersMutation();

  const { team } = useTeamContext();

  const { getInitialValues, getReminderProtocolInputProps } =
    useReminderProtocol(projectData?.project_by_pk);

  const initialValues = useMemo<CreateARStep1Values>(() => {
    const project = projectData?.project_by_pk;

    return project
      ? {
          deadline: new Date(project.deadline),
          driver_name: getDisplayName(
            find(
              propEq('role', Project_User_Role_Enum.Driver),
              project.project_users,
            )?.user,
          ),
          external_link: project.external_link ?? undefined,
          external_name: project.external_name ?? undefined,
          invites: project.project_users.map((user) => ({
            email: user.user.email,
            id: user.user.id,
            role: user.role,
          })),
          link: project.link,
          name: project.name,
          ...getInitialValues(),
        }
      : {
          deadline: new Date(),
          driver_name: '',
          external_link: '',
          external_name: '',
          invites: [],
          link: '',
          name: '',
          ...getInitialValues(),
        };
  }, [getDisplayName, getInitialValues, projectData?.project_by_pk]);

  const onEditAR = useCallback<FormSubmit<CreateARStep1Values>>(
    async ({
      name,
      external_link,
      external_name,
      deadline,
      invites,
      link,
      ...reminderValues
    }) => {
      if (!team?.id) {
        return;
      }

      const newProject: Project_Insert_Input = {
        deadline: deadline.toISOString(),
        external_link,
        external_name,
        id,
        link,
        name,
        ...getReminderProtocolInputProps(reminderValues),
        project_users: {
          data: invites.map((invite) => ({
            role: invite.role,
            user: {
              data: {
                email: invite.email,
                team_users: {
                  data: [
                    {
                      role: Team_User_Role_Enum.Guest,
                      status: Team_User_Status_Enum.Basic,
                      team_id: team.id,
                    },
                  ],
                  on_conflict: {
                    constraint: Team_User_Constraint.TeamUserPkey,
                    update_columns: [Team_User_Update_Column.TeamId],
                  },
                },
              },
              on_conflict: {
                constraint: User_Constraint.UserEmailKey,
                update_columns: [User_Update_Column.Email],
              },
            },
          })),
          on_conflict: {
            constraint: Project_User_Constraint.ProjectUserPkey,
            update_columns: [Project_User_Update_Column.Role],
          },
        },
        team_id: team.id,
      };

      await setProject({
        variables: {
          object: newProject,
          onConflict: {
            constraint: Project_Constraint.ProjectPkey,
            update_columns: without(
              [
                Project_Update_Column.Link,
                Project_Update_Column.Status,
                Project_Update_Column.CreatedAt,
              ],
              values(Project_Update_Column),
            ),
          },
        },
      });

      const project = projectData?.project_by_pk;

      if (project) {
        const projectUsersRemoved = filter(
          (project) =>
            !find((invite) => invite.email === project.user.email, invites),
          project.project_users,
        );

        if (projectUsersRemoved.length) {
          await deleteProjectUsers({
            variables: {
              where: {
                project_id: {
                  _eq: project.id,
                },
                user_id: {
                  _in: projectUsersRemoved.map((user) => user.user.id),
                },
              },
            },
          });
        }
      }
    },
    [
      deleteProjectUsers,
      getReminderProtocolInputProps,
      id,
      projectData?.project_by_pk,
      setProject,
      team?.id,
    ],
  );

  return { arId, initialValues, loading, onEditAR };
};
