import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useCancelSubscriptions from '../hooks/useCancelSubscriptions';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';

const CancelSubscriptions: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const cancelSubscriptions = useCancelSubscriptions();

  const onCancelSubscriptions = useCallback(async () => {
    await cancelSubscriptions();
    onClose();
  }, [cancelSubscriptions, onClose]);

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      pt={18}
      w="full"
    >
      <Heading mb={8} size="headline3">
        {t('cancelSubscription')}
      </Heading>
      <Text mb={6} textAlign="center">
        {t('cancelSubscriptionDetails')}
      </Text>
      <Flex align="center" gridGap={6} justifyContent="space-evenly">
        <Button colorScheme="secondary" onClick={onClose} px={6}>
          {t('back')}
        </Button>
        <Button onClick={onCancelSubscriptions} px={12}>
          {t('cancelSubscription')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(CancelSubscriptions);
