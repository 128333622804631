import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInvitePeopleToTeam } from '../hooks/useInvitePeopleToTeam';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import Autocomplete from './Autocomplete';
import Avatar from './Avatar';
import InvitedUserListItem from './InvitedUserListItem';
import StatusSelectMenu from './StatusSelectMenu';

const AUTOCOMPLETE_PROPS = {
  flex: 6,
  mr: 6,
};

const InvitePeopleToTeam: RouteModalComponent = ({ onClose }) => {
  const { t } = useTranslation(LocalesKeys.TeamSettings);

  const {
    invitedUsers,
    invitedUserEmail,
    filteredTotalMembers,
    onChangeInvitedUserEmail,
    isOpenedRolePicker,
    onChangeInvitedUserStatus,
    onCloseRolePicker,
    onOpenRolePicker,
    invitedMemberStatus,
    setEmailValue,
    onInviteUsers,
    onDeleteInvitedUser,
    isAllInvitedUsersEmailValid,
  } = useInvitePeopleToTeam();

  const SelectMenu = useMemo(
    () => (
      <Box bg="white" mr={5} pl={5}>
        <StatusSelectMenu
          isOpen={isOpenedRolePicker}
          onChange={onChangeInvitedUserStatus}
          onClick={onChangeInvitedUserStatus}
          onClose={onCloseRolePicker}
          onOpen={onOpenRolePicker}
          status={invitedMemberStatus}
        />
      </Box>
    ),
    [
      invitedMemberStatus,
      isOpenedRolePicker,
      onCloseRolePicker,
      onOpenRolePicker,
      onChangeInvitedUserStatus,
    ],
  );

  const renderItemContent = useCallback((item) => {
    const imageUrl = item.image?.url ?? undefined;

    return (
      <Flex align="center">
        <Avatar
          h={10}
          mr={2}
          name={item.value}
          src={imageUrl}
          userId={item.id}
          w={10}
        />
        <Flex direction="column">
          <Text color="black.500" size="body1">
            {item.username}
          </Text>
          <Text color="black.400" size="body2">
            {item.email}
          </Text>
        </Flex>
      </Flex>
    );
  }, []);

  const renderInvitedUser = useCallback(
    ({ email, isEmailValid }) => (
      <InvitedUserListItem
        invitedUserEmail={email}
        isEmailValid={isEmailValid}
        onDeleteInvitedUser={onDeleteInvitedUser}
      />
    ),
    [onDeleteInvitedUser],
  );

  const onInvite = useCallback(async () => {
    await onInviteUsers();
    onClose();
  }, [onClose, onInviteUsers]);

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      pt={18}
      w="full"
    >
      <Heading mb={8} size="headline3">
        {t('invitePeopleHeader')}
      </Heading>
      <Flex flexWrap="wrap" justifyContent="flex-start" w="full">
        {invitedUsers.map(renderInvitedUser)}
      </Flex>
      <Flex align="center" w="full">
        <Autocomplete
          boxProps={AUTOCOMPLETE_PROPS}
          data={filteredTotalMembers}
          onChange={onChangeInvitedUserEmail}
          renderItemContent={renderItemContent}
          rightElement={SelectMenu}
          setFieldValue={setEmailValue}
          touched
          value={invitedUserEmail}
          w="full"
        />
        <Button
          flex={1}
          isDisabled={!isAllInvitedUsersEmailValid}
          onClick={onInvite}
        >
          {t('invite')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default memo(InvitePeopleToTeam);
