import React, { memo, useCallback, useMemo } from 'react';
import { Checkbox, HStack, Heading, PropsOf, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  all,
  and,
  assoc,
  identity,
  indexBy,
  lensProp,
  map,
  mapObjIndexed,
  over,
  values,
} from 'ramda';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { pipe } from 'fp-ts/lib/function';
import { useAuth } from '@stormotion-auth/react';
import { OAuth2ProviderType } from '@stormotion-auth/core';
import NotificationSettingsTable from './NotificationSettingsTable';
import { LocalesKeys } from '../locales/localesKeys';
import { Notification_Destination_Enum } from '../generated/graphql';
import TextInput from './TextInput';

type Props = PropsOf<typeof NotificationSettingsTable> & {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  getFieldMeta: (name: string) => FieldMetaProps<any>;
};

const Loading: React.FC<Props> = ({
  setSettings,
  settings,
  getFieldProps,
  getFieldMeta,
}) => {
  const { t } = useTranslation(LocalesKeys.NotificationSettings);

  const { oAuth2Providers } = useAuth();

  const hasSlack = oAuth2Providers.some(
    ({ provider }) => provider === OAuth2ProviderType.Slack,
  );

  const mappedSettings = useMemo(
    () =>
      settings.map(
        over(lensProp(Notification_Destination_Enum.Slack), and(hasSlack)),
      ),
    [hasSlack, settings],
  );

  const checkedBoxes = useMemo<Record<Notification_Destination_Enum, boolean>>(
    () =>
      pipe(
        values(Notification_Destination_Enum),
        indexBy<Notification_Destination_Enum, Notification_Destination_Enum>(
          identity,
        ),
        mapObjIndexed((destination) =>
          all((setting) => !!setting[destination], mappedSettings),
        ),
      ),
    [mappedSettings],
  );

  const checkDestination = useCallback(
    (destination: Notification_Destination_Enum) => {
      setSettings(
        map(assoc(destination, !checkedBoxes[destination]), mappedSettings),
      );
    },
    [checkedBoxes, setSettings, mappedSettings],
  );

  const checkEmail = useCallback(
    () => checkDestination(Notification_Destination_Enum.Email),
    [checkDestination],
  );

  const checkSlack = useCallback(
    () => checkDestination(Notification_Destination_Enum.Slack),
    [checkDestination],
  );

  const checkPush = useCallback(
    () => checkDestination(Notification_Destination_Enum.Push),
    [checkDestination],
  );

  return (
    <VStack align="flex-start" spacing={6} w="full">
      <Heading size="headline3">{t('communicationMethodHeader')}</Heading>
      <VStack align="flex-start" spacing={0} w="full">
        <HStack as="label" cursor="pointer">
          <Checkbox
            colorScheme="red"
            isChecked={checkedBoxes.EMAIL}
            onChange={checkEmail}
          />
          <Heading size="subhead1">{t('emailCheckbox')}</Heading>
        </HStack>
        <TextInput
          {...getFieldProps('notification_email')}
          {...getFieldMeta('notification_email')}
        />
      </VStack>
      <VStack align="flex-start" spacing={0} w="full">
        <HStack as="label" cursor="pointer">
          <Checkbox
            colorScheme="red"
            isChecked={checkedBoxes.SLACK}
            isDisabled={!hasSlack}
            onChange={checkSlack}
          />
          <Heading size="subhead1">{t('slackCheckbox')}</Heading>
        </HStack>
      </VStack>
      <HStack as="label" cursor="pointer">
        <Checkbox
          colorScheme="red"
          isChecked={checkedBoxes.PUSH}
          onChange={checkPush}
        />
        <Heading size="subhead1">{t('pushCheckbox')}</Heading>
      </HStack>
      <NotificationSettingsTable
        setSettings={setSettings}
        settings={mappedSettings}
      />
    </VStack>
  );
};

export default memo(Loading);
