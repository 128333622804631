export default {
  APPROVED: 'Approved',
  approved: 'Approved',
  APPROVER: 'Approver',
  assetsBody: 'This asset is a part of project or task. ',
  assetsHeader: 'About the asset and project',
  cancel: 'Cancel',
  CANCELLED: 'Cancelled',
  cancelled: 'Cancelled',
  CONTRIBUTOR: 'Contributor',
  created: 'Created by {{username}} {{createdAt}}',
  deletedUserFromTeamLabel:
    'This user was deleted from this team. His last status of AR will matter while he is in the project. You can delete this user so his/her status won`t take part.',
  deleteModalBody: `Are you sure you want to permanently delete this AR? If you delete,
  it will be removed from the dashboards of all reviewers. Instead you
  can Cancel it and move to Archive.`,
  deleteModalCancelButton: 'Cancel and Archive AR',
  deleteModalDeleteButton: 'Permanently Delete',
  deleteModalHeader: 'Delete Approval Request',
  DRAFT: 'Draft',
  DRIVER: 'Driver',
  error: 'Failed to open AR',
  errorTitle: 'This AR was deleted',
  headline: 'How to create custom CRM design for your business?',
  INFORMED: 'Informed',
  memberName: 'Member name',
  myStatus: 'My Status',
  notApproved: 'Not approved',
  noteLabel: 'Note (optional)',
  notePlaceholder: 'Your team will see any notes you add here',
  openDoc: 'Open doc',
  OVERDUE: 'Overdue',
  peopleReviewing: 'People reviewing this asset ({{count}})',
  REJECTED: 'Not approved',
  remove: 'Remove',
  role: 'Role',
  save: 'Save',
  setStatus: 'Set your status for this project. Your team will be notified.',
  status: 'Status',
  UNDER_REVIEW: 'Under review',
  underReview: 'Under review',
  userStatus: 'Status: {{status}}',
};
