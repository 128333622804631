import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const LinkIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M16.994 1.267a5.8 5.8 0 0 0-4.08 1.627l-.008.009-1.72 1.71a.8.8 0 0 0 1.128 1.134l1.716-1.705a4.2 4.2 0 0 1 5.94 5.937l-2.996 2.995a4.2 4.2 0 0 1-6.333-.453.8.8 0 0 0-1.282.958 5.8 5.8 0 0 0 8.747.627l3-3 .01-.01a5.8 5.8 0 0 0-4.122-9.83ZM10.41 8.21a5.8 5.8 0 0 0-4.516 1.684l-3 3-.01.01a5.8 5.8 0 0 0 8.202 8.201l.01-.01 1.71-1.71a.8.8 0 0 0-1.132-1.13L9.97 19.958a4.2 4.2 0 0 1-5.938-5.938l2.995-2.995a4.2 4.2 0 0 1 6.333.453.8.8 0 1 0 1.281-.958 5.8 5.8 0 0 0-4.23-2.311Z"
      fill="#454649"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(LinkIcon);
