import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  CloseButton,
  Flex,
  Heading,
  RenderProps,
  Text,
} from '@chakra-ui/react';
import React, { memo } from 'react';
import WarningIcon from './icons/WarningIcon';

export enum ToastType {
  warning = 'warning',
  success = 'success',
}

interface BaseToastProps extends RenderProps {
  headerMessage: string;
  toastMessage: string;
  type: ToastType;
}

const BaseToast: React.FC<BaseToastProps> = ({
  onClose,
  headerMessage,
  toastMessage,
  type,
}) => (
  <Flex
    bg="white"
    borderColor={type === ToastType.warning ? 'red.600' : 'green.600'}
    borderRadius={8}
    h="fit-content"
    justifyContent="space-between"
    marginRight={24}
    marginTop="11vh"
    w={400}
  >
    <Flex>
      <Flex
        alignItems="center"
        bg={type === 'warning' ? 'red.600' : 'green.600'}
        borderLeftRadius={8}
        justifyContent="center"
        px={4}
      >
        {type === ToastType.warning ? <WarningIcon /> : <CheckCircleIcon />}
      </Flex>
      <Flex flexDirection="column" p={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading size="subhead1">{headerMessage}</Heading>
        </Flex>
        <Text>{toastMessage}</Text>
      </Flex>
    </Flex>
    <Flex mr={2} mt={2}>
      <CloseButton onClick={onClose} />
    </Flex>
  </Flex>
);

export default memo(BaseToast);
