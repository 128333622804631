import {
  InputMaybe,
  Project_Dashboard_Order_By,
  Project_User_Display_Status_Enum,
} from '../generated/graphql';

export const getProjectDashboardQueryVariables = (options: {
  pageSize?: number;
  teamId?: string;
  offset?: InputMaybe<number>;
  mappedSorting?: Project_Dashboard_Order_By;
  displayStatus?: Project_User_Display_Status_Enum;
  driverEmailQuery?: string;
  nameQuery?: string;
  status?: string | null;
}) => {
  const {
    pageSize = 10,
    teamId,
    offset = 0,
    mappedSorting,
    displayStatus,
    driverEmailQuery,
    nameQuery,
    status,
  } = options;
  return {
    limit: pageSize,
    offset,
    order_by: mappedSorting || [],
    where: {
      display_status: { _eq: displayStatus },
      driver: {
        email: {
          _ilike: driverEmailQuery,
        },
      },
      name: { _ilike: nameQuery || '%%' },
      status: { _eq: status || undefined },
      team_id: {
        _eq: teamId,
      },
    },
  };
};
