import React, { SVGProps, memo } from 'react';

const CancelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={20} width={22} {...props}>
    <path
      d="M10 19H3a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2m-.88 7.88-4.24 4.24m0-4.24 4.24 4.24"
      stroke="#454649"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

export default memo(CancelIcon);
