import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const useEmailParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const emailParam = searchParams.get('email');

  const setEmailParam = useCallback<(email: string) => void>(
    (email) => setSearchParams({ email }),
    [setSearchParams],
  );

  return [emailParam, setEmailParam] as const;
};

export default useEmailParam;
