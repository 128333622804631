import { createIcon } from '@chakra-ui/react';
import React from 'react';

export const AttentionIcon = createIcon({
  displayName: 'AttentionIcon',
  path: (
    <path
      clipRule="evenodd"
      d="M19.9933 5.45889C11.9665 5.45889 5.45937 11.966 5.45937 19.9928C5.45937 28.0197 11.9665 34.5268 19.9933 34.5268C28.0202 34.5268 34.5273 28.0197 34.5273 19.9928C34.5273 11.966 28.0202 5.45889 19.9933 5.45889ZM3.85938 19.9928C3.85938 11.0823 11.0828 3.85889 19.9933 3.85889C28.9039 3.85889 36.1273 11.0823 36.1273 19.9928C36.1273 28.9034 28.9039 36.1268 19.9933 36.1268C11.0828 36.1268 3.85938 28.9034 3.85938 19.9928ZM19.9925 13.0586C20.4343 13.0586 20.7925 13.4168 20.7925 13.8586V19.9922C20.7925 20.4341 20.4343 20.7922 19.9925 20.7922C19.5507 20.7922 19.1925 20.4341 19.1925 19.9922V13.8586C19.1925 13.4168 19.5507 13.0586 19.9925 13.0586ZM19.9925 25.3258C19.5507 25.3258 19.1925 25.684 19.1925 26.1258C19.1925 26.5676 19.5507 26.9258 19.9925 26.9258H20.0092C20.451 26.9258 20.8092 26.5676 20.8092 26.1258C20.8092 25.684 20.451 25.3258 20.0092 25.3258H19.9925Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 40 40',
});
