import { Fade, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { HeaderGroup, Row } from 'react-table';
import { ProjectDashboardFragment } from '../generated/graphql';
import useProjectTable, { ProjectTableParams } from '../hooks/useProjectTable';
import DashboardTableRow from './DashboardTableRow';

interface Props extends ProjectTableParams {}

const DashboardTable: React.FC<Props> = (props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useProjectTable(props);

  const renderHeaderColumn = useCallback<
    (column: HeaderGroup<ProjectDashboardFragment>) => JSX.Element
  >(
    (column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>,
    [],
  );

  const renderTableHeader = useCallback<
    (headerGroup: HeaderGroup<ProjectDashboardFragment>) => JSX.Element
  >(
    (headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(renderHeaderColumn)}
      </Tr>
    ),
    [renderHeaderColumn],
  );

  const TableHeaders = useMemo(
    () => headerGroups.map(renderTableHeader),
    [headerGroups, renderTableHeader],
  );

  const renderTableRow = useCallback<
    (row: Row<ProjectDashboardFragment>, index: number) => JSX.Element
  >(
    (row) => {
      prepareRow(row);
      return <DashboardTableRow key={row.id} id={row.original.id} row={row} />;
    },
    [prepareRow],
  );

  const TableRows = useMemo(
    () => rows.map(renderTableRow),
    [renderTableRow, rows],
  );

  return (
    <Fade in>
      <Table colorScheme="black" variant="simple" {...getTableProps()}>
        <Thead>{TableHeaders}</Thead>
        <Tbody {...getTableBodyProps()}>{TableRows}</Tbody>
      </Table>
    </Fade>
  );
};

export default memo(DashboardTable);
