import React, { SVGProps, memo } from 'react';

const ChangeMyStatusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height={15} width={21} {...props}>
    <path
      d="M.21 1.07c0-.472.376-.855.84-.855h18.9c.464 0 .84.383.84.855 0 .477-.376.86-.84.86H1.05a.848.848 0 0 1-.84-.86Zm0 6.43c0-.473.376-.855.84-.855h18.9c.464 0 .84.382.84.855a.847.847 0 0 1-.84.855H1.05A.847.847 0 0 1 .21 7.5Zm.84 5.57a.85.85 0 0 0-.84.86c0 .472.376.855.84.855h18.9c.464 0 .84-.383.84-.855a.85.85 0 0 0-.84-.86Zm0 0"
      style={{
        fill: '#16181c',
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
    />
  </svg>
);

export default memo(ChangeMyStatusIcon);
