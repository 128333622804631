import React, { memo, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LocalesKeys } from '../locales/localesKeys';
import { DetailsIcon } from '../components/icons/DetailsIcon';
import BillingContactChange from '../components/BillingContactChange';
import BillingMethodChange from '../components/BillingMethodChange';
import Loading from '../components/Loading';

import { useBillingContact } from '../hooks/useBillingContact';
import {
  useInvoicesQuery,
  useUpcomingInvoiceQuery,
} from '../generated/graphql';
import BillingTable from '../components/BillingTable';
import { useBillingMethodChange } from '../hooks/useBillingMethodChange';
import { formatPrice, formatTimestamp } from '../utils/formats';
import TeamIcon from '../components/icons/TeamIcon';
import { AttentionIcon } from '../components/icons/AttentionIcon';
import { useTeamContext } from '../store/contexts/TeamContext';

const Billing: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.Billing);

  const { team, loading: teamLoading } = useTeamContext();

  const totalTeamSeats = team?.team_users.length;

  const {
    loading: billingSettingsLoading,
    customerEmail,
    last4,
    updateEmail,
    customerNotSet,
  } = useBillingContact();

  const setBillingMethod = useBillingMethodChange(
    customerNotSet ? customerEmail : undefined,
  );

  const PriceDetailsLabel = useMemo(
    () => (
      <VStack align="flex-start" spacing={2}>
        <Heading size="healine4">{t('tooltipHeading')}</Heading>
        <Text size="caption2">{t('tooltipCaption')}</Text>
      </VStack>
    ),
    [t],
  );

  const BillingSettings = useMemo(
    () => (
      <HStack spacing={6} w="full">
        <Flex
          borderColor="black.200"
          borderRadius="lg"
          borderWidth="thin"
          flex={1}
          flexDir="column"
          p={4}
        >
          <Text color="black.400" pb={4} size="body1">
            {t('sentTo')}
          </Text>
          <Heading pb={10} size="headline4">
            {customerEmail}
          </Heading>
          <BillingContactChange
            newCustomerEmail={customerEmail}
            setEmail={updateEmail}
            teamMembers={team?.team_users ?? []}
          />
        </Flex>
        <Flex
          borderColor="black.200"
          borderRadius="lg"
          borderWidth="thin"
          flex={1}
          flexDir="column"
          p={4}
        >
          <Text color="black.400" pb={4} size="body1">
            {t('billingMethod')}
          </Text>
          <Heading pb={10} size="headline4">
            {last4 && t('last4', { last4 })}
          </Heading>
          <BillingMethodChange
            colorScheme="secondary"
            setBillingMethod={setBillingMethod}
          >
            {t('change')}
          </BillingMethodChange>
        </Flex>
      </HStack>
    ),
    [customerEmail, last4, setBillingMethod, t, team?.team_users, updateEmail],
  );

  const {
    data: nextInvoiceData,
    loading: nextInvoiceLoading,
    error: nextInvoiceError,
  } = useUpcomingInvoiceQuery({
    skip: !team?.customer_id,
    variables: {
      customerId: team?.customer_id ?? '',
    },
  });

  const Statistics = useMemo(
    () => (
      <HStack spacing={6} w="full">
        <VStack
          align="flex-start"
          borderColor="black.100"
          borderRadius="lg"
          borderWidth="thin"
          flex={1}
          flexDir="column"
          justify="space-between"
          p={4}
          spacing={8}
        >
          <Text color="black.400" size="body1">
            {t('nextInvoiceDate')}
          </Text>
          <Heading size="headline4">
            {formatTimestamp(nextInvoiceData?.customer_upcoming_invoice.date)}
          </Heading>
        </VStack>
        <VStack
          align="flex-start"
          borderColor="black.100"
          borderRadius="lg"
          borderWidth="thin"
          flex={1}
          flexDir="column"
          justify="space-between"
          p={4}
          spacing={8}
        >
          <Text color="black.400" size="body1">
            {t('invoiceTotal')}
          </Text>
          <HStack spacing={2}>
            <Heading size="headline4">
              {formatPrice(nextInvoiceData?.customer_upcoming_invoice.total)}
            </Heading>
            <Tooltip label={PriceDetailsLabel} placement="bottom-start" w={64}>
              <DetailsIcon boxSize={4} />
            </Tooltip>
          </HStack>
        </VStack>
        <VStack
          align="flex-start"
          borderColor="black.100"
          borderRadius="lg"
          borderWidth="thin"
          flex={1}
          flexDir="column"
          justify="space-between"
          p={4}
          spacing={8}
        >
          <Text color="black.400" size="body1">
            {t('teamSeats')}
          </Text>
          <Heading size="headline4">{totalTeamSeats}</Heading>
        </VStack>
      </HStack>
    ),
    [
      PriceDetailsLabel,
      nextInvoiceData?.customer_upcoming_invoice.date,
      nextInvoiceData?.customer_upcoming_invoice.total,
      t,
      totalTeamSeats,
    ],
  );

  const InvoiceFailedBanner = useMemo(
    () => (
      <HStack bg="primary.100" borderRadius="md" p={6} spacing={2} w="full">
        <Box>
          <TeamIcon boxSize={10} color="primary.600" />
        </Box>
        <Text flex={2}>{t('nextInvoiceError')}</Text>
      </HStack>
    ),
    [t],
  );

  const PricingUpdateBanner = useMemo(
    () =>
      team && team.team_users.length > 20 ? (
        <HStack bg="primary.100" borderRadius="md" p={6} spacing={2} w="full">
          <Box>
            <TeamIcon boxSize={10} color="primary.600" />
          </Box>
          <Text flex={2}>{t('pricingUpdateBanner')}</Text>
          <Button w="fit-content">{t('pricingContact')}</Button>
        </HStack>
      ) : undefined,
    [t, team],
  );

  const { data: invoiceData, loading: invoicesLoading } = useInvoicesQuery({
    skip: !team?.customer_id,
    variables: {
      customerId: team?.customer_id ?? '',
    },
  });

  const Invoices = useMemo(
    () => (
      <VStack align="flex-start" pt={5} spacing={4}>
        <Heading color="black.500" size="headline3">
          {t('invoices')}
        </Heading>
        <BillingTable invoices={invoiceData?.customer_invoices ?? []} />
      </VStack>
    ),
    [invoiceData?.customer_invoices, t],
  );

  const CustomerNotSetBox = useMemo(
    () => (
      <VStack
        alignItems="flex-end"
        bg="black.700"
        borderRadius="md"
        p={6}
        w="full"
      >
        <HStack w="full">
          <AttentionIcon boxSize={10} color="primary.600" />
          <Text color="white">{t('customerNotSetBody')}</Text>
        </HStack>
        <BillingMethodChange
          setBillingMethod={setBillingMethod}
          w="fit-content"
        >
          {t('setBillingMethod')}
        </BillingMethodChange>
      </VStack>
    ),
    [setBillingMethod, t],
  );

  const loading =
    billingSettingsLoading ||
    nextInvoiceLoading ||
    invoicesLoading ||
    teamLoading;

  const Content = useMemo(() => {
    if (loading) {
      return <Loading />;
    }
    if (!team?.customer_id) {
      return CustomerNotSetBox;
    }
    return (
      <>
        {BillingSettings}
        {nextInvoiceError ? InvoiceFailedBanner : Statistics}
        {PricingUpdateBanner}
        {Invoices}
      </>
    );
  }, [
    BillingSettings,
    CustomerNotSetBox,
    InvoiceFailedBanner,
    Invoices,
    PricingUpdateBanner,
    Statistics,
    loading,
    nextInvoiceError,
    team?.customer_id,
  ]);

  return (
    <VStack p={10} spacing={6} w="full">
      {Content}
    </VStack>
  );
};

export default memo(Billing);
