import { useCallback } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useUploadFileMutation } from '../generated/graphql';

const useUploadFile = () => {
  const [uploadFileMutation] = useUploadFileMutation({
    ignoreResults: true,
  });

  const uploadFile = useCallback<(image: File) => Promise<Maybe<string>>>(
    async (image) => {
      const { name, type } = image;

      const { data } = await uploadFileMutation({
        variables: { content_type: type, name },
      });

      const signedUrl = data?.upload_file?.url;
      const image_id = data?.upload_file?.file_id;

      if (!signedUrl) return null;

      const requestOptions = {
        body: image,
        headers: { 'Content-Type': type },
        method: 'PUT',
      };

      await fetch(signedUrl, requestOptions);

      return image_id;
    },
    [uploadFileMutation],
  );

  return { uploadFile };
};

export default useUploadFile;
