import React, { MouseEventHandler, memo, useCallback } from 'react';
import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CodeInput from './CodeInput';
import AuthBox from './AuthForm';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import { IconClass } from '../theme/utils';
import { FormSubmit } from '../types/formik';
import {
  VerificationValues,
  verificationValidationSchema,
} from '../utils/formValidations';
import useNavigation from '../hooks/useNavigation';
import { LocalesKeys } from '../locales/localesKeys';
import { CodeResponse } from '../hooks/useResendCode';

interface Props {
  onSubmit: FormSubmit<VerificationValues>;
  onResend?: MouseEventHandler<HTMLButtonElement>;
  heading?: string;
  subhead?: string;
  codeResponse?: CodeResponse;
  timeLeftToSendCode: number;
  submit?: string;
}

const INIT_VALUES: VerificationValues = { code: '' };

const BACK_ICON = <ChevronLeftIcon className={IconClass.CustomStroke} />;

const Verification: React.FC<Props> = ({
  heading,
  onResend,
  onSubmit,
  subhead,
  timeLeftToSendCode,
  codeResponse,
  submit,
}) => {
  const { goBack } = useNavigation();
  const { t } = useTranslation(LocalesKeys.Verification);

  const {
    getFieldProps,
    getFieldMeta,
    handleSubmit,
    handleChange,
    isSubmitting,
    values: { code },
  } = useFormik({
    initialValues: INIT_VALUES,
    onSubmit,
    validationSchema: verificationValidationSchema,
  });

  const isDisabled = !code;

  const handleCodeChange = useCallback<(value: string) => void>(
    (value) => handleChange('code')(value),
    [handleChange],
  );

  return (
    <Stack align="start" spacing={6}>
      <Button
        colorScheme="secondary"
        leftIcon={BACK_ICON}
        onClick={goBack}
        px={4.5}
        w="initial"
      >
        {t('back')}
      </Button>
      <AuthBox onSubmit={handleSubmit}>
        <Stack spacing={6}>
          <Stack spacing={4}>
            <Heading as="h2">{heading}</Heading>
            <Text>{subhead}</Text>
          </Stack>
          <Stack align="flex-start" spacing={6}>
            <CodeInput
              autoFocus
              {...getFieldMeta('code')}
              {...getFieldProps('code')}
              onChange={handleCodeChange}
            />
            {timeLeftToSendCode <= 0 && codeResponse !== CodeResponse.ERROR ? (
              <Button
                mt={6}
                onClick={onResend}
                size="subhead1"
                variant="link"
                w="initial"
              >
                {t('resendCode')}
              </Button>
            ) : (
              <Text mt={1} whiteSpace="pre-wrap">
                {t('resendCodeSuccess', { seconds: timeLeftToSendCode })}
              </Text>
            )}
            {codeResponse === CodeResponse.ERROR && (
              <Text color="red" mt={1} whiteSpace="pre-wrap">
                {t('resendCodeFailure')}
              </Text>
            )}
          </Stack>
        </Stack>
        <Button isDisabled={isDisabled} isLoading={isSubmitting} type="submit">
          {submit}
        </Button>
      </AuthBox>
    </Stack>
  );
};

export default memo(Verification);
