import { FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { GraphQLError } from 'graphql';
import { find, includes, mergeAll } from 'ramda';

export const validateGraphQLErrors = <T extends FormikValues>(
  helpers: FormikHelpers<T>,
  fields: {
    name: keyof T;
    message: string;
    match: string;
  }[],
  errors: readonly GraphQLError[],
) => {
  const validations: FormikErrors<T> = mergeAll(
    fields
      .filter((field) =>
        find((error) => includes(field.match, error.message), errors),
      )
      .map(
        (field) =>
          ({
            [field.name]: field.message,
          } as FormikErrors<T>),
      ),
  );
  helpers.setErrors(validations);
};
