import {
  Box,
  Heading,
  Interpolation,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, HeaderGroup, Row, useTable } from 'react-table';
import { Invoice } from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { formatPrice, formatTimestamp } from '../utils/formats';
import BillingTableStatusCell from './BillingTableStatusCell';
import EmptyState from './EmptyState';

type Props = {
  invoices: Invoice[];
};

const TABLE_CSS: Interpolation<{}> = {
  tableLayout: 'fixed',
};

const BillingTable: React.FC<Props> = ({ invoices }) => {
  const { t } = useTranslation(LocalesKeys.Billing);

  const columns = useMemo<Column<Invoice>[]>(
    () => [
      {
        accessor: 'date',
        Cell: ({ value }) => <Text size="body2">{formatTimestamp(value)}</Text>,
        Header: () => (
          <Heading size="headline4" textAlign="left">
            {t('date')}
          </Heading>
        ),
      },
      {
        accessor: 'description',
        Cell: ({ value }) => <Text size="body2">{value}</Text>,
        Header: () => <Heading size="headline4">{t('description')}</Heading>,
      },
      {
        accessor: 'total',
        Cell: ({ value }) => <Text size="body2">{formatPrice(value)}</Text>,
        Header: () => <Heading size="headline4">{t('amount')}</Heading>,
      },
      {
        accessor: 'status',
        Cell: ({ value, row }) => (
          <BillingTableStatusCell invoiceId={row.original.id} value={value} />
        ),
        Header: () => <Heading size="headline4">{t('status')}</Heading>,
      },
    ],
    [t],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: invoices });

  const renderHeaderColumn = useCallback<
    (column: HeaderGroup<Invoice>) => JSX.Element
  >(
    (column) => <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>,
    [],
  );

  const renderTableHeader = useCallback<
    (headerGroup: HeaderGroup<Invoice>) => JSX.Element
  >(
    (headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(renderHeaderColumn)}
      </Tr>
    ),
    [renderHeaderColumn],
  );

  const TableHeaders = useMemo(
    () => headerGroups.map(renderTableHeader),
    [headerGroups, renderTableHeader],
  );

  const renderTableCell = useCallback<(cell: Cell<Invoice>) => JSX.Element>(
    (cell) => <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>,
    [],
  );

  const renderTableRow = useCallback<(row: Row<Invoice>) => JSX.Element>(
    (row) => {
      prepareRow(row);
      return <Tr {...row.getRowProps()}>{row.cells.map(renderTableCell)}</Tr>;
    },
    [prepareRow, renderTableCell],
  );

  const TableRows = useMemo(
    () =>
      rows.length ? (
        rows.map(renderTableRow)
      ) : (
        <Tr>
          <Td colSpan={4}>
            <EmptyState headline={t('invoicesEmptyState')} />
          </Td>
        </Tr>
      ),
    [renderTableRow, rows, t],
  );

  return (
    <Box border="1px solid" borderColor="black.200" borderRadius="lg" w="full">
      <Table
        colorScheme="black"
        css={TABLE_CSS}
        variant="simple"
        {...getTableProps()}
      >
        <Thead>{TableHeaders}</Thead>
        <Tbody {...getTableBodyProps()}>{TableRows}</Tbody>
      </Table>
    </Box>
  );
};

export default BillingTable;
