import { Image, useImage } from '@chakra-ui/react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import React, { memo } from 'react';

interface Props {
  image: string;
}

const divStyle = { height: '100%', width: 'fit-content' };
const transition = { duration: 0.7 };
const animation: Variants = {
  animate: { opacity: 1, width: 'fit-content' },
  exit: { opacity: 0, width: 0 },
  initial: { opacity: 0, width: 0 },
};

const CreateTeamImage: React.FC<Props> = ({ image }) => {
  const status = useImage({ src: image });

  return (
    <AnimatePresence>
      {status === 'loaded' ? (
        <motion.div
          animate="animate"
          exit="exit"
          initial="initial"
          style={divStyle}
          transition={transition}
          variants={animation}
        >
          <Image
            borderRightRadius={8}
            h="100%"
            objectFit="cover"
            src={image}
            w="100%"
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default memo(CreateTeamImage);
