import React, { memo, useCallback } from 'react';
import { Heading, MenuItem, Text, VStack, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AvailableTeamFragment } from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import { LocalesKeys } from '../locales/localesKeys';

type Props = {
  switchTeam: (pickedTeam: AvailableTeamFragment) => void;
  teamOption: AvailableTeamFragment;
};

const Switch: React.FC<Props> = ({ teamOption, switchTeam }) => {
  const { team } = useTeamContext();

  const { t } = useTranslation(LocalesKeys.TeamMenu);

  const onClick = useCallback(() => {
    switchTeam(teamOption);
  }, [switchTeam, teamOption]);

  return (
    <MenuItem
      key={teamOption.id}
      bg={teamOption.id === team?.id ? 'black.200' : 'white'}
      onClick={onClick}
      overflow='scroll'
    >
      <VStack align="flex-start">
        <Heading color="black.500" size="headline3">
          {teamOption.name}
        </Heading>
        <Text size="caption2">
          {teamOption.domain}
          <chakra.span color="black.300">{t('hostnameRest')}</chakra.span>
        </Text>
      </VStack>
    </MenuItem>
  );
};

export default memo(Switch);
