import * as R from 'ramda';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  InputMaybe,
  Project_User_Role_Enum,
  Project_User_Set_Input,
  useDeleteProjectUsersMutation,
  useProjectDashboardByIdQuery,
  useProjectTeamQuery,
  useProject_OneSubscription,
  useUpdateProjectUserMutation,
} from '../generated/graphql';
import { LocalesKeys } from '../locales/localesKeys';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import { useCustomToast } from './useCustomToast';
import useUserEmail from './useUserEmail';

const useARDetails = (onClose?: () => void) => {
  const { t } = useTranslation(LocalesKeys.ARDetails);

  const { id } = useParams();

  const [deleteUserFromProject] = useDeleteProjectUsersMutation();

  const arId = id || '';

  const email = useUserEmail();

  const { data: teamMembersData, updateQuery } = useProjectTeamQuery({
    fetchPolicy: 'cache-first',
    variables: {
      arId,
    },
  });

  useProject_OneSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const project = subscriptionData.data?.project;
      if (!project) {
        return;
      }
      updateQuery((prev) => ({
        // eslint-disable-next-line no-underscore-dangle
        __typename: prev.__typename,
        project,
      }));
    },
    variables: {
      arId,
    },
  });

  const { user } = useUserContext();

  const { team } = useTeamContext();

  const { warningToast } = useCustomToast();

  const { data: project, loading } = useProjectDashboardByIdQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data.project_dashboard[0]) {
        warningToast({
          headerMessage: t('error'),
          toastMessage: t('errorTitle'),
        });
        onClose?.();
      }
    },
    variables: { arId },
  });

  const userId = user?.id;

  const [updateStatus] = useUpdateProjectUserMutation();

  const onDeleteProjectUser = useCallback(
    async (user_id: InputMaybe<string>, project_id: InputMaybe<string>) => {
      await deleteUserFromProject({
        variables: {
          where: { project_id: { _eq: project_id }, user_id: { _eq: user_id } },
        },
      });
    },
    [deleteUserFromProject],
  );

  const updateProjectUser = useCallback(
    async (data: Project_User_Set_Input) =>
      updateStatus({
        variables: {
          data,
          where: {
            project_id: { _eq: arId },
            user_id: { _eq: userId },
          },
        },
      }),
    [arId, updateStatus, userId],
  );

  const pickedAR = useMemo(
    () => project?.project_dashboard[0],
    [project?.project_dashboard],
  );

  const isApprover = pickedAR?.user_role === Project_User_Role_Enum.Approver;

  const isDriver = pickedAR?.driver?.email === email;

  const name = pickedAR?.name;

  const projectStatus = pickedAR?.status;

  const deadline = pickedAR?.deadline?.split('-').reverse().join('.');

  const teamMembers = teamMembersData?.project[0]?.project_users;

  const teamUsers = useMemo(() => team?.team_users || [], [team?.team_users]);

  const currentProjectUser = useMemo(
    () => teamMembers?.find((member) => member.user?.id === userId),
    [teamMembers, userId],
  );

  const status = currentProjectUser?.status;
  const note = currentProjectUser?.note;

  const sortedTeamMembers = useMemo(
    () =>
      teamMembers
        ? [...teamMembers]
            .sort((user) =>
              user.role === Project_User_Role_Enum.Driver ? -1 : 1,
            )
            .map((user) =>
              R.assocPath(
                ['user', 'isUserInTeam'],
                teamUsers.some(
                  (teamUser) => user.user.email === teamUser.user.email,
                ),
                user,
              ),
            )
        : [],
    [teamMembers, teamUsers],
  );

  const isUserInTeam = !!teamMembers?.some(
    (member) => member.user.email === email,
  );

  const createdAt = moment(pickedAR?.created_at).format('DD.MM.YYYY');

  const externalName = pickedAR?.external_name;

  const externalLink = pickedAR?.external_link;

  return {
    arId,
    createdAt,
    deadline,
    externalLink,
    externalName,
    isApprover,
    isDriver,
    isUserInTeam,
    loading,
    name,
    note,
    onDeleteProjectUser,
    pickedAR,
    projectStatus,
    status,
    teamMembers: sortedTeamMembers || [],
    updateProjectUser,
    user,
    userId,
  };
};

export default useARDetails;
