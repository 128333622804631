import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import useUserAttributes from '../hooks/useUserAttributes';
import { LocalesKeys } from '../locales/localesKeys';
import { FormSubmit } from '../types/formik';
import {
  NewPasswordValues,
  newPasswordValidationSchema,
} from '../utils/formValidations';
import Switch from './Switch';
import TextInput from './TextInput';
import { useCustomToast } from '../hooks/useCustomToast';
import useAppAuth from '../hooks/useAppAuth';

const SecuritySettings: React.FC = () => {
  const { t } = useTranslation(LocalesKeys.SecuritySettings);
  const { updateOTP, changePassword } = useAppAuth();

  const { successToast, warningToast } = useCustomToast();

  const textInputBoxProps = useMemo(
    () => ({
      pb: 6,
      w: 'full',
    }),
    [],
  );

  const initialValues = useMemo<NewPasswordValues>(
    () => ({
      newPassword: '',
      oldPassword: '',
    }),
    [],
  );

  const onSubmit = useCallback<FormSubmit<NewPasswordValues>>(
    async ({ oldPassword, newPassword }) => {
      if (oldPassword && newPassword) {
        const result = await changePassword(oldPassword, newPassword);

        if (result) {
          successToast({
            headerMessage: t('changedPasswordSuccessTitle'),
            toastMessage: t('changedPasswordSuccessDescription'),
          });
        } else {
          warningToast({
            headerMessage: t('changedPasswordFailureTitle'),
            toastMessage: t('changedPasswordFailureUnknown'),
          });
        }
        return;
      }

      warningToast({
        headerMessage: t('changedPasswordFailureTitle'),
        toastMessage: t('changedPasswordFailureNoPassword'),
      });
    },
    [changePassword, successToast, t, warningToast],
  );

  const attributes = useUserAttributes();

  const [otpEnabled, setOtpEnabled] = useState(!!attributes.otpEnabled);

  const toggleOTP = useCallback(() => {
    setOtpEnabled(R.not);
  }, []);

  const submitOTP = useCallback(async () => {
    const response = await updateOTP(otpEnabled);

    if (response instanceof Error) {
      warningToast({
        headerMessage: t('securityUpdateFailureTitle'),
        toastMessage: t('securityUpdateFailureDescription'),
      });
    } else {
      successToast({
        headerMessage: t('securityUpdateSuccessTitle'),
        toastMessage: t('securityUpdateSuccessDescription'),
      });
    }
  }, [updateOTP, otpEnabled, warningToast, t, successToast]);

  const { getFieldProps, getFieldMeta, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: newPasswordValidationSchema,
  });

  const Password = useMemo(
    () => (
      <chakra.form onSubmit={handleSubmit} w="full">
        <Heading mb={4} size="headline3">
          {t('passwordHeading')}
        </Heading>
        <TextInput
          {...getFieldMeta('oldPassword')}
          {...getFieldProps('oldPassword')}
          boxProps={textInputBoxProps}
          label={t('oldPasswordLabel')}
          placeholder={t('oldPasswordPlaceholder')}
          type="password"
        />
        <TextInput
          {...getFieldMeta('newPassword')}
          {...getFieldProps('newPassword')}
          boxProps={textInputBoxProps}
          label={t('newPasswordLabel')}
          placeholder={t('newPasswordPlaceholder')}
          type="password"
        />
        <Button colorScheme="secondary" type="submit" w="fit-content">
          {t('passwordSubmit')}
        </Button>
      </chakra.form>
    ),
    [getFieldMeta, getFieldProps, handleSubmit, t, textInputBoxProps],
  );

  const TwoFactorAuth = useMemo(
    () => (
      <>
        <Box pt={20} w="fit-content">
          <Heading mb={4} size="headline3">
            {t('2FAHeading')}
          </Heading>
          <Text color="black.600" mb={2} variant="body1">
            {t('2FADescription1')}
          </Text>
          <Text color="black.600" mb={4} variant="body1">
            {t('2FADescription2')}
          </Text>
          <Flex
            align="center"
            border="1px solid #E1E2E3"
            borderRadius="lg"
            justify="space-between"
            mb={10}
            p={3}
          >
            <Text variant="body1">{t('2FAConfirm')}</Text>
            <Switch checked={otpEnabled} onChange={toggleOTP} />
          </Flex>
        </Box>
      </>
    ),
    [t, otpEnabled, toggleOTP],
  );

  const Submit = useMemo(
    () => (
      <>
        <Flex justify="end" w="full">
          <Button onClick={submitOTP} w="fit-content">
            {t('submit')}
          </Button>
        </Flex>
      </>
    ),
    [t, submitOTP],
  );

  return (
    <VStack
      alignItems="flex-start"
      color="black.500"
      p={10}
      spacing={1}
      w="100%"
    >
      {Password}
      {TwoFactorAuth}
      {Submit}
    </VStack>
  );
};

export default memo(SecuritySettings);
