import React, { SVGProps, memo } from 'react';

const DeadlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={24} width={24} {...props}>
    <path
      clipRule="evenodd"
      d="M16.445 3a.8.8 0 1 0-1.6 0v1H9.156V3a.8.8 0 1 0-1.6 0v1H5.622C4.183 4 3 5.155 3 6.6v12.6c0 1.445 1.183 2.6 2.622 2.6h12.755c1.439 0 2.622-1.155 2.622-2.6V6.6C21 5.155 19.816 4 18.377 4h-1.932V3Zm2.954 6.4V6.6c0-.543-.448-1-1.022-1h-1.932v1a.8.8 0 1 1-1.6 0v-1H9.156v1a.8.8 0 1 1-1.6 0v-1H5.622c-.574 0-1.022.457-1.022 1v2.8h14.8ZM4.6 11h14.8v8.2c0 .543-.45 1-1.023 1H5.622a1.011 1.011 0 0 1-1.022-1V11Z"
      fill="#737477"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(DeadlineIcon);
