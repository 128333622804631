import { useCallback, useState } from 'react';
import {
  useCustomerQuery,
  useUpdateCustomerEmailMutation,
} from '../generated/graphql';
import { useTeamContext } from '../store/contexts/TeamContext';
import useUserEmail from './useUserEmail';

export const useBillingContact = (): {
  customerEmail: string;
  loading: boolean;
  last4: string | undefined;
  updateEmail: (email: string) => Promise<void>;
  customerNotSet: boolean;
} => {
  const { team } = useTeamContext();

  const { data: customerData, loading } = useCustomerQuery({
    skip: !team?.customer_id,
    variables: {
      customerId: team?.customer_id ?? '',
    },
  });

  const email = useUserEmail();

  const [newCustomerEmail, setNewCustomerEmail] = useState<
    string | undefined
  >();

  const customerEmail =
    customerData?.customer.email ?? newCustomerEmail ?? email ?? '';

  const [updateCustomerEmail] = useUpdateCustomerEmailMutation();

  const updateEmail = useCallback<(email: string) => Promise<void>>(
    async (email) => {
      if (!team?.customer_id) {
        setNewCustomerEmail(email);
        return;
      }
      await updateCustomerEmail({
        variables: {
          customerId: team.customer_id,
          email,
        },
      });
    },
    [team?.customer_id, updateCustomerEmail],
  );

  return {
    customerEmail,
    customerNotSet: !team?.customer_id,
    last4: customerData?.customer.payment_method?.last4 ?? undefined,
    loading,
    updateEmail,
  };
};
