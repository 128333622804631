import { Flex, HStack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { findLastIndex } from 'ramda';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CreateTeamButton from '../components/CreateTeamButton';
import CreateTeamImage from '../components/CreateTeamImage';
import CreateTeamStep1 from '../components/CreateTeamStep1';
import CreateTeamStep2, {
  CreateTeamStep2Values,
  createTeamStep2InitialValues,
} from '../components/CreateTeamStep2';
import CreateTeamStep3 from '../components/CreateTeamStep3';
import image1 from '../components/images/create-team-first.jpg';
import image2 from '../components/images/create-team-second.jpg';
import image3 from '../components/images/create-team-third.jpg';
import { useUpdateUserMutation } from '../generated/graphql';
import { useCreateTeam } from '../hooks/useCreateTeam';
import { LocalesKeys } from '../locales/localesKeys';
import { RouteModalComponent } from '../navigation/Modals';
import routes from '../navigation/routes';
import { useTeamContext } from '../store/contexts/TeamContext';
import { useUserContext } from '../store/contexts/UserContext';
import { FormSubmit } from '../types/formik';

export enum TeamRoutes {
  Personalization = '',
  CreateTeam = 'team',
  CreateFirstProject = 'ar',
  createAR = 'ar/create',
}

type RuleType = { isFull: boolean; isOnTeam: boolean };

interface TeamStep {
  route: TeamRoutes;
  screen: JSX.Element;
  image: string;
  skipable: boolean;
  rule({ isFull, isOnTeam }: RuleType): boolean;
}

export const ANIMATIONS = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
};

export const steps: TeamStep[] = [
  {
    image: image1,
    route: TeamRoutes.Personalization,
    rule: ({ isFull }) => isFull,
    screen: <CreateTeamStep1 />,
    skipable: true,
  },
  {
    image: image2,
    route: TeamRoutes.CreateTeam,
    rule: ({ isOnTeam }) => !isOnTeam,
    screen: <CreateTeamStep2 />,
    skipable: false,
  },
  {
    image: image3,
    route: TeamRoutes.CreateFirstProject,
    rule: () => true,
    screen: <CreateTeamStep3 />,
    skipable: false,
  },
];

const FORM_STYLE: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
};

const CreateTeam: RouteModalComponent = () => {
  const { t } = useTranslation(LocalesKeys.CreateTeam);

  const { generateUrl } = useTeamContext();

  const { user } = useUserContext();

  const [isFullPersonalization] = useState(!user?.personalization_passed);
  const [isUserAlreadyInTeam] = useState(
    !window.location.href.startsWith(generateUrl()),
  );

  const userEmail = user?.email || '';

  const createTeamSteps = useMemo(
    () =>
      steps.filter((step) =>
        step.rule({
          isFull: isFullPersonalization,
          isOnTeam: isUserAlreadyInTeam,
        }),
      ),
    [isFullPersonalization, isUserAlreadyInTeam],
  );

  const firstStep = createTeamSteps[0];

  const navigate = useNavigate();

  const location = useLocation();

  const currentStep = findLastIndex(
    (step) => location.pathname.endsWith(step.route.toString()),
    createTeamSteps,
  );

  const currentStepRoute = createTeamSteps[currentStep]?.route || '';

  const isLastStep = currentStep === createTeamSteps.length - 1;

  const [setPersonalizationPassed] = useUpdateUserMutation({
    variables: { _set: { personalization_passed: true }, email: userEmail },
  });

  const handleSkip = useCallback(async () => {
    if (isLastStep) {
      await setPersonalizationPassed();
      navigate(`/${routes.ARRoutes.ARCreate}`);
      return;
    }
    navigate(
      createTeamSteps[currentStep + 1]?.route ?? TeamRoutes.CreateFirstProject,
    );
  }, [
    createTeamSteps,
    currentStep,
    isLastStep,
    navigate,
    setPersonalizationPassed,
  ]);

  const { onCreateTeam } = useCreateTeam();

  const onSubmit = useCallback<FormSubmit<CreateTeamStep2Values>>(
    async (values, helpers) => {
      if (currentStepRoute === TeamRoutes.CreateTeam) {
        const team = await onCreateTeam(values, helpers);

        if (team) {
          return handleSkip();
        }

        return team;
      }

      return handleSkip();
    },
    [currentStepRoute, handleSkip, onCreateTeam],
  );

  const ContentRoute = useMemo(
    () =>
      createTeamSteps.map((step, index) => (
        <Route
          key={step.route}
          element={step.screen}
          index={index === 0}
          path={step.route.toString()}
        />
      )),
    [createTeamSteps],
  );

  const ImageRoute = useMemo(
    () =>
      createTeamSteps.map((step, index) => (
        <Route
          key={step.route}
          element={<CreateTeamImage image={step.image} />}
          index={index === 0}
          path={step.route.toString()}
        />
      )),
    [createTeamSteps],
  );

  const Content = useMemo(
    () => (
      <HStack spacing={0}>
        <Flex direction="column" flex={1} minH="full" padding={10}>
          <Flex justifyContent="space-between">
            <Text color="black.500">
              {t('step', {
                current: (currentStep + 1).toString(),
                max: createTeamSteps.length.toString(),
              })}
            </Text>
            {createTeamSteps[currentStep]?.skipable && (
              <Text color="primary.700" cursor="pointer" onClick={handleSkip}>
                {t('skip')}
              </Text>
            )}
          </Flex>
          <Formik
            initialValues={createTeamStep2InitialValues}
            onSubmit={onSubmit}
          >
            <Form style={FORM_STYLE}>
              <Routes key={location.pathname}>{ContentRoute}</Routes>
              <Flex
                alignSelf="center"
                direction="column"
                flex={1}
                justifyContent="flex-end"
                w="full"
              >
                <CreateTeamButton
                  currentStepRoute={currentStepRoute}
                  isLastStep={isLastStep}
                  stepsCount={createTeamSteps.length}
                />
              </Flex>
            </Form>
          </Formik>
        </Flex>
        <Routes key={location.pathname}>{ImageRoute}</Routes>
      </HStack>
    ),
    [
      t,
      currentStep,
      createTeamSteps,
      handleSkip,
      onSubmit,
      location.pathname,
      ContentRoute,
      currentStepRoute,
      isLastStep,
      ImageRoute,
    ],
  );

  useEffect(() => {
    if (window.location.href.endsWith(routes.ModalRoute.Personalization)) {
      navigate(firstStep?.route || '');
    }

    return () => {
      setPersonalizationPassed();
    };
  }, []);

  return Content;
};

export default memo(CreateTeam);
