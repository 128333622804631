import React, { PropsWithChildren, memo, useMemo } from 'react';
import {
  Button,
  CheckboxProps,
  Input,
  chakra,
  useCheckbox,
} from '@chakra-ui/react';

const BUTTON_CHECKED = {
  bg: 'black.500',
  color: 'white',
};

const DayRadio: React.FC<PropsWithChildren<CheckboxProps>> = ({
  children,
  ...props
}) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = useMemo(() => getInputProps(), [getInputProps]);
  const checkbox = useMemo(() => getCheckboxProps(), [getCheckboxProps]);

  return (
    <chakra.label cursor="pointer">
      <Input display="none" type="radio" {...input} />
      <Button
        _checked={BUTTON_CHECKED}
        as="div"
        colorScheme="secondary"
        pointerEvents="none"
        w={16}
        {...checkbox}
      >
        <>{children}</>
      </Button>
    </chakra.label>
  );
};

export default memo(DayRadio);
