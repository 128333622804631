import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  IndexRange,
  InfiniteLoader,
  InfiniteLoaderChildProps,
  List,
  ListRowRenderer,
} from 'react-virtualized';
import { Box, Flex, VStack } from '@chakra-ui/react';
import NotificationItem from './NotificationItem';
import {
  NotificationFragment,
  useNotificationsSubscription,
} from '../generated/graphql';

type Props = {
  notifications: NotificationFragment[];
  onLoadMore: (params: IndexRange) => Promise<any>;
  totalRows: number;
};

const NotificationsList: React.FC<Props> = ({
  notifications,
  onLoadMore,
  totalRows,
}) => {
  const isRowLoaded = useCallback(
    ({ index }) => !!notifications[index],
    [notifications],
  );

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
      }),
    [],
  );

  const [baseDate, setBaseDate] = useState(new Date());

  useNotificationsSubscription({
    onSubscriptionData: () => {
      cache.clearAll();
      setBaseDate(new Date());
    },
    variables: {
      where: {
        notification: {
          created_at: {
            _gt: baseDate,
          },
        },
      },
    },
  });

  const renderItem: ListRowRenderer = useCallback(
    ({ index, key, style, parent }) => {
      const item = notifications[index];
      return (
        item && (
          <CellMeasurer
            key={key}
            cache={cache}
            columnIndex={0}
            parent={parent}
            rowIndex={index}
          >
            <VStack px={1} style={style}>
              <NotificationItem item={item} />
              {index !== totalRows ? (
                <Box bg="black.200" h="1px" w="full" />
              ) : null}
            </VStack>
          </CellMeasurer>
        )
      );
    },
    [cache, notifications, totalRows],
  );

  const renderInfiniteList = useCallback<
    (props: InfiniteLoaderChildProps) => JSX.Element
  >(
    ({ onRowsRendered, registerChild }) => (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={registerChild}
            deferredMeasurementCache={cache}
            height={height}
            onRowsRendered={onRowsRendered}
            rowCount={totalRows}
            rowHeight={cache.rowHeight}
            rowRenderer={renderItem}
            width={width}
          />
        )}
      </AutoSizer>
    ),
    [cache, renderItem, totalRows],
  );

  return (
    <Flex flex={1}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={onLoadMore}
        rowCount={totalRows}
        threshold={8}
      >
        {renderInfiniteList}
      </InfiniteLoader>
    </Flex>
  );
};

export default memo(NotificationsList);
