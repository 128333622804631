import React, { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const PlusIcon: React.FC<IconProps> = (props) => (
  <Icon fill="none" h={6} w={6} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M12.8 5a.8.8 0 0 0-1.6 0v6.2H5a.8.8 0 0 0 0 1.6h6.2V19a.8.8 0 0 0 1.6 0v-6.2H19a.8.8 0 0 0 0-1.6h-6.2V5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);

export default memo(PlusIcon);
