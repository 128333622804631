import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { IconClass, classSelector } from '../utils';

const baseStyleList: SystemStyleFunction = (props) => ({
  bg: mode('#fff', 'gray.700')(props),
  border: '1px solid #E1E2E3',
  borderRadius: 'lg',
  boxShadow: '0px 6px 20px rgba(22, 24, 28, 0.1)',
  color: 'inherit',
  maxW: '2xs',
  py: '2',
  zIndex: 1,
});

const baseStyleItem: SystemStyleObject = {
  _active: {
    bg: 'black.200',
  },
  _disabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  _expanded: {
    bg: 'black.100',
  },
  _focus: {
    bg: 'black.100',
  },
  px: '0.8rem',
  py: '0.4rem',
  transitionDuration: 'ultra-fast',
  transitionProperty: 'background',
  transitionTimingFunction: 'ease-in',
};

const baseStyleGroupTitle: SystemStyleObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  mx: 4,
  my: 2,
};

const baseStyleCommand: SystemStyleObject = {
  opacity: 0.6,
};

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '0.5rem',
  opacity: 0.6,
};

const baseStyleButton: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  const hoverColor = `${c}.600`;
  const activeColor = `black.600`;

  return {
    _active: {
      [classSelector(IconClass.CustomFill)]: {
        path: { fill: activeColor },
      },
      [classSelector(IconClass.CustomStroke)]: {
        path: { stroke: activeColor },
      },
    },
    _hover: {
      [classSelector(IconClass.CustomFill)]: {
        path: { fill: hoverColor },
      },
      [classSelector(IconClass.CustomStroke)]: {
        path: { stroke: hoverColor },
      },
    },
    transitionDuration: 'normal',
    transitionProperty: 'common',
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  button: baseStyleButton(props),
  command: baseStyleCommand,
  divider: baseStyleDivider,
  groupTitle: baseStyleGroupTitle,
  item: baseStyleItem,
  list: baseStyleList(props),
});

const defaultProps = {
  colorScheme: 'primary',
};

export default {
  baseStyle,
  defaultProps,
  parts: parts.keys,
};
