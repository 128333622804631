import React, { memo, useCallback } from 'react';
import {
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuProps,
  Text,
} from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/theme-tools';
import ChevronUpIcon from './icons/ChevronUpIcon';
import ChevronDownIcon from './icons/ChevronDownIcon';
import { IconClass } from '../theme/utils';
import { RenderMenuContent } from './types';
import { objOrEmpty } from '../utils/common';
import Avatar from './Avatar';

interface Props extends Omit<MenuProps, 'children'> {
  options: AccountMenuOption[];
  image?: string;
  username?: string;
  userId?: string;
}

export interface AccountMenuOption {
  divider?: boolean;
  icon?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  truncate?: boolean;
}

const TRUNCATED_TEXT_STYLE: SystemStyleObject = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const CHEVRON_UP_ICON = <ChevronUpIcon className={IconClass.CustomStroke} />;
const CHEVRON_DOWN_ICON = (
  <ChevronDownIcon className={IconClass.CustomStroke} />
);

const AccountMenu: React.FC<Props> = ({ options, image, username, userId }) => {
  const renderOption = useCallback<(option: AccountMenuOption) => JSX.Element>(
    ({ divider, icon: Icon, onClick, title, truncate }) => {
      const textStyle = objOrEmpty(TRUNCATED_TEXT_STYLE, truncate);

      return (
        <React.Fragment key={title}>
          <MenuItem onClick={onClick} px={4} py={3}>
            <HStack minW={0} spacing={2}>
              {Icon}
              <Text color="black.500" sx={textStyle}>
                {title}
              </Text>
            </HStack>
          </MenuItem>
          {divider ? <MenuDivider m={0} /> : null}
        </React.Fragment>
      );
    },
    [],
  );

  const renderButtonAndOptions = useCallback<RenderMenuContent>(
    ({ isOpen }) => (
      <>
        <MenuButton spacing={2}>
          <HStack>
            <Avatar h={11} name={username} src={image} userId={userId} w={11} />
            {isOpen ? CHEVRON_UP_ICON : CHEVRON_DOWN_ICON}
          </HStack>
        </MenuButton>
        <MenuList overflow="hidden" p={0}>
          {options.map(renderOption)}
        </MenuList>
      </>
    ),
    [image, options, renderOption, userId, username],
  );

  return <Menu>{renderButtonAndOptions}</Menu>;
};

export default memo(AccountMenu);
